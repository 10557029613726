import React from "react";
import { IAudioModel } from "shared-types";
import { AudioTrack } from "../AudioTrack";

import "./AudioTrackList.scss";

export interface IAudioTrackListProps {
  tracks: IAudioModel[];
  currentTrack: IAudioModel;
  selectTrack: (track: IAudioModel) => void;
}

export const AudioTrackList: React.FC<IAudioTrackListProps> = ({
  tracks,
  currentTrack,
  selectTrack,
}) => {
  return (
    <ul className="c-audio-track-list">
      {tracks.map((track) => (
        <AudioTrack
          key={track.id}
          selectTrack={selectTrack}
          track={track}
          isActive={track.id === currentTrack.id}
        />
      ))}
    </ul>
  );
};
