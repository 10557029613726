import React, { FC, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Spinner } from "../Spinner/Spinner";

interface Props {
  isOpen?: boolean;
}

const portalContainer = document.querySelector("body");

const SplashSpinner: FC<Props> = ({ isOpen }) => {
  const element = useRef(document.createElement("div"));

  useEffect(() => {
    (portalContainer as HTMLBodyElement).appendChild(element.current);

    return () => {
      (portalContainer as HTMLBodyElement).removeChild(element.current);
    };
  }, []);

  return createPortal(
    isOpen && (
      <div className="fixed top-0 left-0 w-[100vw] h-[100vh] z-50 bg-[rgb(255,255,255,0.5)]">
        <Spinner />
      </div>
    ),
    element.current
  );
};

export default SplashSpinner;
