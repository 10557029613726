const buildMaxErrorMessage = (
  text: string,
  fieldName: string,
  length: number
) => {
  const field = fieldName.replace(":", "");
  return text
    .replace("{fieldName}", field)
    .replace("{length}", length.toString());
};

export default buildMaxErrorMessage;
