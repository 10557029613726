import React from "react";
import cx from "classnames";

import { Select } from "../../Deprecated/Select/Select";
import { IBaseInputProps } from "../../Atoms/InputsCommon/BaseInputProps";
import { RadioList } from "../RadioList/RadioList";
import { IOption } from "../../../generics/interfaces";

import "./LocalizationDialog.scss";

export interface ILocalizationDialogLabels {
  /** Text for location selection label */
  location: string;

  /** Text for language selection label */
  language: string;
}

export interface ILocationLanguageMapping {
  /** Location */
  location: IOption<string>;

  /** List if allowed Languages for the location */
  languages: Array<IOption<string>>;
}

export interface ILocalizationDialogProps<T>
  extends IBaseInputProps<HTMLInputElement, T> {
  /** Selected option for location */
  selectedLocation: string;

  /** Selected option for language */
  selectedLanguage: string;

  /** Contains labels for localization groups */
  labels: ILocalizationDialogLabels;

  /** Option list for available locations */
  locationOptions: Array<IOption<string>>;

  /** Option list for available languages */
  languageOptions: Array<IOption<string>>;

  /** Optional css class for wrapper */
  className?: string;

  /** Optional prop for disabling/enabling radiolist */
  isLanguageDisabled?: boolean;

  /** onChange event for language */
  languageChanged: (selectedLanguage: string) => void;

  /** onChange event for location */
  locationChanged: (selectedLocation: string) => void;

  /** Optional prop for defining auto language change based on selected location */
  locationLanguagesMapping?: ILocationLanguageMapping[];
}

interface ILocalizationDialogState {
  location: string;
  language: string;
}

export class LocalizationDialog<T> extends React.Component<
  ILocalizationDialogProps<T>,
  ILocalizationDialogState
> {
  constructor(props: ILocalizationDialogProps<T>) {
    super(props);

    const { selectedLanguage, selectedLocation } = this.props;
    this.state = {
      location: selectedLocation,
      language: this.getLanguageOrDefaultByLocation(
        selectedLocation,
        selectedLanguage
      ),
    };
  }

  public render() {
    const {
      className,
      labels,
      locationOptions,
      languageOptions,
      isLanguageDisabled,
      locationLanguagesMapping,
    } = this.props;

    const disabled =
      (locationLanguagesMapping && locationLanguagesMapping.length > 0) ||
      isLanguageDisabled;
    return (
      <div className={cx("c-localization-dialog", className)}>
        <div className="c-localization-dialog__location">
          <Select
            placeholder={this.state.location}
            formatName={this.formatName}
            options={locationOptions}
            onChange={this.onLocationChanged}
            label={labels.location}
            value={this.state.location}
          />
        </div>
        <div className="c-localization-dialog__language-title">
          {labels.language}
        </div>
        <div className="c-localization-dialog__languages">
          <RadioList
            name={"languageRadioList"}
            value={this.state.language}
            options={languageOptions}
            valueChanged={this.onLanguageChanged}
            vertical={true}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  private getLanguageOrDefaultByLocation = (
    location: string,
    defaultLanguage: string = this.props.selectedLanguage
  ) => {
    const { locationLanguagesMapping } = this.props;
    const locationLanguage =
      locationLanguagesMapping &&
      locationLanguagesMapping.find((item) => item.location.value === location);
    return locationLanguage
      ? locationLanguage.languages[0].value
      : defaultLanguage;
  };

  private onLocationChanged = (option: IOption<string>) => {
    this.setState(
      {
        location: option.value,
      },
      () => {
        this.props.locationChanged(option.value);
        const language = this.getLanguageOrDefaultByLocation(
          option.value,
          this.state.language
        );
        this.onLanguageChanged(language);
      }
    );
  };

  private onLanguageChanged = (value: string) => {
    this.setState({ language: value }, () => this.props.languageChanged(value));
  };

  private formatName = (option: IOption<string>) => {
    return (
      <div className="c-localization-dialog__item">
        <div
          className={cx(
            "c-localization-dialog__flag",
            `flag-${(option.value || "").toLowerCase()}`
          )}
        />
        <div className="c-localization-dialog__name">{option.name}</div>
      </div>
    );
  };
}
