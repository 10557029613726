import React, { FC, useState } from "react";
import Section from "../../Section";
import { ISubscription, SelectPlan } from "../../../types";
import Subscription from "../../Subscription";

interface Props {
  title: string;
  subscriptions: ISubscription[];
  plan?: ISubscription;
  handlePlanSelect: SelectPlan;
}

const SubscriptionBlock: FC<Props> = ({
  title,
  subscriptions,
  plan,
  handlePlanSelect,
}) => {
  const [isMoreInfoCollapsed, setIsMoreInfoCollapsed] = useState("");

  const handleShowMorePlanInfoOnMobile = (planId: ISubscription["id"]) =>
    setIsMoreInfoCollapsed(planId);

  // TODO must be removed SL-4845
  const isPromo = subscriptions.some((sub) => sub.code.includes("premium"));

  return (
    <Section title={title} isOpen>
      <div className="checkout-single-page__subscription">
        {subscriptions.map((sub) => {
          return (
            <Subscription
              key={sub.id}
              plan={sub}
              isSelected={plan?.id === sub.id}
              isShaded={!!plan}
              onSelect={handlePlanSelect}
              onShowMorePlanInfoOnMobile={handleShowMorePlanInfoOnMobile}
              showMoreCardId={isMoreInfoCollapsed}
              isPromo={isPromo}
            />
          );
        })}
      </div>
    </Section>
  );
};

export default SubscriptionBlock;
