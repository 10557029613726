// @ts-ignore
import { Action, Location } from "history";
import React from "react";

import { ITrackableChange } from "services/changeTrackingRegistryService";
import { IRouter } from "services/navigator";

export default function withUnsavedChangesNavigationPrevention(
  window: {
    onbeforeunload:
      | ((this: WindowEventHandlers, ev: BeforeUnloadEvent) => any)
      | null;
  },
  changeTrackableGetter: () => ITrackableChange | undefined
) {
  return function withNavigationPreventionHocFactory<
    OriginalProps extends IRouter
  >(
    WrappedComponent: React.ComponentType<OriginalProps>
  ): typeof React.PureComponent {
    return class extends React.PureComponent<OriginalProps> {
      private unblock?: () => void;

      public componentDidMount() {
        window.onbeforeunload = this.blockOnBeforeUnload;
        // noinspection JSPotentiallyInvalidUsageOfThis
        this.unblock = this.props.history.block(this.blockHistoryChange);
      }

      public componentWillUnmount() {
        window.onbeforeunload = null;

        if (this.unblock) {
          this.unblock();
        }
      }

      public render() {
        // noinspection JSPotentiallyInvalidUsageOfThis
        return <WrappedComponent {...this.props} />;
      }

      private blockOnBeforeUnload = (event: BeforeUnloadEvent) => {
        const blockingMessage = this.getBlockNavigationMessage();
        if (blockingMessage) {
          event.preventDefault();
          event.returnValue = blockingMessage; // blocks with a custom message if supported
          return blockingMessage; // also blocks with a custom message if supported
        } else {
          return null; // do not block navigation
        }
      };

      private blockHistoryChange = (location: Location, _: Action) => {
        const pathChanged = this.props.location.pathname !== location.pathname;
        const blockingMessage = this.getBlockNavigationMessage();
        if (pathChanged && blockingMessage) {
          return blockingMessage; // blocks with a custom message if supported
        } else {
          return; // do not block navigation
        }
      };

      private getBlockNavigationMessage: () => string | null = () => {
        const navigationPrevention = changeTrackableGetter();
        if (navigationPrevention?.isUnsaved()) {
          return navigationPrevention.unsavedChangesMessage();
        }
        return null;
      };
    };
  };
}
