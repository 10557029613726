import { generatePath } from "react-router-dom";
import generatePathWithParams from "helpers/generatePathWithParams";
import prefixPathWithBasePath from "helpers/prefixPathWithBasePath";

export enum CheckoutType {
  renewal = "renewal",
  reactivation = "reactivation",
  graduate = "graduate",
}

export const isAcceptedCheckoutType = (value: any): value is CheckoutType => {
  return Object.values(CheckoutType).includes(value);
};

export class ApplicationMap {
  readonly urls = {
    index: "/",
    checkout: "/checkout/:type",
    checkoutComplete: "/checkout/:type/:userType/complete",
    accountSettings: "/account-settings",
    settingsMyDetails: "/account-settings/my-details",
    settingsMyLoginDetails: "/account-settings/my-login-details",
    settingsMySubscription: "/account-settings/my-subscription",
    settingsMySubscriptionChange: "/account-settings/my-subscription/change",
    graduatesTransferLogin: "/graduate-transfer",
    graduatesTransferInformation: "/graduate-transfer/information",
  };

  public routes = {
    index: (): string => prefixPathWithBasePath(this.urls.index),
    checkout: (params?: { type: string }): string =>
      prefixPathWithBasePath(
        params ? generatePath(this.urls.checkout, params) : this.urls.checkout
      ),
    checkoutComplete: (params?: { type: string; userType?: string }): string =>
      prefixPathWithBasePath(
        params
          ? generatePath(this.urls.checkoutComplete, params)
          : this.urls.checkoutComplete
      ),
    accountSettings: (): string =>
      prefixPathWithBasePath(this.urls.accountSettings),
    settingsMyDetails: (): string =>
      prefixPathWithBasePath(this.urls.settingsMyDetails),
    settingsMyLoginDetails: (): string =>
      prefixPathWithBasePath(this.urls.settingsMyLoginDetails),
    settingsMySubscription: (): string =>
      prefixPathWithBasePath(this.urls.settingsMySubscription),
    settingsMySubscriptionChange: (): string =>
      prefixPathWithBasePath(this.urls.settingsMySubscriptionChange),
    graduatesTransferLogin: (): string =>
      prefixPathWithBasePath(this.urls.graduatesTransferLogin),
    graduatesTransferInformation: (): string =>
      prefixPathWithBasePath(this.urls.graduatesTransferInformation),
  };

  public links = {
    index: (): string => generatePathWithParams(this.urls.index),
  };
}

export default new ApplicationMap();
