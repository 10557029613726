import cx from "classnames";
import React from "react";

import "./SectionDescription.scss";

export interface ISectionDescriptionProps {
  className?: string;
}

const SectionDescription: React.FC<ISectionDescriptionProps> = (props) => (
  <div className={cx("c-section-description", props.className)}>
    {props.children}
  </div>
);

export default SectionDescription;
