import React from "react";
import { ReactComponent as SpotlightLogo } from "@assets/svg/Spotlight-Logo-tagline-white.svg";

import "./Page404.scss";
import { Button } from "../../Atoms/Button/Button";

export interface IPage404Texts {
  logoLabel: string;
  headerText: string;
  messageText: string;
  buttonText: string;
}

export interface IPage404Props {
  /** Texts for inner elements */
  texts: IPage404Texts;
  /** Link where user will be redirected to when clicking on the button or logo */
  homePageLink: string;
}

export const Page404: React.FC<IPage404Props> = ({ texts, homePageLink }) => {
  return (
    <div className="c-page-404">
      <a
        href={homePageLink}
        aria-label={texts.logoLabel}
        className="c-page-404__logo"
      >
        <SpotlightLogo />
      </a>
      <h1 className="c-page-404__header">{texts.headerText}</h1>
      <div className="c-page-404__message">{texts.messageText}</div>
      <div className="c-page-404__button-container">
        <Button
          type="primary"
          className="c-page-404__button"
          href={homePageLink}
          text={texts.buttonText}
        />
      </div>
    </div>
  );
};
