import React from "react";
import { ITabProps, Tab } from "../../Atoms/Tab/Tab";
// import TabArrow from '-!svg-react-loader?name=NoticeIcon!src/images/svg/icons/tab-arrow.svg';
import { ReactComponent as TabArrow } from "../../../images/svg/icons/tab-arrow.svg";

import "./Tabs.scss";

export interface ITabsProps {
  /**
   * List of tabs
   */
  tabs: ITabProps[];
  /**
   * Pitch rewind in px
   */
  stepLength: number;
  onClick?: () => void;
}

interface ITabsState {
  tabs: ITabProps[];
  padding: number;
  showRightArrow: boolean;
}

const ARROW_WIDTH = 22;

export class Tabs extends React.Component<ITabsProps, ITabsState> {
  private tabContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private tabSetRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  public static defaultProps = {
    stepLength: 200,
  };

  constructor(props: ITabsProps) {
    super(props);

    if (!props.tabs.some((item) => Boolean(item.isActive))) {
      props.tabs[0].isActive = true;
    }

    this.state = {
      tabs: props.tabs,
      padding: 0,
      showRightArrow: false,
    };
  }

  public render() {
    return (
      <div className="c-tabs" role="tablist">
        <div
          className={`c-tabs__arrow c-tabs__left ${
            this.state.padding === 0 ? "c-tabs__invisible" : ""
          }`}
          onClick={this.moveLeft}
          tabIndex={0}
        >
          <TabArrow />
        </div>
        <div ref={this.tabContainerRef} className="c-tabs__container">
          <div ref={this.tabSetRef} className="c-tabs__set">
            {this.getTabs()}
          </div>
        </div>
        <div
          className={`c-tabs__arrow c-tabs__right ${
            this.state.showRightArrow ? "" : "c-tabs__invisible"
          }`}
          onClick={this.moveRight}
          tabIndex={0}
        >
          <TabArrow />
        </div>
      </div>
    );
  }

  public componentDidMount() {
    this.checkArrowVisibility();
    const tabSet = this.tabSetRef.current;
    if (tabSet) {
      const selectedElement = tabSet.querySelector(".c-tab.active");
      if (selectedElement) {
        this.scrollToElement(selectedElement);
      }
    }
    window.addEventListener("resize", this.checkArrowVisibility);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.checkArrowVisibility);
  }

  public componentWillReceiveProps(newProps: ITabsProps) {
    if (newProps.tabs !== this.state.tabs) {
      if (!newProps.tabs.some((item) => Boolean(item.isActive))) {
        newProps.tabs[0].isActive = true;
      }
      this.setState({ tabs: newProps.tabs }, () => {
        const tabSet = this.tabSetRef.current;
        if (tabSet) {
          const selectedElement = tabSet.querySelector(".c-tab.active");

          if (selectedElement) {
            this.scrollToElement(selectedElement);
          }
        }
      });
    }
  }

  private getTabs(): JSX.Element[] {
    return this.state.tabs.map((tab: ITabProps, index: number) => (
      <Tab
        key={tab.text + index}
        {...tab}
        focus={this.focusItem(tab)}
        click={this.clickItem(tab)}
      />
    ));
  }

  private clickItem = (tab: ITabProps) => {
    return (event: any) => {
      this.props.onClick?.();

      this.setState((prevState) => {
        prevState.tabs.forEach((item: ITabProps) => {
          item.isActive = item === tab;
        });
        return prevState;
      });

      tab.click(event);
    };
  };

  private focusItem = (tab: ITabProps) => {
    return (event: any) => {
      event.preventDefault();

      this.scrollToElement(event.target);

      if (tab.focus) {
        tab.focus(event);
      }
    };
  };

  private checkArrowVisibility = () => {
    this.arrowVisibility(this.state.padding);
  };

  private scrollToElement = (target: any) => {
    const tabContainer = this.tabContainerRef.current;
    const tabSet = this.tabSetRef.current;

    if (tabContainer && tabSet) {
      let padding = tabContainer.scrollLeft;

      if (
        target.offsetLeft + target.offsetWidth >
        padding + tabContainer.offsetWidth - ARROW_WIDTH
      ) {
        padding =
          target.offsetLeft +
          target.offsetWidth -
          tabContainer.offsetWidth +
          2 * ARROW_WIDTH;

        if (tabSet.offsetWidth - tabContainer.offsetWidth < padding) {
          padding = tabSet.offsetWidth - tabContainer.offsetWidth;
        }
      } else if (target.offsetLeft < padding + ARROW_WIDTH && padding > 0) {
        padding =
          target.offsetLeft - 2 * ARROW_WIDTH < 0
            ? 0
            : target.offsetLeft - 2 * ARROW_WIDTH;
      }

      this.scrollTo(tabContainer, padding);
      this.setState({ padding });
      this.arrowVisibility(padding);
    }
  };

  private arrowVisibility = (padding: number) => {
    const tabContainer = this.tabContainerRef.current;
    const tabSet = this.tabSetRef.current;

    if (tabContainer && tabSet) {
      if (
        tabContainer.offsetWidth < tabSet.offsetWidth &&
        tabContainer.offsetWidth + padding < tabSet.offsetWidth
      ) {
        this.setState({ showRightArrow: true });
      } else {
        if (tabContainer.offsetWidth + padding > tabSet.offsetWidth) {
          padding = tabSet.offsetWidth - tabContainer.offsetWidth;
        }
        this.scrollTo(tabContainer, padding);
        this.setState({
          showRightArrow: false,
          padding: padding < 0 ? 0 : padding,
        });
      }
    }
  };

  private moveRight = () => {
    const tabSet = this.tabSetRef.current;
    const tabContainer = this.tabContainerRef.current;
    let padding = 0;
    if (tabContainer && tabSet) {
      if (
        this.state.padding + this.props.stepLength <
        tabSet.offsetWidth - tabContainer.offsetWidth
      ) {
        padding = this.state.padding + this.props.stepLength;
        this.setState({ padding });
        this.scrollTo(tabContainer, this.state.padding + this.props.stepLength);
      } else {
        padding = tabSet.offsetWidth - tabContainer.offsetWidth;

        this.setState({ padding, showRightArrow: false });
        this.scrollTo(
          tabContainer,
          tabSet.offsetWidth - tabContainer.offsetWidth
        );
      }
    }
    return padding;
  };

  private moveLeft = () => {
    const tabContainer = this.tabContainerRef.current;
    const padding = this.state.padding - this.props.stepLength;

    if (tabContainer) {
      if (padding < 0) {
        this.setState({ padding: 0, showRightArrow: true });
        this.scrollTo(tabContainer, 0);
      } else {
        this.setState({ padding, showRightArrow: true });
        this.scrollTo(tabContainer, this.state.padding - this.props.stepLength);
      }
    }

    return padding >= 0 ? padding : 0;
  };

  private scrollTo = (element: HTMLDivElement, to: number) => {
    const start = element.scrollLeft;
    const change = to - start;
    let currentTime = 0;
    const increment = 3;

    const animateScroll = () => {
      currentTime += increment;
      element.scrollLeft = this.easeInOutQuad(currentTime, start, change, 100);
      if (currentTime < 100) {
        requestAnimationFrame(animateScroll);
      }
    };

    animateScroll();
  };

  private easeInOutQuad(
    time: number,
    start: number,
    change: number,
    duration: number
  ) {
    time /= duration / 2;
    if (time < 1) {
      return (change / 2) * time * time + start;
    }
    time--;
    return (-change / 2) * (time * (time - 2) - 1) + start;
  }
}
