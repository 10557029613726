import React, { FC } from "react";
import { ReactComponent as DirectDebitIcon } from "component-library/src/images/svg/icons/direct-debit.svg";
import { ReactComponent as SepaIcon } from "component-library/src/images/svg/icons/sepa.svg";
import { BillingUser } from "translations/src/models/billing";
import { PlanType, securityTextCommonStyles } from "../../../../constants";
import { useBillingTranslations } from "../../../../hooks/useBillingTranslations";
import DirectDebitGuaranteeButton from "../../../DirectDebitGuaranteeButton";

interface Props {
  planType?: PlanType;
}

const DirectDebitGuaranteeBlock: FC<Props> = ({ planType }) => {
  const {
    texts: { directDebit },
  } = useBillingTranslations(BillingUser.Common);

  return (
    <div
      className={`${securityTextCommonStyles} p-[15px] mb-[15px] bg-color-neutral-three rounded-[10px] flex justify-between items-center`}
    >
      {planType === PlanType.DirectDebit && (
        <>
          <div>{directDebit.protectedBy}</div>
          <DirectDebitGuaranteeButton />
          <DirectDebitIcon height={40} width={77} />
        </>
      )}
      {planType === PlanType.Sepa && (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: directDebit.poweredByGoCardless,
            }}
          />
          <SepaIcon />
        </>
      )}
    </div>
  );
};

export default DirectDebitGuaranteeBlock;
