import { AccountType } from "component-library";

export interface IUserType {
  type: AccountType; // Legacy AccountType taken from the frontend template
  memberGroups: IMemberGroup[];
}

export interface IUser extends IUserType {
  id: string;
  username: string;
  email: string;
  forenames: string;
  surname: string;
}

export const memberGroupNames = {
  castingDirector: true,
  performer: true,
  agent: true,
  castingSearchOnly: true,
} as const;

export const memberGroupSubTypes = {
  searchOnlyCastingDirector: true,
  youngPerformer: true,
  graduate: true,
} as const;

export interface IMemberGroup {
  group: keyof typeof memberGroupNames;
  subType?: undefined | keyof typeof memberGroupSubTypes;
}

export interface ISchoolValidation {
  school: string;
}
