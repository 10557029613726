import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { initNavigator } from "services/navigator";

export interface IWithNavigatorProps extends RouteComponentProps<{}> {}

export default function withNavigator(
  WrappedComponent: React.ComponentType<any>
): typeof React.Component {
  return class extends React.Component<IWithNavigatorProps> {
    constructor(props: IWithNavigatorProps) {
      super(props);

      initNavigator({
        history: props.history,
        location: props.location,
        match: props.match,
      });
    }

    public render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
