import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "i18n";
import { RecurlyError } from "@recurly/recurly-js/lib/error";
import { Spinner } from "../../../../../../../Atoms/Spinner/Spinner";
import {
  CheckoutFlow,
  currencyMap,
  CurrencyType,
  IAddOns,
} from "../../../../../types";
import RecurlyWrapper from "../../../../RecurlyWrapper";
import { useDynamicWindowWidthFor, useExternalScript } from "shared-hooks";
import { addOnNamesMap, RECURLY_JS_URL } from "../../../../../constants";
import PriceInfoBlock from "../../../PriceInfoBlock";
import SummaryBlock from "../../../../SummaryBlock";
import { useGetExistingPaymentMethod } from "../../../../../hooks/useGetExistingPaymentMethod";
import UseExistingPaymentMethodBlock from "../../../../UseExistingPaymentMethodBlock/UseExistingPaymentMethodBlock";
import ExistingPaymentMethodBlock from "../../../../PaymentTypeMethodBlock/ExistingPaymentMethodBlock/ExistingPaymentMethodBlock";
import PaymentMethodButton from "../../../../PaymentTypeMethodBlock/PaymentMethodButton";
import { PaymentErrorModal } from "../../../../PaymentErrorModal";
import { usePreviewPurchase } from "../../hooks/usePreviewPurchase";
import { useSubmitPurchase } from "../../hooks/useSubmitPurchase";
import { AddOnPurchaseFormRef } from "../../types";
import { SCREEN_WIDTH_ENUM } from "../../../../../../../../generics/dom-extensions";
import { getPurchaseType } from "../../../../../utils/getPurchaseType";

interface Props {
  addOn: IAddOns;
  purchaseFormRef: AddOnPurchaseFormRef;
  onSuccessCallback: (trackingData?: any) => void;
}

const AddOnPurchase: FC<Props> = ({
  addOn,
  purchaseFormRef,
  onSuccessCallback,
}) => {
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const { t } = useTranslation();
  const [recurlyErrorMsg, setRecurlyErrorMsg] = useState("");
  const {
    data: existingPaymentMethodData,
    isLoading: existingPaymentMethodIsLoading,
  } = useGetExistingPaymentMethod(true);

  const addOns = [addOn.code];
  const { data: previewPurchase, isLoading: previewPurchaseLoading } =
    usePreviewPurchase(addOns);
  const submitPurchase = useSubmitPurchase(addOns);

  const submitPayment = async () => {
    try {
      await submitPurchase();
      onSuccessCallback({
        purchaseType: getPurchaseType(CheckoutFlow.AddOnBundle),
        paymentValue: previewPurchase?.amount,
        addOns,
      });
    } catch (error: unknown) {
      const recurlyError = error as RecurlyError;

      setRecurlyErrorMsg(
        recurlyError?.message || t("common:billing.genericErrorMessage")
      );
    }
  };

  useEffect(() => {
    if (purchaseFormRef) {
      purchaseFormRef.current = { submitPayment };
    }
  }, [submitPayment]);

  if (previewPurchaseLoading || existingPaymentMethodIsLoading)
    return <Spinner />;

  return (
    <div className="flex flex-col">
      {!!recurlyErrorMsg && (
        <PaymentErrorModal
          handleCloseModal={() => setRecurlyErrorMsg("")}
          title={t("common:billing.errorPopup.title")}
          description={recurlyErrorMsg}
        />
      )}
      {!isMobileView && (
        <PriceInfoBlock
          currencySymbol={currencyMap[CurrencyType.GBP]}
          value={addOn.proRataValue}
          title={t(addOnNamesMap[addOn.code]).toUpperCase()}
          variant="secondary"
          key={addOn.code}
          pricePrefix={t("common:billing.ecp.pricePrefix")}
          vatPrice={t("common:billing.vat", { price: addOn.amount })}
        />
      )}

      <div className="flex flex-col-reverse sm:flex-row gap-[30px]">
        <div className="flex-1 mt-[2rem]">
          <h2 className="text-xxl font-semibold mb-4">
            {t("common:billing.ecp.paymentMethodTitle")}
          </h2>
          <PaymentMethodButton
            isSelected
            onSelect={() => {}}
            variant="secondary"
          >
            <ExistingPaymentMethodBlock data={existingPaymentMethodData} />
          </PaymentMethodButton>

          <h2 className="text-xxl font-semibold mb-4 mt-[30px]">
            {t("common:billing.ecp.paymentMethodDetailTitle")}
          </h2>
          <UseExistingPaymentMethodBlock
            data={existingPaymentMethodData}
            isLoading={existingPaymentMethodIsLoading}
          />
        </div>
        <div className="flex-1">
          <SummaryBlock
            isMobileView={isMobileView}
            planName={addOn.name}
            price={{
              total: previewPurchase?.total || "",
              amount: previewPurchase?.amount || "",
              discount: "",
              tax: previewPurchase?.tax || "",
            }}
            couponBag={{
              hasAccountDiscount: false,
              userCouponCodesObject: [],
              setCoupon: async () => {},
              removeCoupon: async () => {},
              couponErrors: [],
              isCheckoutPreviewRequestLoading: previewPurchaseLoading,
            }}
            planNameTooltip={{
              header: "common:billing.ecp.planName.tooltipHeader",
              body: "common:billing.ecp.planName.tooltipBody",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ({ addOn, purchaseFormRef, onSuccessCallback }: Props) => {
  const [loaded] = useExternalScript(RECURLY_JS_URL);

  if (!loaded) return <Spinner />;
  return (
    <RecurlyWrapper>
      <AddOnPurchase
        addOn={addOn}
        purchaseFormRef={purchaseFormRef}
        onSuccessCallback={onSuccessCallback}
      />
    </RecurlyWrapper>
  );
};
