import React, { FC } from "react";
import { useGetCountriesOptions } from "../../../Functional/locations/useGetCountriesOptions";
import { SelectFormik } from "../SelectFormik/SelectFormik";

interface Props {
  name: string;
  label: string;
  placeholder?: string;
  searchableBySearchbox?: boolean;
}

const SelectCountryFormik: FC<Props> = ({
  name,
  label,
  placeholder,
  searchableBySearchbox,
}) => {
  const { countries, isLoading } = useGetCountriesOptions({
    shouldIncludeStates: true,
  });

  if (isLoading) return null;

  return (
    <SelectFormik
      name={name}
      label={label}
      placeholder={placeholder}
      options={countries}
      searchableBySearchbox={searchableBySearchbox}
    />
  );
};

export default SelectCountryFormik;
