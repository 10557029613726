import React from "react";
import { ThumbnailVideo } from "../ThumbnailVideo/ThumbnailVideo";
import { IVideoModel } from "shared-types";
import "./VideoShowreel.scss";
import thumbnailVideo from "../ThumbnailVideo/ThumbnailVideo.scss";

export const MOVEMENT_DELTA = Number(thumbnailVideo.thumbnailVideoItemWidth);

export interface IVideoShowreelProps {
  selectVideo: (video: IVideoModel) => void;
  videoList: IVideoModel[];
  selectedVideo: IVideoModel;
}

export class VideoShowreel extends React.Component<IVideoShowreelProps> {
  private swipingBlock = React.createRef<HTMLDivElement>();

  public render() {
    return (
      <div className="c-video-showreel">
        <div
          className="c-video-showreel__control c-video-showreel__left-control icon-chevron"
          onClick={this.scrollToLeft}
        />
        <div
          className="c-video-showreel__scrolling-block"
          ref={this.swipingBlock}
        >
          {this.renderThumbnailVideo()}
        </div>
        <div
          className="c-video-showreel__control c-video-showreel__right-control icon-chevronright"
          onClick={this.scrollToRight}
        />
      </div>
    );
  }

  public componentDidUpdate(prevProps: Readonly<IVideoShowreelProps>): void {
    const isSelectedVideoChanged =
      prevProps.selectedVideo.id !== this.props.selectedVideo.id;

    if (isSelectedVideoChanged) {
      const swipingBlockElement = this.swipingBlock.current;

      if (swipingBlockElement) {
        this.scrollToActiveThumbnail(swipingBlockElement);
      }
    }
  }

  private scrollToActiveThumbnail = (swipingBlockElement: HTMLElement) => {
    const activeThumbnailElement =
      swipingBlockElement.querySelector("[data-active=true]");

    if (activeThumbnailElement) {
      activeThumbnailElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    }
  };

  private renderThumbnailVideo = () => {
    const { videoList, selectedVideo, selectVideo } = this.props;

    return videoList.map((item) => {
      const isActive = selectedVideo.id === item.id;
      return (
        <ThumbnailVideo
          isActive={isActive}
          selectVideo={selectVideo}
          video={item}
          key={item.id}
        />
      );
    });
  };

  private scrollToLeft = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const swipingBlockContainer = this.swipingBlock.current;
    if (swipingBlockContainer) {
      this.moveVideoRow(swipingBlockContainer, -MOVEMENT_DELTA);
    }
  };

  private scrollToRight = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const swipingBlockContainer = this.swipingBlock.current;
    if (swipingBlockContainer) {
      this.moveVideoRow(swipingBlockContainer, MOVEMENT_DELTA);
    }
  };

  private moveVideoRow = (
    swipingBlockContainer: HTMLDivElement,
    delta: number
  ) => (swipingBlockContainer.scrollLeft += delta);
}
