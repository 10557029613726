import cx from "classnames";
import React from "react";

import "./PageContent.scss";

export interface IPageContentProps {
  className?: string;
  actionsFooter?: boolean;
}

const PageContent: React.FC<IPageContentProps> = (props) => (
  <div
    className={cx("c-page-content", props.className, {
      "c-page-content--actions-footer": props.actionsFooter,
    })}
  >
    {props.children}
  </div>
);

export default PageContent;
