import { ErrorCode } from "react-dropzone";

import { IImageDimensionValidations } from "./DropzoneFormik";
import { IDropzoneUploaderFile } from "../../../Molecules/DropzoneUploader/DropzoneUploader";

export enum ErrorCodes {
  FileInvalidType = "file-invalid-type",
  FileTooLarge = "file-too-large",
  FileTooSmall = "file-too-small",
  TooManyFiles = "too-many-files",
  FileDimensionSizesTooLarge = "file-dimensions-too-large",
  FileDimensionSizesTooSmall = "file-dimensions-too-small",
  UploadError = "upload-error",
}

export interface FileWithErrors {
  id: string;
  file: File;
  errors: ErrorCodes[];
}

export const ReactDropzoneErrorMap = new Map([
  [ErrorCode.FileInvalidType, ErrorCodes.FileInvalidType],
  [ErrorCode.FileTooLarge, ErrorCodes.FileTooLarge],
  [ErrorCode.FileTooSmall, ErrorCodes.FileTooSmall],
  [ErrorCode.TooManyFiles, ErrorCodes.TooManyFiles],
]);

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getImageDimensions = (file: File) => {
  const imageUrl = URL.createObjectURL(file);
  const img = document.createElement("img");

  const promise = new Promise<{ width: number; height: number }>(
    (resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        resolve({ width, height });
      };
      img.onerror = reject;
    }
  );

  img.src = imageUrl;
  return promise;
};

export const areDimensionsValid = async (
  files: IDropzoneUploaderFile[],
  validations?: IImageDimensionValidations
) => {
  if (!validations) return true;

  try {
    const dimensions = await Promise.all(
      files.map(({ file }) => getImageDimensions(file))
    );

    const { maxHeight, maxWidth, minHeight, minWidth } = validations;

    for (const { height, width } of dimensions) {
      if (maxHeight && height > maxHeight) {
        return ErrorCodes.FileDimensionSizesTooLarge;
      }

      if (maxWidth && width > maxWidth) {
        return ErrorCodes.FileDimensionSizesTooLarge;
      }

      if (minWidth && width < minWidth) {
        return ErrorCodes.FileDimensionSizesTooSmall;
      }

      if (minHeight && height < minHeight) {
        return ErrorCodes.FileDimensionSizesTooSmall;
      }
    }

    return true;
  } catch (error) {
    console.error("Can only get image dimensions for images");
    return true;
  }
};
