import { generatePath, match, matchPath } from "react-router-dom";

import { BASE_PATH } from "config/common";

import prefixPathWithBasePath from "helpers/prefixPathWithBasePath";
import { navigator } from "services/navigator";

interface IBaseParams {
  locale: string;
  userType: string;
}

interface IParams {
  [paramName: string]: string | number | boolean | undefined;
}

export default function generatePathWithParams(
  path: string,
  params: IParams = {}
): string {
  const matchObj: match<IBaseParams> = matchPath<IBaseParams>(
    // @ts-ignore
    navigator.getCurrentRoute().pathname,
    {
      path: BASE_PATH,
    }
  ) as match<IBaseParams>;

  return generatePath(prefixPathWithBasePath(path), {
    ...params,
    locale: matchObj.params.locale,
    userType: matchObj.params.userType,
  });
}
