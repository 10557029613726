import React from "react";
import cx from "classnames";

import "./BreadcrumbBar.scss";

export interface IBreadcrumbBarItem {
  text: string;
  url: string;
  handleClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface IBreadcrumbBarProps {
  items: IBreadcrumbBarItem[];
}

export class BreadcrumbBar extends React.Component<IBreadcrumbBarProps> {
  private readonly maxTextLength = 40;

  private readonly truncateMobileItemsCount = 3;

  public render(): React.ReactNode | null {
    if (!this.props.items.length) {
      return null;
    }

    return (
      <div
        className={cx("c-breadcrumb-bar", {
          "c-breadcrumb-bar--truncated-mobile":
            this.props.items.length > this.truncateMobileItemsCount,
        })}
      >
        <div className="m-auto c-breadcrumb-bar__container">
          {this.renderList()}
        </div>
      </div>
    );
  }

  public renderList() {
    return (
      <ul className="c-breadcrumb-bar__list">
        {this.props.items.map(this.renderItem, this)}
      </ul>
    );
  }

  // @ts-ignore
  public renderItem(item, index) {
    return (
      <li className="c-breadcrumb-bar__item" key={item.url + index}>
        <a
          className="c-breadcrumb-bar__link"
          href={item.url}
          onClick={item.handleClick}
        >
          {this.prepareText(item.text)}
        </a>
        {index !== this.props.items.length - 1 && this.renderSeparator()}
      </li>
    );
  }

  public renderSeparator() {
    return <span className="c-breadcrumb-bar__separator">&nbsp;/&nbsp;</span>;
  }

  private prepareText(text: string): string {
    if (text.length <= this.maxTextLength) {
      return text;
    }

    return text.slice(0, this.maxTextLength).trim() + "…";
  }
}
