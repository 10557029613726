import React, { FC } from "react";
import { ReactComponent as LockIcon } from "component-library/src/images/svg/icons/lock.svg";
import { IBillingCommon } from "translations/src/models/billing/common";
import { directDebitFields, FieldNames } from "../../../constants";
import {
  getTootlipProps,
  QuestionTooltip,
} from "../../../../../Molecules/QuestionTooltip";
import BillingInput from "../../CardElements/BillingInput";
import useScrollBillingField from "../../../hooks/useScrollBillingField";
import { BillingAndPaymentFormProps } from "../../../types";
import "./styles.scss";

type Props = {
  firstError: string;
  directDebitForm: IBillingCommon["directDebitForm"];
} & Pick<
  BillingAndPaymentFormProps,
  | "formValues"
  | "setFieldValue"
  | "isSubmitted"
  | "formErrors"
  | "tootlipContainerConst"
  | "removeInvalidCardError"
>;

const DirectDebitForm: FC<Props> = ({
  directDebitForm,
  formValues,
  setFieldValue,
  isSubmitted,
  firstError,
  formErrors,
  tootlipContainerConst,
  removeInvalidCardError,
}) => {
  const shouldScroll = directDebitFields.includes(firstError as FieldNames);
  const ref = useScrollBillingField(shouldScroll);
  const accountNumberError = formErrors[FieldNames.accountNumber];
  const sortCodeError = formErrors[FieldNames.sortCode];

  return (
    <div className="relative" ref={ref}>
      <BillingInput
        label={directDebitForm.accountName}
        name={FieldNames.accountName}
        value={formValues[FieldNames.accountName]}
        dataRecurly={FieldNames.accountName}
        setFieldValue={setFieldValue}
        validationMessage={formErrors[FieldNames.accountName]}
        isSubmitted={isSubmitted}
        annex={
          <QuestionTooltip
            tooltipProps={{
              ...getTootlipProps(
                directDebitForm.accountNameTooltipHeader,
                directDebitForm.accountNameTooltipBody
              ),
              overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
            }}
          />
        }
      />
      <div className="flex flex-col md2:flex-row gap-5">
        <BillingInput
          label={directDebitForm.accountNumber}
          name={FieldNames.accountNumber}
          value={formValues[FieldNames.accountNumber]}
          dataRecurly={FieldNames.accountNumber}
          setFieldValue={(e) => {
            setFieldValue(e);
            if (isSubmitted && accountNumberError)
              removeInvalidCardError(FieldNames.accountNumber);
          }}
          validationMessage={accountNumberError}
          isSubmitted={isSubmitted}
          annex={
            <QuestionTooltip
              tooltipProps={{
                ...getTootlipProps(
                  directDebitForm.accountNumberTooltipHeader,
                  directDebitForm.accountNumberTooltipBody
                ),
                overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
              }}
            />
          }
          wrapperClassName="flex-1"
          className="lock-input"
        >
          <LockIcon className="lock-icon" />
        </BillingInput>
        <BillingInput
          label={directDebitForm.accountNumberConfirmation}
          name={FieldNames.accountNumberConfirmation}
          value={formValues[FieldNames.accountNumberConfirmation]}
          dataRecurly={FieldNames.accountNumberConfirmation}
          setFieldValue={setFieldValue}
          validationMessage={formErrors[FieldNames.accountNumberConfirmation]}
          isSubmitted={isSubmitted}
          annex={
            <QuestionTooltip
              tooltipProps={{
                ...getTootlipProps(
                  directDebitForm.accountNumberTooltipHeader,
                  directDebitForm.accountNumberTooltipBody
                ),
                overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
              }}
            />
          }
          wrapperClassName="flex-1"
          className="lock-input"
        >
          <LockIcon className="lock-icon" />
        </BillingInput>
      </div>
      <BillingInput
        label={directDebitForm.sortCode}
        name={FieldNames.sortCode}
        value={formValues[FieldNames.sortCode]}
        dataRecurly={FieldNames.sortCode}
        setFieldValue={(e) => {
          setFieldValue(e);
          if (isSubmitted && sortCodeError)
            removeInvalidCardError(FieldNames.sortCode);
        }}
        validationMessage={sortCodeError}
        isSubmitted={isSubmitted}
        annex={
          <QuestionTooltip
            tooltipProps={{
              ...getTootlipProps(
                directDebitForm.sortCodeTooltipHeader,
                directDebitForm.sortCodeTooltipBody
              ),
              overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
            }}
          />
        }
        wrapperClassName="flex-1 w-[100%] md:w-[50%] md:pr-[10px]"
        className="lock-input"
      >
        <LockIcon className="lock-icon" />
      </BillingInput>
      <div className="absolute bottom-[-150px]" />
    </div>
  );
};

export default DirectDebitForm;
