import { BillingTranslationsService } from "translations";
import { config } from "../../../../config";
import BillingService from "./billingService";
export {
  FETCH_SUBSCRIPTIONS,
  FETCH_ACCESS_TO_CHECKOUT,
  FETCH_EXISTING_PAYMENT_METHOD,
} from "./billingService";
export { CheckoutFlow } from "../types";

export const billingTranslationsService = new BillingTranslationsService({
  url: config.umbracoApiUrl,
});

export const billingService = new BillingService(config.billingApiUrl);
