import cx from "classnames";
import React from "react";

import "./PageTitle.scss";

export interface IPageTitleProps {
  className?: string;
}

const PageTitle: React.FC<IPageTitleProps> = (props) => (
  <h1 className={cx("c-page-title", props.className)}>{props.children}</h1>
);

export default PageTitle;
