import React, { FC, useCallback, useRef } from "react";
import cx from "classnames";
import { Button } from "../../../../Atoms/Button/Button";
import { ReactComponent as SuccessIcon } from "component-library/src/images/svg/icons/tick-icon.svg";
import "./styles.scss";

const APPLIED_COUPON_TEXT_DIV_HEIGHT = 32;
interface Props {
  codeToRender: string;
  discountCode: string;
  removeCoupon: (code: string) => Promise<void>;
  disabled?: boolean;
}

const DiscountCoupon: FC<Props> = ({
  codeToRender,
  discountCode,
  removeCoupon,
  disabled = false,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const onDeleteClick = useCallback(() => {
    removeCoupon(discountCode);
  }, [discountCode, removeCoupon]);

  const className = cx(
    "flex mt-[14px] bg-white items-center py-1 px-2 text-color-neutral-one rounded",
    {
      "opacity-60 pointer-events-none": disabled,
    }
  );

  const iconClassName = cx(
    "success-icon p-1 rounded-full bg-color-accent-one fill-white",
    {
      "basis-1/3 md:basis-1/6":
        (divRef?.current?.clientHeight || 0) > APPLIED_COUPON_TEXT_DIV_HEIGHT,
    }
  );
  const dimensions = { width: 32, height: 32 };

  return (
    <div className={className}>
      <SuccessIcon className={iconClassName} {...dimensions} />
      <div ref={divRef} className="font-semibold px-2.5">
        {codeToRender}
      </div>
      <div className="ml-auto">
        <Button
          type="secondary"
          iconName="icon-delete"
          className="p-0"
          onClick={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default DiscountCoupon;
