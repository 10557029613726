import { ITranslations } from './translationModels';

const en: ITranslations = {
  filterControl: {
    clearAll: 'Clear all',
    filtersMobile: ' filters',
    filters: 'Filters',
    ctaApply: 'Apply filters',
    ctaAllFilters: 'View all filters',
    tagOverviewPopup: {
      title: 'Applied filters',
      saveButtonText: 'Back to results',
    },
  },
  datePickerUniversal: {
    specificDate: 'Specific date',
    byMonth: 'By month',
    byYear: 'By year',
    invalidDateError: 'Format must be dd/mm/yyyy',
    minDateError: 'Date must be after ',
  },
};

export default en;
