declare const __CONFIG__: {
  isDev?: boolean;
};

const isTestEnv = !!process.env.PW_TEST_ENV;
const PROD_TEST_ENV =
  process.env.PW_TEST_ENV === "prod" ||
  process.env.REACT_APP_HOST_ENV === "prod";

const getConfig = () => {
  if (isTestEnv) {
    return PROD_TEST_ENV
      ? {
          isDev: false,
        }
      : {
          isDev: true,
        };
  }

  return __CONFIG__;
};

export const config = getConfig();
