export enum UmbracoTranslationsUserTypes {
  CastingClassic = "CastingClassic",
  CastingBBCClassic = "CastingBBCClassic",
  CastingSearchOnlyClassic = "CastingSearchOnlyClassic",
  AgentsClassic = "AgentsClassic",
  PerformersClassic = "PerformersClassic",
  AddOnBundle = "AddOnBundle",
  YoungPerformersClassic = "YoungPerformersClassic",
  GraduateClassic = "GraduateClassic",
  Anonymous = "Anonymous",
}
