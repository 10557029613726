export enum UserAttributes {
  FullName = "full_name",
  Email = "email",
  UserIdAgent = "agent_id",
  UserIdCasting = "casting_id",
  UserIdPerformer = "artist_id",
  MasterPortalGuid = "sub",
  IdentityProvider = "idp",
  MemberGroup = "spotlight.MemberGroups",
  Subscriptions = "subscriptions",
  Role = "role",
  PublicationCode = "publication",
}

export enum DefaultUserMemberGroup {
  DefaultUser = "defaultUser",
}

export enum PerformerMemberGroup {
  Performer = "performer",
}

export enum AgentMemberGroup {
  Agent = "agent",
}

export enum CastingDirectorMemberGroup {
  CastingDirector = "castingDirector",
  CastingBBC = "castingDirectorBBC",
  CastingDirectorSearchOnly = "castingDirector:searchOnlyCastingDirector",
}

export enum CastingDirectorNavTranslation {
  CastingBBC = "castingBBC",
  CastingDirector = "castingDirector",
  Anonymous = "anonymous",
  CastingDirectorSearchOnly = "castingDirectorSearchOnly",
}

export enum CastingDirectorUserSubscription {
  SubscriptionFamily = "subscriptionfamily",
}

export const UserGroupMap = {
  [DefaultUserMemberGroup.DefaultUser]: "0",
  [CastingDirectorMemberGroup.CastingDirector]: "1",
  [PerformerMemberGroup.Performer]: "2",
  [AgentMemberGroup.Agent]: "3",
  [CastingDirectorMemberGroup.CastingBBC]: "4", // castingDirectorBBC and castingDirectorSearchOnly are considered same role
  [CastingDirectorMemberGroup.CastingDirectorSearchOnly]: "4",
};

export const SPOTLIGHT_PORTAL_ID_COOKIE = "spotlight.user";
