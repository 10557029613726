import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";

import "./FilterTag.scss";

export interface IFilterTagProps {
  /** The text for the link */
  text: string;
  /** Optional icon class name to render font icon */
  iconName?: string;
  /** Handler for interacting with the button via click, Enter or Spacebar keys */
  onClick?: (event?: React.MouseEvent<HTMLDivElement>) => void;
  /** Optional id for the link */
  id?: string;
  /** Optional className to provide spacing between components */
  className?: string;
}

export const FilterTag: React.FC<IFilterTagProps> = ({
  text,
  onClick,
  id,
  iconName,
  className,
}) => {
  return (
    <div
      data-testid={dataTestIds.componentLibrary["Atoms.FilterTag.filterTag"]}
      tabIndex={0}
      className={cx(
        "c-filter-tag",
        { "c-filter-tag--with-icon": iconName },
        className
      )}
      onClick={onClick}
      id={id}
    >
      <span className="c-filter-tag__text">{text}</span>
      {iconName && (
        <span className="c-filter-tag__icon">
          <span className={cx("c-filter-tag__icon-font", iconName)} />
        </span>
      )}
    </div>
  );
};
