import cx from "classnames";
import React from "react";

import "./BurgerIcon.scss";

export interface IBurgerIconProps {
  open: boolean;
  animated?: boolean;
}

export const BurgerIcon = ({ open, animated }: IBurgerIconProps) => {
  return (
    <div className="c-burger-icon">
      <div
        className={cx({
          "c-burger-icon__box": true,
          "c-burger-icon__box--open": open,
          "c-burger-icon__box--animated": animated,
        })}
      >
        <span className="c-burger-icon__line" />
        <span className="c-burger-icon__line" />
        <span className="c-burger-icon__line" />
      </div>
    </div>
  );
};
