import React from "react";
import { IVideoModel } from "shared-types";
import cx from "classnames";
import {
  formatTime,
  transformMillisecToSec,
} from "../../../../../generics/time-formatting";
import "./ThumbnailVideo.scss";

export interface IThumbnailVideoProps {
  selectVideo: (video: IVideoModel) => void;
  video: IVideoModel;
  isActive: boolean;
}

export const ThumbnailVideo: React.FC<IThumbnailVideoProps> = (props) => {
  const { selectVideo, video, isActive } = props;
  const selectVideoWrapper = () => selectVideo(video);
  return (
    <div
      className={cx("c-thumbnail-video", {
        "c-thumbnail-video--playing-video": isActive,
      })}
      data-active={isActive}
      onClick={selectVideoWrapper}
    >
      <div
        className="c-thumbnail-video__play-icon icon-playsmall"
        style={{ backgroundImage: `url(${video.previewImg})` }}
      />
      <div className="c-thumbnail-video__file-name">{video.fileName}</div>
      <p className="c-thumbnail-video__video-duration">
        {formatTime(transformMillisecToSec(video.duration), ".")}
      </p>
    </div>
  );
};
