import { i18n } from "i18n";

export function getLocalPopUpText() {
  return {
    localizationButton: {
      localizationButtonAriaLabel: i18n.t("common:nav.changeLanguage"),
    },
    localizationPopup: {
      languages: [
        {
          name: i18n.t("common:nav.language.enGB"),
          value: "en",
        },
        {
          name: i18n.t("common:nav.language.frFR"),
          value: "fr",
        },
      ],
      locations: [
        {
          name: i18n.t("common:nav.locale.gb"),
          value: "GB",
        },
        {
          name: i18n.t("common:nav.locale.ie"),
          value: "IE",
        },
        {
          name: i18n.t("common:nav.locale.fr"),
          value: "FR",
        },
      ],
      locationTitle: i18n.t("common:nav.locale.location"),
      languageTitle: i18n.t("common:nav.locale.lang"),
      title: i18n.t("common:nav.locale.popup.title"),
      cancelButton: i18n.t("common:button.label.cancel"),
      saveButton: i18n.t("common:button.label.save"),
      localizationButtonAriaLabel: i18n.t("common:nav.changeLanguage"),
      closeButtonAriaLabel: i18n.t("common:button.label.close"),
    },
  };
}
