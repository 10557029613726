import mergeWith from "lodash/mergeWith";

// used to combine default translations with fallbacks (if missing)
export const fetchWithFallback = async <T extends object>(
  fetch: (...args: any) => Promise<T>,
  fallBackData: T,
  throwOnError: boolean = false
) => {
  let result = {} as T;

  try {
    result = (await fetch()) || ({} as T);
  } catch (e) {
    if (throwOnError) {
      throw e;
    }
  } finally {
    // combine defaults
    result = mergeWith(
      {},
      fallBackData,
      result,
      (a: any, b: any) => (b === null ? a : undefined) // replace null values with fallback also
    );
  }

  return result;
};
