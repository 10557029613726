import React from "react";
import { IFilterTagProps, FilterTag } from "../../../Atoms/FilterTag/FilterTag";
import { IPopupProps, Popup } from "../../../Molecules/Popup/Popup";
import { Button } from "../../../Atoms/Button/Button";

import "./TagsOverviewPopup.scss";

export interface ITagsOverviewPopupProps {
  title: string;
  saveButtonText: string;
  filterTags: IFilterTagProps[];
  onSave?(tags: IFilterTagProps[]): void;
  onClose?(): void;
  hidePopup(): void;
  isControlledTags?: boolean;
}

export interface ITagsOverviewPopupState {
  filterTags: IFilterTagProps[];
}

export class TagsOverviewPopup extends React.Component<
  ITagsOverviewPopupProps,
  ITagsOverviewPopupState
> {
  public readonly state: Readonly<ITagsOverviewPopupState> = {
    filterTags: this.props.filterTags.map((item) => ({
      ...item,
      onClick: () => this.handleTagClick(item),
    })),
  };

  public render() {
    const { isControlledTags } = this.props;
    const popupProps: IPopupProps = {
      width: { lg: 5, md: 6 },
      close: this.handleClose,
      priority: "high",
      texts: {
        closePopup: this.props.saveButtonText,
      },
      fullHeight: true,
    };

    // this is for backwards compatibility, after we replace all filters,
    // this component should only render and don't have his own state
    const tags = isControlledTags
      ? this.props.filterTags
      : this.state.filterTags;

    return (
      <Popup {...popupProps}>
        <div className="g-popup-content g-bg-default">
          <div className="g-popup-content__header">{this.props.title}</div>
          <div className="g-popup-content__scrollable">
            <div className="c-tags-overview-popup__content">
              {tags.map((item) => this.renderFilterTag(item))}
            </div>
          </div>
          <hr className="g-split-line" />
          <div className="g-popup-content__buttons-set">
            <div>
              <Button
                type="primary"
                text={this.props.saveButtonText}
                onClick={this.handleSaveButtonClick}
              />
            </div>
          </div>
        </div>
      </Popup>
    );
  }

  private renderFilterTag = (tag: IFilterTagProps) => (
    <FilterTag
      {...tag}
      className="c-tags-overview-popup__filter-tag"
      iconName="icon-cross1"
      key={tag.id}
    />
  );

  private handleTagClick = (filterTag: IFilterTagProps) => {
    const tags = this.state.filterTags.filter((tag) => tag.id !== filterTag.id);

    this.setState({ filterTags: tags }, () => {
      if (this.state.filterTags.length === 0) {
        this.props.onSave?.(this.state.filterTags);
        this.props.hidePopup();
      }
    });
  };

  private handleSaveButtonClick = () => {
    this.props.onSave?.(this.state.filterTags);
    this.props.hidePopup();
  };

  private handleClose = () => {
    if (this.props.onClose) {
      return this.props.onClose();
    }

    this.props.hidePopup();
  };
}

export default TagsOverviewPopup;
