import React, { FC, useContext } from "react";
import DOMPurify from "dompurify";
import { useBillingTranslations } from "../../../hooks/useBillingTranslations";
import { BillingContext } from "../../../BillingContext";

const CommonHeader: FC = () => {
  const { billingUserType } = useContext(BillingContext);
  const {
    texts: { common: userTexts },
  } = useBillingTranslations(billingUserType);

  return (
    <div>
      <div className="checkout-single-page__description">
        <h1>{userTexts.pageTitle}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(userTexts.pageCopy),
          }}
        />
      </div>
      <div className="checkout-single-page__user-type">
        {userTexts.userType}
      </div>
    </div>
  );
};

export default CommonHeader;
