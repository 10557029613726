import React, { ChangeEvent, FC, useCallback, useEffect } from "react";
import { dataTestIds } from "data-testids";
import { DropdownInput } from "../../../../Molecules/DropdownInput/DropdownInput";
import { IOption } from "../../../../../generics/interfaces";
import { FieldNames } from "../../constants";
import { SetField } from "../../hooks/useBillingFormValues";
import useScrollBillingField from "../../hooks/useScrollBillingField";

interface Props {
  label: string;
  value?: string;
  validationMessage?: string;
  isFormSubmitted?: boolean;
  shouldScroll?: boolean;
  placeholder: string;
  setFieldValue: SetField;
  options: IOption<string>[];
  fieldName?: FieldNames | ChangeEvent<HTMLInputElement>;
  isDisabled?: boolean;
  presetCountry?: string;
}

const CountryStateSelect: FC<Props> = ({
  label,
  placeholder,
  options,
  value,
  validationMessage,
  isFormSubmitted,
  shouldScroll,
  setFieldValue,
  fieldName = FieldNames.country,
  isDisabled = false,
  presetCountry = "",
}) => {
  const ref = useScrollBillingField(!!(validationMessage && shouldScroll));
  const onOptionSelected = useCallback(
    (option?: Partial<IOption<string | boolean>> | undefined) => {
      const value = option?.value ? String(option?.value) : undefined;
      return setFieldValue(fieldName, value);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (presetCountry) {
      onOptionSelected({ value: presetCountry });
    }
  }, [presetCountry]);

  return (
    <div
      ref={ref}
      data-testid={
        dataTestIds.componentLibrary["Organisms.Billing.CardElements.country"]
      }
    >
      <DropdownInput
        variant="select"
        label={label}
        options={options}
        placeholder={placeholder}
        value={presetCountry || value}
        optionSelected={onOptionSelected}
        searchInput={{ show: true }}
        validationMessage={validationMessage}
        invalid={!!validationMessage}
        isFormSubmitted={isFormSubmitted}
        disabled={isDisabled}
      />
      <input type="hidden" value={value} data-recurly={fieldName} />
    </div>
  );
};

export default CountryStateSelect;
