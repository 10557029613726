// @ts-nocheck
/**
 * Nested checkboxes mapping a complex value structure
 */

import React from "react";
import cx from "classnames";

import { InputWrapper } from "../../Atoms/InputsCommon/InputWrapper";
import { IBaseInputProps } from "../../Atoms/InputsCommon/BaseInputProps";
import { InputLabel } from "../../Atoms/InputsCommon/InputLabel";
import {
  CheckboxGroupItem,
  ICheckboxGroupItemConfig,
  ICheckboxConfig,
  ICheckboxGroupItemValue,
} from "./components/CheckboxGroupItem";
import "./CheckboxGroup.scss";

export interface ICheckboxGroupValue {
  [key: string]: ICheckboxGroupItemValue;
}

export interface ICheckboxGroupConfig<
  TOption extends ICheckboxGroupItemConfig | ICheckboxConfig =
    | ICheckboxGroupItemConfig
    | ICheckboxConfig
> {
  name: string;
  options: TOption[];
}

type ColumnCount = 1 | 2 | 3 | 4 | 5;

export interface IColumns {
  count: { sm: ColumnCount; md: ColumnCount; lg: ColumnCount };
  direction: "horizontal" | "vertical";
}

export interface ICheckboxGroupProps
  extends IBaseInputProps<any, ICheckboxGroupValue> {
  /**
   * General label for the whole group
   */
  label?: string;
  /**
   * Config object to generate the group and decide which checkboxes hold values and which define levels
   */
  config: ICheckboxGroupConfig;
  /**
   * Recursive boolean value which determines checked status for the children. Must match config structure
   */
  value: ICheckboxGroupValue;
  /**
   * Callback for when any of the children's checked status changes
   */
  valueChanged: (value: ICheckboxGroupValue) => void;
  /**
   * Optional class name for the topmost div
   */
  className?: string;
  /**
   * Multicolumn layout settings
   */
  columns?: IColumns;
  /**
   * Place annex next to label
   */
  inlineAnnexPosition?: boolean;
  /**
   * Remove margin bottom
   */
  removeBottomSpacing?: boolean;
  /**
   * Pass drop down specific styling when part of Dropdown component
   */
  dropDown?: boolean;

  /**
   * Skip validation
   */
  skipValidation?: boolean;
}

export class CheckboxGroup extends React.Component<ICheckboxGroupProps> {
  public render() {
    const {
      config,
      value,
      className,
      validationMessage,
      label,
      columns,
      removeBottomSpacing,
      dropDown,
      isFormSubmitted,
    } = this.props;

    const disabled = this.props.disabled || !this.validateValue(config, value);
    const hasInputWrapper = Boolean(label || validationMessage);
    const isCollapsible = this.isCollapsible();

    const classNames = cx("c-checkbox-group", className, {
      "c-checkbox-group--disabled": disabled,
      [`c-checkbox-group--columns-${columns?.direction}`]: columns,
      [`c-checkbox-group--columns-sm-${columns?.count.sm}`]: columns?.count.sm,
      [`c-checkbox-group--columns-md-${columns?.count.md}`]: columns?.count.md,
      [`c-checkbox-group--columns-lg-${columns?.count.lg}`]: columns?.count.lg,
    });

    return (
      <div className={classNames}>
        {hasInputWrapper && (
          <InputWrapper
            invalid={
              this.props.invalid && isFormSubmitted ? "error" : undefined
            }
            validationMesssage={this.props.validationMessage}
          >
            {" "}
            {/** for showing validation message under the label */}
            <InputLabel
              noMargin={true}
              bold={true}
              label={this.props.label}
              annex={this.props.annex}
              inlineAnnexPosition={this.props.inlineAnnexPosition}
            />
          </InputWrapper>
        )}
        <div
          className={cx("c-checkbox-group__container", {
            "c-checkbox-group__container--no-label": !label,
            "c-checkbox-group__container--collapsible": isCollapsible,
            "c-checkbox-group__container--no-bottom-margin":
              removeBottomSpacing,
          })}
        >
          {config.options.map((option, index) => (
            <div
              key={config.name + index}
              className="c-checkbox-group__container-item"
            >
              <CheckboxGroupItem
                parentName={config.name}
                isNested={false}
                config={option}
                value={
                  this.props.value[
                    (option as ICheckboxGroupItemConfig).name ||
                      (option as ICheckboxConfig).value
                  ]
                }
                valueChanged={this.valueChanged(
                  (option as ICheckboxGroupItemConfig).name ||
                    (option as ICheckboxConfig).value
                )}
                dropDown={dropDown}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  private valueChanged = (configName: string) => (value: any) => {
    const newValue = { ...this.props.value };
    newValue[configName] = value;
    this.props.valueChanged(newValue);
  };

  private validateValue = (
    config: ICheckboxGroupConfig | ICheckboxGroupItemConfig | ICheckboxConfig,
    value: ICheckboxGroupItemValue
  ): boolean => {
    const { skipValidation } = this.props;

    if (skipValidation) return true;

    const isValid = isCheckboxGroupValueValid(config, value);

    if (!isValid) {
      console.error(
        "CheckboxGroup value invalid against config object. Perhaps you inexistent key is being passed to the value"
      );
    }

    return isValid;
  };

  private isCollapsible = (): boolean =>
    this.props.config.options.some(
      (option: ICheckboxGroupItemConfig) =>
        option.isCollapsible && option.options?.length > 0
    );
}

export function isCheckboxGroupValueValid(
  config: ICheckboxGroupConfig | ICheckboxGroupItemConfig | ICheckboxConfig,
  value: ICheckboxGroupItemValue
) {
  const keys = Object.keys(value);
  let isValid = true;

  for (const key of keys) {
    const matchingOption = (config as ICheckboxGroupItemConfig).options.find(
      (option) => {
        if ((option as ICheckboxConfig).value) {
          return (option as ICheckboxConfig).value === key;
        }

        return (option as ICheckboxGroupItemConfig).name === key;
      }
    );

    if (typeof value[key] === "boolean") {
      isValid = !!matchingOption;
    } else if (matchingOption) {
      isValid = isCheckboxGroupValueValid(matchingOption, value[key]);
    } else {
      return false;
    }

    if (!isValid) {
      return false;
    }
  }

  return isValid;
}
