import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import _some from "lodash/some";
import { IAddress } from "services/api/BillingApi/BillingApi";

// check address has more than just a phone number, and that at least one of the other fields has a truthy value. One of the fields must be country.
export default function checkIsValidAddress(
  address: IAddress | undefined
): boolean {
  if (!address?.country) {
    return false;
  }
  const addressWithoutPhone = _omit(address, "phone");
  return !_isEmpty(addressWithoutPhone) && _some(addressWithoutPhone, Boolean);
}
