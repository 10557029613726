import React, { ReactNode } from "react";
import cx from "classnames";
import "./SubNavigationItem.scss";
import { dataTestIds } from "data-testids";

export interface ISubNavigationItemProps {
  /** Label of the element, will be displayed */
  text: string;
  /** Render specific content */
  renderLabel?: ReactNode;
  /** Link of the element, where we will be redirected after clicking on it */
  linkUrl: string;
  /** Optional property means that this sub navigation item is currently selected, span text will be underlined */
  isSelected?: boolean;
  /** Optional css class for the topmost div for style overriding purposes */
  className?: string;
  /** Handler to hook on Click event. onClick handler is optional parameter.
   *  Use it if you would like to handle redirection manually (for example, using Router history.push function)
   *  If onClick handler isn't provided - SubNavigation Item will be work using default Anchor behavior.
   */
  handleClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    text: string,
    link: string
  ) => void;
}

export class SubNavigationItem extends React.Component<ISubNavigationItemProps> {
  public constructor(props: ISubNavigationItemProps) {
    super(props);
  }

  public render() {
    const { text, linkUrl, isSelected, className, renderLabel } = this.props;
    return (
      <div
        className="c-subnavigation-item"
        data-testid={
          dataTestIds.componentLibrary[
            "Organisms.NavigationHeader.SubNavigationItem.subNavigationLink"
          ]
        }
      >
        <a
          className={cx(
            "c-subnavigation-item__link",
            { selected: isSelected },
            className ? `${className}` : ""
          )}
          href={linkUrl}
          target={linkUrl.startsWith("http") ? "_blank" : "_self"}
          onClick={this.handleClick}
        >
          {renderLabel || <span>{text}</span>}
        </a>
      </div>
    );
  }

  private handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const { text, linkUrl, handleClick } = this.props;
    if (handleClick) {
      handleClick(event, text, linkUrl);
    }
  };
}
