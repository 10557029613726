import { i18n } from "i18n";
import { INavLinks } from "../../../Footer/Footer";

export const getFooterLinks = () => ({
  navigationLinks: {
    footerLinks1: [
      {
        href: "/get-in-touch",
        html: i18n.t("common:nav.links.contact"),
      },
      {
        href: "https://www.spotlight.com/join-us/our-partners/",
        html: i18n.t("common:nav.links.partners"),
      },
      {
        href: "https://www.spotlight.com/the-small-print/new-on-spotlight/",
        html: i18n.t("common:nav.links.newInfo"),
      },
      {
        href: "https://www.spotlight.com/join-us/how-we-support-our-industry/",
        html: i18n.t("common:nav.links.industrySupport"),
      },
      {
        href: "https://www.spotlight.com/the-small-print/what-we-offer-our-members/",
        html: i18n.t("common:nav.links.memberBenefits"),
      },
      {
        href: "https://www.spotlight.com/the-small-print/promoting-inclusivity-in-our-industry/",
        html: i18n.t("common:nav.links.inclusivity"),
      },
    ],
    footerLinks2: [
      {
        href: "https://calendar.spotlight.com/",
        html: i18n.t("common:nav.links.gradPerformanceCalendar"),
      },
      {
        href: "https://www.spotlight.com/news-and-advice/the-essentials/the-spotlight-guide-to-basic-video-editing/",
        html: i18n.t("common:nav.links.showreelEditing"),
      },
      {
        href: "https://app.spotlight.com/contacts",
        html: i18n.t("common:nav.contacts"),
      },
      {
        href: "https://status.spotlight.com/",
        html: i18n.t("common:nav.links.websiteStatus"),
      },
    ],
    footerLinks3: [
      {
        href: "https://www.spotlight.com/the-small-print/terms-conditions/",
        html: i18n.t("common:nav.links.termsConditions"),
      },
      {
        href: "https://www.spotlight.com/the-small-print/privacy-policy/",
        html: i18n.t("common:nav.links.privacyPolicy"),
      },
      {
        href: "javascript:CookieScript.instance.show()",
        html: i18n.t("common:nav.links.cookieSettings"),
      },
      {
        href: "https://www.spotlight.com/help-and-faqs/",
        html: i18n.t("common:nav.links.helpFaqs"),
      },
    ],
  } as INavLinks,
  texts: {
    linkListsTitles: [
      i18n.t("common:nav.links.titles.aboutSpotlight"),
      i18n.t("common:nav.links.titles.spotlightTools"),
      i18n.t("common:nav.links.titles.terms"),
    ],
    followCTA: i18n.t("common:nav.socialMediaLinks.title"),
    copyrightTitle: i18n.t("common:nav.socialMediaLinks.title", {
      year: new Date().getFullYear(),
    }),
    copyrightAddress: i18n.t("common:nav.address"),
  },
});
