// @ts-nocheck
import React from "react";
import FlipMove from "react-flip-move";
import cx from "classnames";

import "./ReorderableList.scss";

import {
  SortableContainer,
  SortableElement,
  SortableContainerProps,
  SortEnd,
  SortEvent,
  Axis,
  SortEventWithTag,
  SortStartHandler,
  SortMoveHandler,
  SortEndHandler,
  SortOverHandler,
  HelperContainerGetter,
} from "react-sortable-hoc";

import { AutoSizer, List } from "react-virtualized";
import { isIOSDevice } from "../../../generics/dom-extensions";

export interface IReorderEndArgs<T> {
  oldIndex: number;
  newIndex: number;
  newList: T[];
}

export interface IReorderableListProps<T> {
  /**
   * A list of data which is represented as the draggable items
   */
  list: T[];
  /**
   * A function which renders JSX elements based on the data list, which are then wrapped inside draggable containers
   */
  itemRenderer: (x: T) => JSX.Element;
  /**
   * Callback to every drag and drop action
   */
  onReorderEnd?: (x: IReorderEndArgs<T>) => void;
  /**
   * An element rendered at the start of the list
   */
  immovableHead?: () => JSX.Element;
  /**
   * An element rendered at the end of the list
   */
  immovableTail?: () => JSX.Element;
  /**
   * Optional css class for the topmost div
   */
  className?: string;
  /**
   * A function which provides a unique value for React key property based on list item. If it is not provided the item index will be used.
   * Warning: item index is not truly unique property and using it may cause item state issues.
   */
  getUniqueKey?: (x: T) => string | number;
  /**
   * Optional flag to disable reordering
   */
  disableReordering?: boolean;
  /**
   * Optional flag for enabling animated list component
   */
  useAnimatedList?: boolean;

  // Properties that belong to react-flip-move. Check its docs. Add more as required
  /**
   * Control animation that runs when the component mounts
   */
  appearAnimation?: FlipMove.AnimationProp;
  /**
   * Control animation that runs when new items are added to the DOM
   */
  enterAnimation?: FlipMove.AnimationProp;
  /**
   * Control animation that runs when new items are removed from the DOM
   */
  leaveAnimation?: FlipMove.AnimationProp;

  // Properties that belong to react-sortable-hoc. Check its docs. Add more as required
  axis?: Axis;
  /**
   * Adds possibility to lock movement on concrete axis during dragging
   */
  lockAxis?: Axis;
  /**
   * You can return true from this handler to prevent sorting from starting. e.g. if the drag+drop starts from an input or anchor
   * element, you probably want the input or anchor onClick event to fire. Alternatively, you may wish to cancel sorting for
   * certain situations. The default implementation of this prevents sorting when any of DEFAULT_SHOULD_CANCEL_START_TAG_NAMES
   * are clicked
   */
  shouldCancelStart?: (event: SortEvent | SortEventWithTag) => boolean;
  onSortStart?: SortStartHandler;
  onSortMove?: SortMoveHandler;
  onSortEnd?: SortEndHandler;
  onSortOver?: SortOverHandler;
  distance?: number;
  pressDelay?: number;
  helperClass?: string;
  helperContainer?: HTMLElement | HelperContainerGetter;
  /**
   * Use react-virtualized List to display items (in case of many items)
   * Also parent container should height and overflow-y scroll should be set to allow scrolling
   */
  useVirtualGrid?: boolean;
  /**
   * Row height is needed to be set when used with useVirtualGrid property
   */
  rowHeight?: number;
}

interface IVirtualContainerProps {
  width: number;
  height: number;
}

export class ReorderableList<T> extends React.PureComponent<
  IReorderableListProps<T>
> {
  private defaultDelay: number | undefined = isIOSDevice() ? 200 : 0;
  private sortable: React.ComponentClass<
    SortableContainerProps | (SortableContainerProps & IVirtualContainerProps)
  >;
  private sortableItem: any;
  private isAllAnimationDisabled: boolean;

  constructor(props) {
    super(props);

    this.isAllAnimationDisabled = false;
    this.initSortable(this.props.useVirtualGrid);
    this.updateDelayProp(this.props.distance);
  }

  public componentDidUpdate() {
    this.isAllAnimationDisabled = false;
  }

  public componentWillReceiveProps(newProps) {
    if (newProps.useVirtualGrid !== this.props.useVirtualGrid) {
      this.initSortable(newProps.useVirtualGrid);
    }

    this.updateDelayProp(newProps.distance);
  }

  private updateDelayProp = (distance?: number) => {
    if (distance) {
      this.defaultDelay = undefined;
    }
  };

  private getWrappedReorderableItems = () => {
    return (
      <FlipMove
        appearAnimation={
          this.props.appearAnimation ? this.props.leaveAnimation : "none"
        }
        leaveAnimation={
          this.props.leaveAnimation ? this.props.leaveAnimation : "none"
        }
        enterAnimation={
          this.props.enterAnimation ? this.props.enterAnimation : "none"
        }
        disableAllAnimations={this.isAllAnimationDisabled}
      >
        {this.getReorderableItems()}
      </FlipMove>
    );
  };

  private virtualRowRenderer = ({ index, key, style }: any) => {
    const SortableItem = this.sortableItem;
    const value = this.props.list[index];
    return <SortableItem key={key} index={index} style={style} value={value} />;
  };

  private initSortable = (useVirtualGrid?: boolean) => {
    this.sortableItem = SortableElement(({ value, style }) => (
      <li style={style} className={this.getItemContainerClassName()}>
        {this.props.itemRenderer(value)}
      </li>
    ));

    if (useVirtualGrid) {
      this.sortable = SortableContainer(({ width, height }) => {
        return this.getReorderableItemsVirtual({ width, height });
      });
    } else {
      this.sortable = SortableContainer(() => (
        <div
          className={`c-reorderable-list${
            this.props.className ? " " + this.props.className : ""
          }`}
        >
          <ul>
            {this.props.immovableHead && (
              <li className={this.getItemContainerClassName()}>
                {this.props.immovableHead}
              </li>
            )}
            {this.props.useAnimatedList
              ? this.getWrappedReorderableItems()
              : this.getReorderableItems()}
            {this.props.immovableTail && (
              <li className={this.getItemContainerClassName()}>
                {this.props.immovableTail}
              </li>
            )}
          </ul>
        </div>
      ));
    }
  };

  private getReorderableItemsVirtual = ({ width, height }) => {
    return (
      <List
        rowHeight={this.props.rowHeight}
        rowRenderer={this.virtualRowRenderer}
        rowCount={this.props.list.length}
        width={width}
        height={height}
        /*pass fake property, to force re-render of the list*/
        data={this.props.list}
      />
    );
  };

  private getReorderableItems = () => {
    const SortableItem = this.sortableItem;

    return this.props.list.map((value, index) => (
      <SortableItem
        key={`item-${
          this.props.getUniqueKey ? this.props.getUniqueKey(value) : index
        }`}
        index={index}
        disabled={!!this.props.disableReordering}
        value={value}
      />
    ));
  };

  private onSortEnd = (sort: SortEnd, event: SortEvent) => {
    if (this.props.onSortEnd) {
      this.props.onSortEnd(sort, event);
    }

    if (this.props.onReorderEnd) {
      const copy = [...this.props.list];
      const value = copy.splice(sort.oldIndex, 1)[0];
      copy.splice(sort.newIndex, 0, value);

      this.isAllAnimationDisabled = true;
      this.props.onReorderEnd({
        oldIndex: sort.oldIndex,
        newIndex: sort.newIndex,
        newList: copy,
      });
    }
  };

  private getItemContainerClassName = () => {
    return "c-reorderable-list__item-container " + (this.props.axis || "y");
  };

  /**
   * The list of HTML element tag names that should prevent the drag and drop action. They are provided here
   * for if you need to provide your own shouldCancelStart implementation.
   */
  public static readonly DEFAULT_SHOULD_CANCEL_START_TAG_NAMES: ReadonlyArray<string> =
    ["input", "textarea", "select", "option", "button", "a"];
  /** Provides the default implementation of should canccel start. It's take from the react-sortable-hoc repo, but also adds anchors to the list
   * of elements that shouldn't start a drag
   */
  private defaultShouldCancelStart = (event: SortEvent | SortEventWithTag) => {
    // Cancel sorting if the event target is an `input`, `textarea`, `select` or `option`

    const tagName = event.target["tagName"] || "";
    return (
      ReorderableList.DEFAULT_SHOULD_CANCEL_START_TAG_NAMES.indexOf(
        tagName.toLowerCase()
      ) !== -1
    );
  };

  public render() {
    const Sortable = this.sortable;
    const { helperClass, useVirtualGrid } = this.props;

    const sortableProps = {
      shouldCancelStart: this.defaultShouldCancelStart,
      ...this.props,
      onSortEnd: this.onSortEnd,
      helperClass: cx(
        { "animated-helper": this.props.useAnimatedList },
        helperClass && `${helperClass}`
      ),
      pressDelay: this.props.pressDelay || this.defaultDelay,
    };

    return useVirtualGrid ? (
      <AutoSizer>
        {({ height, width }) => (
          <Sortable {...sortableProps} width={width} height={height} />
        )}
      </AutoSizer>
    ) : (
      <Sortable {...sortableProps} />
    );
  }
}
