export class LogConverter {
  public static convert(context: any) {
    const contextCopy = { ...context };

    Object.keys(contextCopy).forEach((contextProps) => {
      const propValue = contextCopy[contextProps];

      if (propValue instanceof Response) {
        contextCopy[contextProps] = LogConverter.parseResponse(propValue);
      } else if (propValue instanceof Error) {
        contextCopy[contextProps] = LogConverter.parseError(propValue);
      } else {
        contextCopy[contextProps] = propValue;
      }
    });

    return contextCopy;
  }

  private static parseResponse(response: Response) {
    const headers: any[] = [];
    response.headers.forEach((value, name) => headers.push({ name, value }));

    const responseReplica = {
      url: response.url,
      statusText: response.statusText,
      status: response.status,
      redirected: response.redirected,
      ok: response.ok,
      headers,
    };

    return responseReplica;
  }

  private static parseError(error: Error) {
    const errorProps = Object.getOwnPropertyNames(error);

    return errorProps.reduce((errorCopy, prop) => {
      // @ts-ignore
      errorCopy[prop] = error[prop];

      return errorCopy;
    }, {});
  }
}
