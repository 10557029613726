export const CastingSearchOnlyFallback = {
  header: {
    signOut: "Sign out",
    signIn: "Sign in",
    menu: "Menu",
    myAccount: {
      text: "My account",
      actionText: "/myhome/",
    },
  },
  leftHandNavigation: {
    menuNav: [
      {
        text: "Industry resources",
        link: "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight/",
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/help.svg",
        index: 0,
        children: [],
      },
      {
        text: "Contacts listings",
        link: "/contacts/",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1163/contacts.svg",
        index: 1,
        children: [],
      },
      {
        text: "Agent lists",
        link: "/interactive/link/agentlists.asp",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1174/twoclients.svg",
        index: 2,
        children: [],
      },
      {
        text: "Performer search",
        link: "/interactive/search/search.asp?card=13",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1166/magnify.svg",
        index: 3,
        children: [],
      },
      {
        text: "Shortlists",
        link: "/shortlists",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1171/shortlist.svg",
        index: 4,
        children: [],
      },
      {
        text: "My messages",
        link: "/interactive/link/messages.asp",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1167/messages.svg",
        index: 5,
        children: [],
      },
      {
        text: "Create a message",
        link: "/interactive/link/message.asp",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1162/send.svg",
        index: 6,
        children: [],
      },
      {
        text: "My contact details",
        link: "https://portal.spotlight.com/portal/Account/Details",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1173/sun.svg",
        index: 7,
        children: [],
      },
      {
        text: "Performance calendar",
        link: "https://calendar.spotlight.com/",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1170/performancecal.svg",
        index: 8,
        children: [],
      },
      {
        text: "Help",
        link: "https://www.spotlight.com/the-small-print/help-for-casting-users-of-the-spotlight-link/",
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1164/lhnhelp.svg",
        index: 9,
        children: [],
      },
    ],
    bottomNav: [
      {
        text: "Get in touch",
        link: "/get-in-touch",
        index: 10,
      },
      // {
      //   text: "Events",
      //   link: "/news-and-advice/events",
      //   index: 11,
      // },
      {
        text: "News & Advice",
        link: "https://www.spotlight.com/news-and-advice",
        index: 12,
      },
      {
        text: "Contacts listings",
        link: "/contacts",
        index: 13,
      },
      {
        text: "Rooms & Studio Hire",
        link: "https://www.spotlight.com/rooms-and-studios/studios/",
        index: 14,
      },
    ],
  },
  localizationPopUp: {
    languages: [
      {
        label: "English",
        value: "en",
      },
      {
        label: "Française",
        value: "fr",
      },
    ],
    locations: [
      {
        label: "United Kingdom",
        value: "gb",
      },
      {
        label: "Ireland",
        value: "ie",
      },
      {
        label: "France",
        value: "fr",
      },
    ],
    locationTitle: "Location",
    languageTitle: "Language",
    title: "Your location and language",
    cancelButton: "Cancel",
    closeButtonAriaLabel: "Close",
    saveButton: "Save",
    localizationButtonAriaLabel: "Switch language",
  },
  footer: {
    copyrightAddress: "Spotlight, 7 Leicester Place, London, WC2H 7RJ",
    footerItems: {
      footerLinks1: [
        {
          href: "/get-in-touch",
          html: "Get in touch",
        },
        {
          href: "https://jobs.spotlight.com/",
          html: "Work with us",
        },
        {
          href: "https://www.spotlight.com/our-partners/",
          html: "Our Partners",
        },
        {
          href: "/the-small-print/new-on-spotlight/",
          html: "What's new on Spotlight",
        },
        {
          href: "/join-us/how-we-support-our-industry/",
          html: "How we support our industry",
        },
        {
          href: "/the-small-print/what-we-offer-our-members/",
          html: "What we offer our members",
        },
        {
          href: "/the-small-print/promoting-inclusivity-in-our-industry/",
          html: "Promoting inclusivity in our industry",
        },
      ],
      footerLinks2: [
        {
          href: "/calendar/",
          html: "Graduate Performance Calendar",
        },
        {
          href: "/showreel-editing/",
          html: "Showreel Editing",
        },
        {
          href: "/contacts-info",
          html: "Get listed on Contacts",
        },
        {
          href: "https://status.spotlight.com/",
          html: "Website status page",
        },
      ],
      footerLinks3: [
        {
          href: "https://www.spotlight.com/the-small-print/terms-conditions/",
          html: "Terms & Conditions",
        },
        {
          href: "https://www.spotlight.com/the-small-print/privacy-policy/",
          html: "Privacy Policy",
        },
        {
          href: "https://www.spotlight.com/help-and-faqs/",
          html: "Help & FAQs",
        },
      ],
    },
    linkListTitles: ["About Spotlight", "Spotlight Tools", "The Small Print"],
    socialMedia: {
      text: "Follow us",
      elements: [],
    },
  },
  errorPopUp: {
    accountAccessError: {
      accountAccessErrorTitle: "Account access problem",
      accountAccessErrorBody:
        '<p><span>You do not have permission to access this page with your current sign in details. If you require any further help, please get in touch at </span><a rel="noopener noreferrer" href="mailto:questions@spotlight.com" target="_blank" class="c-link">questions@spotlight.com</a><span>.</span></p>',
    },
    networkError: {
      networkErrorTitle: "Network error",
      networkErrorBody:
        "<p><span>Please check your internet connection</span></p>",
    },
    accountError: {
      accountErrorTitle: "Account Error",
      accountErrorBody:
        '<p><span>There is a problem with your account. Please contact <a href="mailto:questions@spotlight.com">Spotlight support</a></span><span> </span><span>to resolve this issue.</span></p>',
    },
    systemError: {
      systemErrorTitle: "System Error",
      systemErrorBody:
        '<p><span>Unable to complete this action. We may be experiencing system errors - please visit the</span><span> <a href="https://status.spotlight.com/">service page</a></span><span> for more information. We apologise for any inconvenience.</span></p>',
    },
    buttons: {
      close: "Close",
    },
  },
  common: {
    labels: {
      tooltipCloseButton: "Close",
      tooltipHelpButton: "Help",
      topLabel: "",
    },
    page404: {
      page404Header: "Oops!",
      page404Message: "It seems the page is lost.",
      page404Button: "Go to homepage",
    },
    alertMessages: {
      yourChangesWillNotBeSaved: "Your changes will not be saved",
    },
  },
};
