import { AsyncAction } from 'types/utilities';

import { ICommonTranslation, IPagesTranslation } from 'models/translation';

import translationService from 'services/api/translation/translationService';

import { ActionType } from '../types';

import {
  GetCommonTranslation,
  GetCommonTranslationSuccess,
  GetPageTranslation,
  GetPageTranslationSuccess,
} from './translation.types';

// ============================== Redux Actions =======================================================================
export const getPageTranslationSuccess: GetPageTranslationSuccess = (data, pageKey, locale) => ({
  type: ActionType.GetPageTranslationSuccess,
  payload: {
    pageKey,
    data,
    locale,
  },
});

export const getCommonTranslationSuccess: GetCommonTranslationSuccess = (data, locale) => ({
  type: ActionType.GetCommonTranslationSuccess,
  payload: {
    data,
    locale,
  },
});

export const getPageTranslation: AsyncAction<GetPageTranslation> = ({ locale, pageKey }, options = {}) => async (
  dispatch
) => {
  try {
    const translation: IPagesTranslation[keyof IPagesTranslation] = await translationService.getTranslation(
      { locale, pageKey },
      options
    );

    dispatch(getPageTranslationSuccess(translation, pageKey, locale));
  } catch (e) {
    if (options.throwOnError) {
      throw e;
    }
  }
};

export const getCommonTranslation: AsyncAction<GetCommonTranslation> = ({ locale }, options = {}) => async (
  dispatch
) => {
  try {
    // There are no common translations... yet...
    // const translation: ICommonTranslation = await translationService.getTranslation<ICommonTranslation>(
    //  { locale },
    //  options
    //);
    dispatch(getCommonTranslationSuccess({}, locale));
  } catch (e) {
    if (options.throwOnError) {
      throw e;
    }
  }
};
