import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { IApplicationState } from 'store/index';
import { IPopup, PopupType } from 'store/ui/ui.types';

import ErrorPopup, { IErrorPopupDynamicProps } from 'components/Popups/ErrorPopup/ErrorPopup';

const Popups: React.FC = (): React.ReactElement | null => {
  const popup: IPopup | null = useSelector((state: IApplicationState) => state.ui.popup, shallowEqual);

  if (!popup) {
    return null;
  }

  const data = popup.data || {};

  switch (popup.type) {
    case PopupType.ErrorPopup:
      return <ErrorPopup {...(data as IErrorPopupDynamicProps)} />;
    default:
      return null;
  }
};

export default Popups;
