import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { Trans } from "i18n";
import { Spinner } from "../../../../../../../Atoms/Spinner/Spinner";
import FeatureBox from "../../../FeatureBox";
import {
  features,
  getAddOnTexts,
} from "../../../PlanSelector/PlanSelector.helpers";
import { IAddOns } from "../../../../../types";

interface Props {
  data: IAddOns[];
  setAddOn?: Dispatch<SetStateAction<IAddOns | undefined>>;
  isLoading: boolean;
  texts?: Record<string, string>;
}

const AddOnPreview: FC<Props> = ({ setAddOn, data, isLoading, texts }) => {
  useEffect(() => {
    if (data.length && setAddOn) {
      setAddOn(data[0]);
    }
  }, [data]);

  if (isLoading) return <Spinner />;

  return (
    <div>
      {data.map((addOn) => {
        const feature = features[addOn.code];
        const featurePrice = addOn.proRataValue ?? addOn.totalValue;
        const featureTexts = getAddOnTexts(addOn.code);

        return (
          <>
            <FeatureBox
              key={addOn.code}
              texts={{
                ...featureTexts,
                ...texts,
                description: "", // moving description to disclaimer position on modal
              }}
              vatPrice={addOn.amount}
              featurePrice={featurePrice}
              isFeatureSelected
              features={feature.mainFeatures}
              subFeatures={feature.subFeatures}
              currency={addOn.currency}
            />
            {texts?.disclaimer && (
              <p className="text-sm">
                <Trans
                  i18nKey={texts.disclaimer}
                  components={{
                    link1: <a href={texts.disclaimerLink} target="_blank" />,
                  }}
                />
              </p>
            )}
          </>
        );
      })}
    </div>
  );
};

export default AddOnPreview;
