import { i18n, TFunction } from "i18n";
import { getMediaRequestsSubNav } from "./sharedLinks";
import { IModifiers } from "./getNavHeaderLinks";

export function getGradsNav(t: TFunction, modifiers: IModifiers) {
  const { isMediaRequestEnabled } = modifiers;

  return {
    mainNavigationItems: [
      {
        text: t("common:nav.links.contactsListings"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1200/contacts.svg",
        linkUrl:
          "/contacts/?utm_source=leftNav&utm_medium=oldSite&utm_campaign=contactsLaunch",
      },
      {
        text: t("common:nav.links.jobsFeed"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2230/search.svg",
        linkUrl: "/jobs/matching-my-filters",
        subNavigationItems: [
          {
            text: t("common:nav.links.jobMatches"),
            linkUrl: "/jobs/matching-my-filters",
          },
          {
            text: t("common:nav.links.paidJobs"),
            linkUrl: "/jobs/all-paid",
          },
          {
            text: t("common:nav.links.allJobs"),
            linkUrl: "/jobs/all-opportunities",
          },
          ...getMediaRequestsSubNav(i18n.t, isMediaRequestEnabled),
          {
            text: t("common:nav.links.jobSettings"),
            linkUrl: "/settings/",
          },
        ],
      },
      {
        text: t("common:nav.links.usersProfile"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1199/clients.svg",
        linkUrl: "/profile/",
        subNavigationItems: [
          {
            text: t("common:nav.links.manageAgents"),
            linkUrl: "/profile/contacts",
          },
          {
            text: t("common:nav.links.viewUsersProfile"),
            linkUrl: "/profile/myviewpin/",
          },
          {
            text: t("common:nav.links.updateProfile"),
            linkUrl: "/profile/",
          },
          {
            text: t("common:nav.links.printUsersProfile"),
            linkUrl: "/profile?action=print",
          },
          {
            text: t("common:nav.links.emailUsersProfile"),
            linkUrl: "/profile?action=email",
          },
          {
            text: t("common:nav.links.usersProfileLink"),
            linkUrl: "/profile/linktoprofile",
          },
        ],
      },
      {
        text: t("common:nav.links.manageMedia"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1201/showreel.svg",
        linkUrl: "/profile/photos",
        subNavigationItems: [
          {
            text: t("common:nav.links.photos"),
            linkUrl: "/profile/photos",
          },
          {
            text: t("common:nav.links.video"),
            linkUrl: "/profile/media/video",
          },
          {
            text: t("common:nav.links.audio"),
            linkUrl: "/profile/media/audio",
          },
        ],
      },
      {
        text: t("common:nav.links.accountSettings"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1202/sun.svg",
        linkUrl: "/myhome/account-settings",
        subNavigationItems: [
          {
            text: t("common:nav.links.usersDetails"),
            linkUrl: "/myhome/account-settings/my-details",
          },
          {
            text: t("common:nav.links.privacySettings"),
            linkUrl: "/artists/privacy.aspx",
          },
        ],
      },
      {
        text: t("common:nav.help"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1203/lhnhelp-1.svg",
        linkUrl: "https://www.spotlight.com/help-and-faqs/",
      },
    ],
  };
}
