import React from "react";
import "./InputSearchIcon.scss";

export const InputSearchIcon: React.FC = () => {
  return (
    <div className="c-input-search-icon__container">
      <div className="c-input-search-icon icon-magnify" aria-label="Search" />
    </div>
  );
};
