import React from "react";

import {
  IconTextLink,
  IIconTextLinkProps,
} from "../../../Atoms/IconTextLink/IconTextLink";
import { LinkTarget } from "../../../../generics/types";
import { Photo } from "../../../Atoms/Photo/Photo";
import { QuickViewHeader } from "../QuickViewHeader/QuickViewHeader";

import "./QuickViewContent.scss";

export interface IQuickViewContentProps {
  /** Url for loading image */
  personImgUrl: string;
  /** Alternate text for image */
  personImgAltText?: string;
  /** list of objects that contains structure for the IconTextLink (see in Atoms) */
  links: IIconTextLinkProps[];
  /** target type for image link. by default '_blank' */
  linksTarget?: LinkTarget;
  /** Placeholder for performer full name */
  personFullName: string;
  /** URL for linking performer name */
  personLinkUrl: string;
  /** target type for person link. by default '_blank' */
  personLinkTarget?: LinkTarget;
  /** Optional id for wrapper */
  id?: string;
  /** right block details */
  personDetails?: JSX.Element;
}

export class QuickViewContent extends React.Component<IQuickViewContentProps> {
  public render() {
    const {
      personImgUrl,
      personImgAltText,
      links,
      linksTarget,
      personFullName,
      personLinkUrl,
      personLinkTarget,
      id,
      personDetails,
      children,
    } = this.props;

    return (
      <div className="c-quick-view-content g-inner-grid g-col-lg-12" id={id}>
        <div className="g-col-lg-12">
          <QuickViewHeader
            title={personFullName}
            link={personLinkUrl}
            linkTarget={personLinkTarget}
          />
        </div>
        <div className="c-quick-view-content__wrapper g-col-lg-12 g-inner-grid">
          <div className="c-quick-view-content__content g-col-sm-6 g-col-md-3 g-col-lg-3">
            <a
              className="c-quick-view-content__image-container"
              href={personLinkUrl}
              target={personLinkTarget || "_blank"}
            >
              <Photo
                className="c-quick-view-content__image"
                src={personImgUrl}
                alt={personImgAltText}
                hasLoadingSpinner={true}
              />
            </a>
          </div>
          <div className="flex flex-col flex-wrap self-start flex-1 gap-2 p-2 md:pl-0 md:flex-row g-col-sm-6 g-col-md-9 g-col-lg-9">
            {links.map((item, key) => (
              <IconTextLink
                key={key}
                variant="button-icon-text-secondary"
                target={linksTarget || "_blank"}
                text={item.text}
                linkUrl={item.linkUrl}
                imageSourceUrl={item.imageSourceUrl}
                className="max-w-full overflow-hidden"
              />
            ))}
          </div>
          <div className="c-quick-view-content__details g-col-sm-12 g-col-md-6 g-col-lg-6">
            {personDetails}
          </div>
          <div className="c-quick-view-content__children g-col-lg-12">
            {children}
          </div>
        </div>
      </div>
    );
  }
}
