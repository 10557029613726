import React, { FC, MutableRefObject } from "react";
import cx from "classnames";
import "./styles.scss";

interface Props {
  title?: string;
  isOpen?: boolean;
  sectionRef?: MutableRefObject<any>;
  customClasses?: string;
}

const Section: FC<Props> = ({
  title,
  isOpen = false,
  sectionRef,
  children,
  customClasses,
}) => {
  const sectionClassName = cx("section__header", {
    section__header_close: !isOpen,
  });

  return (
    <div className={`section ${customClasses}`} ref={sectionRef}>
      {title && <h2 className={sectionClassName}>{title}</h2>}
      {isOpen && children}
    </div>
  );
};

export default Section;
