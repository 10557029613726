import {
  IJoiningPagesFallbacks,
  JoiningPage,
} from "../../models/joining-pages";

import { AboutYouPageTranslations } from "./AboutYouPage";
import { JoiningPagesCommonTranslations } from "./Common";
import { CompletePageFallbacks } from "./CompletePage";
import { JoiningAsPageTranslations } from "./JoiningAsPage";
import { LoginPageTranslations } from "./LoginPage";
import { ReviewPageTranslations } from "./ReviewPage";

export const JoiningPagesFallbacks: IJoiningPagesFallbacks = {
  [JoiningPage.Common]: JoiningPagesCommonTranslations,
  [JoiningPage.LoginPage]: LoginPageTranslations,
  [JoiningPage.JoiningAsPage]: JoiningAsPageTranslations,
  [JoiningPage.AboutYouPage]: AboutYouPageTranslations,
  [JoiningPage.ReviewPage]: ReviewPageTranslations,
  [JoiningPage.CompletePage]: CompletePageFallbacks,
};
