import { useQuery } from "react-query";
import { billingService, FETCH_SUBSCRIPTIONS } from "../services";

export const useGetSubscriptions = (
  enabled: boolean = true,
  planType: string = ""
) => {
  const { data, isLoading } = useQuery(
    [FETCH_SUBSCRIPTIONS, enabled, planType],
    async () => {
      return await billingService.getSubscriptions(planType);
    },
    {
      enabled,
    }
  );

  return { data: data || [], isLoading };
};
