import React from "react";

/**
 * Stores the previous value of state
 * i.e. value is 1 then updates to 2, value here would be 1
 */
export const usePrevious = <T,>(value: T) => {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
