import cx from "classnames";
import React from "react";

import "./PageSection.scss";

export interface IPageSectionProps {
  className?: string;
  noPaddingSm?: boolean;
}

const PageSection: React.FC<IPageSectionProps> = (props) => (
  <div
    className={cx("c-page-section", props.className, {
      "c-page-section--no-padding-sm": props.noPaddingSm,
    })}
  >
    {props.children}
  </div>
);

export default PageSection;
