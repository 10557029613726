import { TFunction } from "i18n";
import { FacetType, IFacet } from "shared-services";
import { FilterDrawerMediaFilterNames } from "../FilterDrawerMediaFilter";
import { IFilterTagProps } from "../../Atoms/FilterTag/FilterTag";
import { IFilterDrawerItem } from "../FilterControl/FilterDrawer/FilterDrawer";
import { IRangeSelectionValue } from "../../Molecules/RangeSelectionDropdown/RangeSelectionDropdown";
import { IOption } from "../../../generics/interfaces";
import { ICheckboxGroupValue } from "../CheckboxGroup/CheckboxGroup";

export enum FiltersFieldNames {
  Gender = "gender",
  PlayingAge = "playingAge",
  Locations = "locationId",
  Media = "media",
}

export type SetFilterValues<T> = (values: Partial<T>) => void;

export interface IGetFilterFieldConfig<T> {
  name: FiltersFieldNames;
  facetName?: FacetType;
  values: T;
  filterDrawerValues: T;
  setFilterValues: SetFilterValues<T>;
  setFilterDrawerValues: SetFilterValues<T>;
  facets: Record<FacetType, IFacet[]>;
  stats?: IFilterStats;
  dataTestId?: string;
  t: TFunction;
}

export interface IFilterFieldConfig<T> {
  name: FiltersFieldNames;
  facetName?: FacetType;
  getFilterFiledProps: (props: IGetFilterFieldConfig<T>) => {
    tags: IFilterTagProps[];
    filterDrawerConfig: IFilterDrawerItem;
    filterControlComponent?: JSX.Element;
  };
  defaultValue: unknown;
  dataTestId?: string;
}

export type FilterConfig<T> = IFilterFieldConfig<T>[];

export interface IFilterValues {
  [FiltersFieldNames.Gender]: ICheckboxGroupValue;
  [FiltersFieldNames.PlayingAge]: IRangeSelectionValue;
  [FiltersFieldNames.Locations]: IOption<string>[];
  [FiltersFieldNames.Media]: {
    [FilterDrawerMediaFilterNames.HasShowreels]: boolean;
    [FilterDrawerMediaFilterNames.HasAudioClips]: boolean;
  };
}

export interface IFilterStats {
  performersWithShowReelsCount?: number;
  performersWithAudioClipsCount?: number;
}
