import React, { CSSProperties } from "react";
import { useField } from "formik";
import Select, {
  components,
  ControlProps,
  CSSObjectWithLabel,
  StylesConfig,
} from "react-select";
import { useFormikScrollToError } from "shared-hooks";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { FixedSizeList as List } from "react-window";
import isEqual from "lodash/isEqual";
import { dataTestIds } from "data-testids";

import { ERROR, InputWrapper } from "../../InputsCommon/InputWrapper";
import { InputLabel } from "../../InputsCommon/InputLabel";
import { InputSearchIcon } from "../../InputsCommon/InputSearchIcon";
import { ReactComponent as ArrowSelectDown } from "../../../../images/svg/icons/arrow-select-down.svg";
import { ReactComponent as ArrowSelectUp } from "../../../../images/svg/icons/arrow-select-up.svg";

import "./SelectFormik.scss";

const {
  ValueContainer,
  SingleValue,
  Placeholder,
  Option,
  MenuList,
  ClearIndicator,
} = components;

const OPTION_HEIGHT = 50;

export interface IOption {
  label: string;
  value: any;
}

interface ISelectFormikProps {
  // compulsory props
  name: string;
  options: IOption[] | ((input: string) => Promise<IOption[]>);
  // optional props
  label?: string;
  annex?: JSX.Element;
  selectMultiple?: boolean;
  searchableByInput?: boolean;
  searchableBySearchbox?: boolean;
  canCreateOptions?: boolean;
  noOptionsText?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  maxAmountOfOptionsBeforeScroll?: number; // set the max amount of options to show inside menu list before scrolling
  whiteInput?: boolean;
  isDisabled?: boolean;
  tooltipComponent?: () => JSX.Element;
  isClearable?: boolean;
  className?: string;
}

export const SelectFormik: React.FC<ISelectFormikProps> = ({
  name,
  options,
  label,
  annex,
  selectMultiple,
  searchableByInput,
  searchableBySearchbox,
  canCreateOptions,
  noOptionsText,
  placeholder,
  searchPlaceholder,
  maxAmountOfOptionsBeforeScroll,
  whiteInput,
  tooltipComponent,
  isDisabled = false,
  isClearable = false,
  className,
}) => {
  const isAsync = typeof options === "function";

  const { containerElementRef, fieldRef } = useFormikScrollToError<
    any,
    HTMLDivElement
  >(name);

  const [field, meta, helpers] = useField(name);
  const invalid = meta.touched && meta.error ? ERROR : undefined;

  const containerRef = React.useRef(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  // used to set default value of non async select
  let defaultValue = null;
  if (!isAsync) {
    defaultValue = options?.find((option) =>
      isEqual(option.value, field.value)
    );
  }

  const addOption = (optionToAdd: any) => {
    if (selectMultiple && !field.value?.includes(optionToAdd)) {
      helpers.setValue([...field.value, optionToAdd]);
    } else if (!selectMultiple) {
      helpers.setValue(optionToAdd);
    }
  };

  // @ts-ignore
  const onDomClick = (e: MouseEvent) => {
    // @ts-ignore
    const menu = containerRef?.current?.querySelector(".select__menu");

    if (
      // @ts-ignore
      !containerRef?.current?.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValue("");
    }
  };

  const handleChange = (option: any) => {
    if (!option) {
      helpers.setValue(null);
      return;
    }
    const selectedOption = option.value;
    addOption(selectedOption);
    setIsFocused(false);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", onDomClick);
    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  const customStyles: StylesConfig<IOption, false> = {
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      borderRadius: 0,
      marginBottom: 0,
      marginTop: 0,
      border: "1px solid $color-neutral-two",
      zIndex: 200,
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
      ...provided,
      maxHeight: (maxAmountOfOptionsBeforeScroll || 5) * OPTION_HEIGHT,
    }),
    // @ts-ignore
    option: (provided: CSSObjectWithLabel) => ({
      ...provided,
      ["&:active"]: {
        backgroundColor: "#fff",
      },
      background: "#f5f4f9",
      color: "#1c1c1c",
      height: OPTION_HEIGHT,
      paddingLeft: "20px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontFamily: "$font-primary",
    }),
    // @ts-ignore
    control: (provided: CSSProperties, state: ControlProps<{}>) => {
      let borderColour = "#6e6e77";
      if (meta.touched && meta.error) borderColour = "#cd263a";
      if (state.isFocused || state.menuIsOpen) borderColour = "#476cb5";
      return {
        ...provided,
        ["&:hover"]: null,
        borderRadius: 0,
        boxShadow: "none",
        border: `1px solid ${borderColour}`,
        outline: state.isFocused && "1px solid #476cb5",
        background: whiteInput ? "#fff" : "#f5f4f9",
        color: "#1c1c1c",
        height: OPTION_HEIGHT,
        width: "100%",
        cursor: searchableByInput ? "text" : "pointer",
        fontFamily: `$font-primary`,
      };
    },
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      marginRight: "10px",
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: "#6E6E77",
    }),
    clearIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      cursor: "pointer",
    }),
  };

  const props = {
    styles: customStyles,
    components: {
      MenuList: CustomMenuList,
      ValueContainer: CustomValueContainer,
      DropdownIndicator,
      IndicatorSeparator: () => null,
      Option: CustomOption,
      ClearIndicator: CustomClearIndicator,
    },
    inputValue: inputValue,
    isSearchable: Boolean(searchableByInput),
    ignoreAccents: false,
    onMenuInputFocus: () => setIsFocused(true),
    onChange: handleChange,
    onInputChange: (val: any) => setInputValue(val),
    showSearchInput: Boolean(searchableBySearchbox),
    ...{
      menuIsOpen: isFocused || undefined,
      isFocused: isFocused || undefined,
    },
    noOptionsMessage: () => noOptionsText || "",
    placeholder: placeholder || "",
    searchPlaceholder,
    isDisabled,
    onBlur: () => {
      helpers.setTouched(true);
    },
    isClearable,
    name,
  };

  let ReactSelectVariant = null;

  if (isAsync && canCreateOptions) {
    ReactSelectVariant = (
      <AsyncCreatableSelect
        ref={fieldRef}
        cacheOptions
        defaultOptions
        loadOptions={options}
        {...props}
      />
    );
  }

  if (isAsync && !canCreateOptions) {
    ReactSelectVariant = (
      <AsyncSelect
        ref={fieldRef}
        cacheOptions
        defaultOptions
        loadOptions={options}
        {...props}
      />
    );
  }

  if (!isAsync && canCreateOptions) {
    ReactSelectVariant = (
      <CreatableSelect
        ref={fieldRef}
        options={options}
        {...props}
        defaultValue={defaultValue}
      />
    );
  }

  if (!isAsync && !canCreateOptions) {
    ReactSelectVariant = (
      <Select
        ref={fieldRef}
        options={options}
        {...props}
        defaultValue={defaultValue}
      />
    );
  }

  return (
    <div className={`SelectFormik__container ${className}`}>
      <div className="SelectFormik__scrollAnchor" ref={containerElementRef} />
      <InputWrapper invalid={invalid} validationMesssage={meta.error}>
        <div className="flex items-baseline justify-between">
          <InputLabel label={label} annex={annex} htmlFor={field.name} />
          {tooltipComponent && tooltipComponent()}
        </div>

        <div
          data-testid={`${dataTestIds.componentLibrary["Atoms.FormikInputs.SelectFormik.select"]}${props.name}`}
          ref={containerRef}
        >
          {ReactSelectVariant}
        </div>
      </InputWrapper>
    </div>
  );
};

// @ts-ignore
const CustomOption = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    // @ts-ignore
    <Option
      {...newProps}
      className="SelectFormik__option"
      data-testid={
        dataTestIds.componentLibrary[
          "Atoms.FormikInputs.SelectFormik.list-option"
        ]
      }
    >
      {children}
    </Option>
  );
};

const DropdownIndicator = (props: any) => {
  // do not show dropdown indicator when it's a search input variant
  if (props.selectProps.isSearchable) return null;

  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <ArrowSelectUp /> : <ArrowSelectDown />}
    </components.DropdownIndicator>
  );
};

const CustomClearIndicator = (props: any) => {
  return (
    <ClearIndicator {...props}>
      <button className="icon-cross-circle" aria-label="Clear input" />
    </ClearIndicator>
  );
};

// @ts-ignore
const CustomMenuList = ({ selectProps, ...props }) => {
  const {
    onInputChange,
    inputValue,
    onMenuInputFocus,
    showSearchInput,
    searchPlaceholder,
  } = selectProps;

  const { maxHeight, children, options, getValue } = props;

  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"],
  };

  const [value] = getValue();
  const initialOffset = options.indexOf(value) * OPTION_HEIGHT;
  const isOptions = Array.isArray(children);
  const amountOfOptions = isOptions ? children.length : 0;

  // used to set height of options container
  let menuHeight = 50;
  if (isOptions) {
    const totalOptionsHeight = amountOfOptions * OPTION_HEIGHT;
    menuHeight =
      totalOptionsHeight > maxHeight ? maxHeight : totalOptionsHeight;
  }

  return (
    <div style={{ background: "#f5f4f9" }}>
      {showSearchInput && (
        <div
          style={{
            padding: 8,
            background: "#f5f4f9",
          }}
        >
          {/* @ts-ignore */}
          <input
            className="box-border w-full p-2 bg-white border border-color-neutral-one pl-9"
            autoCorrect="off"
            autoComplete="off"
            spellCheck="false"
            type="text"
            value={inputValue}
            onChange={(e) =>
              onInputChange(e.currentTarget.value, {
                action: "input-change",
              })
            }
            onMouseDown={(e) => {
              e.stopPropagation();
              // @ts-ignore
              e.target.focus();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              // @ts-ignore
              e.target.focus();
            }}
            onFocus={onMenuInputFocus}
            placeholder={searchPlaceholder}
            {...ariaAttributes}
          />
          <div style={{ marginTop: 2 }}>
            <InputSearchIcon />
          </div>
        </div>
      )}

      {/* switch between virtualization when list is large enough */}
      {amountOfOptions > 200 ? (
        <List
          height={menuHeight}
          width={"100%"}
          itemCount={children.length}
          itemSize={OPTION_HEIGHT}
          initialScrollOffset={initialOffset}
        >
          {/* @ts-ignore */}
          {({ style, index }) => <div style={style}>{children[index]}</div>}
        </List>
      ) : (
        // @ts-ignore
        <MenuList {...props} selectProps={selectProps} />
      )}
    </div>
  );
};

// @ts-ignore
const CustomValueContainer = ({ children, selectProps, ...props }) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme,
  };
  const { isSearchable, inputValue } = selectProps;
  const hidePlaceholderValue = isSearchable && Boolean(inputValue);

  return (
    // @ts-ignore
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <SingleValue
            {...commonProps}
            // @ts-ignore
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}
          >
            {!hidePlaceholderValue &&
              selectProps.getOptionLabel(props.getValue()[0])}
          </SingleValue>
        ) : (
          <Placeholder
            {...commonProps}
            key="placeholder"
            isDisabled={selectProps.isDisabled}
            // @ts-ignore
            data={props.getValue()}
          >
            {!hidePlaceholderValue && selectProps.placeholder}
          </Placeholder>
        );
      })}
    </ValueContainer>
  );
};
