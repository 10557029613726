const defaultCode = "+44";

export const getNumber = (incomingPhoneNumber = "") => {
  const incomingPhoneNumberTrimmed = incomingPhoneNumber.trim();
  // default to UK code
  let callingCode, number;
  if (incomingPhoneNumberTrimmed.startsWith("+")) {
    const parts = incomingPhoneNumberTrimmed.split(" ");
    if (parts.length === 1) {
      callingCode = defaultCode;
      number = incomingPhoneNumberTrimmed.substring(defaultCode.length);
    } else {
      callingCode = parts[0];
      number = parts[1];
    }
  } else {
    callingCode = defaultCode;
    number = incomingPhoneNumberTrimmed;
  }

  return {
    callingCode,
    number: number === "undefined" ? "" : number,
  };
};
