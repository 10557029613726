import { TFunction } from "i18n";

export function getCDSearchOnlyNav(t: TFunction) {
  return {
    mainNavigationItems: [
      {
        text: t("common:nav.links.createJob"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1160/edit.svg",
        linkUrl: "https://spotlightuk.typeform.com/to/z7dZxceX",
      },
      {
        text: t("common:nav.links.industryResources"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/help.svg",
        linkUrl:
          "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight/",
      },
      {
        text: t("common:nav.links.contactsListings"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1163/contacts.svg",
        linkUrl: "/contacts/",
      },
      {
        text: t("common:nav.links.agentsList"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1174/twoclients.svg",
        linkUrl: "/jobs/agent-lists",
      },
      {
        text: t("common:nav.links.performerSearch"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1166/magnify.svg",
        linkUrl: "/performers",
      },
      {
        text: t("common:nav.links.shortlists"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1171/shortlist.svg",
        linkUrl: "/shortlists",
      },
      {
        text: t("common:nav.links.contactDetails"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1173/sun.svg",
        linkUrl: "https://portal.spotlight.com/portal/Account/Details",
      },
      {
        text: t("common:nav.links.performanceCalendar"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1170/performancecal.svg",
        linkUrl: "https://calendar.spotlight.com/",
      },
      {
        text: t("common:nav.help"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1164/lhnhelp.svg",
        linkUrl:
          "https://www.spotlight.com/the-small-print/help-for-casting-users-of-the-spotlight-link/",
      },
    ],
  };
}
