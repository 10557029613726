// @ts-nocheck
import React from "react";
import {
  ICheckboxConfig,
  ICheckboxGroupItemConfig,
} from "../../Organisms/CheckboxGroup/components/CheckboxGroupItem";
import { IDropdownCheckboxValueProps } from "./DropdownCheckbox";

import "./DropdownCheckboxValue.scss";

const traverseBy = (obj, callback) => {
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      if (obj[k] && typeof obj[k] === "object") {
        traverseBy(obj[k], callback);
      } else {
        callback(k, obj[k], obj);
      }
    }
  }
};

export interface IDropdownCheckboxValueItemConfig extends ICheckboxConfig {
  selectedLabel: string;
}

export interface IDropdownCheckboxGroupValueItemConfig
  extends ICheckboxGroupItemConfig {
  options: Array<
    IDropdownCheckboxGroupValueItemConfig | IDropdownCheckboxValueItemConfig
  >;
}

export interface IDropdownCheckboxValueSettings {
  selectionPlaceholder: React.ReactNode;
  selectionPrefix: React.ReactNode;
}

export function getDropdownCheckboxValueComponent(
  settings: IDropdownCheckboxValueSettings
) {
  return (
    props: IDropdownCheckboxValueProps<IDropdownCheckboxValueItemConfig>
  ) => <DropdownCheckboxValue {...settings} {...props} />;
}

export function DropdownCheckboxValue({
  options,
  selected,
  selectionPlaceholder,
  selectionPrefix,
}: IDropdownCheckboxValueProps<IDropdownCheckboxValueItemConfig> &
  IDropdownCheckboxValueSettings) {
  const selectedValues: string[] = [];
  traverseBy(selected, (key, val) => val && selectedValues.push(key));

  const valuesToRender = selectedValues
    .map((val) => options.find((opt) => opt.value === val))
    .map((opt) => opt && opt.selectedLabel)
    .filter(Boolean);

  return valuesToRender.length ? (
    <span className="c-dropdown-checkbox-value__container">
      <span>
        {selectionPrefix}{" "}
        <span className="c-dropdown-checkbox-value__selection">
          {valuesToRender.join(", ")}
        </span>
      </span>
    </span>
  ) : (
    <span className="c-dropdown-checkbox-value__placeholder">
      {selectionPlaceholder}
    </span>
  );
}
