import React from "react";
import cx from "classnames";

import { getWindowWidth } from "../../../generics/dom-extensions";
import { debounce } from "../../../generics/debounce";

import "./Dropdown.scss";

export interface IDropdownProps {
  toggleContent: string | React.ReactNode;
  /**
   * Defines function which will render the dropdown content
   */
  renderOptions: () => string | React.ReactNode;
  /**
   * Defines function which will render after options list
   */
  renderOptionsTail?: () => React.ReactNode;
  /**
   * Optional
   * If true, keeps the dropdown expanded while selecting, but will still close normally via tabbing or clicking out of it
   */
  keepOpenOnSelect?: boolean;
  /**
   * Optional
   * The onFocus property sets and returns the event handler for the change event.
   */
  onFocus?: (event: React.FocusEvent) => void;
  /**
   * Optional
   * The onBlur property sets and returns the event handler for the change event.
   */
  onBlur?: (event: React.FocusEvent) => void;
  /**
   * Optional
   * The onClose property sets the event handler for the dropdown closing event.
   */
  onClose?: () => void;
  /**
   *  Optional css class for the topmost div
   */
  className?: string;
  /**
   *  Optional aria-label
   */
  ariaLabel?: string;
  /**
   * Optional flag to disable the component
   */
  disabled?: boolean;
  /**
   * optional id for the list box (defaults to a random one)
   */
  listboxID?: string;
  /**
   * Optional
   * Defines the content to render into the toggle
   */
  "data-testid"?: string;
}

export interface IDropdownState {
  isOpen: boolean;
  isFocused: boolean;
  isDisabled: boolean;
}

export class Dropdown extends React.Component<IDropdownProps, IDropdownState> {
  private readonly rootRef = React.createRef<HTMLDivElement>();
  private readonly controlRef = React.createRef<HTMLDivElement>();
  private readonly listRef = React.createRef<HTMLDivElement>();
  private readonly toggleRef = React.createRef<HTMLDivElement>();
  private readonly listboxID = this.props.listboxID || Math.random().toString();

  public readonly state = {
    isOpen: false,
    isFocused: false,
    isDisabled: Boolean(this.props.disabled),
  };

  private documentKeyDownHandlers = {
    Escape: (event: React.KeyboardEvent<HTMLElement>) => {
      event.preventDefault();
      this.toggle();
    },
  };

  private toggleKeyDownHandlers = {
    " ": (event: React.KeyboardEvent<HTMLElement>) => {
      event.preventDefault();
      this.toggle();
    },
    Enter: (event: React.KeyboardEvent<HTMLElement>) => {
      event.preventDefault();
      this.toggle();
    },
  };

  public render() {
    const listboxID = this.listboxID;
    const {
      props: {
        ariaLabel,
        className,
        toggleContent,
        renderOptions,
        renderOptionsTail,
      },
      state: { isOpen },
    } = this;

    return (
      <div
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-owns={listboxID}
        className={cx(className, "c-dropdown")}
        ref={this.rootRef}
      >
        <div
          className="c-dropdown__control"
          ref={this.controlRef}
          aria-controls={listboxID}
          aria-label={ariaLabel}
        >
          <div
            tabIndex={0}
            onBlur={this.handleToggleBlur}
            onFocus={this.handleToggleFocus}
            className="c-dropdown__toggle"
            onClick={this.toggle}
            ref={this.toggleRef}
            onKeyDown={this.handleToggleKeyDown}
            data-testid={this.props["data-testid"]}
          >
            <div className="c-dropdown__toggle-content">{toggleContent}</div>
          </div>
          <div
            ref={this.listRef}
            className={cx({
              "c-dropdown__options-container": true,
              open: isOpen,
            })}
          >
            <div className="c-dropdown__options">
              {isOpen && renderOptions()}
            </div>
            {isOpen && renderOptionsTail && renderOptionsTail()}
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    this.removeListeners();
  }

  public componentDidUpdate() {
    this.adaptToWindow();
  }

  private toggle = () => {
    if (this.state.isDisabled) {
      return;
    }

    this.setState((state) => {
      if (state.isOpen) {
        this.removeListeners();
        this.props.onClose?.();
      } else {
        this.addListeners();
      }

      return { ...state, isOpen: !state.isOpen };
    });
  };

  private addListeners = () => {
    window.addEventListener("scroll", this.adaptToWindowDebounced);
    window.addEventListener("resize", this.adaptToWindowDebounced);
    document.addEventListener("focus", this.handleDocBlur, true);
    document.addEventListener("click", this.handleDocBlur, true);
    document.addEventListener("keydown", this.handleDocumentKeydown);
  };

  private removeListeners = () => {
    window.removeEventListener("scroll", this.adaptToWindowDebounced);
    window.removeEventListener("resize", this.adaptToWindowDebounced);
    document.removeEventListener("focus", this.handleDocBlur, true);
    document.removeEventListener("click", this.handleDocBlur, true);
    document.removeEventListener("keydown", this.handleDocumentKeydown);
  };

  private close = () => {
    if (this.state.isOpen) {
      this.toggle();
    }
  };

  private handleToggleBlur = (event: React.FocusEvent) => {
    this.setState({ isFocused: false }, () => {
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    });
  };

  private handleDocBlur = (event: FocusEvent | MouseEvent) => {
    const rootNode = this.rootRef.current;
    if (!rootNode) {
      return;
    }

    // handle both outside focus and click
    if (
      !rootNode.contains(event.target as Node) ||
      (this.state.isOpen && !this.props.keepOpenOnSelect)
    ) {
      this.close();
    }
  };

  private handleToggleFocus = (event: React.FocusEvent) => {
    this.setState({ isFocused: true }, () => {
      if (this.props.onFocus) {
        this.props.onFocus(event);
      }
    });
  };

  private handleDocumentKeydown = (event) => {
    const keydownHandler = this.documentKeyDownHandlers[event.key];

    if (keydownHandler) {
      keydownHandler(event);
    }
  };

  private handleToggleKeyDown = (event) => {
    const keydownHandler = this.toggleKeyDownHandlers[event.key];

    if (keydownHandler) {
      keydownHandler(event);
    }
  };

  private adaptToWindow = () => {
    const list = this.listRef.current;
    const root = this.rootRef.current;

    if (list == null || root == null) {
      return;
    }

    const rootBoundingRect = root.getBoundingClientRect();
    const listBoundingRect = list.getBoundingClientRect();

    const listLX = listBoundingRect.left;
    const listRX = listBoundingRect.right;
    const inputLX = rootBoundingRect.left;
    const inputRX = rootBoundingRect.right;
    const windowWidth = getWindowWidth();

    const elementWidth = Math.abs(listRX - listLX);

    const rightEdge = -(inputLX + elementWidth - windowWidth);
    const leftEdge = elementWidth - inputRX;

    const leftPatch = Math.min(0, rightEdge);

    if (leftEdge > 0 && rightEdge > 0) {
      list.style.left = `0px`;
      list.style.right = "";
    } else if (leftEdge < 0 && rightEdge < 0) {
      list.style.left = "";
      list.style.right = `0px`;
    } else {
      list.style.left = `${leftPatch}px`;
      list.style.right = "";
    }
  };

  private adaptToWindowDebounced = debounce(this.adaptToWindow, 200, false);
}
