import React from "react";
import { connect } from "react-redux";
import { hidePopup } from "store/ui/ui.actions";
import { HidePopup } from "store/ui/ui.types";
import getErrorPopupType from "helpers/getErrorPopupType";
import ErrorPopup from "components/Popups/ErrorPopup/ErrorPopup";
import { getErrorPopupTexts } from "component-library";

interface IErrorBoundaryProps {
  children: React.ReactNode;
  hidePopup: HidePopup;
}

interface IErrorBoundaryState {
  showError: boolean;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  public readonly state: IErrorBoundaryState = { showError: false };

  public componentDidCatch(e: Error) {
    this.setState({
      showError: true,
    });
  }

  public handleClick = () => {
    location.reload();
  };

  public render() {
    if (this.state.showError) {
      return (
        <ErrorPopup
          {...getErrorPopupTexts()[getErrorPopupType()]}
          handleButtonClick={this.handleClick}
          handleCloseClick={this.handleClick}
          hidePopup={this.props.hidePopup}
        />
      );
    }

    return this.props.children;
  }
}

export default connect(null, {
  hidePopup,
})(ErrorBoundary);
