import React, { ReactNode } from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";

import { LinkTarget } from "../../../../generics/types";
import { IconTextLink } from "../../../Atoms/IconTextLink/IconTextLink";

import "./MainNavigationItem.scss";

export interface IMainNavigationItemProps {
  /** The text for the link */
  text: string;
  /** render label */
  renderLabel?: ReactNode;
  /** render svg icon */
  svgIconStart?: ReactNode;
  /** divider */
  divider?: boolean;
  /** URL to go to if the label is used as a link */
  linkUrl: string;
  /** Optinal icon source */
  icon?: string;
  /** Handler for interacting with the button via click, Enter or Spacebar keys */
  handleClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    text: string,
    link: string
  ) => void;
  /** Optional css class for the topmost div */
  className?: string;
  /** Active state for link */
  isSelected?: boolean;
  /** Optional link target */
  target?: LinkTarget;
}

export class MainNavigationItem extends React.Component<IMainNavigationItemProps> {
  public render() {
    const {
      linkUrl,
      text,
      isSelected,
      children,
      target,
      icon,
      svgIconStart,
      renderLabel,
      divider,
    } = this.props;

    if (divider)
      return <div className="h-[1px] w-full bg-grey-medium-3 my-2.5" />;

    return (
      <div
        className={cx(`c-main-nav-item`, {
          "c-main-nav-item__selected": isSelected,
        })}
      >
        <div
          className="c-main-nav-item__container"
          data-testid={
            dataTestIds.componentLibrary[
              "Organisms.NavigationHeader.MainNavigationItem.mainNavigationLink"
            ]
          }
        >
          <IconTextLink
            text={text}
            renderLabel={renderLabel}
            svgIconStart={svgIconStart}
            variant="mainnav"
            active={isSelected}
            imageSourceUrl={icon}
            linkUrl={linkUrl}
            target={target}
            handleClick={this.handleClick}
          />
        </div>
        {children && <div className="c-main-nav-item__child"> {children} </div>}
      </div>
    );
  }

  private handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const { text, linkUrl, handleClick } = this.props;
    if (handleClick) {
      handleClick(event, text, linkUrl);
    }
  };
}
