import { SubscriptionCalculatedStatus } from "app/constants";

export interface ICheckRenewalPeriod {
  id?: string;
  subscription?: { calculatedStatus?: string };
}

export default function checkIsNoActiveSubscription({
  id,
  subscription,
}: ICheckRenewalPeriod) {
  return (
    (id && !subscription) ||
    subscription?.calculatedStatus === SubscriptionCalculatedStatus.Expired
  );
}
