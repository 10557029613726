import React, { FC, useCallback, useEffect, useState } from "react";
import {
  CardYearElement,
  CardMonthElement,
  IndividualElementChangeEvent,
} from "@recurly/react-recurly";
import cx from "classnames";
import CardElementWrapper, {
  CardElementWrapperProps,
} from "./CardElementWrapper";
import useScrollBillingField from "../../hooks/useScrollBillingField";
import { FieldNames } from "../../constants";

interface Props extends CardElementWrapperProps {
  placeholderYear?: string;
  placeholderMonth?: string;
  shouldScroll?: boolean;
  removeError: (field: string) => void;
  isMobileView: boolean | undefined;
}

const CardExpDate: FC<Props> = ({
  label,
  className,
  annex,
  placeholderYear = "",
  placeholderMonth = "",
  shouldScroll,
  validationMessage,
  removeError,
  isMobileView,
}) => {
  const ref = useScrollBillingField(!!(validationMessage && shouldScroll));
  const fieldClass = cx(`card-element ${isMobileView ? "w-[80px]" : ""}`, {
    invalid: !!validationMessage,
  });
  const [monthValid, setMonthValid] = useState<boolean>();
  const [yearValid, setYearValid] = useState<boolean>();

  useEffect(() => {
    if (monthValid && yearValid) {
      removeError(FieldNames.date);
    }
  }, [monthValid, yearValid, removeError]);

  const onChangeMonth = useCallback(
    (data: IndividualElementChangeEvent) => setMonthValid(data.valid),
    [setMonthValid]
  );

  const onChangeYear = useCallback(
    (data: IndividualElementChangeEvent) => setYearValid(data.valid),
    [setYearValid]
  );

  return (
    <CardElementWrapper
      label={label}
      annex={annex}
      className={className}
      validationMessage={validationMessage}
    >
      <div className="flex gap-[10px]" ref={ref}>
        <CardMonthElement
          className={fieldClass}
          style={{
            fontSize: "18px",
            placeholder: { content: placeholderMonth },
          }}
          onChange={onChangeMonth}
        />
        <CardYearElement
          className={fieldClass}
          style={{
            fontSize: "18px",
            placeholder: { content: placeholderYear },
          }}
          onChange={onChangeYear}
        />
      </div>
    </CardElementWrapper>
  );
};

export default CardExpDate;
