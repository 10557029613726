import { AccountType } from "shared-types";

import { fallbackNavigation } from "../fallbackTranslations";
import { fetchWithFallback } from "./fetchWithFallback";

import { INavigation } from "../models/navigation";

export const ENDPOINT_SEGMENT_BY_USER_TYPE = {
  [AccountType.DefaultUser]: "Anonymous",
  [AccountType.Agent]: "Agents",
  [AccountType.Performer]: "Performers",
  [AccountType.CastingDirector]: "Casting",
  [AccountType.CastingSearchOnly]: "CastingSearchOnly",
};

export class NavigationService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  public async getAppNav(
    {
      locale,
      userType,
    }: {
      locale: string;
      userType: AccountType;
    },
    options: { throwOnError?: boolean } = {}
  ): Promise<INavigation> {
    const userTypeString = ENDPOINT_SEGMENT_BY_USER_TYPE[userType];
    return fetchWithFallback(
      () =>
        fetch(
          `${this.url}/umbraco/api/navigation/${locale}/${userTypeString}/`
        ).then((res) => res.json()),
      // @ts-ignore
      fallbackNavigation[userType],
      options.throwOnError
    );
  }
}
