import React, { FC } from "react";
import { useTranslation } from "i18n";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import { CurrencyType, PlanCodePeriod, currencyMap } from "../../../types";
import { Button } from "../../../../../Atoms/Button/Button";
import "./styles.scss";

interface Props {
  isSelected: boolean;
  onSelect: () => void;
  planPeriod: PlanCodePeriod;
  currency: CurrencyType;
  pricePlusVat: number;
  price: number;
  texts: Record<string, any>;
  showBadge?: boolean;
}

const SubscriptionCard: FC<Props> = ({
  isSelected,
  onSelect,
  planPeriod,
  price,
  pricePlusVat,
  currency,
  texts,
  showBadge = false,
}) => {
  const { t } = useTranslation();

  const isAnnual = planPeriod === PlanCodePeriod.Annual;
  const currencySymbol = currencyMap[currency];
  const monthlyCalculatedPrice = isAnnual
    ? (pricePlusVat / 12).toFixed(2)
    : undefined;

  const textClassName = cx("block__text", {
    "block__text--selected": isSelected,
  });

  return (
    <div
      className={`ADD-ON-subscription-card ${
        isSelected ? "ADD-ON-subscription-card--selected" : ""
      }`}
      data-testid={
        dataTestIds.componentLibrary[
          "Organisms.Billing.AddOnCheckout.SubscriptionCard.addOnSubscriptionCard"
        ]
      }
    >
      <div className="ADD-ON-subscription-card__inner">
        <div className="block">
          <div className="block__details">
            <p className="block__header">{t(texts.title)}</p>
            {showBadge && (
              <span className="block__deal-badge">{t(texts.badgeText)}</span>
            )}
          </div>

          {texts.description && (
            <p className={textClassName}>{t(texts.description)}</p>
          )}
          <p className={textClassName}>
            <span
              className={cx("block__price", {
                "block__price--selected": isSelected,
              })}
            >
              {currencySymbol}
              {pricePlusVat.toFixed(2)}
            </span>{" "}
            <span>/ {t(texts.period)}</span>
            {isAnnual && monthlyCalculatedPrice && (
              <>
                <br />
                {t(
                  "common:billing.addOn.planSelector.annual.price.description"
                )}{" "}
                ({currencySymbol}
                {monthlyCalculatedPrice} / {t("common:label.month")})
              </>
            )}
          </p>
          <p className={textClassName}>
            {t("common:billing.vat", {
              price: currencySymbol + price.toFixed(2),
            })}
          </p>
        </div>
        <Button
          type="secondary"
          text={t(isSelected ? texts.button.selected : texts.button.unselected)}
          onClick={onSelect}
          className={cx({ "selected-button": isSelected })}
          data-testid={
            dataTestIds.componentLibrary[
              "Organisms.AddOnCheckout.SubscriptionCard.subscriptionWrapper"
            ]
          }
        />
      </div>
    </div>
  );
};

export default SubscriptionCard;
