import { useEffect, useState, Dispatch, SetStateAction } from "react";

const usePreventLeaveBillingPage = (
  errorMessage: string = "Are you sure you wish to continue?"
): Dispatch<SetStateAction<boolean>> => {
  const [preventLeave, setPreventLeave] = useState<boolean>(true);

  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = errorMessage;
      return errorMessage;
    };

    const addListener = () =>
      window.addEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });

    const removeListener = () =>
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });

    if (preventLeave) {
      addListener();
    }

    if (!preventLeave) {
      removeListener();
    }

    return removeListener;
  }, [errorMessage, preventLeave]);

  return setPreventLeave;
};

export default usePreventLeaveBillingPage;
