import React from "react";
import cx from "classnames";

import "./Radio.scss";

export interface IRadioProps {
  /** Name of the form element, as you would use for a "normal" radio */
  name: string;
  /** Value of the form element, as you would use for a "normal" radio */
  value: string;
  /** Optional id for the input element */
  htmlId?: string;
  /** Optional label */
  label?: string;
  /** Optional handler to hook into the change event. The function's argument will be the event itself. */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /** The checked attribute is a boolean attribute. When present, it specifies that a radio button should be pre-selected (checked) when the page loads. */
  checked?: boolean;
  /** Controlls whether the input should be disabled */
  disabled?: boolean;
  /** Optional css class for the topmost div */
  className?: string;
}

export class Radio extends React.PureComponent<IRadioProps> {
  public render() {
    const { className, disabled, label, checked, value, name, htmlId } =
      this.props;

    const attributes: any = {
      name,
      value,
      checked,
      id: htmlId || undefined,
    };

    return (
      <div
        className={cx("c-radio", {
          [className as string]: Boolean(className),
          "c-radio__disabled": disabled,
        })}
      >
        <label className="c-radio__container">
          <input
            {...attributes}
            className="c-radio__input"
            type="radio"
            onChange={this.handleChange}
            disabled={disabled}
          />
          <span className="c-radio__content">
            <span className="c-radio__box" />
            <span className="c-radio__text">{label}</span>
          </span>
        </label>
      </div>
    );
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.props.disabled && this.props.onChange) {
      return this.props.onChange(e);
    }
  };
}
