import { IApplicationState } from "app/store";
import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Store } from "redux";

import { BASE_PATH } from "config/common";
import config from "config/global";
import ErrorBoundary from "app-shell/ErrorBoundary/ErrorBoundary";
import { GoogleTagManager } from "app-shell/GoogleTagManager/GoogleTagManager";
import RoxProvider from "app-shell/RoxProvider/RoxProvider";
import App from "../../App";

interface IRootProps {
  store: Store<IApplicationState>;
}

class Root extends React.Component<IRootProps> {
  public render() {
    return (
      <Provider store={this.props.store}>
        <ErrorBoundary>
          <RoxProvider>
            <GoogleTagManager {...config.gtm} />
            <Router>
              <Switch>
                <Route path={BASE_PATH}>
                  <App />
                </Route>
                <Route>
                  <Redirect to={BASE_PATH} />
                </Route>
              </Switch>
            </Router>
          </RoxProvider>
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default Root;
