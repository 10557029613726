let scrollWidth: number | undefined;

function calculateScrollWidth(): number {
  const id = 'scrollWidthDetectionElement';
  const hiddenCalcArea = document.createElement('div');
  hiddenCalcArea.id = id;
  hiddenCalcArea.setAttribute('aria-hidden', 'true');
  hiddenCalcArea.style.height = '1px';
  hiddenCalcArea.style.overflow = 'scroll';

  document.body.appendChild(hiddenCalcArea);

  const box = document.getElementById(id);
  const width = box ? box.offsetWidth - box.clientWidth : 0;

  document.body.removeChild(box as Element);

  return width;
}

export function getScrollWidth(): number {
  if (scrollWidth === undefined) {
    scrollWidth = calculateScrollWidth();
  }

  return scrollWidth;
}
