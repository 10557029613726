import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FEATURE_FLAGS } from "./utils";

type FeatureFlagStatus = {
  [key in FEATURE_FLAGS]: boolean;
};

type FeatureFlagInput = FEATURE_FLAGS | FEATURE_FLAGS[];

// overload function signature to accept both single and multiple flags and return the correct type
//@ts-expect-error
function useLaunchDarklyFlags(featureFlagNames: FEATURE_FLAGS): boolean;
//@ts-expect-error
function useLaunchDarklyFlags(
  featureFlagNames: FEATURE_FLAGS[]
): FeatureFlagStatus;

//@ts-expect-error
const useLaunchDarklyFlags = (
  featureFlagNames: FeatureFlagInput
): boolean | FeatureFlagStatus => {
  const flags = useFlags();

  // Check if featureFlagNames is an array or a single value
  if (Array.isArray(featureFlagNames)) {
    const flagStatuses = featureFlagNames.reduce((acc, featureName) => {
      const flagName =
        featureName in FEATURE_FLAGS ? FEATURE_FLAGS[featureName] : featureName;
      acc[flagName] = flags ? flags[flagName] : false;
      return acc;
    }, {} as FeatureFlagStatus);

    return flagStatuses;
  } else {
    // Handle single flag
    const flagName =
      featureFlagNames in FEATURE_FLAGS
        ? FEATURE_FLAGS[featureFlagNames]
        : featureFlagNames;

    return flags ? flags[flagName] : false;
  }
};

export default useLaunchDarklyFlags;
