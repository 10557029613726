import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import {
  AddOnCheckout,
  BillingContextProvider,
  CDCheckout,
  CheckoutFlow,
  PlanFilter,
  Spinner,
  StepTracker,
} from "component-library";
import { setCookie, ExpiryTimeUnits } from "shared-utils";
import { useTranslation } from "i18n";
import { authorizationService } from "shared-auth";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import { useStepTracking } from "shared-hooks";
import {
  SinglePageCheckoutNew,
  SinglePageCheckout,
  SubscriptionPage,
} from "component-library/src/components/Organisms/Billing";
import {
  checkoutAccessTypeMap,
  CheckoutAccessTypes,
  PlanCodePeriod,
} from "component-library/src/components/Organisms/Billing/types";
import { BillingUser } from "translations/src/models/billing";
import Layout from "app-shell/Layout/Layout";
import applicationMap from "services/applicationMap";
import { RoxFlag } from "config/rox";
import { IApplicationState } from "store";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";
import {
  renewSubscriptionWithExisting,
  transferSubscriptionToGrad,
} from "services/api/BillingApi/BillingApi";
import { AccountTypeBillingTypeMap } from "config/common";
import "./Checkout/styles.scss";
import { getGraduateStepTrackingItems } from "./TransferProfilePage/constants";

type CheckoutPageWrapperProps = {
  checkoutFlow?: CheckoutAccessTypes;
  goBackOverride?: () => void;
};

const PAYMENT_COOKIE = "PaymentStatus";

const CheckoutPageWrapper: FC<CheckoutPageWrapperProps> = ({
  checkoutFlow,
  goBackOverride,
  children,
}) => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isAllowAnnualPromo, isAllowNewCheckout, isRoxLoaded } = useSelector(
    (state: IApplicationState) => ({
      isAllowAnnualPromo:
        state.rox.configuration?.[RoxFlag.Premium_AnnualPromo].isEnabled(),
      isAllowNewCheckout:
        state.rox.configuration?.[RoxFlag.NewCheckoutPage].isEnabled(),
      isRoxLoaded: state.rox.isLoaded,
    })
  );
  const isNewJoiningCDProcess = useLaunchDarklyFlags(
    FEATURE_FLAGS.JoiningCDProcess
  );
  const isAddOnCheckoutFlagEnabled = useLaunchDarklyFlags(
    FEATURE_FLAGS.AddOnCheckout
  );
  const [userType, setUserType] =
    useState<Exclude<BillingUser, BillingUser.Common>>();
  const [addOnCheckoutStep, setAddOnCheckoutStep] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (history.location.pathname.includes("graduate")) {
      return setUserType(BillingUser.Graduate);
    }
    authorizationService.getUser().then((user) => {
      if (!userType && user) {
        setUserType(
          AccountTypeBillingTypeMap[user.profile["spotlight.MemberGroups"]]
        );
      }
    });
  }, [userType, setUserType]);

  const isGraduatePerformer = userType === BillingUser.Graduate;

  const { mutate: renewalMutation, isLoading: isRenewalLoading } = useMutation(
    isGraduatePerformer
      ? transferSubscriptionToGrad
      : renewSubscriptionWithExisting,
    {
      // TODO: Figure out a way to dynamically set the isDirectDebit and use handleNavigateToCompletePage()
      onSuccess: () => history.push(applicationMap.routes.accountSettings()),
    }
  );

  const handleNavigateToCompletePage = (isDirectDebit?: boolean) => {
    history.replace(
      applicationMap.routes.checkoutComplete({ type, userType: userType }),
      {
        isDirectDebit,
      }
    );
  };

  const successCallback = async () => {
    await authorizationService.signInSilent();

    setCookie(PAYMENT_COOKIE, Date.now(), {
      expiryTime: 30,
      expiryTimeUnit: ExpiryTimeUnits.MIN,
    });
  };

  const postCheckoutCallback = async (
    data: any,
    trackingData: Record<string, any>,
    isUsingExistingPaymentMethod?: boolean
  ) => {
    const { planId, couponCodes } = data;

    if (isUsingExistingPaymentMethod) {
      await renewalMutation({ planId, couponCodes });
    }

    pushToDataLayer({
      event: TRACKING_EVENT_TYPE.ReactivateRenew,
      memberTier: data?.planTypeFilter,
      ...trackingData,
    });
  };

  const isMainPerformer = userType === BillingUser.Performer;
  const isYoungPerformer = userType === BillingUser.YoungPerformer;
  const isAddOnCheckout =
    (isMainPerformer || isYoungPerformer || isGraduatePerformer) &&
    isAddOnCheckoutFlagEnabled;
  const isNewCheckout =
    isAllowNewCheckout && (isMainPerformer || isYoungPerformer);

  const urlParams = new URLSearchParams(window.location.search);
  const preselectedPlan = urlParams.get("preSelectedPlan");

  const renderCheckoutComponent = () => {
    if (userType === BillingUser.Casting && isNewJoiningCDProcess) {
      return <CDCheckout isRenewalReactivation />;
    }

    if (isAddOnCheckout) {
      const stepMap: { [key: number]: JSX.Element } = {
        0: <SubscriptionPage />,
        1: <AddOnCheckout />,
      };
      return stepMap[addOnCheckoutStep];
    }

    if (isNewCheckout) {
      return <SinglePageCheckoutNew isRenewalReactivation />;
    }

    return <SinglePageCheckout isRenewalReactivation />;
  };

  const returnToHomepage = () => {
    history.push(applicationMap.routes.index());
  };

  const handleGoBack = () => {
    if (isAddOnCheckout && addOnCheckoutStep > 0) {
      return setAddOnCheckoutStep(0);
    }
    if (goBackOverride) {
      return goBackOverride();
    }
    return returnToHomepage();
  };

  const handleGoNext = () => {
    if (isAddOnCheckout) {
      return setAddOnCheckoutStep(1);
    }
    return undefined;
  };

  const checkoutFlowType = checkoutFlow
    ? checkoutAccessTypeMap[checkoutFlow]
    : CheckoutFlow.Default;

  const showStepTracker = isGraduatePerformer && !pathname.includes("complete");
  const steps = useStepTracking({
    stepNumber: addOnCheckoutStep + 1,
    stepTrackingItems: getGraduateStepTrackingItems(t),
    disableAllSteps: true,
  });

  if (
    !userType ||
    !isRoxLoaded ||
    isAllowNewCheckout === undefined ||
    isRenewalLoading
  )
    return <Spinner />;

  return (
    <Layout hideFooter contentContainerCustomClassName="h-full">
      {showStepTracker && <StepTracker items={steps} />}
      <div className="pt-2 md:px-2 checkout-page">
        <BillingContextProvider
          billingUserType={userType}
          goBack={handleGoBack}
          goNext={handleGoNext}
          goBackText={t("common:button.label.back")}
          onPaymentSuccessGoBackAction={returnToHomepage}
          navigateToCompletePage={handleNavigateToCompletePage}
          checkoutFlow={checkoutFlowType}
          isAnnualPromoEnabled={isAllowAnnualPromo}
          planTypeFilterOverride={
            isNewCheckout ? PlanFilter.NoFilter : PlanFilter.Classic
          }
          isCommonHeader={userType !== BillingUser.Performer}
          successCallback={successCallback}
          postCheckoutCallback={postCheckoutCallback}
          preselectedPlanOverride={
            preselectedPlan as PlanFilter | PlanCodePeriod
          }
        >
          {children ?? renderCheckoutComponent()}
        </BillingContextProvider>
      </div>
    </Layout>
  );
};

export default CheckoutPageWrapper;
