import {
  IScrollingLibrary,
  ScrollManager as BaseScrollManager,
} from "component-library";

import { SCROLLING_CONTAINER_ID } from "config/common";

export class ScrollManager extends BaseScrollManager {
  private scrollPositions: number[] = [];
  private shouldRestore: boolean = false;

  constructor(scrollingContainerId: string, lib?: IScrollingLibrary) {
    super(scrollingContainerId, lib);
  }

  public reset(): void {
    this.scrollToTop();
  }

  public restore(): void {
    if (!this.shouldRestore) {
      return;
    }

    const position = this.scrollPositions.pop();

    if (position) {
      this.scrollTo(position, 0);
    }

    this.shouldRestore = false;
  }

  public save(shouldSavePosition: boolean) {
    if (shouldSavePosition) {
      this.scrollPositions.push(this.getScrollTop());
    }

    this.shouldRestore = !shouldSavePosition;
  }
}

export default new ScrollManager(SCROLLING_CONTAINER_ID);
