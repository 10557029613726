import { JoiningPagesFallbacks } from "../fallbackTranslations/joining-pages";
import { JoiningPage } from "../models/joining-pages";
import { IAboutYouPageTranslations } from "../models/joining-pages/AboutYouPage";
import { IJoiningPagesCommon } from "../models/joining-pages/Common";
import { ICompletePageTranslations } from "../models/joining-pages/CompletePage";
import { IJoiningAsPageTranslations } from "../models/joining-pages/JoiningAsPage";
import { ILoginPageTranslations } from "../models/joining-pages/LoginPage";
import { IReviewPageTranslations } from "../models/joining-pages/ReviewPage";
import { fetchWithFallback } from "./fetchWithFallback";

export type TranslationType<T> = T extends JoiningPage.Common
  ? IJoiningPagesCommon
  : T extends JoiningPage.LoginPage
  ? ILoginPageTranslations
  : T extends JoiningPage.JoiningAsPage
  ? IJoiningAsPageTranslations
  : T extends JoiningPage.AboutYouPage
  ? IAboutYouPageTranslations
  : T extends JoiningPage.ReviewPage
  ? IReviewPageTranslations
  : T extends JoiningPage.CompletePage
  ? ICompletePageTranslations
  : never;

export class JoiningPageTranslationsService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  public async getFormPageTranslations<T extends JoiningPage>(
    { locale, page }: { locale: string; page: T },
    options: { throwOnError?: boolean } = {}
  ): Promise<TranslationType<T>> {
    return fetchWithFallback(
      () =>
        fetch(
          `${this.url}/umbraco/api/content/home-page/joining-pages-application/${page}?culture=${locale}`
        ).then((res) => res.json()),
      JoiningPagesFallbacks[page],
      options.throwOnError
    );
  }
}
