import React, { FC } from "react";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { User } from "oidc-client";
import { buildUser } from "./utils";
import config from "../../config";

const LaunchDarklyProvider: FC<{ thisUser: User | null | undefined }> = ({
  children,
  thisUser,
}) => {
  return (
    <LDProvider
      clientSideID={config.launchDarkly.clientSideID}
      context={{
        kind: "user",
        ...buildUser(thisUser),
      }}
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}
    >
      {children}
    </LDProvider>
  );
};

export default LaunchDarklyProvider;
