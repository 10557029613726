import React from "react";
import cx from "classnames";

import "./ExpandingArea.scss";

export interface IExpandingAreaProps {
  variant: "primary" | "secondary" | "tertiary";
  isOpen: boolean;
  children: React.ReactNode;
}

export class ExpandingArea extends React.PureComponent<IExpandingAreaProps> {
  public static defaultProps = {
    variant: "primary",
  };

  private rootRef = React.createRef<HTMLDivElement>();
  private contentRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    if (this.props.isOpen) {
      const rootEl = this.rootRef.current!;
      rootEl.style.height = "auto";
      rootEl.style.overflow = "visible";
    }
  }

  // @ts-ignore
  public componentDidUpdate(prevProps) {
    const rootEl = this.rootRef.current!;
    const height = `${
      this.contentRef.current!.getBoundingClientRect().height
    }px`;

    if (!prevProps.isOpen && this.props.isOpen) {
      this.handleOpen(rootEl, height);
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.handleClose(rootEl, height);
    }
  }

  public render() {
    return (
      <div
        aria-hidden={!this.props.isOpen}
        className={cx(
          "c-expanding-area",
          `c-expanding-area--${this.props.variant}`
        )}
        ref={this.rootRef}
      >
        <div
          className="c-expanding-area__content-container"
          ref={this.contentRef}
        >
          <div className="c-expanding-area__content-arrow" />
          <div className="c-expanding-area__content">{this.props.children}</div>
        </div>
      </div>
    );
  }

  private handleOpen(rootEl: HTMLDivElement, height: string) {
    const handleTransition = () => {
      if (this.props.isOpen) {
        rootEl.style.height = "auto";
        rootEl.style.overflow = "visible";
      }

      rootEl.removeEventListener("transitionend", handleTransition);
    };

    rootEl.addEventListener("transitionend", handleTransition);
    rootEl.style.height = height;
  }

  private handleClose(rootEl: HTMLDivElement, height: string) {
    rootEl.style.height = height;
    rootEl.style.overflow = "";

    window.requestAnimationFrame(() => {
      rootEl.style.height = "";
    });
  }
}
