import cx from "classnames";
import React from "react";

import SectionControls from "./SectionControls/SectionControls";
import SectionDescription from "./SectionDescription/SectionDescription";
import SectionDivider from "./SectionDivider/SectionDivider";
import SectionRow from "./SectionRow/SectionRow";
import SectionTable from "./SectionTable/SectionTable";
import SectionTitle from "./SectionTitle/SectionTitle";
import SectionContent from "./SectionContent/SectionContent";

import "./Section.scss";

export interface ISectionProps {
  className?: string;
}

class Section extends React.PureComponent<ISectionProps> {
  public static Content = SectionContent;
  public static Row = SectionRow;
  public static Title = SectionTitle;
  public static Description = SectionDescription;
  public static Table = SectionTable;
  public static Controls = SectionControls;
  public static Divider = SectionDivider;

  render() {
    return (
      <article className={cx("c-section", this.props.className)}>
        {this.props.children}
      </article>
    );
  }
}

export default Section;
