import { ITranslations } from './translationModels';

const fr: ITranslations = {
  filterControl: {
    clearAll: 'Tout effacer',
    filtersMobile: ' filtres',
    filters: 'Filters',
    ctaApply: 'Appliquer',
    ctaAllFilters: 'Voir tous les filtres',
    tagOverviewPopup: {
      title: 'Filtres utilisés',
      saveButtonText: 'Retour',
    },
  },
  datePickerUniversal: {
    specificDate: 'Par date',
    byMonth: 'Par mois',
    byYear: 'Par année',
    invalidDateError: 'Le format doit être jj/mm/aaaa',
    minDateError: 'La date doit être postérieure à ',
  },
};

export default fr;
