import { Reducer } from 'redux';

import { ActionType } from 'store/types';

import { IRoxState, RoxActionTypes } from './rox.types';

export const initialState: IRoxState = {
  isLoaded: false,
  configuration: undefined,
};

const reducer: Reducer<IRoxState, RoxActionTypes> = (state: IRoxState = initialState, action: RoxActionTypes) => {
  switch (action.type) {
    case ActionType.GetRoxSuccess:
      return {
        ...state,
        configuration: action.payload,
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default reducer;
