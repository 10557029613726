import { date } from "yup";
import { i18n } from "i18n";
import { Message } from "yup/lib/types";

export default function courseDatesValidationSchema(fieldName: string) {
  const point =
    fieldName.toLowerCase().includes("start") ||
    fieldName.toLowerCase().includes("from")
      ? "Start"
      : "End";
  return {
    [fieldName]: date()
      .nullable()
      .required(
        i18n.t("common:validation.error.entryRequired", {
          fieldName: i18n.t(`common:label.courseDates${point}`),
        }) as Message<{}>
      ),
  };
}
