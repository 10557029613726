import { i18n } from "i18n";

export function getPage404Texts() {
  return {
    logoLabel: i18n.t("common:page404.logoLabel"),
    headerText: i18n.t("common:page404.headerText"),
    messageText: i18n.t("common:page404.messageText"),
    buttonText: i18n.t("common:page404.buttonText"),
  };
}
