import React from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import { useFormikScrollToError } from "shared-hooks";
import { dataTestIds } from "data-testids";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerFormik.scss";

import { ReactComponent as CalendarIcon } from "../../../../images/svg/icons/calendar.svg";

import { ERROR, InputWrapper } from "../../InputsCommon/InputWrapper";
import { InputCleaner } from "../../InputsCommon/InputCleaner";
import { InputLabel } from "../../InputsCommon/InputLabel";

interface IDatePickerFormikProps {
  name: string;
  placeholder?: string;
  dateFormat?: string;
  label?: string;
  annex?: JSX.Element;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  type?: "dayPicker" | "yearPicker" | "yearMonthPicker";
  minDate?: Date;
  maxDate?: Date;
  disableTyping?: boolean;
  tooltipComponent?: () => JSX.Element;
  className?: string;
}

export const DatePickerFormik: React.FC<IDatePickerFormikProps> = (props) => {
  const datePickerType = props.type || "dayPicker";
  const [field, meta, helpers] = useField(props.name);
  const [isOpen, setIsOpen] = React.useState(false);

  const invalid = meta.touched && meta.error ? ERROR : undefined;

  const { fieldRef, containerElementRef } = useFormikScrollToError<
    any,
    HTMLDivElement
  >(props.name, true);

  return (
    <div ref={containerElementRef} className={props.className}>
      <InputWrapper invalid={invalid} validationMesssage={meta.error}>
        <div className="flex items-baseline justify-between">
          {props.label && (
            <InputLabel
              label={props.label}
              annex={props.annex}
              htmlFor={field.name}
            />
          )}
          {props.tooltipComponent && props.tooltipComponent()}
        </div>
        <div className="c-date-picker">
          {field.value && (
            <div className="input-cleaner">
              <InputCleaner onClick={() => helpers.setValue(null)} />
            </div>
          )}
          <DatePicker
            {...field}
            {...props}
            selected={field.value || null}
            onChange={(val) => {
              helpers.setValue(val);
              setIsOpen(false);
            }}
            isClearable
            formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
            onInputClick={() => setIsOpen(true)}
            onClickOutside={() => setIsOpen(false)}
            onKeyDown={(e) => e.key === "Escape" && setIsOpen(false)}
            open={isOpen}
            calendarStartDay={1}
            placeholderText={props.placeholder}
            wrapperClassName={`input-wrapper ${
              meta.error && meta.touched && "input-wrapper--error"
            }`}
            autoComplete="off"
            onFocus={() => setIsOpen(true)}
            dateFormat={props.dateFormat || "dd/MM/yyyy"}
            showMonthDropdown={props.showMonthDropdown}
            showYearDropdown={props.showYearDropdown}
            dropdownMode="select"
            minDate={props.minDate}
            maxDate={props.maxDate}
            showYearPicker={datePickerType === "yearPicker"}
            showMonthYearPicker={datePickerType === "yearMonthPicker"}
            onChangeRaw={(e) => {
              if (props.disableTyping) {
                e.preventDefault();
              }
            }}
            ref={fieldRef}
          />
          <a
            className="c-date-picker__input__toggle"
            onClick={() => setIsOpen(true)}
            tabIndex={-1}
            data-testid={
              dataTestIds.componentLibrary[
                "Atoms.FormikInputs.DatePickerFormik.calendarIcon"
              ]
            }
            aria-label={props.label}
          >
            <CalendarIcon />
          </a>
        </div>
      </InputWrapper>
    </div>
  );
};
