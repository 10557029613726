import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "component-library";
import { useTranslation } from "i18n";
import PageWrapperWithinLayout from "app-shell/PageWrapperWithinLayout";
import applicationMap from "services/applicationMap";

interface Props {}

const SubscriptionCancelled: FC<Props> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <PageWrapperWithinLayout
      pageHeader={t(
        "performer:accountSettings.mySubscriptionPage.cancelledTitle"
      )}
      breadcrumbs={[]}
    >
      <div className="py-5">
        <p className="mb-5">
          {t("performer:accountSettings.mySubscriptionPage.cancelledSubTitle")}
        </p>
        <p className="mb-5">
          {t(
            "performer:accountSettings.mySubscriptionPage.cancelledDescription"
          )}
        </p>
        <Button
          type="primary"
          text={t("common:button.label.goToAccountSettings")}
          className="max-w-[280px]"
          onClick={() => history.push(applicationMap.routes.accountSettings())}
        />
      </div>
    </PageWrapperWithinLayout>
  );
};

export default SubscriptionCancelled;
