import React from "react";
import cx from "classnames";

import { IOption } from "../../../generics/interfaces";

import "./DropdownTag.scss";

export interface IDropdownTagProps<T> {
  /** A list of options for the component */
  items: Array<IOption<T>>;
  /** Optional Handler for item selection, returns the selected item */
  onSelect?: (item: IOption<T>) => void;
  /** Optional default selection, if not set - the first item of lists is selected by default */
  selectedItem?: IOption<T>;
}

interface IDropdownTagState<T> {
  isDropdownOpen: boolean;
  selectedItem: IOption<T>;
}

export class DropdownTag<T extends string | number> extends React.Component<
  IDropdownTagProps<T>,
  IDropdownTagState<T>
> {
  private dropdownRef = React.createRef<HTMLDivElement>();

  public readonly state: Readonly<IDropdownTagState<T>> = {
    isDropdownOpen: false,
    selectedItem: this.props.selectedItem || this.props.items[0],
  };

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  public render() {
    return (
      <div
        tabIndex={0}
        className={cx("c-dropdown-tag", {
          "c-dropdown-tag--open": this.state.isDropdownOpen,
        })}
        onClick={this.handleClick}
        ref={this.dropdownRef}
      >
        <span className="c-dropdown-tag__text">
          {this.state.selectedItem?.name}
        </span>
        {this.renderDropdown()}
      </div>
    );
  }

  private handleClick = () => {
    this.toggleDropdown();
  };

  private handleOutsideClick = (event: MouseEvent) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target as Node)
    ) {
      this.closeDropdown();
    }
  };

  private renderDropdown() {
    if (!this.state.isDropdownOpen) {
      return null;
    }

    return (
      <div className="g-dropdown-list c-dropdown-tag__dropdown" role="listbox">
        {this.renderLinks()}
      </div>
    );
  }

  private renderLinks() {
    return this.props.items.map((item: IOption<T>, index) => {
      return (
        <a
          className="g-dropdown-list__item g-alternative-link g-alternative-link--lg c-dropdown-tag__dropdown-item"
          key={item.value || index}
          onClick={this.handleSelectChange(item)}
        >
          {item.name}
        </a>
      );
    });
  }

  private handleSelectChange =
    (item: IOption<T>) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      this.setState({ selectedItem: item }, () => {
        this.closeDropdown();
        this.props.onSelect && this.props.onSelect(item);
      });
    };

  private toggleDropdown() {
    this.state.isDropdownOpen ? this.closeDropdown() : this.openDropdown();
  }

  private openDropdown() {
    this.setState(
      {
        isDropdownOpen: true,
      },
      () => {
        document.addEventListener("click", this.handleOutsideClick, false);
      }
    );
  }

  private closeDropdown() {
    document.removeEventListener("click", this.handleOutsideClick);

    this.setState({
      isDropdownOpen: false,
    });
  }
}
