import { useQuery } from "react-query";
import { billingService, FETCH_EXISTING_PAYMENT_METHOD } from "../services";

export const useGetExistingPaymentMethod = (enabled: boolean = false) => {
  return useQuery(
    FETCH_EXISTING_PAYMENT_METHOD,
    async () => {
      return await billingService.getExistingPaymentMethod();
    },
    { enabled }
  );
};
