import React from "react";
import cx from "classnames";

import "./RadioList.scss";
import {
  GenericRadio,
  IGenericRadioOnChangeHandler,
} from "../../Atoms/Radio/GenericRadio";
import { ERROR, InputWrapper } from "../../Atoms/InputsCommon/InputWrapper";
import { IBaseInputProps } from "../../Atoms/InputsCommon/BaseInputProps";
import {
  InputLabel,
  InputLabelStyleTypes,
} from "../../Atoms/InputsCommon/InputLabel";
import { IOption } from "../../../generics/interfaces";
import { Omit } from "../../../generics/type-manipulation";

interface IRadioListExtendedOption<T> extends Omit<IOption<T>, "name"> {
  name: string | JSX.Element;
  renderExpandingArea?: () => JSX.Element;
}

export type IRadioListProps<T> = Pick<
  IBaseInputProps<HTMLInputElement, T>,
  Exclude<keyof IBaseInputProps<HTMLInputElement, T>, "onChange">
> & {
  /** Place annex next to label */
  inlineAnnexPosition?: boolean;
  /** Externally set selected value to choose one of the radios */
  options: Array<IRadioListExtendedOption<T>>;
  /** Whether to display radios vertically in stead of horizontally */
  vertical?: boolean;
  /** Label style type. Check out example for available options */
  labelStyleType?: InputLabelStyleTypes;
  /** Remove bottom padding */
  noBottomPadding?: boolean;
  /** Optional handler to hook into the change event. */
  onChange?: IGenericRadioOnChangeHandler;
  /** Limit option and label width */
  isWidthLimited?: boolean;
};

interface IRadioListState {
  blurred: boolean;
}

export class RadioList<T> extends React.Component<
  IRadioListProps<T>,
  IRadioListState
> {
  constructor(props: IRadioListProps<T>) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  public renderRadioList() {
    return this.props.options.map((option, index) => {
      return (
        <GenericRadio
          key={index}
          name={this.props.name}
          value={option.value}
          label={option.name}
          checked={this.props.value === option.value}
          onChange={this.props.onChange}
          valueChanged={this.valueChanged}
          onFocus={this.props.onFocus}
          onBlur={this.onBlur}
          disabled={this.props.disabled}
          renderExpandingArea={option.renderExpandingArea}
        />
      );
    });
  }

  public renderContent = (validationMessage: React.ReactNode | null) => {
    return (
      <React.Fragment>
        <InputLabel
          label={this.props.label}
          annex={this.props.annex}
          inlineAnnexPosition={this.props.inlineAnnexPosition}
          noMargin={this.props.vertical}
          styleType={
            this.props.labelStyleType || InputLabelStyleTypes.Secondary
          }
        />
        {validationMessage || null}
        <div className="c-radiolist__container">{this.renderRadioList()}</div>
      </React.Fragment>
    );
  };

  public render() {
    const invalidState =
      this.props.invalid && (this.state.blurred || this.props.isFormSubmitted)
        ? ERROR
        : undefined;

    return (
      <div
        className={cx("c-radiolist", {
          [this.props.className as string]: Boolean(this.props.className),
          "c-radiolist__vertical": this.props.vertical,
          "c-radiolist__disabled": this.props.disabled,
          "c-radiolist--no-bottom-padding": this.props.noBottomPadding,
          "c-radiolist--width-limited": this.props.isWidthLimited,
        })}
      >
        <InputWrapper
          invalid={invalidState}
          renderChildren={this.renderContent}
          validationMesssage={this.props.validationMessage}
        />
      </div>
    );
  }

  private onBlur = (event: React.FormEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }

    this.setState({
      blurred: true,
    });
  };

  private valueChanged = (value: T) => {
    if (!this.props.disabled && this.props.valueChanged) {
      this.props.valueChanged(value);
    }
  };
}
