import React, { FC } from "react";
import { Checkbox } from "../../Atoms/Checkbox/Checkbox";
import { useTranslation } from "i18n";
import "./styles.scss";

export enum FilterDrawerMediaFilterNames {
  HasShowreels = "hasShowreels",
  HasAudioClips = "hasAudioClips",
}

interface Props {
  showreelsValueChanged: (checked: boolean) => void;
  showreelsChecked?: boolean;
  showreelsCount?: number;
  audioClipValueChanged: (checked: boolean) => void;
  audioClipChecked?: boolean;
  audioClipCount?: number;
}

const FilterDrawerMediaFilter: FC<Props> = ({
  showreelsChecked = false,
  showreelsCount = 0,
  showreelsValueChanged,
  audioClipValueChanged,
  audioClipChecked = false,
  audioClipCount = 0,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Checkbox
        className="mb-2 media-filter"
        checked={showreelsChecked}
        name={FilterDrawerMediaFilterNames.HasShowreels}
        value=""
        label={t("common:search.filters.showreels")}
        valueChanged={showreelsValueChanged}
        disabled={!showreelsCount}
        labelPostFix={showreelsCount}
      />
      <Checkbox
        className="media-filter"
        checked={audioClipChecked}
        name={FilterDrawerMediaFilterNames.HasAudioClips}
        value=""
        label={t("common:search.filters.audioClip")}
        valueChanged={audioClipValueChanged}
        disabled={!audioClipCount}
        labelPostFix={audioClipCount}
      />
    </>
  );
};

export default FilterDrawerMediaFilter;
