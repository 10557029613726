import React from "react";
import {
  AlertMessage,
  IAlertMessageProps,
  IPopupProps,
  Popup,
} from "component-library";

export interface IErrorPopupDynamicProps {
  title: string;
  description: string;
  closeButtonText: string;
  handleButtonClick?(): void;
  handleCloseClick?(): void;
  hidePopup(): void;
}

export interface IErrorPopupProps extends IErrorPopupDynamicProps {}

export class ErrorPopup extends React.PureComponent<IErrorPopupProps> {
  public handleClose = () => {
    if (this.props.handleCloseClick) {
      return this.props.handleCloseClick();
    }

    this.props.hidePopup();
  };

  public handleCloseButtonClick = () => {
    if (this.props.handleButtonClick) {
      return this.props.handleButtonClick();
    }

    this.props.hidePopup();
  };

  public render() {
    const popupProps: IPopupProps = {
      width: { lg: 5, md: 6 },
      close: this.handleClose,
      priority: "high",
      texts: { closePopup: this.props.closeButtonText },
    };

    const alertProps: IAlertMessageProps = {
      icon: "notice",
      texts: {
        title: this.props.title,
        description: this.props.description,
      },
      buttons: [
        {
          name: this.props.closeButtonText,
          type: "primary",
          click: this.handleCloseButtonClick,
        },
      ],
    };

    return (
      <Popup {...popupProps}>
        <AlertMessage {...alertProps} />
      </Popup>
    );
  }
}

export default ErrorPopup;
