export const MIRROR_PROPS = [
  'box-sizing',
  'width',
  'font-size',
  'font-weight',
  'font-family',
  'font-style',
  'letter-spacing',
  'text-indent',
  'white-space',
  'word-break',
  'overflow-wrap',
  'padding-left',
  'padding-right',
  'text-transform',
];
