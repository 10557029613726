import React, { FC } from "react";
import { IPrice, ISubscription, ISummary, ICouponObject } from "../../../types";
import OrderSummaryLEGACY from "../../OrderSummaryLEGACY/OrderSummaryLEGACY";

interface Props {
  plan: ISubscription;
  summary: ISummary;
  price: IPrice | undefined;
  couponBag: {
    userCouponCodesObject: ICouponObject[];
    setCoupon: (couponCode: string) => Promise<void>;
    removeCoupon: (couponCode: string) => Promise<void>;
    couponErrors: string[];
    hasAccountDiscount?: boolean;
    isCheckoutPreviewRequestLoading: boolean;
  };
  isMobileView?: boolean;
}

const SummaryBlock: FC<Props> = ({
  plan,
  summary,
  price,
  couponBag,
  isMobileView,
}) => {
  const classNames = isMobileView ? "m-4" : "mt-[5.5625rem] max-w-[480px]";

  return (
    <aside className={`sticky flex-1 ${classNames}`}>
      <OrderSummaryLEGACY
        name={`${summary.firstName} ${summary.lastName}`}
        email={summary.email}
        phoneNumber={summary.phoneNumber}
        planName={plan.name}
        total={price?.total || plan.total}
        amount={price?.amount || plan.amount}
        discountAmount={price?.discount}
        creditApplied={price?.creditApplied}
        accountCredited={price?.accountCredited}
        vatAmount={price?.tax || plan.addOns[0]?.amount}
        vatLabel="VAT 20%"
        userCouponCodesObject={couponBag.userCouponCodesObject}
        setCoupon={couponBag.setCoupon}
        removeCoupon={couponBag.removeCoupon}
        couponErrors={couponBag.couponErrors}
        hasAccountDiscount={couponBag.hasAccountDiscount}
        isMobileView={isMobileView}
        isCheckoutPreviewRequestLoading={
          couponBag.isCheckoutPreviewRequestLoading
        }
      />
    </aside>
  );
};

export default SummaryBlock;
