import { fetchWithFallback } from "./fetchWithFallback";
import { BillingUser } from "../models/billing";
import { BillingFallbacks } from "../fallbackTranslations/billing";
import { IBillingCommon } from "../models/billing/common";
import { IBillingUser } from "../models/billing/user";

export type TranslationType<T> = T extends BillingUser.Common
  ? IBillingCommon
  : IBillingUser;

export class BillingTranslationsService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  public async getTranslations<T extends BillingUser>(
    { locale, user }: { locale: string; user: T },
    options: { throwOnError?: boolean } = {}
  ): Promise<TranslationType<T>> {
    return fetchWithFallback(
      () =>
        fetch(
          `${this.url}/umbraco/api/content/home-page/billing/${user}?culture=${locale}`
        ).then((res) => res.json()),
      BillingFallbacks[user],
      options.throwOnError
    );
  }
}
