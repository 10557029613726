import React, { FC, useState } from "react";
import { useTranslation } from "i18n";
import {
  AlertMessage,
  Popup,
  NotificationToast,
  NotificationToastTypes,
} from "component-library";
import { revertSubscription } from "services/api/BillingApi/BillingApi";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";

interface Props {
  isOpen: boolean;
  closePopUp: () => void;
  onSuccessCallback: () => void;
}

const ReactivateModal: FC<Props> = ({
  isOpen,
  closePopUp,
  onSuccessCallback,
}) => {
  const { t } = useTranslation();
  const [notificationIOpen, setNotificationIOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    const success = await revertSubscription();
    if (success) {
      onSuccessCallback();
      closePopUp();
      setNotificationIOpen(true);
      pushToDataLayer({
        event: TRACKING_EVENT_TYPE.RevertCancellation,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isOpen && (
        <Popup
          width={{ lg: 5, md: 6 }}
          texts={{ closePopup: t("common:button.label.cancel") }}
          close={closePopUp}
          className="z-50"
        >
          <AlertMessage
            icon="notice"
            texts={{
              title: t(
                "performer:accountSettings.mySubscriptionPage.reactivateModal.title"
              ),
              description: t(
                "performer:accountSettings.mySubscriptionPage.reactivateModal.descr"
              ),
            }}
            buttons={[
              {
                name: t("common:button.label.keepMyMembership"),
                click: onSubmit,
                type: "primary",
                ctaDisabled: isLoading,
              },
            ]}
          />
        </Popup>
      )}
      <NotificationToast
        show={notificationIOpen}
        handleHide={() => setNotificationIOpen(false)}
        text={t(
          "performer:accountSettings.mySubscriptionPage.notification.emailSent"
        )}
        type={NotificationToastTypes.Success}
        className="w-[320px] m-auto"
        stickToComponent
      />
    </>
  );
};

export default ReactivateModal;
