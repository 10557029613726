import React, { FC } from "react";
import { IRangeSelectionValue } from "../RangeSelectionDropdown/RangeSelectionDropdown";
import { DropdownInput } from "../DropdownInput/DropdownInput";
import { IOption } from "../../../generics/interfaces";

export interface Props {
  value: IRangeSelectionValue;
  minimumRangeValue: number;
  maximumRangeValue: number;
  content: {
    lowerDropdownPlaceholder: string;
    lowerDropdownLabel: string;
    upperDropdownPlaceholder: string;
    upperDropdownLabel: string;
  };
  getValueRepresentation?: (value: number) => string;
  valueChanged: (value: IRangeSelectionValue) => void;
}

const getDropdownOptions = (
  lowerValue: number,
  upperValue: number,
  valueRenderer?: (value: number) => string
): Array<IOption<number>> => {
  const options: Array<IOption<number>> = [];

  for (let i = lowerValue; i <= upperValue; i++) {
    const name = valueRenderer ? valueRenderer(i) : i.toString();
    options.push({ name, value: i });
  }

  return options;
};

const RangeSelection: FC<Props> = ({
  value,
  minimumRangeValue,
  maximumRangeValue,
  content,
  getValueRepresentation,
  valueChanged,
}) => {
  const lowerRangeMinimum = minimumRangeValue;
  const lowerRangeMaximum = value.upperRangeValue || maximumRangeValue;
  const upperRangeMinimum = value.lowerRangeValue || minimumRangeValue;
  const upperRangeMaximum = maximumRangeValue;

  const lowerOptionsList = getDropdownOptions(
    lowerRangeMinimum,
    lowerRangeMaximum,
    getValueRepresentation
  );
  const upperOptionsList = getDropdownOptions(
    upperRangeMinimum,
    upperRangeMaximum,
    getValueRepresentation
  );

  const selectedLowerItem = lowerOptionsList.find(
    (item) => item.value === value.lowerRangeValue
  );
  const selectedUpperItem = upperOptionsList.find(
    (item) => item.value === value.upperRangeValue
  );

  const selectValue =
    (key: keyof IRangeSelectionValue) => (item?: IOption<number>) => {
      if (item) {
        const newValue: IRangeSelectionValue = {
          ...value,
          [key]: item.value,
        };

        valueChanged(newValue);
      }
    };

  return (
    <div className="c-range-selection-dropdown__form">
      <div className="c-range-selection-dropdown__form-container">
        <div className="c-range-selection-dropdown__form-left-selector">
          <DropdownInput<number>
            variant="select"
            placeholder={content.lowerDropdownPlaceholder}
            options={lowerOptionsList}
            label={content.lowerDropdownLabel}
            optionSelected={selectValue("lowerRangeValue")}
            selected={selectedLowerItem}
          />
        </div>
        <div className="c-range-selection-dropdown__form-right-selector">
          <DropdownInput<number>
            variant="select"
            placeholder={content.upperDropdownPlaceholder}
            options={upperOptionsList}
            label={content.upperDropdownLabel}
            optionSelected={selectValue("upperRangeValue")}
            selected={selectedUpperItem}
          />
        </div>
      </div>
    </div>
  );
};

export default RangeSelection;
