import { authAxios as axios } from "shared-auth";
import { useQuery } from "react-query";
import { config } from "../../../../../../../config";
import { IPrice } from "../../../../types";

const GET_PURCHASE_PREVIEW = "GET_PURCHASE_PREVIEW";

export function usePreviewPurchase(codes: string[]) {
  return useQuery<IPrice>([GET_PURCHASE_PREVIEW, codes], async () => {
    const { data } = await axios.post(
      `${config.billingApiUrl}addons/preview`,
      codes
    );
    return data;
  });
}
