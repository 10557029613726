import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { User } from "oidc-client";
import { authorizationService, initAuthAxios } from "shared-auth";
import { initBraze, InitializeBrazeProps } from "shared-services";
import { setUserData } from "store/user/user.actions";
import { AccountType } from "component-library";
import { connect } from "react-redux";
import { compose } from "redux";
import { SetUserData } from "store/user/user.types";
import {
  IMemberGroup,
  memberGroupNames,
  memberGroupSubTypes,
} from "models/user";
import config from "config/global";

declare type withOidcAuthenticationProps = RouteComponentProps & {
  setUserData: SetUserData;
};
type State = { redirectToUrl?: string; error?: Error };

function withOidcAuthentication<P extends withOidcAuthenticationProps>(
  WrappedComponent: React.ComponentClass<P> | React.FunctionComponent<P>
): React.ComponentType<any> {
  return class extends React.Component<P, State> {
    state: State = {};
    isContentVisible = false;
    constructor(props: P) {
      super(props);
    }

    componentDidMount() {
      this.initAuthentication();
    }

    public render() {
      if (authorizationService.isSignInUrl() && this.state?.redirectToUrl) {
        return <Redirect to={this.state.redirectToUrl} />;
      }
      if (this.isContentVisible) {
        return <WrappedComponent {...this.props} />;
      }

      return null;
    }

    onSignInSuccess = (user: User, url: string | undefined) => {
      this.isContentVisible = true;
      this.setState({
        redirectToUrl: url,
      });
      if (!user.profile.sid) {
        throw new Error(
          "onSignInSuccess did not get a valid sid or preferred_username"
        );
      } else {
        let userType: AccountType;
        const memberGroups = parseMemberGroup(
          user.profile["spotlight.MemberGroups"]
        );
        if (memberGroups[0] == undefined) {
          // TODO decide what is supposed to happen if we don't know someone's user type
          userType = AccountType.Performer;
        } else if (memberGroups[0].group == "castingDirector") {
          userType = AccountType.CastingDirector;
        } else if (memberGroups[0].group == "agent") {
          userType = AccountType.Agent;
        } else if (memberGroups[0].group == "performer") {
          userType = AccountType.Performer;
        } else {
          // TODO decide what is supposed to happen if we don't know someone's user type
          userType = AccountType.Performer;
        }

        // To append for non-dev - change Octopus Spotlight.Frontend.CookieScriptSrc variable
        if (config.cookieScriptSrc) {
          const script = document.createElement("script");

          script.src = config.cookieScriptSrc;
          script.type = "text/javascript";

          document.body.appendChild(script);
        }

        this.props.setUserData &&
          this.props.setUserData({
            id: user.profile.sid,
            username: user.profile.name ?? "UnknownName",
            memberGroups: memberGroups,
            type: userType,
            email: user.profile.email ?? "UnknownEmail@spotlight.com",
            forenames: user.profile.given_name ?? "UnknownForename",
            surname: user.profile.family_name ?? "UnknownSurname",
          });
      }
    };

    onError = (e: Error) => {
      this.setState({
        error: e,
      });
    };

    private initAuthentication = async () => {
      if (!authorizationService.isAuthenticated()) {
        if (authorizationService.isSignInUrl()) {
          // we've been sent back from the login redirect process. Process the response
          await authorizationService.signinRedirectCallback();
        } else {
          // attempt to get the user out of storage without logging in
          const user = await authorizationService.getUser();

          if (user) {
            //init Braze for allowed Member
            const brazeProps: InitializeBrazeProps = {
              memberGroupFromToken: user?.profile?.["spotlight.MemberGroups"],
              portalId: user?.profile?.sub,
              brazeConfig: {
                apiKey: config.braze.apiKey,
                baseUrl: config.braze.baseUrl,
              },
            };
            initBraze(brazeProps);

            initAuthAxios();
          }

          if (user == null) {
            // No user stored in local storage, start the log in process
            authorizationService.signInRedirect();
          } else if (!authorizationService.hasRequestedScopes(user.scopes)) {
            authorizationService.signInRedirect();
          } else {
            const REUSED_TOKEN_GRACE_PERIOD_SECONDS = 60;
            // We've got a user out of storage
            if (!user.profile["spotlight.MemberGroups"]) {
              // The token in storage doesn't have the spotlight.MemberGroups claim, make the sign in again
              console.log("User's spotlight.MemberGroups was empty");
              authorizationService.signInRedirect();
            } else if (
              user.expires_at + REUSED_TOKEN_GRACE_PERIOD_SECONDS <
              Math.floor(new Date().getTime() / 1000)
            ) {
              // The token in storage doesn't have the enough time remaining
              console.log("Refreshing token");
              try {
                await authorizationService.signInSilent();
                console.log("Token refreshed");
                this.onSignInSuccess(user, undefined);
              } catch (e) {
                console.log("Token refresh failed", e);
                // We failed to use the refresh token, restart the log in process so they get a new refresh token (assuming they are allowed in)
                authorizationService.signInRedirect();
              }
            } else {
              // The token that we have is fine
              this.onSignInSuccess(user, undefined);
            }
          }
        }
      } else {
        this.isContentVisible = true;
      }
    };
  };
}

function parseMemberGroup(memberGroup: string | undefined): IMemberGroup[] {
  if (!memberGroup) {
    return [];
  }
  const result: IMemberGroup[] = [];
  for (const part of memberGroup.split(/,/gi)) {
    const innerParts = part.split(/\:/gi);
    if (innerParts[0] in memberGroupNames) {
      result.push({
        group: innerParts[0] as keyof typeof memberGroupNames,
        subType:
          innerParts[1] in memberGroupSubTypes
            ? (innerParts[1] as keyof typeof memberGroupSubTypes)
            : undefined,
      });
    }
  }
  return result;
}

export default compose(
  connect(undefined, { setUserData }),
  withOidcAuthentication
);
