import { animateScroll } from "react-scroll";

interface IScrollingLibraryOptions {
  duration: number;
  containerId: string;
}

export interface IScrollingLibrary {
  scrollToTop(options?: IScrollingLibraryOptions): void;
  scrollToBottom(options?: IScrollingLibraryOptions): void;
  scrollTo(toY: number, options?: IScrollingLibraryOptions): void;
}

export function initScrollManager(scrollingContainerId: string) {
  return new ScrollManager(scrollingContainerId, animateScroll);
}

export class ScrollManager {
  // @ts-ignore
  private scrollElement: Element | null;

  private get scrollContainer(): Element | null {
    return this.scrollElement
      ? this.scrollElement
      : (this.scrollElement = document.getElementById(
          this.scrollingContainerId
        ));
  }

  constructor(
    private scrollingContainerId: string,
    private lib: IScrollingLibrary = animateScroll
  ) {}

  public getScrollTop() {
    return this.scrollContainer?.scrollTop ?? 0;
  }

  public scrollToElement(
    element?: Element | null,
    offset: number = 0,
    duration: number = 300,
    scrollingContainerId?: string
  ) {
    const top =
      (element?.getBoundingClientRect()?.top || 0) +
      (this.scrollContainer?.scrollTop || 0) -
      (this.scrollContainer?.getBoundingClientRect()?.top || 0) -
      offset;

    this.scrollTo(top, duration, scrollingContainerId);
  }

  public scrollTo(
    y: number,
    duration: number = 300,
    scrollingContainerId?: string
  ) {
    this.lib.scrollTo(y, {
      duration,
      containerId: scrollingContainerId ?? this.scrollingContainerId,
    });
  }

  public scrollToTop(duration: number = 0) {
    this.lib.scrollToTop({ duration, containerId: this.scrollingContainerId });
  }

  public scrollToBottom(duration: number = 0) {
    this.lib.scrollToBottom({
      duration,
      containerId: this.scrollingContainerId,
    });
  }
}
