import { IGetMatchingNamesResponse } from "./monicker.types";

export class MonickerService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  getMatchingNames(name: string): Promise<IGetMatchingNamesResponse> {
    const nameToCheck = name.toLowerCase();
    return fetch(`${this.url}Search/Casting?term=${nameToCheck}`).then((res) =>
      res.json()
    );
  }
}
