import React from "react";
import cx from "classnames";
import DOMPurify from "dompurify";

import "./Checkbox.scss";

export interface ICheckboxProps {
  /** Name of the form element, as you would use for a "normal" checkbox */
  name: string;
  /** Value of the form element, as you would use for a "normal" checkbox */
  value: string;
  /** Optional id for the input element */
  htmlId?: string;
  /** Optional label */
  label?: string;
  /** Optional accessibility label for screen readers */
  ariaLabel?: string;
  /** Whether the checkbox should be checked by default. Guideline: pass true or leave empty, but accepts truthy/falsy values as well */
  defaultChecked?: boolean;
  /** Control checked state externally */
  checked?: boolean;
  /** Show number of results if available next to label */
  labelPostFix?: number;
  /** Callback for checking or unchecking, returns checked status */
  valueChanged?: (value: boolean) => void;
  /** Optional handler to hook into the change event. The function's argument will be the event itself. */
  onChange?: (event: any) => any;
  /** Optional css class for the topmost div */
  className?: string;
  /**
   * Disable control
   */
  disabled?: boolean;
  /**
   * listener of click event
   */
  onClick?: (event: any) => any;
  /**
   * Optional component to display on the top-right
   */
  annex?: JSX.Element;
  /**
   * Optionally state whether part of a dropDown to apply
   * drop down specific styling
   */
  dropDown?: boolean;
  dataTestId?: string;
  /**
   * Optional
   * Gives checkbox div a white bg
   */
  whiteInput?: boolean;
  /**
   * Optional
   * If true, label is rendered as rich text
   */
  isRichTextLabel?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const [checked, setChecked] = React.useState(
    props.checked || props.defaultChecked || false
  );

  const handleChange = (e: any) => {
    props.onChange && props.onChange(e);
    setChecked((currentChecked) => !currentChecked);
  };

  // update state with externally controlled checkboxes
  React.useEffect(() => {
    if (props.checked !== undefined && checked !== props.checked) {
      setChecked(props.checked);
    }
  }, [props.checked]);

  React.useEffect(() => {
    if (props.valueChanged && checked !== props.checked) {
      props.valueChanged(checked);
    }
  }, [checked]);

  const attributes: any = {
    name: props.name,
    value: props.value,
    disabled: props.disabled,
    defaultChecked: props.defaultChecked,
    onChange: handleChange,
    id: props.htmlId,
    "data-testid": props.dataTestId,
  };

  const boxClassName = cx("c-checkbox__box", {
    "c-checkbox__box--disabled": props.disabled,
  });
  const labelClassName = cx("c-checkbox__label", {
    "c-checkbox__label--disabled": props.disabled,
  });
  const labelPostfixClassName = cx("c-checkbox__label-postfix", {
    "c-checkbox__label-postfix--disabled": props.disabled,
  });

  const renderLabel = () => {
    const labelProps = {
      className: labelClassName,
      htmlFor: attributes.id,
    };

    if (props.isRichTextLabel) {
      return (
        <label
          {...labelProps}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(props.label || ""),
          }}
        />
      );
    }
    return <label {...labelProps}>{props.label}</label>;
  };

  return (
    <div
      className={cx("c-checkbox", props.className, {
        "c-checkbox--disabled": props.disabled,
      })}
    >
      <div
        className={`c-checkbox__wrapper ${
          !props.dropDown && "c-checkbox__additionalMargin"
        }`}
      >
        <input
          type="checkbox"
          {...attributes}
          aria-label={props.ariaLabel}
          onClick={(e) => props.onClick?.(e)}
          onKeyPress={(e) => {
            props.onClick?.(e);
            e.key === "Enter" && attributes.onChange(e);
          }}
          checked={checked}
          className={
            props.isRichTextLabel ? "c-checkbox__input--rich-label" : ""
          }
        />
        <div
          className={boxClassName}
          style={props.whiteInput ? { background: "white" } : {}}
        />
        {props.label && renderLabel()}
        {props.labelPostFix !== undefined && (
          <div className={labelPostfixClassName}>({props.labelPostFix})</div>
        )}
      </div>
      {props.annex && <div className="c-checkbox__annex">{props.annex}</div>}
    </div>
  );
};
