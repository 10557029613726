import React, { FC } from "react";
import { useTranslation } from "i18n";
import { ReactComponent as SuccessIcon } from "../../../../../../images/svg/icons/success.svg";
import {
  getTootlipProps,
  QuestionTooltip,
} from "../../../../../Molecules/QuestionTooltip";
import "./styles.scss";

interface Props {
  title: string;
  isClassic: boolean;
}

const PlanRow: FC<Props> = ({ title, isClassic }) => {
  const { t } = useTranslation();

  return (
    <tr key={title}>
      <td>
        {t(title)}
        <QuestionTooltip
          tooltipProps={{
            ...getTootlipProps("", t(`${title}.tooltip`)),
            overflowRestrictingContainerSelector: `.plan-table`,
            className: "plan-row-tooltip",
          }}
        />
      </td>
      {isClassic ? (
        <td>
          <SuccessIcon className="success-icon" />
        </td>
      ) : (
        <td className="negative">-</td>
      )}
      <td>
        <SuccessIcon className="success-icon" />
      </td>
    </tr>
  );
};

export default PlanRow;
