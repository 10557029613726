import React from "react";
import cx from "classnames";

import { config } from "../../../config";
import { Button } from "../../Atoms/Button/Button";
import { Popup } from "../../Molecules/Popup/Popup";
import {
  AlertMessage,
  IAlertMessageProps,
} from "../../Molecules/AlertMessage/AlertMessage";

import "./AddPhoto.scss";

export interface IAddPhotoTexts {
  addPhoto: string;
  photoInvalidWarning: {
    heading: string;
    fileType: string;
    fileSize: string;
    resolution: string;
    chooseNew: string;
    cancel: string;
  };
}
export const IAddPhotoTextsKeys = ["addPhoto", "photoInvalidWarning"];

export type AddPhotoButtonType = "link" | "button" | "panel";

export interface IAddPhotoProps {
  /**
   * A callback which gets called after successful validation.
   * Must accept the photo file as an argument and as such provides access to it for later use.
   */
  photoAdded: (image: HTMLImageElement) => any;
  /** Optional callback to intercept AddPhoto action execution */
  preValidation?: () => void;
  /** The form that the component is supposed to take. */
  as: AddPhotoButtonType;
  texts: IAddPhotoTexts;
  /** Optional css class for the topmost div */
  className?: string;
}

interface IAddPhotoState {
  isUploadedPhotoInvalid: boolean;
  invalidPhotoMessage: string;
}

const PHOTO_UPLOAD_ZERO_STATE = {
  isUploadedPhotoInvalid: false,
  invalidPhotoMessage: "",
};

export class AddPhoto extends React.Component<IAddPhotoProps, IAddPhotoState> {
  public readonly state: Readonly<IAddPhotoState> = {
    ...PHOTO_UPLOAD_ZERO_STATE,
  };
  private addPhotoInputRef = React.createRef<HTMLInputElement>();

  public render() {
    const { className, as, texts } = this.props;
    const { isUploadedPhotoInvalid, invalidPhotoMessage } = this.state;

    const acceptedFileExtensions =
      config.photoValidation.acceptedFileExtensions.join();

    return (
      <div className={cx("c-add-photo", className)}>
        {this.renderButton(as, texts)}
        <input
          type="file"
          accept={acceptedFileExtensions}
          hidden={true}
          ref={this.addPhotoInputRef}
          onChange={this.onFileUpload}
        />
        {isUploadedPhotoInvalid &&
          this.renderPhotoInvalidError(texts, invalidPhotoMessage)}
      </div>
    );
  }

  public showExplorer = () => {
    if (this.addPhotoInputRef.current) {
      const event = document.createEvent("MouseEvents");
      event.initEvent("click", true, false);
      this.addPhotoInputRef.current.dispatchEvent(event);
    }
  };

  private renderButton = (
    type: AddPhotoButtonType,
    content: IAddPhotoTexts
  ) => {
    switch (type) {
      case "link":
        return (
          <a className="g-tertiary-link" onClick={this.addPhoto}>
            + {content.addPhoto}
          </a>
        );
      case "button":
        return (
          <Button
            type="primary"
            text={content.addPhoto}
            onClick={this.addPhoto}
          />
        );
      case "panel":
        return (
          <a
            className="c-add-photo__panel"
            onClick={this.addPhoto}
            onKeyPress={(e) => e.key === "Enter" && this.addPhoto()}
            role="button"
            aria-label={content.addPhoto}
            tabIndex={0}
          >
            <div className="text-center c-add-photo__panel-inner">
              <div className="icon-plus" />
              <div className="c-add-photo__panel-text">{content.addPhoto}</div>
            </div>
          </a>
        );
    }
  };

  private renderPhotoInvalidError = (
    { photoInvalidWarning }: IAddPhotoTexts,
    message: string
  ) => {
    const PHOTO_INVALID_PROPS: IAlertMessageProps = {
      icon: "warning",
      texts: {
        title: photoInvalidWarning.heading,
        description: message,
      },
      buttons: [
        {
          name: photoInvalidWarning.chooseNew,
          type: "primary",
          click: this.retriggerPhotoUpload,
        },
        {
          name: photoInvalidWarning.cancel,
          type: "secondary",
          click: this.resetPhotoUpload,
        },
      ],
    };

    return (
      <Popup
        width={{ lg: 4, md: 6 }}
        close={this.resetPhotoUpload}
        priority="high"
        texts={{ closePopup: photoInvalidWarning.cancel }}
      >
        <AlertMessage {...PHOTO_INVALID_PROPS} />
      </Popup>
    );
  };

  private handleImageLoad = (img: HTMLImageElement) => () => {
    const width = img.naturalWidth;
    const height = img.naturalHeight;

    if (
      width < config.photoValidation.minResolution.width ||
      height < config.photoValidation.minResolution.height
    ) {
      return this.setInvalidPhotoState(
        this.props.texts.photoInvalidWarning.resolution
      );
    }
    return this.props.photoAdded(img);
  };

  // @ts-ignore
  private onFileUpload = (event) => this.validatePhoto(event.target.files);

  private validatePhoto = (files: FileList) => {
    if (files.length) {
      const file = files[0];
      if (config.photoValidation.acceptedFileTypes.indexOf(file.type) <= -1) {
        return this.setInvalidPhotoState(
          this.props.texts.photoInvalidWarning.fileType
        );
      }

      if (file.size > config.photoValidation.maxFileSize) {
        return this.setInvalidPhotoState(
          this.props.texts.photoInvalidWarning.fileSize
        );
      }

      const img = new Image();

      img.src = window.URL.createObjectURL(file);
      img.onload = this.handleImageLoad(img);
    }
  };

  private setInvalidPhotoState = (message: string) =>
    this.setState({
      isUploadedPhotoInvalid: true,
      invalidPhotoMessage: message,
    });

  private resetPhotoUpload = () => {
    this.resetPhotoInput();
    this.setState({ ...PHOTO_UPLOAD_ZERO_STATE });
  };

  private resetPhotoInput = () => {
    if (this.addPhotoInputRef.current) {
      this.addPhotoInputRef.current.value = "";
    }
  };

  private retriggerPhotoUpload = () => {
    this.setState({ ...PHOTO_UPLOAD_ZERO_STATE }, this.addPhoto);
  };

  private addPhoto = () => {
    this.resetPhotoInput();
    if (this.props.preValidation) {
      this.props.preValidation();
    } else {
      this.showExplorer();
    }
  };
}
