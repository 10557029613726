import React from "react";
import cx from "classnames";

import "./InputLabel.scss";

export enum InputLabelStyleTypes {
  Primary = "primary",
  Secondary = "secondary",
}

export interface IInputLabelProps {
  label?: string;
  /** Optionally specify which element the label is for */
  htmlFor?: string;
  /** Optional component to display on the top-right */
  annex?: JSX.Element;
  /** Place annex next to label */
  inlineAnnexPosition?: boolean;
  /** Remove margins */
  noMargin?: boolean;
  /** Make the text bold */
  bold?: boolean;
  /** Style type. Check out example for available options */
  styleType?: InputLabelStyleTypes;
}

export class InputLabel extends React.Component<IInputLabelProps> {
  public render() {
    const {
      label,
      htmlFor,
      annex,
      inlineAnnexPosition,
      styleType,
      bold,
      noMargin,
    } = this.props;

    return (
      <div
        className={cx(`c-input-label`, {
          "c-input-label--with-annex": Boolean(annex),
          "c-input-label--inline-annex": inlineAnnexPosition,
          "c-input-label--no-margin": noMargin,
          "c-input-label--bold": bold,
          [`c-input-label--${styleType}` as string]: Boolean(styleType),
        })}
      >
        {label ? (
          <label className="c-input-label__label" htmlFor={htmlFor}>
            {label}
          </label>
        ) : null}
        {annex ? <div className="c-input-label__annex">{annex}</div> : null}
      </div>
    );
  }
}
