import React from "react";
import FocusTrap from "focus-trap-react";
import cx from "classnames";

import {
  removeClass,
  addClass,
  isSmallDeviceWidth,
} from "../../../generics/dom-extensions";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "../../../generics/body-scroll-lock";

import "./Popup.scss";

export interface IPopupProps {
  /**
   * Priority describes the order on the screen
   */
  priority?: "high" | "medium" | "content";
  /**
   * Width describes number of grid columns  on different resolution which pop-up will fill
   */
  width: {
    lg: number;
    md: number;
  };
  /**
   * Callback function on close popup event - handle hiding the popup in this function in your page.
   */
  close: (e: any) => void;
  /**
   * Texts for buttons, labels and alt text
   */
  texts?: {
    closePopup: string;
  };
  /** Optional css class for the topmost div */
  className?: string;
  /** Optional add css class &--full-height */
  fullHeight?: boolean;
  /** You could pass custom scrollable container */
  scrollableContainerSelector?: string;
  /** Optionally hide the close button */
  hideCloseButton?: boolean;
  children?: React.ReactNode;
  "data-testid"?: string;
}

export class Popup extends React.Component<IPopupProps, any> {
  private readonly closeButton: React.RefObject<HTMLDivElement>;
  private readonly popupContainerRef: React.RefObject<HTMLDivElement>;
  // @ts-ignore
  private scrollableContainer: Element | null;

  constructor(props: IPopupProps) {
    super(props);
    this.closeButton = React.createRef<HTMLDivElement>();
    this.popupContainerRef = React.createRef<HTMLDivElement>();
    document.addEventListener("keyup", this.handleEscape, false);
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur?.();
    }
  }

  public componentDidMount() {
    const customScrollableContainer = document.querySelector(
      `.c-popup ${
        this.props.scrollableContainerSelector
          ? this.props.scrollableContainerSelector
          : ".g-popup-content__scrollable"
      }`
    );
    this.scrollableContainer = customScrollableContainer
      ? customScrollableContainer
      : this.popupContainerRef.current;
    disableBodyScroll(this.scrollableContainer);
  }

  public componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscape, false);
    enableBodyScroll(this.scrollableContainer);
    removeClass(document.body, "g-no-scroll");
  }

  public render() {
    addClass(document.body, "g-no-scroll");

    const popupClassNames = cx(
      `c-popup`,
      `c-popup--${this.props.priority}`,
      { "c-popup--full-height": this.props.fullHeight },
      this.props.className
    );

    return (
      <FocusTrap>
        <div className={popupClassNames}>
          {isSmallDeviceWidth() && this.props.fullHeight
            ? this.renderContent()
            : this.renderWithWrapper()}
        </div>
      </FocusTrap>
    );
  }

  private renderWithWrapper = () => {
    const popupWrapperColumnClassNames = cx(
      `c-popup__col`,
      `g-col-sm-12`,
      `g-col-md-${this.props.width.md}`,
      `g-col-lg-${this.props.width.lg}`,
      { "c-popup__col--full-height": this.props.fullHeight }
    );
    const lateralBlockClass = this.getLateralBlockClass();

    return (
      <div
        className="c-popup__wrapper g-content-area"
        data-testid={this.props["data-testid"]}
      >
        <div className={lateralBlockClass} />
        <div className={popupWrapperColumnClassNames}>
          <div className="c-popup__col-content">
            <div className="c-popup__container-wrapper">
              {this.renderContent()}
            </div>
          </div>
        </div>
        <div className={lateralBlockClass} />
      </div>
    );
  };

  private renderContent = () => {
    const popupContainerClassNames = cx(`c-popup__container`, {
      "c-popup__container--full-height": this.props.fullHeight,
    });
    const popupContentClassNames = cx(`c-popup__content`, {
      "c-popup__content--full-height": this.props.fullHeight,
    });

    return (
      <div
        role="dialog"
        aria-modal="true"
        ref={this.popupContainerRef}
        className={popupContainerClassNames}
        tabIndex={0}
      >
        {this.props.hideCloseButton ? null : (
          <div
            ref={this.closeButton}
            id="modal-close-button"
            onClick={this.close}
            aria-label={this.props?.texts?.closePopup}
            tabIndex={0}
            onKeyPress={this.handleKeyPress}
            className="c-popup__close icon-cross"
          />
        )}
        <div className={popupContentClassNames} aria-live="assertive">
          {this.props.children}
        </div>
      </div>
    );
  };

  public getLateralBlockClass = () => {
    const lateralIndentsMd = Math.floor((12 - this.props.width.md) / 2);
    const lateralIndentsLg = Math.floor((12 - this.props.width.lg) / 2);
    let lateralBlockClass = "g-hidden-sm ";

    lateralBlockClass +=
      lateralIndentsMd > 0 ? `g-col-md-${lateralIndentsMd} ` : "g-hidden-md ";
    lateralBlockClass +=
      lateralIndentsLg > 0 ? `g-col-lg-${lateralIndentsLg}` : "g-hidden-lg";

    return lateralBlockClass;
  };

  public handleEscape = (event: any) => {
    if (event.key === "Escape" || event.key === "Esc") {
      this.close(event);
    }
  };

  public handleKeyPress = (event: any): void => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
      return this.close(event);
    }
  };

  public close = (e: any) => {
    this.props.close(e);
    removeClass(document.body, "g-no-scroll");
  };
}
