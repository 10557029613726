export enum FileUploadState {
  NotStarted,
  Unsupported,
  PreparationComplete,
  Error,
  UploadComplete,
  Complete,
  Rename,
  RenameError,
}
