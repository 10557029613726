interface IFeature {
  title: string;
  isClassic: boolean;
}

export const plansInfo: IFeature[] = [
  {
    title: "common:billing.planFeature.rolesJobsFeed",
    isClassic: true,
  },
  {
    title: "common:billing.planFeature.publicProfile",
    isClassic: true,
  },
  {
    title: "common:billing.planFeature.accessContacts",
    isClassic: true,
  },
  {
    title: "common:billing.planFeature.developmentEvents",
    isClassic: true,
  },
  {
    title: "common:billing.planFeature.faq",
    isClassic: true,
  },
  {
    title: "common:billing.planFeature.talentScout",
    isClassic: true,
  },
  {
    title: "common:billing.planFeature.profileInsights",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.performerSearch",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.mediaStorage",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.submissionIndicator",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.availability",
    isClassic: false,
  },
];

export const industrySupport: IFeature[] = [
  {
    title: "common:billing.planFeature.groupAdvice",
    isClassic: true,
  },
  {
    title: "common:billing.planFeature.events",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.oneToOneAdvice",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.archivedEvents",
    isClassic: false,
  },
];

export const resources: IFeature[] = [
  {
    title: "common:billing.planFeature.discounts",
    isClassic: true,
  },
  {
    title: "common:billing.planFeature.personalInsurance",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.travelInsurance",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.taxInvestigation",
    isClassic: false,
  },
  {
    title: "common:billing.planFeature.healthSupport",
    isClassic: false,
  },
];
