import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";

import "./ActionsFooter.scss";

export type ActionsFooterRenderControl = (
  renderText: (text: string) => JSX.Element
) => JSX.Element;

export interface IActionsFooterProps {
  variant: "primary" | "secondary";
  renderControl: ActionsFooterRenderControl;
  isSticky?: boolean;
  fullWidth?: boolean;
}

interface IActionsFooterState {
  isSticky: boolean;
}

export class ActionsFooter extends React.Component<
  IActionsFooterProps,
  IActionsFooterState
> {
  private actionsFooterContainerRef = React.createRef<HTMLDivElement>();
  private anchorRef = React.createRef<HTMLDivElement>();
  private closeTimeout: number | undefined = undefined;

  private observer: IntersectionObserver | undefined;
  private isInViewport = false;
  private closeTimeoutDelay = 50;

  public readonly state: Readonly<IActionsFooterState> = {
    isSticky: Boolean(this.props.isSticky),
  };

  public componentDidMount() {
    this.setAnchorHeight();
    this.setObserver();
  }

  // @ts-ignore
  public componentDidUpdate(prevProps) {
    this.handleIsStickyPropUpdate(prevProps.isSticky);
  }

  public componentWillUnmount() {
    this.unsetObserver();
  }

  public render() {
    const containerClassNames = this.props.fullWidth
      ? "g-col-lg-12"
      : "g-col-lg-6 g-col-md-8 g-col-sm-12 g-start-lg-7 g-start-md-5";
    const contentClassNames = this.props.fullWidth
      ? "g-col-lg-12"
      : "g-col-lg-6 g-col-md-8 g-col-sm-12";
    const controlClassNames = this.props.fullWidth
      ? "g-col-lg-12"
      : "g-col-lg-6 g-col-md-8 g-col-sm-12";

    return (
      <>
        <div
          className={cx("c-actions-footer", "g-content-area", {
            "c-actions-footer--sticky": this.state.isSticky,
            [`c-actions-footer--${this.props.variant}`]: this.props.variant,
          })}
          data-testid={
            dataTestIds.componentLibrary[
              "Molecules.ActionsFooter.actionsFooter"
            ]
          }
        >
          <div
            className={cx(
              "c-actions-footer__container",
              "g-inner-grid",
              containerClassNames
            )}
            ref={this.actionsFooterContainerRef}
          >
            <div className={cx("c-actions-footer__content", contentClassNames)}>
              <div
                className={cx(
                  "c-actions-footer__control g-inner-grid",
                  controlClassNames
                )}
              >
                {this.props.renderControl((text) => (
                  <div className="c-actions-footer__text">
                    <span>{text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <span className="c-actions-footer__anchor" ref={this.anchorRef} />
      </>
    );
  }

  private handleTransitionEnd = () => {
    const element = this.actionsFooterContainerRef.current!;
    element.removeEventListener("transitionend", this.handleTransitionEnd);

    if (this.state.isSticky) {
      element.style.transform = "";
      this.setState({ isSticky: false });
    }
  };

  private setAnchorHeight() {
    const anchor = this.anchorRef.current;
    const footerContainer = this.actionsFooterContainerRef.current;

    if (!anchor || !footerContainer) {
      return;
    }

    anchor.style.height = `${footerContainer.getBoundingClientRect().height}px`;
  }

  private setObserver() {
    if (
      typeof IntersectionObserver === "undefined" ||
      !this.anchorRef.current
    ) {
      return;
    }

    this.observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio >= 1) {
          this.isInViewport = true;
          const element = this.actionsFooterContainerRef.current;

          if (element && element.style.transform) {
            element.style.transitionDuration = "0.01s";
          }
        } else if (entries[0].intersectionRatio <= 0) {
          this.isInViewport = false;
        }
      },
      {
        threshold: [0, 1],
      }
    );

    this.observer.observe(this.anchorRef.current);
  }

  private unsetObserver() {
    this.anchorRef.current && this.observer?.unobserve(this.anchorRef.current);
  }

  private handleIsStickyPropUpdate(prevIsSticky: boolean) {
    if (this.props.isSticky && !prevIsSticky) {
      window.clearTimeout(this.closeTimeout);
      this.actionsFooterContainerRef.current?.removeEventListener(
        "transitionend",
        this.handleTransitionEnd
      );
      this.show();
    }

    if (!this.props.isSticky && prevIsSticky) {
      this.hide();
    }
  }

  private hide() {
    const element = this.actionsFooterContainerRef.current;

    if (!element) {
      return;
    }

    this.closeTimeout = window.setTimeout(() => {
      if (!this.isInViewport) {
        element.style.transitionDuration = "";
        element.addEventListener("transitionend", this.handleTransitionEnd);
        element.style.transform = "translateY(100%)";
      } else {
        this.setState({
          isSticky: false,
        });
      }
    }, this.closeTimeoutDelay);
  }

  private show() {
    const element = this.actionsFooterContainerRef.current;

    if (!element) {
      return;
    }

    element.style.transition = "none";

    window.requestAnimationFrame(() => {
      if (!this.isInViewport) {
        element.style.transform = "translateY(100%)";
      }

      this.setState(
        {
          isSticky: true,
        },
        () => {
          window.requestAnimationFrame(() => {
            element.style.transition = "";
            element.style.transform = "translateY(0)";
          });
        }
      );
    });
  }
}

export default ActionsFooter;
