import { useLayoutEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { breakpoints } from "../patternlib-styles/settings/breakpoints";
import { isSafari } from "./browser-detection";

export type ScreenWidthEnumValueType =
  (typeof SCREEN_WIDTH_ENUM)[keyof typeof SCREEN_WIDTH_ENUM];

const MOBILE_AGENTS_PATTERN =
  /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

export const SCREEN_WIDTH_ENUM = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XLG: "xlg",
} as const;

export function hasClass(element: HTMLElement, className: string): boolean {
  return !!element.className.match(
    new RegExp("(\\s|^)" + className + "(\\s|$)")
  );
}

export function addClass(element: HTMLElement, className: string): void {
  if (!hasClass(element, className)) {
    element.className += " " + className;
  }
}

export function isMobileDevice() {
  return MOBILE_AGENTS_PATTERN.test(navigator.userAgent.toLowerCase());
}

export function removeClass(element: HTMLElement, className: string): void {
  if (hasClass(element, className)) {
    element.className = element.className.replace(" " + className, "");
  }
}

export function getWindowWidth(): number {
  return document.documentElement.clientWidth || window.innerWidth || 0;
}

export function isIOSDevice() {
  return (
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.platform &&
    /iPad|iPhone|iPod|MacIntel|(iPad Simulator)|(iPhone Simulator)|(iPod Simulator)/.test(
      window.navigator.platform
    ) &&
    window.navigator.maxTouchPoints > 2
  );
}

export function isLargeDeviceWidth(): boolean {
  return getWindowWidth() >= breakpoints.largeDeviceMinWidth;
}

export function isMediumDeviceWidth(): boolean {
  const width = getWindowWidth();
  return (
    width >= breakpoints.mediumDeviceMinWidth &&
    width <= breakpoints.mediumDeviceMaxWidth
  );
}

export function isSmallDeviceWidth(): boolean {
  return getWindowWidth() <= breakpoints.smallDeviceMaxWidth;
}

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setIsMobile(window.innerWidth <= breakpoints.smallDeviceMaxWidth);
    };
    window.addEventListener("resize", _debounce(updateSize, 100));
    return (): void => window.removeEventListener("resize", updateSize);
  }, []);

  return isMobile;
};

export const doesDeviceWidthMatchType = ({
  screenWidthEnum,
  currentWidth,
}: {
  screenWidthEnum: ScreenWidthEnumValueType;
  currentWidth?: number;
}): boolean => {
  if (!currentWidth) return false;

  switch (screenWidthEnum) {
    case SCREEN_WIDTH_ENUM.SM:
      return currentWidth <= breakpoints.smallDeviceMaxWidth;
    case SCREEN_WIDTH_ENUM.MD:
      return (
        currentWidth >= breakpoints.mediumDeviceMinWidth &&
        currentWidth <= breakpoints.mediumDeviceMaxWidth
      );
    case SCREEN_WIDTH_ENUM.LG:
      return currentWidth >= breakpoints.largeDeviceMinWidth;

    default:
      return false;
  }
};

export function getElementOffset(el: HTMLElement): number {
  return el
    ? el.getBoundingClientRect().top - document.body.getBoundingClientRect().top
    : 0;
}

export function printCrossBrowser() {
  if (isSafari()) {
    document.execCommand("print", false);
  } else {
    window.print();
  }
}
