import React from "react";
import { isSafari } from "../../../generics/browser-detection";
import { debounce } from "../../../generics/debounce";

import {
  addClass,
  isIOSDevice,
  isMediumDeviceWidth,
  isSmallDeviceWidth,
  removeClass,
} from "../../../generics/dom-extensions";

export enum ScreenSize {
  Small = "small-screen",
  Medium = "medium-screen",
  Large = "large-screen",
}

export const IS_IOS = "is-ios-device";
export const IS_SAFARI = "is-safari";

export interface IBrowserPatcherProps {
  /**  you could disable fix of html position by add this props to true  */
  disableVirtualKeyboardFix?: boolean;
  /**  you could disable setting large-, medium-, small-screen classes to html by add this props to true  */
  disableSetScreenSizeToHtml?: boolean;
}

export interface IBrowserPatcherState {
  currentSize: ScreenSize;
}

export class BrowserPatcher extends React.PureComponent<
  IBrowserPatcherProps,
  IBrowserPatcherState
> {
  constructor(props: IBrowserPatcherProps) {
    super(props);

    this.state = {
      currentSize: this.getScreenSize(),
    };
  }

  private html = document.querySelector("html")!;
  private isIOS = isIOSDevice();
  private isSafari = isSafari();

  public componentDidMount() {
    if (this.isIOS) {
      addClass(this.html, IS_IOS);
      this.fixSafariOrientationChange();

      if (!this.props.disableVirtualKeyboardFix) {
        this.fixHTMLPosition();
      }
    }

    if (this.isSafari) {
      addClass(this.html, IS_SAFARI);

      if (!this.props.disableSetScreenSizeToHtml) {
        addClass(this.html, this.state.currentSize);
        this.trackBrowserResize();
      }
    }
  }

  public componentWillUnmount() {
    if (this.isIOS) {
      window.removeEventListener(
        "orientationchange",
        this.handleOrientationChange
      );
    }

    if (this.isSafari) {
      window.removeEventListener("resize", this.handleBrowserResizeDebounced);
    }
  }

  public render() {
    return null;
  }

  private handleOrientationChange = () => {
    this.html.style.height = "100vh";

    setTimeout(() => {
      this.html.style.height = "100%";
    }, 500);
  };

  private fixSafariOrientationChange() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
  }

  private fixHTMLPosition() {
    this.html.style.position = "fixed";
    this.html.style.width = "100%";
  }

  private trackBrowserResize() {
    window.addEventListener("resize", this.handleBrowserResizeDebounced);
  }

  private handleBrowserResize = () => {
    const { currentSize } = this.state;
    const updatedSize = this.getScreenSize();

    if (currentSize !== updatedSize) {
      removeClass(this.html, currentSize);
      addClass(this.html, updatedSize);

      this.setState({ currentSize: updatedSize });
    }
  };

  private getScreenSize = () => {
    return isSmallDeviceWidth()
      ? ScreenSize.Small
      : isMediumDeviceWidth()
      ? ScreenSize.Medium
      : ScreenSize.Large;
  };

  private handleBrowserResizeDebounced = debounce(
    this.handleBrowserResize,
    100
  );
}
