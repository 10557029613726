import axios from "axios";
import authorizationService from "./authorizationService";

export const authAxios = axios.create();

export function initAuthAxios() {
  authAxios.interceptors.request.use((config) => {
    if (config.headers)
      config.headers.Authorization =
        authorizationService.getAuthorizationHeader();
    return config;
  });

  authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        await authorizationService.trySilentSignInThenRedirect();

        originalRequest.headers.Authorization =
          authorizationService.getAuthorizationHeader();

        return axios(originalRequest);
      }

      return Promise.reject(error);
    }
  );
}
