import _compact from "lodash/compact";
import { i18n } from "i18n";
import { getMediaRequestsSubNav } from "./sharedLinks";

interface IGetPerformerNav {
  isFilmmakersProfile?: boolean;
  isMediaRequestEnabled?: boolean;
}

export function getPerformerNav({
  isFilmmakersProfile,
  isMediaRequestEnabled,
}: IGetPerformerNav) {
  return {
    mainNavigationItems: _compact([
      {
        text: i18n.t("common:nav.links.contactsListings"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2228/contacts.svg",
        linkUrl:
          "/contacts/?utm_source=leftNav&utm_medium=oldSite&utm_campaign=contactsLaunch",
      },
      {
        text: i18n.t("common:nav.links.jobsFeed"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2230/search.svg",
        linkUrl: "/jobs/matching-my-filters",
        subNavigationItems: [
          {
            text: i18n.t("common:nav.links.jobMatches"),
            linkUrl: "/jobs/matching-my-filters",
          },
          {
            text: i18n.t("common:nav.links.paidJobs"),
            linkUrl: "/jobs/all-paid",
          },
          {
            text: i18n.t("common:nav.links.allJobs"),
            linkUrl: "/jobs/all-opportunities",
          },
          ...getMediaRequestsSubNav(i18n.t, isMediaRequestEnabled),
          {
            text: i18n.t("common:nav.links.jobSettings"),
            linkUrl: "/settings/",
          },
        ],
      },
      {
        text: i18n.t("common:nav.links.usersProfile"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2229/clients.svg",
        linkUrl: "/profile/",
        subNavigationItems: _compact([
          !isFilmmakersProfile && {
            text: i18n.t("common:nav.links.representation"),
            linkUrl: "/profile/contacts",
          },
          {
            text: i18n.t("common:nav.links.viewUsersProfile"),
            linkUrl: "/profile/myviewpin",
          },
          !isFilmmakersProfile && {
            text: i18n.t("common:nav.links.updateProfile"),
            linkUrl: "/profile/",
          },
          !isFilmmakersProfile && {
            text: i18n.t("common:nav.links.printUsersProfile"),
            linkUrl: "/profile?action=print",
          },
          !isFilmmakersProfile && {
            text: i18n.t("common:nav.links.emailUsersProfile"),
            linkUrl: "/profile?action=email",
          },
          {
            text: i18n.t("common:nav.links.usersProfileLink"),
            linkUrl: "/profile/linktoprofile",
          },
        ]),
      },
      !isFilmmakersProfile && {
        text: i18n.t("common:nav.links.manageMedia"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2228/media.svg",
        linkUrl: "/profile/photos",
        subNavigationItems: [
          {
            text: i18n.t("common:nav.links.photos"),
            linkUrl: "/profile/photos",
          },
          {
            text: i18n.t("common:nav.links.video"),
            linkUrl: "/profile/media/video",
          },
          {
            text: i18n.t("common:nav.links.audio"),
            linkUrl: "/profile/media/audio",
          },
        ],
      },
      !isFilmmakersProfile && {
        text: i18n.t("common:nav.links.accountSettings"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2229/settings.svg",
        linkUrl: "/myhome/account-settings",
        subNavigationItems: [
          {
            text: i18n.t("common:nav.links.usersDetails"),
            linkUrl: "/myhome/account-settings/my-details",
          },
          {
            text: i18n.t("common:nav.links.loginDetails"),
            linkUrl: "/myhome/account-settings/my-login-details",
          },
          {
            text: i18n.t("common:nav.links.usersSubscription"),
            linkUrl: "/myhome/account-settings/my-subscription",
          },
          {
            text: i18n.t("common:nav.links.privacySettings"),
            linkUrl: "/artists/privacy.aspx",
          },
        ],
      },
      {
        text: i18n.t("common:nav.help"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/help.svg",
        linkUrl: "https://www.spotlight.com/help-and-faqs/",
      },
    ]),
  };
}
