export enum PremiumEntitlements {
  Classic_Site_Access = "classic_site_access",
  Premium_Site_Access = "premium_site_access",
  Classic_Events = "classic_events",
  Premium_Events = "premium_events",
  Classic_Yes_No = "classic_yes_no",
  Premium_Yes_No = "premium_yes_no",
  Premium_Stats = "premium_stats",
  Premium_Media_Quota = "premium_media_quota",
  Premium_Performer_Search = "premium_performer_search",
  Premium_Early_Sub = "premium_early_sub",
  Premium_Calendar = "premium_calendar",
  Premium_Aux = "premium_aux",
  Classic_Scout = "classic_scout",
  Premium_Scout = "premium_scout",
  FilmmakersSync = "filmmakers_sync",
}
