import { constructQueryString } from "../helpers/query-string";
import { IGetAgenciesResponse } from "./liteagent.types";

export class LiteAgentService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  getAgencies(queryString?: {
    performerType?: number;
    agentsorschools?: string;
  }): Promise<IGetAgenciesResponse> {
    const query = constructQueryString(queryString);
    return fetch(`${this.url}/getagencies${query}`).then((res) => res.json());
  }
}
