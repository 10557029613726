import cx from "classnames";
import React from "react";

import "./SectionControls.scss";

export interface ISectionControlsProps {
  className?: string;
}

const SectionControls: React.FC<ISectionControlsProps> = (props) => (
  <div className={cx("c-section-controls", props.className)}>
    {props.children}
  </div>
);

export default SectionControls;
