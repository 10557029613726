const iconMap: Record<string, number> = {
  "icon-Large-Grid": 0xe92a,
  "icon-Small-grid": 0xe958,
  "icon-profile": 0xe965,
  "icon-retry": 0xe966,
  "icon-open-in-new-tab": 0xe967,
  "icon-filmcamera": 0xe968,
  "icon-controlsPIP": 0xe969,
  "icon-Expand": 0xe911,
  "icon-Filter": 0xe90c,
  "icon-Print": 0xe938,
  "icon-alarm": 0xe922,
  "icon-arrowdown": 0xe900,
  "icon-arrowup": 0xe901,
  "icon-arrowleft": 0xe963,
  "icon-arrowright": 0xe964,
  "icon-audio": 0xe921,
  "icon-backtotoparrow": 0xe920,
  "icon-basket": 0xe91c,
  "icon-bell": 0xe944,
  "icon-book": 0xe923,
  "icon-bottom": 0xe924,
  "icon-brightness": 0xe91b,
  "icon-bubble1": 0xe945,
  "icon-burger": 0xe90d,
  "icon-camera1": 0xe959,
  "icon-check-circle": 0xe946,
  "icon-check1": 0xe947,
  "icon-chevron": 0xe90b,
  "icon-chevroncircle": 0xe90f,
  "icon-chevrondown": 0xe925,
  "icon-chevronleft": 0xe926,
  "icon-chevronright": 0xe927,
  "icon-chevronup": 0xe928,
  "icon-circledot": 0xe92b,
  "icon-clients": 0xe929,
  "icon-cog1": 0xe948,
  "icon-contacts": 0xe91d,
  "icon-contrast": 0xe902,
  "icon-crop": 0xe903,
  "icon-cross1": 0xe96a,
  "icon-cross": 0xe96a,
  "icon-cursor": 0xe942,
  "icon-delete": 0xe904,
  "icon-doc-plus": 0xe949,
  "icon-download1": 0xe94a,
  "icon-drag": 0xe94b,
  "icon-copylink": 0xe96b,
  "icon-role": 0xe96c,
  "icon-duplicate": 0xe986,
  "icon-edit": 0xe906,
  "icon-events": 0xe91e,
  "icon-expandimg": 0xe915,
  "icon-eye-red": 0xe912,
  "icon-eye-red1": 0xe92c,
  "icon-eyeclosed": 0xe92d,
  "icon-faqs": 0xe92e,
  "icon-filter": 0xe92f,
  "icon-folder": 0xe930,
  "icon-heart": 0xe94c,
  "icon-hide": 0xe905,
  "icon-home1": 0xe94d,
  "icon-lhnhelp": 0xe931,
  "icon-list": 0xe987,
  "icon-lock1": 0xe94e,
  "icon-magnify": 0xe932,
  "icon-map-pointer": 0xe94f,
  "icon-map1": 0xe950,
  "icon-messages": 0xe933,
  "icon-minus": 0xe914,
  "icon-move-arrow": 0xe913,
  "icon-move1": 0xe951,
  "icon-notes": 0xe934,
  "icon-nudge": 0xe988,
  "icon-paperclip1": 0xe952,
  "icon-pen": 0xe935,
  "icon-pencil": 0xe936,
  "icon-performancecal": 0xe937,
  "icon-phone-landline1": 0xe953,
  "icon-phone-mobile1": 0xe954,
  "icon-picture1": 0xe955,
  "icon-playsmall": 0xe90e,
  "icon-plus": 0xe91a,
  "icon-processing": 0xe939,
  "icon-replace": 0xe907,
  "icon-rotate": 0xe908,
  "icon-send": 0xe989,
  "icon-settings": 0xe93a,
  "icon-share": 0xe91f,
  "icon-sharearrow": 0xe93b,
  "icon-shortlist": 0xe93c,
  "icon-showreel": 0xe93d,
  "icon-socialfacebook": 0xe916,
  "icon-socialinsta": 0xe917,
  "icon-socialtwitter": 0xe918,
  "icon-straighten": 0xe909,
  "icon-sun": 0xe93e,
  "icon-sync1": 0xe956,
  "icon-table": 0xe93f,
  "icon-top": 0xe940,
  "icon-twoclients": 0xe941,
  "icon-undo": 0xe90a,
  "icon-upload": 0xe98b,
  "icon-up-down": 0xe98a,
  "icon-wallet": 0xe943,
  "icon-website": 0xe919,
  "icon-zip1": 0xe957,
  "icon-website2": 0xe95a,
  "icon-controlsPause": 0xe95b,
  "icon-controlsPlay": 0xe95c,
  "icon-controlsSkip": 0xe95d,
  "icon-controlsMute": 0xe95e,
  "icon-controlsvolDown": 0xe95f,
  "icon-controlsvolUp": 0xe960,
  "icon-cross-circle": 0xe961,
  "icon-controlsMuteoff": 0xe962,
};

export default iconMap;
