import React from "react";
import cx from "classnames";

interface IPageContentProps {
  children: React.ReactNode;
  noPadding?: boolean;
  maxWidth?: number;
  className?: string;
}

export const PageContent: React.FC<IPageContentProps> = ({
  children,
  noPadding,
  maxWidth,
  className,
}) => {
  const styles: any = {};
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  maxWidth && (styles.maxWidth = maxWidth);

  return (
    <div className="flex justify-center flex-1">
      <div
        className={cx(
          `w-full max-w-7xl flex flex-col`,
          {
            "px-2 md:px-4": !noPadding,
          },
          className
        )}
        style={styles}
      >
        {children}
      </div>
    </div>
  );
};
