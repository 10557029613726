import React, { createContext, useMemo, FC } from "react";
import { useGetCommonTranslations } from "../hooks/useGetCommonTranslations";
import { Spinner } from "../../../Atoms/Spinner/Spinner";

export const TranslationContext = createContext({});

export const TranslationContextProvider: FC = ({ children }) => {
  const { data: commonTranslations, isLoading } = useGetCommonTranslations();

  const translations = useMemo(
    () => ({
      common: commonTranslations,
    }),
    [commonTranslations]
  );

  return (
    <TranslationContext.Provider value={translations}>
      {isLoading ? <Spinner /> : children}
    </TranslationContext.Provider>
  );
};
