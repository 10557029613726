//@ts-nocheck
import * as React from "react";
import { Autocomplete, debounce, IAutocompleteItem } from "component-library";
import { Search } from "./Search";

import "./PerformerSearch.scss";
import SearchAndFiltersService from "services/api/SearchAndFilters/SearchAndFiltersService";
import { IPerformer, PerformerType } from "models/search";
import { IApplicationState } from "store";
import { connect } from "react-redux";
import config from "config/global";
import PageKey from "config/PageKey";
import { IMyAccountPageTranslation } from "models/translation";

export interface IPerformerSearchProps {
  pageTranslations: IMyAccountPageTranslation;
}

const initialState = {
  searchString: "",
  searchStringForResults: "",
  isSearchTriggered: false,
  isAutocompleteVisible: false,
  autocompleteList: [] as IAutocompleteItem[],
};

type State = typeof initialState;

export class PerformerSearch extends React.Component<
  IPerformerSearchProps,
  State
> {
  state = initialState;
  queuedSearch = false;

  render() {
    return (
      <div className="c-performer-search-custom">
        <Search
          searchString={this.state.searchString}
          onClear={this.onTextCleared}
          onTextChange={this.onTextChange}
          onSearch={this.onSearch}
          placeholder={
            this.props.pageTranslations["performer Search"]
              ?.searchPlaceholderText
          }
          loading={this.state.isSearchTriggered}
        />
        <Autocomplete
          list={this.state.autocompleteList}
          display={this.state.isAutocompleteVisible}
          selectItem={this.onSelectItem}
          formatName={this.formatPerformerName}
        />
      </div>
    );
  }

  onTextCleared = () => {
    this.setState({ searchString: "" });
  };
  onTextChange = (newValue: string) => {
    this.setState({ searchString: newValue }, () => {
      this.searchPerformerNameDebounced();
    });
  };
  onSearch = () => {
    this.goToPerformerSearch(this.state.searchString);
  };
  private onSelectItem = (item: IAutocompleteItem) => {
    if (typeof item.data == "string") {
      if (item.data.length > 0) {
        this.goToPerformerSearch(item.data);
      }
    } else {
      const viewPin = (item.data as IPerformer).viewPin;
      this.goToPerformerProfile(viewPin);
    }
  };
  private goToPerformerSearch(searchTerm: string) {
    window.location.href =
      config.siteRootUrl.replace(/\/$/, "") +
      "/performers?performerName=" +
      encodeURIComponent(searchTerm);
  }
  private goToPerformerProfile(viewPin: string) {
    window.location.href =
      config.siteRootUrl.replace(/\/$/, "") + "/" + viewPin;
  }

  private searchPerformerName = async () => {
    const { isSearchTriggered } = this.state;

    if (this.state.searchString.length > 0) {
      if (isSearchTriggered) {
        this.queuedSearch = true;
      } else {
        this.setState({
          isSearchTriggered: true,
        });

        const searchString = this.state.searchString;
        const response = await SearchAndFiltersService.getSearchResults(
          searchString
        );

        if (response?.profiles) {
          const autocompleteList: IAutocompleteItem[] = response.profiles.map(
            (profile) => ({
              name: `${profile.forename} ${profile.surname}`,
              data: profile,
            })
          );

          if (response.profiles.length == 0) {
            autocompleteList.push({
              name:
                '<div class="c-performer-search-custom__no-results">' +
                (this.props.pageTranslations["performer Search"]
                  ?.searchNoResultsFound || "") +
                "</div>",
              data: "",
            });
          } else {
            autocompleteList.push({
              name:
                '<div class="c-performer-search-custom__search-more">' +
                (
                  this.props.pageTranslations["performer Search"]
                    ?.searchShowMoreResultsFor || ""
                ).replace(
                  "{searchTerm}",
                  (searchString || "").replace(/[\<\>]/gi, "")
                ) +
                "</div>",
              data: searchString,
            });
          }

          this.setState({
            autocompleteList,
            isAutocompleteVisible: Boolean(autocompleteList.length),
            searchStringForResults: searchString,
            isSearchTriggered: false,
          });
        } else {
          this.setState({
            isSearchTriggered: false,
            autocompleteList: [],
            isAutocompleteVisible: false,
            searchStringForResults: "",
          });
        }

        if (this.queuedSearch) {
          this.queuedSearch = false;
          this.searchPerformerName();
        }
      }
    } else {
      this.setState({
        autocompleteList: [],
        isAutocompleteVisible: false,
        searchStringForResults: "",
      });
    }
  };

  private formatPerformerName = (item: IAutocompleteItem) => {
    if (typeof item.data == "string") {
      return item.name;
    }

    const label = item.name
      .toUpperCase()
      .replace(
        this.state.searchStringForResults.toUpperCase(),
        this.state.searchStringForResults.toUpperCase().bold()
      );

    const performerType = this.getPerformerType(item.data);

    return label + `<br/> (${performerType})`.small();
  };

  private getPerformerType = (performer: IPerformer) => {
    const performerSearch = this.props.pageTranslations["performer Search"];

    let performerType: string | undefined;

    switch (performer.performerType) {
      case PerformerType.Graduate:
        performerType = performerSearch?.searchLabelGraduate;
        break;
      case PerformerType.Performer:
        performerType = performerSearch?.searchLabelPerformer;
        break;
      case PerformerType.YoungPerformer:
        performerType = performerSearch?.searchLabelYoungPerformer;
        break;
    }

    return performerType || "";
  };

  private searchPerformerNameDebounced = debounce(
    this.searchPerformerName,
    300
  );
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    searchPerformersPlaceholderText: "Search performers",
    pageTranslations: state.translation.pages[PageKey.MyAccount],
  };
};

export default connect(mapStateToProps)(PerformerSearch);
