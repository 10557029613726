export enum ExpiryTimeUnits {
  MIN = "min",
  DAY = "day",
}

export function setCookie(
  name: string,
  value: string | number,
  expiryTimeOrOptions: {
    expiryTime: number;
    expiryTimeUnit: ExpiryTimeUnits.MIN | ExpiryTimeUnits.DAY;
    domain?: string;
    options?: Record<string, any>;
  }
) {
  switch (expiryTimeOrOptions.expiryTimeUnit) {
    case ExpiryTimeUnits.MIN:
      expiryTimeOrOptions.expiryTime = expiryTimeOrOptions.expiryTime * 60000;
      break;

    case ExpiryTimeUnits.DAY:
      expiryTimeOrOptions.expiryTime =
        24 * 60 * 60 * 1000 * expiryTimeOrOptions.expiryTime;
      break;
    default:
      throw new Error("expiryTimeUnit not supported, add case");
  }

  const d = new Date();
  d.setTime(d.getTime() + expiryTimeOrOptions.expiryTime);
  let cookieValue =
    name +
    "=" +
    encodeURIComponent(value) +
    ";path=/;expires=" +
    d.toUTCString();
  if (expiryTimeOrOptions.domain) {
    cookieValue += ";domain=" + expiryTimeOrOptions.domain;
  }

  if (expiryTimeOrOptions.options) {
    Object.entries(expiryTimeOrOptions.options).forEach(([key, val]) => {
      cookieValue += `;${key}=${val}`;
    });
  }

  document.cookie = cookieValue;
}

export function getCookie(name: string): string | null {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? decodeURIComponent(v[2]) : null;
}

export function deleteCookie(name: string) {
  setCookie(name, "", { expiryTime: -1, expiryTimeUnit: ExpiryTimeUnits.DAY });
}

/**
 * Returns the domain which should be used as the cookie domain!
 */
export function getCookieDomain(domain?: string) {
  const hostnameParts = (domain || window.location.hostname).split(/\./gi);
  const lastTwoHostnameParts = hostnameParts.slice(
    hostnameParts.length - 2,
    hostnameParts.length
  );
  return lastTwoHostnameParts.join(".");
}
