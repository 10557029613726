import React from "react";
import cx from "classnames";

import { ReactComponent as SuccessIcon } from "../../../images/svg/icons/success-icon.svg";
import { ReactComponent as WarningIcon } from "../../../images/svg/icons/warning-icon-new.svg";
import { ReactComponent as CloseIcon } from "../../../images/svg/icons/close-circle-icon.svg";

import "./NotificationToast.scss";

export enum NotificationToastTypes {
  Success = "success",
  Error = "error",
}

export interface INotificationToastProps {
  /** Optional css class for the topmost div */
  className?: string;

  /** Callback fired when the component requests to be closed */
  handleHide: () => void;

  /** Stick to related component. It is set automatically if used within WithNotificationToast HOC so basically you don't need to set it manually */
  stickToComponent?: boolean;

  /** If `true`, the component is shown */
  show: boolean;

  /** Text to render inside the the component */
  text: React.ReactNode;

  /** The number of milliseconds to wait before hiding the component */
  timeoutDuration?: number;

  /** Style type. Check out example for available options */
  type?: NotificationToastTypes;
  children?: React.ReactNode;
}

export class NotificationToast extends React.PureComponent<INotificationToastProps> {
  public static defaultProps = {
    type: NotificationToastTypes.Success,
    timeoutDuration: 5000,
  };

  private timeout: number | undefined = undefined;

  public componentDidUpdate(prevProps: INotificationToastProps) {
    if (this.props.show && !prevProps.show) {
      return this.startTimeout();
    }

    if (!this.props.show && prevProps.show) {
      return this.stopTimeout();
    }
  }

  public componentWillUnmount() {
    this.stopTimeout();
  }

  public renderIcon() {
    if (this.props.type === NotificationToastTypes.Success) {
      return <SuccessIcon width="20" height="20" />;
    }
    if (this.props.type === NotificationToastTypes.Error) {
      return <WarningIcon width="20" height="20" />;
    }

    return null;
  }

  public render() {
    const { className, handleHide, stickToComponent, show, text, type } =
      this.props;

    return (
      <div
        className={cx("c-notification-toast", {
          "c-notification-toast--active": show,
          "c-notification-toast--absolute": stickToComponent,
          "c-notification-toast--fixed": stickToComponent === false,
          [`c-notification-toast--${type}`]: type,
          [className as string]: Boolean(className),
        })}
        aria-hidden={!show}
      >
        <div className="c-notification-toast__title">
          <div className="c-notification-toast__title-icon">
            {this.renderIcon()}
          </div>
          <div className="c-notification-toast__title-text">{text}</div>
        </div>
        <button
          className="c-notification-toast__control"
          aria-label="Close"
          onClick={handleHide}
          type="button"
        >
          <CloseIcon width="20" height="20" />
        </button>
      </div>
    );
  }

  private startTimeout() {
    this.timeout = window.setTimeout(
      this.props.handleHide,
      this.props.timeoutDuration
    );
  }

  private stopTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }
}
