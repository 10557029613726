import React, { FC, useContext } from "react";
import Section from "../../Section";
import { ISubscription, PlanCodePeriod, PlanFilter } from "../../../types";
import SubscriptionCard from "./SubscriptionCard";
import { BillingContext } from "../../../BillingContext";

interface Props {
  classicSubscription?: ISubscription;
  premiumSubscription?: ISubscription;
  planPeriod: PlanCodePeriod;
  selectedPlanType: PlanFilter;
  setSelectedPlanType: (plan: PlanFilter) => void;
}

const SubscriptionBlock: FC<Props> = ({
  classicSubscription,
  premiumSubscription,
  planPeriod,
  selectedPlanType,
  setSelectedPlanType,
}) => {
  const { isAnnualPromoEnabled } = useContext(BillingContext);

  return (
    <Section isOpen>
      <div className="grid grid-cols-12 gap-x-6 gap-y-5">
        <SubscriptionCard
          isSelected={selectedPlanType === PlanFilter.Classic}
          onSelect={() => setSelectedPlanType(PlanFilter.Classic)}
          planType={PlanFilter.Classic}
          price={classicSubscription?.amount}
          totalPrice={classicSubscription?.total}
          isPromoEnabled={isAnnualPromoEnabled}
          planPeriod={planPeriod}
          currency={classicSubscription?.currency}
        />
        {/*<SubscriptionCard*/}
        {/*  isSelected={selectedPlanType === PlanFilter.Premium}*/}
        {/*  onSelect={() => setSelectedPlanType(PlanFilter.Premium)}*/}
        {/*  planType={PlanFilter.Premium}*/}
        {/*  price={premiumSubscription?.amount}*/}
        {/*  totalPrice={premiumSubscription?.total}*/}
        {/*  isPromoEnabled={isAnnualPromoEnabled}*/}
        {/*  planPeriod={planPeriod}*/}
        {/*  currency={premiumSubscription?.currency}*/}
        {/*/>*/}
      </div>
    </Section>
  );
};

export default SubscriptionBlock;
