import { constructQueryString } from "../helpers/query-string";
import {
  FacetType,
  IGetFacetsResponse,
  SkillsFacetsType,
} from "./facets.types";

const defaultLanguage = "en-gb";
export const GET_SKILLS_FACETS = "get-skills-facets";
export const GET_GENDER_FACET = "get-gender-facets";
export const GET_HnE_FACETS = "get-h-n-e-facets";

export class FacetService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  getFacets({
    category,
    language = defaultLanguage,
  }: {
    category: FacetType;
    language?: string;
  }): Promise<IGetFacetsResponse> {
    const queryString = constructQueryString({
      categories: category,
      lang: language,
    });

    return fetch(`${this.url}/facets/categories${queryString}`).then((res) =>
      res.json()
    );
  }

  getFacetsCategories(
    categories: (FacetType | SkillsFacetsType)[]
  ): Promise<IGetFacetsResponse> {
    const queryString = new URLSearchParams([
      ...categories.map(
        (category: string) => ["categories", category],
        ["lang", defaultLanguage]
      ),
    ]);

    return fetch(`${this.url}/facets/categories?${queryString}`).then((res) => {
      if (res.status !== 200) throw Error(String(res.status));
      return res.json();
    });
  }
}
