import React from "react";

import "./AudioTimeBar.scss";

export interface IAudioTimeBarProps {
  currentValue: number;
  totalValue: number;
  changeValue: (selectedValue: number) => void;
}

export const AudioTimeBar: React.FC<IAudioTimeBarProps> = ({
  totalValue,
  currentValue,
  changeValue,
}) => {
  const tapeWidth = (currentValue / totalValue) * 100;

  const changeValueWrapper: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => changeValue(Number(value));

  return (
    <div className="c-audio-time-bar">
      <div
        style={{ width: `${tapeWidth}%` }}
        className="c-audio-time-bar__tape"
      />
      <input
        min="0"
        max={totalValue}
        value={currentValue}
        type="range"
        className="c-audio-time-bar__time-bar"
        onChange={changeValueWrapper}
        tabIndex={-1}
      />
    </div>
  );
};
