import React, { Ref } from "react";
import { Button } from "component-library";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { useTranslation } from "i18n";
import { dataTestIds } from "data-testids";

type FormSectionProps<T> = {
  children: JSX.Element[] | JSX.Element;
  initialValues: T;
  innerRef: Ref<FormikProps<T>>;
  handleSubmit: (values: T) => void;
  validationSchema: any;
  buttonText?: string;
};

function FormSection<T extends FormikValues>({
  children,
  initialValues,
  innerRef,
  handleSubmit,
  validationSchema,
  buttonText,
}: FormSectionProps<T>) {
  const { t } = useTranslation();

  return (
    <Formik<T>
      innerRef={innerRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    >
      {({ submitForm }) => (
        <Form
          className="flex flex-col mt-6"
          data-testid={
            dataTestIds.userAccountApp["AccountSettings.FormSection.Form"]
          }
        >
          <div className="flex w-full md:w-1/2">{children}</div>
          <div className="flex justify-end w-full">
            <Button
              type="primary"
              text={
                buttonText ||
                t("performer:accountSettings.loginDetails.updateEmailButton")
              }
              className="md:w-[315px] mt-6"
              onClick={submitForm}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FormSection;
