import { IReviewPageTranslations } from "../../models/joining-pages/ReviewPage";

export const ReviewPageTranslations: IReviewPageTranslations = {
  main: {
    pageTitle: "Review",
    nameLabel: "Name:",
  },
  parentGuardian: {
    pgSectionLabel: "Parent / guardian details",
    pgEmailLabel: "Email address: ",
    pgPhoneNumberLabel: "Phone number: ",
  },
  eligibility: {
    training: "Training",
    credits: "Credits",
    recommendation: "Recommendation",
    upload: "Upload:",
    refereeNameLabel: "Referee's name:",
    refereeMembershipLabel: "Referee's membership:",
  },
  aboutYou: {
    sectionLabel: "About You",
    numbersLabel: "Phone numbers:",
    currentMemberLabel: "Previous or current Spotlight member?",
    dobLabel: "Date of birth:",
    publishAgeLabel: "Publish age?",
    findMeAsLabel: "Find me as:",
    showOnProfileLabel: "Show on profile:",
    membershipsLabel: "Memberships:",
    deafOrDisabled: "I identify as Deaf or disabled",
  },
  agents: {
    agentLabel: "Agent:",
  },
  reviewCD: {
    pageTitleCD: "Review",
    nameLabelCD: "Name",
    phoneLabelCD: "Phone numbers",
    emailLabelCD: "Email address",
    occupationLabelCD: "Your occupation",
    companyNameLabelCD: "Company name",
    companyWebsiteLabelCD: "Company website",
    membershipLabelCD: "Memberships",
    memberNameLabelCD: "Member name",
    memberEmailLabelCD: "Member email address",
    memberNumberLabelCD: "Membership number",
    prevSpotlightAccountLabelCD: "Previous Spotlight account?",
    spotlightAccountNameLabelCD: "Spotlight account name",
    spotlightMemberTypeLabelCD: "Spotlight member type",
    spotlightEmailLabelCD: "Spotlight email address",
    castingForLabelCD: "Casting for",
    linksLabelCD: "Links",
    uploadCVLabelCD: "Upload CV",
    performerUnder18LabelCD: "Casting performers under the age of 18",
    performerUnder18AwareLabelCD: "Aware of  relevant  regulations",
    useSpotlightForLabelCD: "Use Spotlight for",
    furtherDetails: "Further detail",
  },
  marketingPreferences: {
    marketingLabel: "Marketing preferences",
    marketingDescription: `<p>Our newsletters are packed with news and content from across the industry, and we might also invite you to the odd event or ask your advice. To get great articles and advice delivered to you, please select the marketing communications you would like to receive from us by ticking the relevant boxes below. Please note this does not affect the customer service communications which we will need to send you relating to your membership.</p>`,
    byEmailLabel: "By email",
    byPhone: "By telephone",
    bySms: "By SMS/text notifications",
    hideOptions: "Hide options",
    showOptions: "Show options",
    frequencyLabel: "Approx. frequency",
    thirdPartiesDescription:
      "We would also like to send you offers that we think may be of interest to you from carefully selected third party organisations. We will never pass your personal data on to a third party for marketing purposes..",
    thirdPartiesLabel: "Receive offers from third parties",
    thirdPartiesFootnote:
      "Please note that you can change your options at any time by accessing the Privacy Settings section of your account.",
  },
};
