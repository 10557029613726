import { useScrollToElement } from "shared-hooks";
import { useEffect } from "react";

const useScrollBillingField = (shouldScroll?: boolean) => {
  const { ref, scrollToElement } = useScrollToElement({
    block: "nearest",
    inline: "start",
  });

  useEffect(() => {
    if (shouldScroll) {
      scrollToElement();
    }
  }, [shouldScroll, scrollToElement]);

  return ref;
};

export default useScrollBillingField;
