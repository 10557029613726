import cx from "classnames";
import React from "react";

import SectionTableRow from "./SectionTableRow/SectionTableRow";

import "./SectionTable.scss";

export interface ISectionTableProps {
  className?: string;
}

class SectionTable extends React.PureComponent<ISectionTableProps> {
  public static Row = SectionTableRow;

  public render() {
    return (
      <ul className={cx("c-section-table", this.props.className)}>
        {this.props.children}
      </ul>
    );
  }
}

export default SectionTable;
