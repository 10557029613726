import { useEffect } from 'react';
import { connect } from 'react-redux';

import { setPage404 } from 'store/ui/ui.actions';
import { SetPage404 } from 'store/ui/ui.types';

export interface IPage404TriggerProps {
  setPage404: SetPage404;
}

export const Page404Trigger: React.FC<IPage404TriggerProps> = (props) => {
  useEffect(() => {
    props.setPage404(true);
  }, []);

  return null;
};

export default connect(() => ({}), { setPage404 })(Page404Trigger);
