import React from "react";
import cx from "classnames";
import "./BottomNav.scss";
import { ILink } from "../../../../generics/interfaces";

export interface IBottomNavProps {
  /** Optional css class for the topmost div */
  className?: string;
  /**
   * List of navigation links
   */
  links: ILink[];
}

export const BottomNav: React.FC<IBottomNavProps> = (
  props: IBottomNavProps
) => {
  return (
    <div
      className={cx(
        "c-bottom-menu",
        props.className ? `${props.className}` : ""
      )}
    >
      {props.links.map((link, index) => (
        <a
          className="c-bottom-menu__link"
          key={index}
          href={link.href}
          title={link.title}
          target={link.href.startsWith("http") ? "_blank" : "_self"}
        >
          {link.html}
        </a>
      ))}
    </div>
  );
};
