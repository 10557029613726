import React from "react";
import "./RangeSlider.scss";

export interface IRangeSliderProps {
  /**
   * The minimum numeric value for this input, which must not be greater than its maximum (max attribute) value.
   */
  minValue: number;
  /**
   * The maximum numeric value for the input. Must not be less than its minimum (min attribute) value.
   */
  maxValue: number;
  /**
   * The initial value of the control.
   */
  value: number;
  /**
   * Works with the min and max attributes to limit the increments.
   */
  step: number;
  /**
   * Names of parts for control
   */
  texts: {
    minValueName: string;
    maxValueName: string;
  };
  /**
   * The onchange property sets and returns the event handler for the change event.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * This Boolean attribute prevents the user from interacting with the input.
   */
  disabled?: boolean;
  /** Optional css class for the topmost div */
  className?: string;
}

export interface IRangeSliderState {
  rangeValue: number;
}

export class RangeSlider extends React.Component<
  IRangeSliderProps,
  IRangeSliderState
> {
  public constructor(props: IRangeSliderProps) {
    super(props);

    this.state = {
      rangeValue: props.value,
    };
  }

  public render() {
    const texts = this.props.texts;

    return (
      <div
        className={`c-range-slider${
          this.props.className ? " " + this.props.className : ""
        } ${this.props.disabled ? "disabled" : ""}`}
      >
        <div className="c-range-slider__labels">
          <div className="c-range-slider__extremes">{texts.minValueName}</div>
          <div className="c-range-slider__middle" />
          <div className="c-range-slider__extremes">{texts.maxValueName}</div>
        </div>
        <div className="c-range-slider__bar">
          <div
            className="c-range-slider__tape"
            style={{ width: this.getTapeCssWidth(this.state.rangeValue) }}
          />
          <input
            className="c-range-slider__range"
            type="range"
            step={this.props.step}
            disabled={this.props.disabled}
            value={this.state.rangeValue}
            onChange={this.changeValue}
            max={this.props.maxValue}
            min={this.props.minValue}
          />
        </div>
      </div>
    );
  }

  public get value(): number {
    return this.state.rangeValue;
  }

  public componentWillReceiveProps(nextProps: IRangeSliderProps) {
    if (nextProps.value !== this.state.rangeValue) {
      const value =
        nextProps.value !== undefined
          ? nextProps.value
          : (this.props.maxValue + this.props.minValue) / 2;
      this.setState({ rangeValue: value });
    }
  }

  public changeValue = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ): void => {
    if (this.props.onChange) {
      event.preventDefault();
      this.props.onChange(event);
    }
    this.setState({ rangeValue: parseFloat(event.target.value) });
  };

  public getTapeCssWidth(value: number): string {
    const relativeValue =
      ((value - this.props.minValue) /
        (this.props.maxValue - this.props.minValue)) *
      100;
    return `${relativeValue}%`;
  }
}
