import React from "react";
import cx from "classnames";

import "./LoadingIndicator.scss";

export enum LoadingIndicatorSizes {
  SMALL = "sm",
  MEDIUM = "md",
}

export interface ILoadingIndicatorProps {
  text?: string;
  variant: "primary" | "secondary" | "tertiary";
  size?: LoadingIndicatorSizes;
}

const DIMENSIONS_MAP = {
  sm: "20px",
  md: "50px",
};

export class LoadingIndicator extends React.PureComponent<ILoadingIndicatorProps> {
  public static defaultProps = {
    variant: "primary",
    size: LoadingIndicatorSizes.SMALL,
  };

  public render() {
    const dimensions =
      DIMENSIONS_MAP[this.props.size || LoadingIndicator.defaultProps.size];

    return (
      <span
        className={cx(
          "loading-indicator",
          `loading-indicator--${this.props.variant}`
        )}
        role="progressbar"
      >
        <span className="loading-indicator__inner">
          <span className="loading-indicator__spinner-container">
            <svg
              className="loading-indicator__spinner"
              width={dimensions}
              height={dimensions}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                className="loading-indicator__spinner-path"
                fill="none"
                strokeWidth="2"
                cx="10"
                cy="10"
                r="8.5"
              />
            </svg>
          </span>
          {this.props.text && (
            <span className="loading-indicator__text">{this.props.text}</span>
          )}
        </span>
      </span>
    );
  }
}

export default LoadingIndicator;
