export const Countries = [
  { value: "+44", code: "gb", label: "United Kingdom" },
  { value: "+93", code: "afghanistan", label: "Afghanistan" },
  { value: "+355", code: "albania", label: "Albania" },
  { value: "+213", code: "algeria", label: "Algeria" },
  { value: "+376", code: "andorra", label: "Andorra" },
  { value: "+244", code: "angola", label: "Angola" },
  { value: "+54", code: "argentina", label: "Argentina" },
  { value: "+374", code: "armenia", label: "Armenia" },
  { value: "+61", code: "australia", label: "Australia" },
  { value: "+43", code: "austria", label: "Austria" },
  { value: "+994", code: "azerbaijan", label: "Azerbaijan" },
  { value: "+973", code: "bahrain", label: "Bahrain" },
  { value: "+880", code: "bangladesh", label: "Bangladesh" },
  { value: "+375", code: "belarus", label: "Belarus" },
  { value: "+32", code: "belgium", label: "Belgium" },
  { value: "+501", code: "belize", label: "Belize" },
  { value: "+229", code: "benin", label: "Benin" },
  { value: "+591", code: "bolivia", label: "Bolivia" },
  {
    value: "+387",
    code: "bosniaAndHerzegovina",
    label: "Bosnia And Herzegovina",
  },
  { value: "+267", code: "botswana", label: "Botswana" },
  { value: "+55", code: "brazil", label: "Brazil" },
  { value: "+673", code: "brunei", label: "Brunei" },
  { value: "+975", code: "bhutan", label: "Buhtan" },
  { value: "+359", code: "bulgaria", label: "Bulgaria" },
  { value: "+226", code: "burkinaFaso", label: "Burkina Faso" },
  { value: "+257", code: "burundi", label: "Burundi" },
  { value: "+855", code: "cambodia", label: "Cambodia" },
  { value: "+237", code: "cameroon", label: "Cameroon" },
  { value: "+1", code: "canada", label: "Canada" },
  { value: "+238", code: "capeVerde", label: "Cape Verde" },
  {
    value: "+236",
    code: "centralAfricanRepublic",
    label: "Central African Republic",
  },
  { value: "+235", code: "chad", label: "Chad" },
  { value: "+56", code: "chile", label: "Chile" },
  { value: "+86", code: "china", label: "China" },
  { value: "+57", code: "colombia", label: "Colombia" },
  { value: "+506", code: "costaRica", label: "Costa Rica" },
  { value: "+385", code: "croatia", label: "Croatia" },
  { value: "+53", code: "cuba", label: "Cuba" },
  { value: "+357", code: "cyprus", label: "Cyprus" },
  { value: "+420", code: "cz", label: "Czech Republic" },
  { value: "+45", code: "denmark", label: "Denmark" },
  { value: "+253", code: "djibouti", label: "Djibouti" },
  { value: "+243", code: "drc", label: "DRC" },
  { value: "+670", code: "eastTimor", label: "East Timor" },
  { value: "+593", code: "ecuador", label: "Ecuador" },
  { value: "+20", code: "egypt", label: "Egypt" },
  { value: "+503", code: "elSalvador", label: "El Salvador" },
  { value: "+240", code: "equatorialGuinea", label: "Equatorial Guinea" },
  { value: "+291", code: "eritrea", label: "Eritrea" },
  { value: "+372", code: "estonia", label: "Estonia" },
  { value: "+251", code: "ethiopia", label: "Ethiopia" },
  { value: "+679", code: "fiji", label: "Fiji" },
  { value: "+358", code: "finland", label: "Finland" },
  { value: "+33", code: "france", label: "France" },
  { value: "+241", code: "gabon", label: "Gabon" },
  { value: "+220", code: "gambia", label: "Gambia" },
  { value: "+995", code: "georgia", label: "Georgia" },
  { value: "+49", code: "germany", label: "Germany" },
  { value: "+233", code: "ghana", label: "Ghana" },
  { value: "+350", code: "gibraltar", label: "Gibraltar" },
  { value: "+30", code: "greece", label: "Greece" },
  { value: "+299", code: "greenland", label: "Greenland" },
  { value: "+502", code: "guatemala", label: "Guatemala" },
  { value: "+224", code: "guinea", label: "Guinea" },
  { value: "+245", code: "guineaBissau", label: "Guinea Bissau" },
  { value: "+592", code: "guyana", label: "Guyana" },
  { value: "+509", code: "haiti", label: "Haiti" },
  { value: "+504", code: "honduras", label: "Honduras" },
  { value: "+852", code: "hongKong", label: "Hong Kong" },
  { value: "+36", code: "hungary", label: "Hungary" },
  { value: "+354", code: "iceland", label: "Iceland" },
  { value: "+91", code: "india", label: "India" },
  { value: "+62", code: "indonesia", label: "Indonesia" },
  { value: "+98", code: "iran", label: "Iran" },
  { value: "+964", code: "iraq", label: "Iraq" },
  { value: "+353", code: "ireland", label: "Ireland" },
  { value: "+972", code: "israel", label: "Israel" },
  { value: "+39", code: "italy", label: "Italy" },
  { value: "+225", code: "ivoryCoast", label: "Ivory Coast" },
  { value: "+81", code: "japan", label: "Japan" },
  { value: "+962", code: "jordan", label: "Jordan" },
  { value: "+997", code: "kazakhstan", label: "Kazakhstan" },
  { value: "+254", code: "kenya", label: "Kenya" },
  { value: "+686", code: "kiribati", label: "Kiribati" },
  { value: "+383", code: "kosovo", label: "Kosovo" },
  { value: "+965", code: "kuwait", label: "Kuwait" },
  { value: "+996", code: "kyrgyzstan", label: "Kyrgyzstan" },
  { value: "+856", code: "laos", label: "Laos" },
  { value: "+371", code: "latvia", label: "Latvia" },
  { value: "+961", code: "lebanon", label: "Lebanon" },
  { value: "+266", code: "lesotho", label: "Lesotho" },
  { value: "+231", code: "liberia", label: "Liberia" },
  { value: "+218", code: "libya", label: "Libya" },
  { value: "+423", code: "liechtenstein", label: "Liechtenstein" },
  { value: "+370", code: "lithuania", label: "Lithuania" },
  { value: "+352", code: "luxembourg", label: "Luxembourg" },
  { value: "+853", code: "macau", label: "Macau" },
  { value: "+389", code: "macedonia", label: "Macedonia" },
  { value: "+261", code: "madagascar", label: "Madagascar" },
  { value: "+265", code: "malawi", label: "Malawi" },
  { value: "+60", code: "malaysia", label: "Malaysia" },
  { value: "+960", code: "maldives", label: "Maldives" },
  { value: "+223", code: "mali", label: "Mali" },
  { value: "+356", code: "malta", label: "Malta" },
  { value: "+692", code: "marshallIsland", label: "Marshall Islands" },
  { value: "+222", code: "mauritania", label: "Mauritania" },
  { value: "+230", code: "mauritius", label: "Mauritius" },
  { value: "+52", code: "mexico", label: "Mexico" },
  { value: "+691", code: "micronesia", label: "Micronesia" },
  { value: "+373", code: "moldova", label: "Moldova" },
  { value: "+377", code: "monaco", label: "Monaco" },
  { value: "+976", code: "mongolia", label: "Mongolia" },
  { value: "+382", code: "montenegro", label: "Montenegro" },
  { value: "+212", code: "morocco", label: "Morocco" },
  { value: "+258", code: "mozambique", label: "Mozambique" },
  { value: "+95", code: "myanmar", label: "Myanmar" },
  { value: "+264", code: "namibia", label: "Namibia" },
  { value: "+977", code: "nepal", label: "Nepal" },
  { value: "+31", code: "netherlands", label: "Netherlands" },
  { value: "+64", code: "newZealand", label: "New Zealand" },
  { value: "+505", code: "nicaragua", label: "Nicaragua" },
  { value: "+227", code: "niger", label: "Niger" },
  { value: "+234", code: "nigeria", label: "Nigeria" },
  { value: "+850", code: "northKorea", label: "North Korea" },
  { value: "+47", code: "norway", label: "Norway" },
  { value: "+968", code: "oman", label: "Oman" },
  { value: "+92", code: "pakistan", label: "Pakistan" },
  { value: "+680", code: "palau", label: "Palau" },
  { value: "+970", code: "palestine", label: "Palestine" },
  { value: "+507", code: "panama", label: "Panama" },
  { value: "+675", code: "papuaNewGuinea", label: "Papua new guinea" },
  { value: "+595", code: "paraguay", label: "Paraguay" },
  { value: "+51", code: "peru", label: "Peru" },
  { value: "+63", code: "philippines", label: "Philippines" },
  { value: "+48", code: "poland", label: "Poland" },
  { value: "+351", code: "portugal", label: "Portugal" },
  { value: "+974", code: "qatar", label: "Qatar" },
  { value: "+242", code: "rpc", label: "Republic of the Congo" },
  { value: "+40", code: "romania", label: "Romania" },
  { value: "+7", code: "russia", label: "Russia" },
  { value: "+250", code: "rwanda", label: "Rwanda" },
  { value: "+685", code: "samoa", label: "Samoa" },
  { value: "+378", code: "sanMarino", label: "San Marino" },
  { value: "+239", code: "saoTomeAndPrince", label: "São Tomé and Príncipe" },
  { value: "+966", code: "saudiArabia", label: "Saudi Arabia" },
  { value: "+221", code: "senegal", label: "Senegal" },
  { value: "+381", code: "serbia", label: "Serbia" },
  { value: "+248", code: "seychelles", label: "Seychelles" },
  { value: "+232", code: "sierraLeone", label: "Sierra Leone" },
  { value: "+65", code: "singapore", label: "Singapore" },
  { value: "+421", code: "slovakia", label: "Slovakia" },
  { value: "+386", code: "slovenia", label: "Slovenia" },
  { value: "+677", code: "solomonIslands", label: "Solomon Islands" },
  { value: "+252", code: "somalia", label: "Somalia" },
  { value: "+27", code: "southAfrica", label: "South Africa" },
  { value: "+82", code: "southKorea", label: "South Korea" },
  { value: "+211", code: "southSudan", label: "South Sudan" },
  { value: "+34", code: "spain", label: "Spain" },
  { value: "+94", code: "sriLanka", label: "Sri Lanka" },
  { value: "+249", code: "sudan", label: "Sudan" },
  { value: "+597", code: "suriname", label: "Suriname" },
  { value: "+268", code: "swaziland", label: "Swaziland" },
  { value: "+46", code: "sweden", label: "Sweden" },
  { value: "+41", code: "switzerland", label: "Switzerland" },
  { value: "+963", code: "syria", label: "Syria" },
  { value: "+886", code: "taiwan", label: "Taiwan" },
  { value: "+992", code: "tajikistan", label: "Tajikistan" },
  { value: "+255", code: "tanzania", label: "Tanzania" },
  { value: "+66", code: "thailand", label: "Thailand" },
  { value: "+228", code: "togo", label: "Togo" },
  { value: "+676", code: "tonga", label: "Tonga" },
  { value: "+216", code: "tunisia", label: "Tunisia" },
  { value: "+90", code: "turkey", label: "Turkey" },
  { value: "+993", code: "turkmenistan", label: "Turkmenistan" },
  { value: "+688", code: "tuvalu", label: "Tuvalu" },
  { value: "+256", code: "uganda", label: "Uganda" },
  { value: "+380", code: "ukraine", label: "Ukraine" },
  { value: "+971", code: "uae", label: "United Arab Emirates" },
  { value: "+1", code: "us", label: "United States" },
  { value: "+598", code: "uruguay", label: "Uruguay" },
  { value: "+998", code: "uzbekistan", label: "Uzbekistan" },
  { value: "+678", code: "vanuatu", label: "Vanuatu" },
  { value: "+58", code: "venezuela", label: "Venezuela" },
  { value: "+84", code: "vietnam", label: "Vietnam" },
  { value: "+967", code: "yemen", label: "Yemen" },
  { value: "+260", code: "zambia", label: "Zambia" },
  { value: "+263", code: "zimbabwe", label: "Zimbabwe" },
];
