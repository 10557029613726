import React from "react";
import Calendar, { Detail } from "react-calendar";
import moment from "moment";
import cx from "classnames";

import { Input } from "../../Molecules/Input/Input";
import { Radio } from "../../Atoms/Radio/Radio";
import { InputCleaner } from "../../Atoms/InputsCommon/InputCleaner";
import { DatePickerChangeEvent } from "./DatePickerChangeEvent";
import { addClass, removeClass } from "../../../generics/dom-extensions";

// import CalendarIcon from "-!svg-react-loader?name=CalendarIcon!src/images/svg/icons/calendar.svg";
import { ReactComponent as CalendarIcon } from "../../../images/svg/icons/calendar.svg";

import "./DatePicker.scss";

const DEFAULT_LOCALE = "en-UK";
const DEFAULT_MIN_DATE = new Date("1900-01-01");
const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export interface IDatePickerProps {
  /** Name when being used like an input, analogous to other inputs */
  name?: string;
  /** Optional label */
  label?: string;
  /** Optional css class for the topmost div */
  className?: string;
  /** Locale for the datepicker. Defaults to en-UK. This NEEDS TO BE PROVIDED for localized applications
   * in order for the datepicker to display labels in the correct language. For example, fr-FR will show them in French.
   */
  locale?: string;
  /** Optional moment.js valid formatting rule for the date string inside the input. Defaults to DD/MM/YYYY */
  format?: string;
  /** Whether the calendar should be disabled */
  disabled?: boolean;
  /** Minimum available date */
  minDate?: Date;
  /** Maximum available date */
  maxDate?: Date;
  /** Optional initial value */
  value: Date | undefined;
  /** Callback to when a date is picked - returns a synthetic event and selected details level */
  onChange?: (event: DatePickerChangeEvent, detailsLevel?: string) => void;
  /** Callback to when a date is picked - returns the picked value and selected details level */
  valueChanged?: (value: Date | undefined, detailsLevel?: string) => void;
  /** Whether the outside form has been submitted, if used within one, in order to make the Input show the validation message */
  isFormSubmitted?: boolean;
  /** Whether the value of the selected date is valid or not - false if valid */
  invalid?: boolean;
  /** Validation error message to show when invalid is true */
  validationMessage?: string;
  /** Internal validation message for when a user types in a date which is outside of the allowed date range */
  invalidDateMessage: string;
  /**
   * Help icon config
   */
  annex?: JSX.Element;
  /**
   * Granulation of date picker
   */
  maxDetail: Detail;
}

export interface IDatePickerState {
  dateString: string | undefined;
  dateMask: string | undefined;
  view: any;
  isCalendarOpen: boolean;
  invalidDate: boolean;
  maxDetail: Detail;
  reloadCalendar: boolean;
}

export class DatePicker extends React.Component<
  IDatePickerProps,
  IDatePickerState
> {
  private format: string;
  private datePickerRef: React.RefObject<HTMLDivElement>;
  private datePickerContainerRef: React.RefObject<HTMLDivElement>;
  private datePickerGridRef: React.RefObject<HTMLDivElement>;
  private inputRef: React.RefObject<Input>;
  private validDateRange: {
    min: Date;
    max: Date | undefined;
  };

  public static defaultProps = {
    maxDetail: "month",
  };

  constructor(props: IDatePickerProps) {
    super(props);

    this.format = props.format || DEFAULT_DATE_FORMAT;
    this.datePickerRef = React.createRef<HTMLDivElement>();
    this.datePickerContainerRef = React.createRef<HTMLDivElement>();
    this.datePickerGridRef = React.createRef<HTMLDivElement>();
    this.inputRef = React.createRef<Input>();
    this.validDateRange = {
      min: props.minDate || DEFAULT_MIN_DATE,
      max: props.maxDate || undefined,
    };

    this.state = {
      view: "month",
      maxDetail: props.maxDetail,
      isCalendarOpen: false,
      dateString: props.value ? moment(props.value).format(this.format) : "",
      invalidDate: false,
      dateMask: props.value
        ? this.formatMask(
            moment(props.value).format(this.format),
            props.maxDetail
          )
        : "",
      reloadCalendar: false,
    };
  }

  public componentWillReceiveProps(newProps) {
    this.format = newProps.format || DEFAULT_DATE_FORMAT;
    this.validDateRange = {
      min: newProps.minDate || DEFAULT_MIN_DATE,
      max: newProps.maxDate || undefined,
    };

    const date = newProps.value
      ? moment(newProps.value).format(this.format)
      : "";
    const dateMask =
      date !== this.state.dateString
        ? this.formatMask(date, newProps.maxDetail)
        : this.formatMask(date, this.state.maxDetail);

    this.setState({
      dateString: date,
      dateMask,
      maxDetail: newProps.maxDetail,
    });
  }

  public render() {
    if (this.state.reloadCalendar) {
      this.setState({ reloadCalendar: false });
    }

    return (
      <div
        className={cx("c-datepicker", this.props.className, {
          "c-datepicker__disabled": this.props.disabled,
        })}
        ref={this.datePickerRef}
        onKeyDown={this.escape}
      >
        <div className="c-datepicker__input">
          <Input
            ref={this.inputRef}
            className="c-datepicker__input-content"
            type="text"
            label={this.props.label}
            value={this.state.dateMask}
            onClick={this.toggleByMouse}
            onKeyDown={this.onInputKeydown}
            onBlur={this.inputChanged}
            annex={this.props.annex}
            disabled={this.props.disabled}
            invalid={this.state.invalidDate || this.props.invalid}
            validationMessage={
              this.state.invalidDate
                ? this.props.invalidDateMessage
                : this.props.validationMessage
            }
            isFormSubmitted={this.props.isFormSubmitted}
          >
            {this.state.dateString ? (
              <InputCleaner onClick={this.cleanField} />
            ) : null}
          </Input>
          <a
            className="c-datepicker__input__toggle"
            href="#"
            tabIndex={-1}
            onClick={this.toggleByMouse}
          >
            <CalendarIcon />
          </a>
        </div>
        {this.state.isCalendarOpen && (
          <div className="c-datepicker__calendar-positioner">
            <div
              ref={this.datePickerContainerRef}
              className="c-datepicker__calendar-container"
            >
              <div className="c-datepicker__arrow" />
              <div
                ref={this.datePickerGridRef}
                className="c-datepicker__calendar"
              >
                <div className="c-datepicker__calendar-modes">
                  <div className="c-datepicker__calendar-mode">
                    <Radio
                      checked={this.state.maxDetail === "month"}
                      label="Specific date"
                      onChange={this.changeFormat}
                      name={"c-datepicker__calendar-modes"}
                      value={"month"}
                    />
                  </div>
                  <div className="c-datepicker__calendar-mode">
                    <Radio
                      checked={this.state.maxDetail === "year"}
                      label="By month"
                      onChange={this.changeFormat}
                      name={"c-datepicker__calendar-modes"}
                      value={"year"}
                    />
                  </div>
                  <div className="c-datepicker__calendar-mode">
                    <Radio
                      checked={this.state.maxDetail === "decade"}
                      label="By year"
                      onChange={this.changeFormat}
                      name={"c-datepicker__calendar-modes"}
                      value={"decade"}
                    />
                  </div>
                </div>
                {!this.state.reloadCalendar && (
                  <Calendar
                    className={this.state.view}
                    minDate={this.validDateRange.min}
                    maxDate={this.validDateRange.max}
                    onChange={this.pickDate}
                    onDrillDown={this.updateView}
                    onDrillUp={this.updateView}
                    value={this.props.value}
                    maxDetail={this.state.maxDetail}
                    minDetail="decade"
                    prev2Label={null}
                    next2Label={null}
                    prevLabel={<div className="icon-chevron" />}
                    nextLabel={<div className="icon-chevron" />}
                    locale={this.props.locale || DEFAULT_LOCALE}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  private datePickerPosition = () => {
    const datePickerGrid = this.datePickerGridRef.current;
    const datePickerContainer = this.datePickerContainerRef.current;

    if (datePickerGrid && datePickerContainer) {
      const containerRect =
        datePickerContainer.getBoundingClientRect() as DOMRect;
      const datepickerRect = datePickerGrid.getBoundingClientRect() as DOMRect;
      const isOutOfRightSide =
        datepickerRect.width + containerRect.left >
        document.documentElement.clientWidth;
      const isInputWiderThanDatePicker =
        containerRect.width > datepickerRect.width;

      if (isOutOfRightSide || isInputWiderThanDatePicker) {
        addClass(datePickerGrid, "align-right");
      } else {
        removeClass(datePickerGrid, "align-right");
      }
    }
  };

  private changeFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      dateMask: this.formatMask(
        this.state.dateString,
        event.target.value as Detail
      ),
      reloadCalendar: true,
      maxDetail: event.target.value as Detail,
    });
  };

  private cleanField = (event: React.MouseEvent) => {
    event.stopPropagation();
    this.clearDate();
  };

  private clearDate = () => {
    this.setState({ dateMask: "", dateString: "", invalidDate: false });
    this.fireChangeEvent(undefined);
  };

  private formatMask = (date?: string, detailsLevel?: Detail) => {
    let format = "";
    const level = detailsLevel ? detailsLevel : this.state.maxDetail;

    switch (level) {
      case "month":
        format = this.format;
        break;
      case "year":
        format = "MM/YYYY";
        break;
      case "decade":
        format = "YYYY";
        break;
    }

    return date ? moment(date, this.format).format(format).toString() : "";
  };

  /**
   * Escape-only handler for the entire component, to close the calendar wherever you are
   */
  private escape = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      this.keydownHandlers["Escape"]();
    }
  };

  private onInputKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.keydownHandlers[event.key]) {
      this.keydownHandlers[event.key](event);
    }
  };

  private keydownHandlers = {
    Enter: (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      this.inputChanged(event);
      this.toggleCalendar();
    },
    Escape: () => {
      this.hideCalendar();
    },
  };

  private toggleByMouse = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    if (!this.props.disabled) {
      this.toggleCalendar();
    }
  };

  private toggleCalendar = () => {
    if (this.state.isCalendarOpen) {
      this.hideCalendar();
    } else {
      this.showCalendar();
    }
  };

  private showCalendar = () => {
    this.setState({ isCalendarOpen: true }, () => {
      document.addEventListener("click", this.handleOutsideClick);
      window.addEventListener("resize", this.datePickerPosition);
      this.datePickerPosition();
    });
  };

  private handleOutsideClick = (event: any) => {
    const datePicker = this.datePickerRef.current;
    if (datePicker && datePicker) {
      if (!datePicker.contains(event.target)) {
        this.hideCalendar();
      }
    }
  };

  private hideCalendar = () => {
    this.setState({ isCalendarOpen: false }, () => {
      document.removeEventListener("click", this.handleOutsideClick);
      window.removeEventListener("resize", this.datePickerPosition);
    });
  };

  private updateView = ({ activeStartDate, view }) => {
    /**
     * Change view mode and update event listener for external clicks because ref.current and children change
     */
    document.removeEventListener("click", this.handleOutsideClick);
    this.setState({ view }, () => {
      document.addEventListener("click", this.handleOutsideClick);
    });
  };

  private inputChanged = (event) => {
    if (event && event.target) {
      let date = event.target.value;
      switch (this.state.maxDetail) {
        case "year":
          date = "01/" + date;
          break;
        case "decade":
          date = "01/01/" + date;
          break;
      }

      const newState = {
        dateString: date,
        invalidDate: false,
        dateMask: this.state.dateMask,
      };

      const newDate = moment(date, this.format);
      const newDateString = newDate.format(this.format);
      const selectedDate = newDate.toDate();

      const isDateValid =
        !isNaN(Date.parse(newDate.toString())) &&
        selectedDate >= this.validDateRange.min &&
        (this.validDateRange.max
          ? selectedDate <= this.validDateRange.max
          : true);

      if (isDateValid) {
        newState.dateString = newDateString;
        newState.dateMask = this.formatMask(newDateString);
        newState.invalidDate = false;
        this.fireChangeEvent(selectedDate);
      } else if (newState.dateString) {
        newState.invalidDate = true;
      } else if (!newState.dateString) {
        this.clearDate();
      }

      this.setState(newState);
    }
  };

  private pickDate = (date: Date) => {
    const dateString = moment(date).format(this.format);

    this.fireChangeEvent(date);
    this.setState(
      {
        dateString,
        dateMask: this.formatMask(dateString),
        isCalendarOpen: false,
        invalidDate: false,
      },
      () => {
        window.removeEventListener("resize", this.datePickerPosition);
      }
    );
  };

  private fireChangeEvent = (date: Date | undefined) => {
    if (this.props.valueChanged) {
      this.props.valueChanged(date, this.state.maxDetail);
    }

    return (
      this.props.onChange &&
      this.props.onChange(
        {
          currentTarget: {
            value: date,
          },
        },
        this.state.maxDetail
      )
    );
  };
}
