import React from "react";
import Img from "react-image";

import { Stub } from "../Stub/Stub";
import { Shimmer } from "../Shimmer/Shimmer";

export interface IPhotoProps extends React.ImgHTMLAttributes<any> {
  /** if image has a spinner while loading */
  hasLoadingSpinner?: boolean;
  /** trigger when image has finished loading */
  onLoad?: (image: HTMLImageElement) => void;
}

export const Photo = (props: IPhotoProps) => {
  const { hasLoadingSpinner, ...rest } = props;
  return (
    <Img
      {...rest}
      loader={hasLoadingSpinner ? <Shimmer /> : undefined}
      unloader={<Stub />}
      onLoad={(image) => props.onLoad?.(image.currentTarget)}
    />
  );
};
