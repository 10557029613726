import { TFunction } from "i18n";
import { IModifiers } from "./getNavHeaderLinks";
import { getMediaRequestsSubNav } from "./sharedLinks";

export function getAgentsNav(t: TFunction, modifiers: IModifiers) {
  const { isAgencySettingsFlagEnabled, isMediaRequestEnabled } = modifiers;

  return {
    mainNavigationItems: [
      {
        text: t("common:nav.links.industryResources"),
        icon: "https://spotlight-cmsmedia-devaws.s3-eu-west-1.amazonaws.com/media/1134/help.svg",
        linkUrl:
          "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight/",
      },
      {
        text: t("common:nav.links.contactsListings"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2228/contacts.svg",
        linkUrl: "/contacts",
      },
      {
        text: t("common:nav.links.jobsFeed"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2230/search.svg",
        linkUrl: "/jobs/matching-my-filters",
        subNavigationItems: [
          {
            text: t("common:nav.links.jobMatches"),
            linkUrl: "/jobs/matching-my-filters",
          },
          {
            text: t("common:nav.links.projects"),
            linkUrl: "/jobs/all-opportunities",
          },
          {
            text: t("common:nav.links.clientAlerts"),
            linkUrl: "/jobs/client-alerts",
          },
          ...getMediaRequestsSubNav(t, isMediaRequestEnabled),
          {
            text: t("common:nav.links.messages"),
            linkUrl: "/agentweb/feed/messages",
          },
        ],
      },
      {
        text: t("common:nav.links.clients"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2229/clients.svg",
        linkUrl: "/clients",
        subNavigationItems: [
          {
            text: t("common:nav.links.clientList"),
            linkUrl: "/clients",
          },
          {
            text: t("common:nav.links.emailClientCvs"),
            linkUrl: "/agentweb/EmailCvs",
          },
          {
            text: t("common:nav.links.searchSuggestions"),
            linkUrl: "/searchsuggestions",
          },
          {
            text: t("common:nav.links.clientPins"),
            linkUrl: "/agentweb/Clients/PinList/filtered",
          },
          {
            text: t("common:nav.links.youngPerformerApplications"),
            linkUrl: "/join/children/manage.asp",
          },
        ],
      },
      {
        text: t("common:nav.links.seekingRepresentation"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/3369/experience.svg",
        linkUrl: "/clients/seeking-representation",
      },
      {
        text: t("common:nav.links.accountSettings"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2229/settings.svg",
        linkUrl: "/settings",
        subNavigationItems: generateSubNavigationItems(
          t,
          isAgencySettingsFlagEnabled
        ),
      },
      {
        text: t("common:nav.links.helpTutorials"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/help.svg",
        linkUrl:
          "https://www.spotlight.com/the-small-print/help-for-agents-using-the-spotlight-link/",
      },
      {
        text: t("common:nav.links.performanceCalendar"),
        icon: "https://spotlight-cmsmedia.s3-eu-west-1.amazonaws.com/media/2227/performancecal.svg",
        linkUrl: "https://calendar.spotlight.com/",
      },
    ],
  };
}

function generateSubNavigationItems(
  t: TFunction,
  isAgencySettingsFlagEnabled?: boolean
) {
  const agencyDetailsLink = isAgencySettingsFlagEnabled
    ? {
        text: t("common:nav.links.agencyDetails"),
        linkUrl: "/clients/agency",
      }
    : {
        text: t("common:nav.links.agencyDetails"),
        linkUrl: "/agentweb/Account",
      };

  const items = [
    {
      text: t("common:nav.links.agentUserDetails"),
      linkUrl: "https://portal.spotlight.com/portal/account/details",
    },
    agencyDetailsLink,
    {
      text: t("common:nav.links.clientAccess"),
      linkUrl: "/clientaccess",
    },
    {
      text: t("common:nav.links.agentTermsConditions"),
      linkUrl: "https://www.spotlight.com/the-small-print/terms-conditions/",
    },
  ];

  return items;
}
