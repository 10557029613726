import { ANNUAL_PLAN, SubscriptionCalculatedStatus } from "app/constants";

export interface ICheckRenewalPeriod {
  planCode?: string;
  calculatedStatus?: string;
}

export default function checkIsRenewalPeriod({
  planCode,
  calculatedStatus,
}: ICheckRenewalPeriod) {
  return (
    planCode?.includes(ANNUAL_PLAN) &&
    calculatedStatus === SubscriptionCalculatedStatus.Renewing
  );
}
