import React, { FC } from "react";
import { Tooltip, ITooltipProps, ITooltipContent } from "../Tooltip/Tooltip";
import cx from "classnames";
import "./styles.scss";

export enum QuestionTooltipVariant {
  primary = "primary",
  secondary = "secondary",
}

export interface FormTooltipProps {
  tooltipProps: ITooltipProps;
  variant?: QuestionTooltipVariant;
}

export const getTootlipProps = (
  tooltipHeader: ITooltipContent["header"],
  tooltipBody: ITooltipContent["content"],
  isRichText = false
): ITooltipProps => {
  return {
    texts: {
      header: tooltipHeader,
      content: tooltipBody,
      closeButton: "Close tooltip",
      tooltipName: "Open tooltip",
    },
    config: {
      minimalTopDistance: 50,
      isRichText,
    },
  };
};

const QuestionTooltip: FC<FormTooltipProps> = ({
  tooltipProps,
  variant = QuestionTooltipVariant.primary,
}) => {
  const questionMarkClass = cx("question-tooltip__question-mark", {
    primary: variant === QuestionTooltipVariant.primary,
    secondary: variant === QuestionTooltipVariant.secondary,
  });
  return (
    <div className="question-tooltip">
      <div className="question-tooltip__tooltip-wrapper">
        <Tooltip {...tooltipProps}>
          <div className={questionMarkClass}>?</div>
        </Tooltip>
      </div>
    </div>
  );
};

export default QuestionTooltip;
