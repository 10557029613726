interface Heap {
  identity: string;
  identify: (args: any) => void;
  addUserProperties: (args: any) => void;
  resetIdentity: () => void;
}

declare global {
  interface Window {
    heap: Heap;
  }
}

export class HeapService {
  private authority: string;

  constructor(authority: string | undefined) {
    this.authority = authority ?? "";
  }

  setUserDetails(): void {
    if (!this.authority) {
      return;
    }
    const userItemJson = localStorage.getItem(
      `oidc.user:${this.authority}:spotlight`
    );

    const userItem = userItemJson ? JSON.parse(userItemJson) : undefined;
    const userId = userItem?.profile?.sub;
    const membershipGroup = userItem?.profile?.["spotlight.MemberGroups"];

    if (userItem && userId) {
      try {
        const isDevEnv = process.env.NODE_ENV === "development";
        isDevEnv &&
          console.log("Setting up heap variables: ", {
            userId,
            membershipGroup,
          });

        if (window.heap?.identity !== userId) {
          window.heap.identify(userId);
          window.heap.addUserProperties({ membershipGroup });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  clearUserDetails(): void {
    try {
      window.heap.resetIdentity();
    } catch (e) {
      console.error(e);
    }
  }
}
