import React, { FC, useEffect, useState } from "react";
import { format } from "date-fns";
import { BillingUser } from "translations/src/models/billing";
import { ReactComponent as DirectDebitIcon } from "component-library/src/images/svg/icons/direct-debit.svg";
import { ReactComponent as Sepa } from "component-library/src/images/svg/icons/sepa.svg";
import { ReactComponent as GoCardless } from "component-library/src/images/svg/icons/go-cardless.svg";
import { ReactComponent as SpotlightLogo } from "@assets/svg/Spotlight-Logo-tagline-black.svg";
import { dataTestIds } from "data-testids";
import ScrollToTopContainer from "../ScrollToTopContainer";
import { Button } from "../../../../Atoms/Button/Button";
import DirectDebitGuaranteeButton from "../DirectDebitGuaranteeButton";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import maskSensitive from "../../utils/maskSensitive";
import { DIRECT_DEBIT_PING_TIMEOUT, PlanType } from "../../constants";
import "./styles.scss";

interface Props {
  name: string;
  email: string;
  accountName?: string;
  sortCode?: string;
  accountNumber?: string;
  planType?: PlanType;
  directDebitPreviewOnEdit: () => void;
  submitForm: () => void;
  setPreventLeave: (state: boolean) => void;
}

const DirectDebitConfirmPage: FC<Props> = ({
  name,
  email,
  accountName = "",
  sortCode = "",
  accountNumber = "",
  planType = "",
  directDebitPreviewOnEdit,
  submitForm,
  setPreventLeave,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    texts: { common, directDebitForm, directDebit },
  } = useBillingTranslations(BillingUser.Common);

  const completeDirectDebit = () => {
    setLoading(true);
    submitForm();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPreventLeave(false);
    }, DIRECT_DEBIT_PING_TIMEOUT - 1000);
    const leaveTimeout = setTimeout(() => {
      window.alert(directDebit.directDebitPageAlert);
      window.location.reload();
    }, DIRECT_DEBIT_PING_TIMEOUT);

    return () => {
      clearTimeout(timeout);
      clearTimeout(leaveTimeout);
    };
  }, [setPreventLeave]);

  const isSepa = planType === PlanType.Sepa;

  return (
    <ScrollToTopContainer>
      {({ elRef }) => {
        return (
          <div className="direct-debit-confirm">
            <h1 className="header" ref={elRef}>
              {directDebit.confirmHeader}
            </h1>
            <div className="flex flex-col items-center">
              <div className="max-w-[538px] w-[100%] mb-[30px]">
                <div className="bg-color-neutral-three p-[30px] rounded-t-[15px]">
                  <div className="mb-5 text-grey-color-neutral-two text-m">
                    {directDebit.confirmDescription}
                  </div>
                  {isSepa ? (
                    <Sepa />
                  ) : (
                    <DirectDebitIcon width={78} height={29} />
                  )}
                </div>
                <div className="data-block">
                  {isSepa ? (
                    <>
                      <div className="data-row">
                        <div className="name">
                          {directDebit.sepaCreditorsNameLabel}
                        </div>
                        <div className="value">
                          {directDebit.sepaCreditorsName}
                        </div>
                      </div>
                      <div className="data-row">
                        <div className="name">
                          {directDebit.sepaCreditorLabel}
                        </div>
                        <div className="value">{directDebit.sepaCreditor}</div>
                      </div>
                      <div className="data-row">
                        <div className="name">
                          {directDebit.sepaAddressLabel}
                        </div>
                        <div className="value">{directDebit.sepaAddress}</div>
                      </div>
                      <div className="data-row">
                        <div className="name">{directDebit.sepaTypeLabel}</div>
                        <div className="value">{directDebit.sepaType}</div>
                      </div>
                      <div className="data-row">
                        <div className="name">
                          {directDebit.sepaReferenceLabel}
                        </div>
                        <div className="value">{directDebit.sepaReference}</div>
                      </div>
                      <div className="data-row">
                        <div className="name">{directDebit.sepaDateLabel}</div>
                        <div className="value">
                          {format(new Date(), "dd/MM/yyyy")}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="data-row">
                        <div className="name">{directDebit.confirmName}</div>
                        <div className="value">{name}</div>
                      </div>
                      <div className="data-row">
                        <div className="name">{directDebit.confirmEmail}</div>
                        <div className="value">{email}</div>
                      </div>
                    </>
                  )}

                  <div className="h-[1px] bg-grey-fog-1 mb-[30px]" />
                  <div className="flex justify-between">
                    <div className="data-row">
                      <div className="name">
                        {directDebit.confirmAccountHolder}
                      </div>
                      <div className="value">{accountName}</div>
                    </div>
                    <Button
                      text={common.change}
                      onClick={directDebitPreviewOnEdit}
                      iconName="icon-edit"
                      className="edit-button"
                    />
                  </div>
                  <div className="flex">
                    {!isSepa && (
                      <div className="flex-1 data-row">
                        <div className="name">
                          {directDebitForm.sortCode.replace(":", "")}
                        </div>
                        <div className="value">{sortCode}</div>
                      </div>
                    )}
                    <div className="flex-1 data-row">
                      <div className="name">
                        {directDebitForm.accountNumber.replace(":", "")}
                      </div>
                      <div className="value">
                        {common.ending}{" "}
                        {maskSensitive(
                          accountNumber,
                          accountNumber.length - 3,
                          accountNumber.length - 8
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                text={directDebit.confirmDirectDebitButton}
                type="primary"
                iconName="icon-lock1"
                className="submit-button"
                onClick={completeDirectDebit}
                loading={loading}
                data-testid={
                  dataTestIds.componentLibrary[
                    "Organisms.Billing.DirectDebit.confirmButton"
                  ]
                }
              />
              <div className="security-block">
                <SpotlightLogo className="mx-[10px]" width={252} />
                <a href={`mailto:${directDebit.confirmContactEmail}`}>
                  {directDebit.confirmContactEmail}
                </a>
                <p className="flex items-center paragraph">
                  {directDebit.confirmPoweredBy} <GoCardless />
                </p>
                <p
                  className="legal"
                  dangerouslySetInnerHTML={{
                    __html: directDebit.confirmLegal1,
                  }}
                />
                {!isSepa && (
                  <p className="legal">
                    {directDebit.confirmLegal2WithDDGuarantee}{" "}
                    <DirectDebitGuaranteeButton />.
                  </p>
                )}
                <p>{directDebit.confirmLegal3}</p>
                {!isSepa && <p>{directDebit.confirmLegal4}</p>}
                {isSepa && (
                  <>
                    <p>{directDebit.sepaLegalParagraph1}</p>
                    <p>{directDebit.sepaLegalParagraph2}</p>
                    <p>{directDebit.sepaLegalParagraph3}</p>
                    <p>{directDebit.sepaLegalParagraph4}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </ScrollToTopContainer>
  );
};

export default DirectDebitConfirmPage;
