import { authAxios as axios } from "shared-auth";
import config from "config/global";
import { IPerformersList } from "models/search";

class SearchAndFiltersService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  public async getSearchResults(
    text: string
  ): Promise<undefined | IPerformersList> {
    const { data } = await axios.post<IPerformersList>(`${this.url}/search`, {
      take: 10,
      performerName: text,
    });

    return data;
  }
}

const apiUrl = (config.searchAndFiltersApiUrl || "").replace(/\/$/, "");

if (apiUrl.trim().length == 0) {
  throw new Error("Invalid searchAndFiltersApiUrl.");
}

export default new SearchAndFiltersService({
  url: apiUrl,
});
