import React, { FC } from "react";
import cx from "classnames";
import { CurrencyType, currencyMap } from "../../../types";
import { ActionButton } from "../../../../../Atoms/ActionButton/ActionButton";

interface IPriceInfoBlockProps {
  value: number;
  title: string;
  variant: "primary" | "secondary";
  name?: string;
  currencySymbol?: string;
  paymentPeriod?: string;
  vatPrice?: string;
  pricePrefix?: string;
  icon?: string;
  onIconClick?: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => void;
}

const infoBoxesClassNames = "flex items-center justify-between rounded-md";

const PriceInfoBlock: FC<IPriceInfoBlockProps> = ({
  name,
  currencySymbol = currencyMap[CurrencyType.GBP],
  value,
  title,
  paymentPeriod,
  variant,
  vatPrice,
  pricePrefix,
  icon,
  onIconClick,
}) => {
  return (
    <div
      className={cx(infoBoxesClassNames, {
        "bg-color-brand-two text-white p-4 pr-10": variant === "primary",
        "bg-color-neutral-four py-2 px-4": variant === "secondary",
      })}
    >
      <div className="flex flex-col items-start gap-1">
        <p className="self-start text-[22px] font-secondary">{title}</p>
        {name && <p className="text-lg">{name}</p>}
      </div>
      <div className="flex items-center gap-3">
        <div className="flex flex-col items-center gap-1">
          <p
            className={cx({
              "text-[26px]": variant === "primary",
              "text-[22px] leading-none": variant === "secondary",
            })}
          >
            {pricePrefix} {currencySymbol}
            {value.toFixed(2)} {paymentPeriod}
          </p>
          {vatPrice && (
            <div
              className={cx("text-m whitespace-nowrap", {
                "text-white": variant === "primary",
                "text-color-neutral-one": variant === "secondary",
              })}
            >
              {vatPrice}
            </div>
          )}
        </div>
        {icon && onIconClick && (
          <ActionButton icon={icon} onClick={onIconClick} size="small" />
        )}
      </div>
    </div>
  );
};

export default PriceInfoBlock;
