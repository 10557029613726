import React, { FC, useState } from "react";
import { useTranslation, Trans } from "i18n";
import { DropdownTag } from "../../Atoms/DropdownTag/DropdownTag";
import { IOption } from "../../../generics/interfaces";
import "./styles.scss";

export enum FilterMode {
  Any,
  All,
}

interface Props {
  translationKey: string;
  filterModeDefaultValue: FilterMode;
  updateFilterMode(mode: FilterMode): void;
}

const FilterModeSection: FC<Props> = ({
  translationKey,
  updateFilterMode,
  filterModeDefaultValue,
}) => {
  const { t } = useTranslation();

  const filterModes = [
    {
      name: t("common:label.any"),
      value: FilterMode.Any,
    },
    {
      name: t("common:label.all"),
      value: FilterMode.All,
    },
  ];

  const [selectedMode, setSelectedMode] = useState(
    filterModes.find((mode) => mode.value === filterModeDefaultValue)
  );

  const onSelect = (selectedItem: IOption<FilterMode>) => {
    setSelectedMode(selectedItem);
    updateFilterMode(selectedItem.value);
  };

  return (
    <div className="c-filter-mode">
      <div className="c-filter-mode__text">
        <Trans
          i18nKey={translationKey}
          components={{
            selectorWrapper: <div className="c-filter-mode__selector" />,
            selector: (
              <DropdownTag
                items={filterModes}
                onSelect={onSelect}
                selectedItem={selectedMode}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default FilterModeSection;
