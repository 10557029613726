import cx from "classnames";
import React from "react";
import { Spinner } from "component-library";

import "./GlobalSpinner.scss";

interface IGlobalSpinnerProps {
  isInner?: boolean;
}

export class GlobalSpinner extends React.Component<IGlobalSpinnerProps> {
  public render() {
    return (
      <div
        className={cx({
          "c-global-spinner": true,
          "c-global-spinner--inner": this.props.isInner,
        })}
      >
        <Spinner />
      </div>
    );
  }
}

export default GlobalSpinner;
