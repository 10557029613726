// @ts-nocheck
import React from "react";
import FocusTrap from "focus-trap-react";
import cx from "classnames";
import isEqual from "lodash/isEqual";
import { dataTestIds } from "data-testids";

import { removeClass, addClass } from "../../../../generics/dom-extensions";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "../../../../generics/body-scroll-lock";
import { getTranslation } from "../../../../translations/translationManager";

import { Button } from "../../../Atoms/Button/Button";
import { FilterDrawerItem } from "../FilterDrawerItem/FilterDrawerItem";
import "./FilterDrawer.scss";

// tslint:disable-next-line:no-var-requires
const styles = require("./FilterDrawer.scss");

export interface IFilterDrawerItem {
  title: string;
  selected?: boolean;
  id: string;
  renderFilterContent?: () => JSX.Element | null;
  filters?: IFilterDrawerItem[];
}

export interface IFilterDrawerProps {
  /**
   * Filters list to be rendered in Drawer.
   */
  filters: IFilterDrawerItem[];
  /**
   * Callback function on close FilterDrawer event - handle hiding the FilterDrawer in this function in your page.
   */
  onClose: Callback;
  /**
   * Callback function on clear all FilterDrawer button click.
   */
  onClearAllFilters?: Callback;
  /**
   * Callback function on apply FilterDrawer button click.
   */
  onApplyFilters?: Callback;
  /** Optional css class for the topmost div. */
  className?: string;
  /** Optional locale string like en-GB to use translations. */
  locale?: string;
  /** Optional element which will be rendered after list of all filters */
  filtersTail?: React.ReactNode;
}

type Callback = () => void;

interface IFilterDrawerState {
  isOpen: boolean;
  isFiltersListsShown: boolean;
  filterContent: JSX.Element | null;
  filterTitle: string;
  path: string[];
}

export class FilterDrawer extends React.Component<
  IFilterDrawerProps,
  IFilterDrawerState
> {
  private readonly filterDrawerContainerRef = React.createRef<HTMLDivElement>();
  private readonly filterDrawerContentRef = React.createRef<HTMLDivElement>();

  public readonly state: Readonly<IFilterDrawerState> = {
    isOpen: false,
    isFiltersListsShown: true,
    filterContent: null,
    filterTitle: "",
    path: [],
  };

  public componentDidMount() {
    document.addEventListener("keyup", this.handleEscape, false);
    (document.activeElement as any).blur();
    disableBodyScroll(this.filterDrawerContentRef.current);

    this.setState({ isOpen: true }, () =>
      addClass(document.body, "g-no-scroll")
    );
  }

  public componentDidUpdate(prevProps: Readonly<IFilterDrawerProps>) {
    if (
      !this.state.isFiltersListsShown &&
      this.state.path.length > 0 &&
      !isEqual(prevProps.filters, this.props.filters)
    ) {
      const path = this.state.path.slice();
      const filter = this.getContentByPath(path);
      const filterContent = filter?.renderFilterContent?.();

      if (filterContent) {
        this.setState({ filterContent });
      }
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscape, false);
    enableBodyScroll(this.filterDrawerContentRef.current);
    removeClass(document.body, "g-no-scroll");
  }

  public render() {
    const filterDrawerClassNames = cx(`c-filter-drawer`, this.props.className);
    const filterDrawerContainerClassNames = cx(`c-filter-drawer__container`, {
      "c-filter-drawer__container--active": this.state.isOpen,
    });

    return (
      <FocusTrap>
        <div className={filterDrawerClassNames}>
          <div
            role="dialog"
            ref={this.filterDrawerContainerRef}
            className={filterDrawerContainerClassNames}
            tabIndex={0}
            data-testid={
              dataTestIds.componentLibrary[
                "Organisms.FilterControl.filterDrawer"
              ]
            }
          >
            <div className="c-filter-drawer__wrapper">
              <div className="c-filter-drawer__header">
                {!this.state.isFiltersListsShown && (
                  <div
                    className="c-filter-drawer__back icon-arrowleft"
                    onClick={this.handleBackToFilters}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) =>
                      e.key === "Enter" && this.handleBackToFilters()
                    }
                  />
                )}
                <h4
                  className={cx(`c-filter-drawer__title`, {
                    "c-filter-drawer__title--list":
                      this.state.isFiltersListsShown,
                  })}
                >
                  {this.state.isFiltersListsShown
                    ? this.translations.filters
                    : this.state.filterTitle}
                </h4>
                <Button
                  type="primary"
                  text=""
                  iconName="icon-cross1"
                  onClick={this.handleClose}
                  className="c-filter-drawer__close"
                  ariaLabel="Close filters"
                />
              </div>
              <hr className="g-split-line" />
              <div
                ref={this.filterDrawerContentRef}
                className="c-filter-drawer__content"
                aria-live="assertive"
              >
                {this.state.isFiltersListsShown ? (
                  <React.Fragment>
                    {this.renderFilters(this.props.filters)}
                    {this.props.filtersTail}
                  </React.Fragment>
                ) : (
                  this.state.filterContent
                )}
              </div>
              <hr className="g-split-line" />
              <div className="c-filter-drawer__buttons-set">
                <Button
                  type="secondary"
                  text={this.translations.clearAll}
                  onClick={this.props.onClearAllFilters}
                  className="c-filter-drawer__clear-all"
                  data-testid="clear-all"
                />
                <Button
                  type="primary"
                  text={this.translations.ctaApply}
                  onClick={this.onApplyFilters}
                  className="c-filter-drawer__apply"
                />
              </div>
            </div>
          </div>
        </div>
      </FocusTrap>
    );
  }

  private renderFilters = (filters) => {
    return filters.map((filter) => (
      <FilterDrawerItem
        key={filter.id}
        onClick={this.renderFilterContent(filter)}
        title={filter.title}
        id={filter.id}
        selected={filter.selected}
      />
    ));
  };

  private get translations() {
    return getTranslation(this.props.locale).filterControl;
  }

  public handleEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape" || event.key === "Esc") {
      this.close(this.props.onClose);
    }
  };

  public close = (callback: Callback | undefined) => {
    this.setState({ isOpen: false }, () =>
      setTimeout(() => {
        callback && callback();
      }, styles.transitionTime * 1000)
    );
  };

  public onApplyFilters = () => {
    this.close(this.props.onApplyFilters);
  };

  public handleClose = () => {
    this.close(this.props.onClose);
  };

  public handleBackToFilters = () => {
    const path = this.state.path.slice();
    path.pop();

    if (path.length > 0) {
      const filterItem = this.getContentByPath(path);
      this.setState({
        isFiltersListsShown: false,
        filterContent: this.renderFilters(filterItem ? filterItem.filters : []),
        filterTitle: filterItem!.title,
        path,
      });
    } else {
      this.setState({
        isFiltersListsShown: true,
        filterContent: null,
        filterTitle: "",
        path,
      });
    }
  };

  public getContentByPath = (path: string[]): IFilterDrawerItem | undefined => {
    let content: IFilterDrawerItem[] = this.props.filters;
    let filterItem: IFilterDrawerItem | undefined;

    path.forEach((id) => {
      filterItem = content.find((item) => item.id === id);
      content = filterItem?.filters ? filterItem.filters : [];
    });

    return filterItem;
  };

  public renderFilterContent = (filter: IFilterDrawerItem) => () => {
    const path = this.state.path.slice();
    path.push(filter.id);

    let filterContent;
    if (filter.filters) {
      filterContent = this.renderFilters(filter.filters);
    } else {
      filterContent = filter.renderFilterContent
        ? filter.renderFilterContent()
        : null;
    }

    this.setState({
      isFiltersListsShown: false,
      filterContent,
      filterTitle: filter.title,
      path,
    });
  };
}
