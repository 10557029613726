export class GridStyles {
  public header: string = "";
  public headerRow: string = "";
  public headerCell: string = "";
  public headerLabel: string = "";
  public headerTitle: string = "";
  public headerSortIcon: string = "";
  public body: string = "";
  public bodyRow: string = "";
  public bodyCell: string = "";
}

export const secondaryVariantStyles: GridStyles = {
  ...new GridStyles(),
  headerRow: "h-10",
  headerCell: "bg-transparent h-10 border-none flex items-center",
  headerLabel: "text-grey-shark text-m",
  bodyRow: "bg-white odd:bg-color-neutral-three border-none relative",
  bodyCell: "md:min-h-[70px] md:flex md:items-center",
};
