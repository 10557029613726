import { i18n } from "i18n";
import { IBreadcrumbBarItem } from "./BreadcrumbBar";

export const generateBreadcrumbsData = (
  crumbsList: IBreadcrumbBarItem[],
  targetCrumbs: number[]
) => {
  return targetCrumbs.map((index) => {
    const { text, url } = crumbsList[index];
    return {
      text: i18n.t(text),
      url,
    };
  });
};
