// @ts-nocheck
import React from "react";
import { PageView } from "./PageView";
import { BreakView } from "./BreakView";
import "./PaginationControl.scss";

const DEFAULT_PAGES_WITHOUT_BRAKES_COUNT = 10;

const DEFAULT_INITIAL_PAGE = 1;

const DEFAULT_MARGIN_PAGES_DISPLAYED = 1;

export interface IPaginationControlProps {
  /**
   * The total number of pages.
   */
  pageCount: number;

  /** The range of pages displayed.
   * This property means how much pages will be displayed from selected page till (...).
   * This property means whole amount of pages which will be displayed around selected one.
   * If it's possible - half of page range will be displayed on every side.
   * If not - it will flexible fit to selected page position.
   */
  pageRangeDisplayed: number;

  /**
   * The method to call when a page is clicked. Exposes the current page object as an argument.
   */
  handlePageChange: (selectedPage: number) => void;

  /** Optional. The initially selected page.
   *  By default - 1
   */
  initialSelectedPage: number;

  /** Optional. The number of pages which display on the left and right edges.
   *  This property means how much pages will be displayed from at the beginning and at the end of the page list.
   *  By default - 1. Default example: "1...9 10 11 12 13...14" - by the one page on both edges.
   */
  marginPagesDisplayed: number;

  /** Optional. This property means that option DEFAULT_PAGES_WITHOUT_BRAKES_COUNT will be ignored for list length <= 10.
   *  Page list will contain breaks: "1 2 3 4 ... 10"
   *  Otherwise all page list will be displayed if amount of pages is less or equal to 10: "1 2 3 4 5 6 7 8 9 10"
   */
  arePageBreaksMandatory?: boolean;

  /** Optional. you can pass selected page. It is needed in case you have 2 controls on the page
   */
  selectedPage?: number;
}

export interface IPaginationControlState {
  /**
   * Currently selected page.
   */
  selectedPage: number;
}

export class PaginationControl extends React.Component<
  IPaginationControlProps,
  IPaginationControlState
> {
  public static defaultProps = {
    initialSelectedPage: DEFAULT_INITIAL_PAGE,
    marginPagesDisplayed: DEFAULT_MARGIN_PAGES_DISPLAYED,
    arePageBreaksMandatory: false,
  };

  constructor(props: IPaginationControlProps) {
    super(props);
    this.state = {
      selectedPage: this.props.initialSelectedPage,
    };
  }

  public componentDidUpdate(prevProps) {
    if (
      !!this.props.selectedPage &&
      this.props.selectedPage !== prevProps.selectedPage
    ) {
      this.setState({ selectedPage: this.props.selectedPage });
    }
  }

  public render() {
    return (
      this.props.pageCount > 1 && (
        <div className="c-pagination-control">
          {this.renderArrow("left")}
          {this.renderPagination()}
          {this.renderArrow("right")}
        </div>
      )
    );
  }

  private onChangePage = (selectedPage) => {
    if (
      this.props.handlePageChange &&
      typeof this.props.handlePageChange === "function"
    ) {
      this.props.handlePageChange(selectedPage);
    }
  };

  private handlePreviousPage = (event) => {
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
    if (this.state.selectedPage > 1) {
      this.handlePageSelected(event, this.state.selectedPage - 1);
    }
  };

  private handleNextPage = (event) => {
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
    if (this.state.selectedPage < this.props.pageCount) {
      this.handlePageSelected(event, this.state.selectedPage + 1);
    }
  };

  private handlePageSelected = (event, selectedPage) => {
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
    if (this.state.selectedPage === selectedPage) {
      return;
    }
    this.setState({ selectedPage }, () => this.onChangePage(selectedPage));
  };

  private renderArrow = (arrowType: "left" | "right") => {
    const arrowProps =
      arrowType === "left"
        ? {
            onClickHandler: this.handlePreviousPage,
            iconClassName: "chevronleft",
            isEdge: this.state.selectedPage === 1,
          }
        : {
            onClickHandler: this.handleNextPage,
            iconClassName: "chevronright",
            isEdge: this.state.selectedPage === this.props.pageCount,
          };

    return (
      (arrowProps.isEdge && (
        <div className="c-pagination-control__arrow-empty-space" />
      )) || (
        <div
          onKeyDown={this.getNavigateOnEnterKeyPressed(
            arrowProps.onClickHandler
          )}
          tabIndex={0}
          className="c-pagination-control__arrow"
          onClick={arrowProps.onClickHandler}
          role="button"
        >
          <div
            className={`c-pagination-control__arrow-icon icon-${arrowProps.iconClassName}`}
          />
        </div>
      )
    );
  };

  private renderPagination = () => {
    const {
      pageCount,
      pageRangeDisplayed,
      marginPagesDisplayed,
      arePageBreaksMandatory,
    } = this.props;
    const items: JSX.Element[] = [];
    const startIndex = 1;
    if (
      pageCount <= DEFAULT_PAGES_WITHOUT_BRAKES_COUNT &&
      !arePageBreaksMandatory
    ) {
      for (let pageIndex = startIndex; pageIndex <= pageCount; pageIndex++) {
        items.push(this.getPageElement(pageIndex));
      }
    } else {
      let leftSide = pageRangeDisplayed / 2;
      let rightSide = pageRangeDisplayed - leftSide;
      // If the selected page index is on the default right side of the pagination,
      // we consider that the new right side is made up of it (= only one break element).
      // If the selected page index is on the default left side of the pagination,
      // we consider that the new left side is made up of it (= only one break element).
      if (this.state.selectedPage > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - this.state.selectedPage;
        leftSide = pageRangeDisplayed - rightSide;
      } else if (
        this.state.selectedPage <
        pageRangeDisplayed / 2 + startIndex
      ) {
        // The proper amount of pages on the left side will be (if selected page is 1 and on the left side should be 0)
        leftSide = this.state.selectedPage - startIndex;
        rightSide = pageRangeDisplayed - leftSide;
      }

      let breakView;
      for (let index = startIndex; index <= pageCount; index++) {
        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (index <= marginPagesDisplayed) {
          items.push(this.getPageElement(index));
          continue;
        }
        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (index > pageCount - marginPagesDisplayed) {
          items.push(this.getPageElement(index));
          continue;
        }
        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (
          index >= this.state.selectedPage - leftSide &&
          index <= this.state.selectedPage + rightSide
        ) {
          items.push(this.getPageElement(index));
          continue;
        }
        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (items[items.length - 1] !== breakView) {
          breakView = <BreakView key={index} />;
          items.push(breakView);
        }
      }
    }
    return items;
  };

  private getPageElement = (pageIndex) => (
    <PageView
      key={pageIndex}
      handleClick={this.handlePageSelected}
      handleKeyDown={this.getNavigateOnEnterKeyPressed(this.handlePageSelected)}
      isSelected={this.state.selectedPage === pageIndex}
      pageIndex={pageIndex}
    />
  );

  private getNavigateOnEnterKeyPressed =
    (navigateFunc) => (event, pageIndex?: number) => {
      if (event.key === "Enter") {
        navigateFunc(event, pageIndex);
      }
    };
}
