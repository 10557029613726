import React from "react";
import {
  FiltersFieldNames,
  IFilterFieldConfig,
  IFilterValues,
} from "../../types";
import LocationsSearch from "../../../../Molecules/LocationsSearch";
import { IOption } from "../../../../../generics/interfaces";
import { Dropdown } from "../../../../Atoms/Dropdown/Dropdown";

export const locationFilter: IFilterFieldConfig<IFilterValues> = {
  name: FiltersFieldNames.Locations,
  defaultValue: [],
  getFilterFiledProps: ({
    values,
    filterDrawerValues,
    setFilterValues,
    setFilterDrawerValues,
    t,
  }) => {
    const locationsValue = values[FiltersFieldNames.Locations];

    const filterControlComponent = (
      <Dropdown
        toggleContent={t("search.filters.title.location")}
        keepOpenOnSelect
        renderOptions={() => (
          <div className="p-[10px] h-[200px]">
            <LocationsSearch
              placeholder={t("common:placeholder.search")}
              value={locationsValue}
              onChange={(value) => {
                setFilterValues({
                  [FiltersFieldNames.Locations]: value as IOption<string>[],
                });
              }}
              name={FiltersFieldNames.Locations}
              isMultiple
            />
          </div>
        )}
        key={FiltersFieldNames.Locations}
      />
    );

    const filterDrawerConfig = {
      title: t("search.filters.title.location"),
      id: FiltersFieldNames.Locations,
      selected: filterDrawerValues[FiltersFieldNames.Locations].length > 0,
      renderFilterContent: () => (
        <LocationsSearch
          label={t("common:search.filters.location.privacy")}
          placeholder={t("common:placeholder.search")}
          value={filterDrawerValues[FiltersFieldNames.Locations]}
          onChange={(value) => {
            setFilterDrawerValues({
              [FiltersFieldNames.Locations]: value as IOption<string>[],
            });
          }}
          name={FiltersFieldNames.Locations}
          isMultiple
        />
      ),
    };

    const tags = locationsValue.map((loc) => {
      return {
        id: `${FiltersFieldNames.Locations}-${loc.value}`,
        text: t("common:search.filters.tagLabel.location", {
          location: loc.name,
        }),
        onClick: () =>
          setFilterValues({
            [FiltersFieldNames.Locations]: locationsValue.filter(
              (location) => location.value !== loc.value
            ),
          }),
      };
    });

    return {
      tags,
      filterDrawerConfig,
      filterControlComponent,
    };
  },
};
