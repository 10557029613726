import React, { FC, useCallback } from "react";
import {
  CardNumberElement,
  CardNumberElementChangeEvent,
} from "@recurly/react-recurly";
import cx from "classnames";
import CardElementWrapper, {
  CardElementWrapperProps,
} from "./CardElementWrapper";
import useScrollBillingField from "../../hooks/useScrollBillingField";
import { FieldNames } from "../../constants";

interface Props extends CardElementWrapperProps {
  placeholder?: string;
  shouldScroll?: boolean;
  removeError: (field: string) => void;
}

const CardNumber: FC<Props> = ({
  label,
  className,
  annex,
  placeholder,
  shouldScroll,
  validationMessage,
  removeError,
}) => {
  const ref = useScrollBillingField(!!(validationMessage && shouldScroll));

  const onChange = useCallback(
    (data: CardNumberElementChangeEvent) => {
      if (data.valid) {
        removeError(FieldNames.number);
      }
    },
    [removeError]
  );

  return (
    <div ref={ref} className={className}>
      <CardElementWrapper
        label={label}
        annex={annex}
        validationMessage={validationMessage}
      >
        <CardNumberElement
          className={cx("card-element", { invalid: !!validationMessage })}
          style={{
            fontSize: "18px",
            placeholder: { content: placeholder },
          }}
          onChange={onChange}
        />
      </CardElementWrapper>
    </div>
  );
};

export default CardNumber;
