import React from "react";
import { ReactComponent as UserSolid } from "../../../images/svg/icons/user-solid.svg";

import "./Stub.scss";

export interface IStubProps {
  /**
   * Label for a stub
   */
  text?: string;
  /**
   * Additional class name for style overriding
   */
  className?: string;
}

export const Stub: React.FC<IStubProps> = (props: IStubProps) => {
  return (
    <div className={`c-stub ${props.className ? props.className : ""}`}>
      <div className="c-stub__container">
        <div className="w-1/2 m-auto">
          <UserSolid />
        </div>
      </div>
      <div className="c-stub__text">{props.text}</div>
    </div>
  );
};
