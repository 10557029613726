/**
 * Recursive component for generating nested checkbox items. Don't use it independently. Its styles are also defined in CheckboxGroup.scss
 */

import React from "react";
import cx from "classnames";
import { Checkbox, ICheckboxProps } from "../../../Atoms/Checkbox/Checkbox";
import { Omit } from "../../../../generics/type-manipulation";
import { SlideDown } from "../../../Functional/SlideDown/SlideDown";
import "./CheckboxGroupItem.scss";

export type ICheckboxConfig = Omit<ICheckboxProps, "name"> & {
  label: string; // force label requirement, otherwise props as usual, except name
  disabled?: boolean;
};

export type ICheckboxGroupItemValue =
  | { [key: string]: ICheckboxGroupItemValue }
  | boolean;

export interface ICheckboxGroupItemConfig {
  label: string;
  name: string;
  isCollapsible?: boolean;
  isExpandedByDefault?: boolean;
  labelPostFix?: number;
  annex?: JSX.Element;
  options: Array<ICheckboxConfig | ICheckboxGroupItemConfig>;
}

export interface ICheckboxGroupItemProps {
  config: ICheckboxGroupItemConfig | ICheckboxConfig;
  value: ICheckboxGroupItemValue;
  parentName: string;
  isNested?: boolean;
  nestedLevel?: number;
  className?: string;
  valueChanged: (value: ICheckboxGroupItemValue) => void;
  dropDown?: boolean;
}

export interface ICheckboxGroupItemState {
  isCollapsed: boolean;
}

export class CheckboxGroupItem extends React.Component<
  ICheckboxGroupItemProps,
  ICheckboxGroupItemState
> {
  public constructor(props: ICheckboxGroupItemProps) {
    super(props);

    const config = this.props.config as ICheckboxGroupItemConfig;
    this.state = {
      isCollapsed:
        !!config.options &&
        !config.isExpandedByDefault &&
        !!config.isCollapsible,
    };
  }

  public render() {
    const { parentName, config, isNested } = this.props;

    return (
      <div
        className={cx("c-checkbox-group-item", {
          "c-checkbox-group-item--nested": isNested,
        })}
      >
        {isNested && <div className="c-checkbox-group-item__marker" />}
        {this.renderGroupItemsBasedOnType(parentName, config, !!isNested)}
      </div>
    );
  }

  private renderGroupItemsBasedOnType = (
    parentName: string,
    config: ICheckboxConfig | ICheckboxGroupItemConfig,
    isNested: boolean
  ) => {
    if ((config as ICheckboxGroupItemConfig).options) {
      return this.renderChildGroupItem(
        parentName,
        config as ICheckboxGroupItemConfig,
        isNested
      );
    }

    return this.renderSingleCheckbox(parentName, config as ICheckboxConfig);
  };

  private renderChildGroupItem = (
    parentName: string,
    config: ICheckboxGroupItemConfig,
    isNested: boolean
  ) => {
    const childrenCheckedStatus = this.getOverallCheckedState(
      this.props.value,
      config.options
    );

    return (
      <div className="c-checkbox-group-item__expander">
        {config.isCollapsible && !!config.options.length && (
          <div
            className={cx("c-checkbox-group-item__toggle", {
              "c-checkbox-group-item__toggle--nested": isNested,
              "c-checkbox-group-item__toggle--parent": !isNested,
              "icon-plus": this.state.isCollapsed,
              "icon-minus": !this.state.isCollapsed,
            })}
            tabIndex={0}
            onClick={this.toggleClick}
            onKeyPress={this.toggleKeyPress}
          />
        )}
        <div className="c-checkbox-group-item__group">
          <Checkbox
            htmlId={`${this.props.nestedLevel || 0} - ${config.label}`}
            label={config.label}
            ariaLabel={config.label}
            labelPostFix={config.labelPostFix}
            name="expander"
            value="expand"
            className={`c-checkbox-group-item__status--${childrenCheckedStatus}`}
            checked={childrenCheckedStatus === "all"}
            valueChanged={this.toggleCheckedStatusOfAllChildren}
            annex={config.annex}
            disabled={this.isChildCheckboxesDisabled(config)}
            dropDown={this.props.dropDown}
          />
          {this.renderOptions(config, parentName)}
        </div>
      </div>
    );
  };

  private renderSingleCheckbox = (
    parentName: string,
    config: ICheckboxConfig
  ) => (
    <Checkbox
      htmlId={`${this.props.nestedLevel || 0} - ${config.label}`}
      name={parentName}
      value={config.value}
      label={config.label}
      ariaLabel={config.label}
      labelPostFix={config.labelPostFix}
      checked={!!this.props.value}
      valueChanged={this.valueChanged(this.props.value, config.value)}
      key={parentName + config.value + this.props.value}
      className={cx({ "c-checkbox-group-item--checked": this.props.value })}
      annex={config.annex}
      disabled={config.disabled}
      dropDown={this.props.dropDown}
    />
  );

  private renderOptions = (
    config: ICheckboxGroupItemConfig,
    parentName: string
  ) => {
    const options = (
      <div>
        {config.options.map((option, index) => {
          const name =
            (option as ICheckboxConfig).value ||
            (option as ICheckboxGroupItemConfig).name;

          return (
            <CheckboxGroupItem
              parentName={config.name}
              // @ts-ignore
              value={this.props.value && this.props.value[name]}
              isNested={true}
              nestedLevel={(this.props.nestedLevel || 0) + 1}
              config={option}
              valueChanged={this.valueChanged(this.props.value, name)}
              key={parentName + config.name + index}
              dropDown={this.props.dropDown}
            />
          );
        })}
      </div>
    );

    if (config.isCollapsible) {
      return (
        <SlideDown closed={this.state.isCollapsed} transitionOnAppear={false}>
          {options}
        </SlideDown>
      );
    } else {
      return options;
    }
  };

  private toggleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      this.toggleCollapsedState();
    }
  };

  private toggleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.currentTarget.blur();
    this.toggleCollapsedState();
  };

  private toggleCollapsedState = () =>
    this.setState({ isCollapsed: !this.state.isCollapsed });

  /**
   * Recursively goes down the tree of checkboxes to figure out if all of them are checked ('all') or unchecked ('none'),
   * or if at least one each is checked or unchecked respectively ('partial')
   */
  private getOverallCheckedState = (
    value: ICheckboxGroupItemValue,
    childConfigs: Array<ICheckboxGroupItemConfig | ICheckboxConfig>
  ): "all" | "partial" | "none" => {
    if (value == null) {
      /**
       * If there is no value, obviously none of the ckeckboxex are checked
       */
      return "none";
    } else if (typeof value === "boolean") {
      /**
       * If the value is a boolean, we're at the deepest level in the checkbox tree, meaning it's an actual single checkbox.
       * So since it's the only one, if it's checked return 'all', otherwise return 'none'
       */
      return value ? "all" : "none";
    } else {
      let hasChildAll = false; // true if the tree at this level has at least one child with 'all' checked
      let hasChildNone = false; // true if the tree at this level has at least one child with 'none' checked

      for (const config of childConfigs) {
        /**
         * Go one level deeper and run this same method over all direct descendants in order, analyzing its output
         */
        switch (
          this.getOverallCheckedState(
            value[
              (config as ICheckboxConfig).value ||
                (config as ICheckboxGroupItemConfig).name
            ],
            (config as ICheckboxGroupItemConfig).options || []
          )
        ) {
          case "all":
            hasChildAll = true; // We've found at least one direct descendant with 'all' checked
            break;

          case "partial":
            return "partial"; // If any descendant at any lower level is 'partial', the whole tree at this level is obviously 'partial' - 'partial' then bubbles up to the top

          case "none":
            hasChildNone = true; // We've found at least one direct descendant with 'none' checked
            break;
        }

        if (hasChildAll && hasChildNone) {
          // if we have at least one decendent with 'all' and one with 'none', it means the whole tree at this level is 'partial'
          return "partial";
        }
      }

      if (hasChildAll) {
        // if we haven't returned for hasChildAll && hasChildNone, then their bool values are opposite. Return accordingly
        return "all";
      } else {
        return "none";
      }
    }
  };

  private valueChanged =
    (value: ICheckboxGroupItemValue, name: string) =>
    (innerValue: ICheckboxGroupItemValue) => {
      if (
        value == null &&
        (this.props.config as ICheckboxGroupItemConfig).options
      ) {
        // if requiring object value type which has not been defined, add it to the value object
        this.props.valueChanged({ [name]: innerValue });
      } else if (typeof value === "object") {
        const newValue = { ...value };
        newValue[name] = innerValue;
        this.props.valueChanged(newValue);
      } else {
        this.props.valueChanged(innerValue);
      }
    };

  private toggleCheckedStatusOfAllChildren = (checked: boolean) => {
    this.props.valueChanged(
      this.makeCheckedStatusForAllChildren(this.props.config, checked)
    );
  };

  private makeCheckedStatusForAllChildren = (
    config: ICheckboxGroupItemConfig | ICheckboxConfig,
    checked: boolean
  ): ICheckboxGroupItemValue => {
    if ((config as ICheckboxConfig).value) {
      return checked;
    }

    const newValue: ICheckboxGroupItemValue = {};

    (config as ICheckboxGroupItemConfig).options.forEach((option) => {
      newValue[
        (option as ICheckboxConfig).value ||
          (option as ICheckboxGroupItemConfig).name
      ] = this.makeCheckedStatusForAllChildren(option, checked);
    });

    return newValue;
  };

  private isChildCheckboxesDisabled = (
    config: ICheckboxGroupItemConfig | ICheckboxConfig
  ): boolean => {
    let result: boolean = true;
    if ((config as ICheckboxGroupItemConfig).options) {
      (config as ICheckboxGroupItemConfig).options.forEach((p) => {
        result = result && this.isChildCheckboxesDisabled(p);
      });
      return result;
    }

    return (config as ICheckboxConfig).disabled ?? false;
  };
}
