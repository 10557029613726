import { IJoiningPagesCommon } from "../../models/joining-pages/Common";

export const JoiningPagesCommonTranslations: IJoiningPagesCommon = {
  common: {
    joiningAsLabel: "You are joining as a:",
    performer: "Performer",
    youngPerformer: "Young Performer",
    castingProfessional: "Casting Professional",
    continue: "Continue",
    back: "Back",
    delete: "Delete",
    dropzonePlaceholder: "Click to select file or drag here",
    edit: "Edit",
    from: "From",
    nextUpLabel: "Next up: ",
    no: "No",
    yes: "Yes",
    to: "To",
    pleaseSelect: "Please select",
    search: "Search",
    cancel: "Cancel",
    continueTo: "Continue to",
    eligibility: "Eligibility",
    aboutYou: "About you",
    agencies: "Agencies",
    review: "Review",
    checkout: "Checkout",
    welcome: "Welcome",
    or: "or",
    optional: "optional",
    uploadingMessage: "Uploading",
    phoneSearchPlaceholder: "Search country code",
    selectMembershipPlaceholder: "Select membership",
    add: "Add",
    complete: "Complete",
    joiningSubmit: "Submit",
  },
  errorsCommon: {
    title: "File error",
    close: "Close",
    fileTypeErrorTitle:
      "The file type you have tried to upload is not supported.",
    fileTypeErrorMessage: "Please try one of these types:",
    fileSizeLargeErrorTitle: "The file you have tried to upload is too large.",
    fileSizeLargeErrorMessage: "Please ensure the file does not exceed {size}",
    fileSizeSmallErrorTitle: "The file you have tried to upload is too small.",
    fileSizeSmallErrorMessage: "Please ensure the file is at least {size}",
    tooManyFilesErrorTitle: "Too many files",
    tooManyFilesErrorMessage: "Please ensure you only upload 1 file",
    fileDimensionsErrorTooSmall:
      "Sorry, that image is too small. Photographs less than {minWidth} pixels wide and {minHeight} pixels high will be rejected.",
    fileDimensionsErrorTooLarge:
      "Sorry, that image is too large. Photographs more than {maxWidth} pixels wide and {maxHeight} pixels high will be rejected.",
    entryNotValid: "{fieldName} is not valid.",
    entryRequired: "{fieldName} is required.",
    entryMaxLength: "{fieldName} must be at most {length} characters.",
    entryMinLength: "{fieldName} must be at least {length} characters.",
    failedToUploadFile: "Failed to upload document. Please try again.",
    failedToSubmitData: "Failed to submit data. Please try again.",
    applicantIsTooOldForYP:
      "Applicant is too old for a Young Performer, please apply for a performer membership",
  },
  cancelModal: {
    cancelModalTitle: "Warning",
    cancelModalMessage:
      "<p>Are you sure you want to leave the application?</p><p>All the completed pages will be saved and you can return to your application by using the password we will email you.</p><p><b>Any information on the current page will be lost.</b></p>",
    cancelModalLeaveButton: "Leave application",
    cancelModalContinueButton: "Continue application",
  },
};
