import React, { FC } from "react";
import { IPrice, ICouponObject } from "../../types";
import OrderSummary from "../OrderSummary/OrderSummary";

interface Props {
  planName: string;
  price: IPrice | undefined;
  couponBag: {
    userCouponCodesObject: ICouponObject[];
    setCoupon: (couponCode: string) => Promise<void>;
    removeCoupon: (couponCode: string) => Promise<void>;
    couponErrors: string[];
    hasAccountDiscount?: boolean;
    isCheckoutPreviewRequestLoading: boolean;
  };
  isMobileView?: boolean;
  planNameTooltip?: {
    header: string;
    body: string;
  };
  nextBillingDate?: {
    date: string;
    price: string;
  };
}

const SummaryBlock: FC<Props> = ({
  planName,
  price,
  couponBag,
  isMobileView,
  planNameTooltip,
  nextBillingDate,
}) => {
  const classNames = isMobileView ? "m-2" : "mt-[2rem] w-full max-w-[450px]";

  return (
    <aside className={`sticky ${classNames}`}>
      <OrderSummary
        planName={planName}
        total={price?.total}
        amount={price?.amount}
        discountAmount={price?.discount}
        creditApplied={price?.creditApplied}
        accountCredited={price?.accountCredited}
        vatAmount={price?.tax}
        vatLabel="VAT 20%"
        userCouponCodesObject={couponBag.userCouponCodesObject}
        setCoupon={couponBag.setCoupon}
        removeCoupon={couponBag.removeCoupon}
        couponErrors={couponBag.couponErrors}
        hasAccountDiscount={couponBag.hasAccountDiscount}
        isMobileView={isMobileView}
        isCheckoutPreviewRequestLoading={
          couponBag.isCheckoutPreviewRequestLoading
        }
        addOns={price?.addOns}
        planNameTooltip={planNameTooltip}
        nextBillingDate={nextBillingDate}
      />
    </aside>
  );
};

export default SummaryBlock;
