import { useQuery } from "react-query";
import { BillingUser } from "translations/src/models/billing";
import { TranslationType } from "translations/src/services/billing";
import { FETCH_BILLING_TRANSLATIONS } from "../constants";
import { billingTranslationsService } from "../services";

export const useFetchTranslation = <T extends BillingUser>(
  user: T,
  locale: string
): {
  data: TranslationType<T> | undefined;
} => {
  const { data } = useQuery(
    [FETCH_BILLING_TRANSLATIONS, locale, user],
    () => billingTranslationsService.getTranslations({ locale, user }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return { data };
};
