import React, { forwardRef, Ref } from "react";
import { ReactComponent as WarningIcon } from "component-library/src/images/svg/icons/warning-icon.svg";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { BillingUser } from "translations/src/models/billing";

interface Props {
  onClick: () => void;
}

const FailedBanner = forwardRef(
  ({ onClick }: Props, ref: Ref<HTMLDivElement>) => {
    const {
      texts: { failedBanner },
    } = useBillingTranslations(BillingUser.Common);

    return (
      <div className="bg-red-100 py-5 px-[18px]" ref={ref}>
        <div className="flex items-center text-m leading-[21px] md:text-[22px] md:leading-9">
          <WarningIcon className="h-5 w-5 mr-[13px]" />
          {failedBanner.failedBannerHeader}
        </div>
        <a
          className="text-[14px] leading-[17px] md:text-[18px] md:leading-[30px] pl-[33px] font-semibold underline cursor-pointer"
          onClick={onClick}
        >
          {failedBanner.failedBannerLink}
        </a>
      </div>
    );
  }
);

export default FailedBanner;
