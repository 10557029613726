import React, { FC } from "react";
import { ReactComponent as LockIcon } from "component-library/src/images/svg/icons/lock.svg";
import { securityTextCommonStyles } from "../../../constants";

interface Props {
  securityTitle: string;
  isMobileView: boolean | undefined;
}

const SecurityBlock: FC<Props> = ({ securityTitle, isMobileView }) => {
  return (
    <p
      className={`${securityTextCommonStyles} flex flex-[2.5] items-center gap-[10px] text-color-neutral-one ${
        isMobileView ? "mb-5" : ""
      }`}
    >
      <LockIcon height={21} width={16} />
      {securityTitle}
    </p>
  );
};

export default SecurityBlock;
