export function isSafari(): boolean {
  const { vendor, userAgent } = navigator;

  return Boolean(
    vendor &&
      vendor.indexOf('Apple') > -1 &&
      userAgent &&
      userAgent.indexOf('CriOS') === -1 &&
      userAgent.indexOf('FxiOS') === -1
  );
}
