import { authAxios as axios } from "./authAxios";
import config from "../../config";

enum AccountType {
  DefaultUser = 0,
  CastingDirector = 1,
  Performer = 2,
  Agent = 3,
  CastingSearchOnly = 4,
}

export async function getWhoAmI() {
  const { data } = await axios(config.whoAmIUrl);

  return {
    ...data,
    type: AccountType[data.role],
  };
}
