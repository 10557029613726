/**
 * Stub input group implementation to demo input styles.
 */

import React from "react";

import {
  IValidity,
  ValidationFunction,
} from "../../../generics/form-validation";
import { WatchedFunction } from "../../../generics/watched-function";

import "./InputGroup.scss";
import cx from "classnames";

export interface IInputConfig {
  /**
   * The input's name
   */
  name: string;
  /**
   * The input's current value
   */
  value: any;
  /**
   * Array of validation functions to run against the input's value. Priority of validation is the order in which the functions are placed within the array.
   */
  validators: ValidationFunction[];
}

/**
 * Initial less strict version of FieldValidation as defined in InputGroupTypes, maintained for backwards compatibility reasons
 */
export interface IValidationData {
  [name: string]: IValidity;
}

/**
 * Initial less strict version of IDataModelValidationResult as defined in InputGroupTypes, maintained for backwards compatibility reasons.
 * Can be passed to the IInputGroupProps.validationDone callback to get back the validation data for your entire form.
 */
export interface IGroupValidationResult {
  isGroupInvalid: boolean;
  validationData: IValidationData;
}

export interface IInputGroupProps {
  /**
   * Variant of the input group according to designs. If empty, will default to a white background with grey background inputs. Other two variants as displayed
   */
  variant?: "secondary" | "tertiary";
  /**
   * Configuration of inputs which are passed as children. Needs to be provided for each input name.
   */
  inputConfigs?: IInputConfig[];
  /**
   * Wrapper over an outer function which, whenever called, triggers this component's validation logic. validate.call can be bound to blur events, a submit button or whatever else.
   */
  triggerValidation?: WatchedFunction;
  /**
   * Callback to the validation action which makes use of validity data.
   * Recommended approach is to use the more specific IDataModelValidationResult<DataModel> if you want a strongly typed form
   */
  validationDone?: (result: IGroupValidationResult) => void;
  /** Optional css class for the topmost div */
  className?: string;
  children?: React.ReactNode;
}

interface IInputGroupState {
  invalid: boolean;
}

export class InputGroup extends React.Component<
  IInputGroupProps,
  IInputGroupState
> {
  public constructor(props: IInputGroupProps) {
    super(props);

    this.state = {
      invalid: false,
    };
  }

  public render() {
    return (
      <div
        className={cx(
          "c-input-group",
          {
            [`c-input-group--${this.props.variant}`]: this.props.variant,
            "c-input-group--default": !this.props.variant,
          },
          this.props.className
        )}
      >
        {this.props.children}
      </div>
    );
  }

  public componentDidMount() {
    if (this.props.triggerValidation) {
      this.props.triggerValidation.addWatcher(this.validateInputs);
    }
    this.validateInputs();
  }

  public componentWillUnmount() {
    if (this.props.triggerValidation) {
      this.props.triggerValidation.removeWatcher(this.validateInputs);
    }
  }

  private validateInputs = () => {
    if (this.props.inputConfigs) {
      const validationData: IValidationData = {};
      let isGroupInvalid = false;

      this.props.inputConfigs.forEach((input) => {
        validationData[input.name] = {
          invalid: false,
          validationMessage: "",
        };

        for (const validator of input.validators) {
          const validity: IValidity = validator(input.value);

          if (validity.invalid) {
            isGroupInvalid = true;
            validationData[input.name] = validity;
            break;
          }
        }
      });

      if (this.props.validationDone) {
        this.props.validationDone({ isGroupInvalid, validationData });
      }
    }
  };
}
