import React from "react";
import { ReactComponent as FacebookIcon } from "../../../images/svg/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../images/svg/icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../../images/svg/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../../images/svg/icons/youtube.svg";
import "./SocialList.scss";

interface ISocialLink {
  text: string;
  url: string;
  icon: any; // React.Component;
}

interface ISocialListProps {
  /**
   * The message to display above the links
   */
  followCTA: string;
  /** Optional css class for the topmost div */
  className?: string;
}

const ICON_CLASS = "c-social-list__icon";

export class SocialList extends React.Component<ISocialListProps> {
  private links: ISocialLink[] = [
    {
      text: "YouTube",
      url: "https://www.youtube.com/user/TheUKSpotlight",
      icon: <YoutubeIcon className={ICON_CLASS} />,
    },
    {
      text: "Twitter",
      url: "https://www.twitter.com/spotlightuk",
      icon: <TwitterIcon className={ICON_CLASS} />,
    },
    {
      text: "Facebook",
      url: "https://www.facebook.com/spotlightuk",
      icon: <FacebookIcon className={ICON_CLASS} />,
    },
    {
      text: "Instagram",
      url: "https://www.instagram.com/spotlightuk/",
      icon: <InstagramIcon className={ICON_CLASS} />,
    },
  ];

  private socialLinks = this.links.map((link) => (
    <li key={link.url} className="c-social-list__item">
      <a
        className="c-social-list__link"
        href={link.url}
        target="_blank"
        rel="noopener"
      >
        {link.icon}
        <span className="visually-hidden">{link.text}</span>
      </a>
    </li>
  ));

  public render() {
    return (
      <div
        className={`c-social-list${
          this.props.className ? " " + this.props.className : ""
        }`}
      >
        <span className="c-social-list__title">{this.props.followCTA}</span>
        <ul className="c-social-list__list">{this.socialLinks}</ul>
      </div>
    );
  }
}
