import React, { FC } from "react";
import { useTranslation } from "i18n";
import { Popup } from "../../../../Molecules/Popup/Popup";
import { AlertMessage } from "../../../../Molecules/AlertMessage/AlertMessage";
import { industrySupport, plansInfo, resources } from "./constants";
import Portal from "../../../../Atoms/Portal";
import PlanRow from "./PlanRow/PlanRow";
import "./styles.scss";

interface Props {
  isOpen: boolean;
  closePopUp: () => void;
}

const PlanInfoModal: FC<Props> = ({ isOpen, closePopUp }) => {
  const { t } = useTranslation();

  return (
    <Portal>
      {isOpen && (
        <Popup
          width={{ lg: 6, md: 10 }}
          texts={{ closePopup: t("common:button.label.cancel") }}
          close={closePopUp}
          className="z-50"
        >
          <AlertMessage
            icon="notice"
            texts={{
              title: t("common:billing.updateSubscription.memberShipOptions"),
            }}
            buttons={[
              {
                name: t("common:button.label.close"),
                click: closePopUp,
                type: "primary",
              },
            ]}
          >
            <table className="plan-table">
              <thead>
                <tr>
                  <th>
                    {t(
                      "common:billing.planFeature.tableHeading.spotlightFeatures"
                    )}
                  </th>
                  <th>
                    {t("common:billing.planFeature.tableHeading.classic")}
                  </th>
                  <th>
                    {t("common:billing.planFeature.tableHeading.premium")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {plansInfo.map((planInfo) => (
                  <PlanRow {...planInfo} />
                ))}
              </tbody>
              <thead>
                <tr>
                  <th>
                    {t(
                      "common:billing.planFeature.tableHeading.industrySupport"
                    )}
                  </th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {industrySupport.map((planInfo) => (
                  <PlanRow {...planInfo} />
                ))}
              </tbody>
              <thead>
                <tr>
                  <th>
                    {t("common:billing.planFeature.tableHeading.resources")}
                  </th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {resources.map((planInfo) => (
                  <PlanRow {...planInfo} />
                ))}
              </tbody>
            </table>
          </AlertMessage>
        </Popup>
      )}
    </Portal>
  );
};

export default PlanInfoModal;
