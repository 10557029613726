import React from "react";
import cx from "classnames";

import {
  removeClass,
  addClass,
  isSmallDeviceWidth,
} from "../../../../generics/dom-extensions";

import "./QuickViewCard.scss";

export interface IQuickViewCardProps {
  /** Variant for arrow positions */
  selectedIndex: number;
  /** No of cards in the row */
  noOfCards: number;
  /** Handler for close event */
  handleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  /** Optional parameter to enable next-card button */
  isNextCardAvailable?: boolean;
  /** Optional action handler for next-card button */
  nextCardButtonAction?: () => void;
  /** Optional parameter to enable previous-card button */
  isPrevCardAvailable?: boolean;
  /** Optional action handler for previous-card button */
  prevCardButtonAction?: () => void;
  children: React.ReactNode;
}

export class QuickViewCard extends React.Component<IQuickViewCardProps> {
  constructor(props: IQuickViewCardProps) {
    super(props);
  }

  public componentDidMount() {
    if (isSmallDeviceWidth()) {
      addClass(document.body, "g-no-scroll");
    }
  }

  public componentWillUnmount() {
    removeClass(document.body, "g-no-scroll");
  }

  public render() {
    const { selectedIndex, noOfCards, children, handleClick } = this.props;

    return (
      <div
        className={cx("c-quick-view-card", {
          [`c-quick-view-card--position-${selectedIndex}-${noOfCards}`]:
            !!selectedIndex && !!noOfCards,
        })}
      >
        <div className="c-quick-view-card__wrapper">
          {children && (
            <div className="c-quick-view-card__content"> {children} </div>
          )}
        </div>
        {this.renderNavigationArrows()}
        <div
          id="card-close-button"
          onClick={handleClick}
          onKeyPress={this.handleKeyPressOnClose}
          tabIndex={0}
          className="c-quick-view-card__close icon-cross"
        />
      </div>
    );
  }

  private renderNavigationArrows = () => {
    const {
      isPrevCardAvailable,
      isNextCardAvailable,
      prevCardButtonAction,
      nextCardButtonAction,
    } = this.props;

    return (
      <React.Fragment>
        {isPrevCardAvailable && (
          <div
            className="c-quick-view-card__prev-card icon-chevronleft"
            tabIndex={0}
            onClick={prevCardButtonAction}
            onKeyPress={this.handleKeyPress(prevCardButtonAction)}
          />
        )}
        {isNextCardAvailable && (
          <div
            className="c-quick-view-card__next-card icon-chevronright"
            tabIndex={0}
            onClick={nextCardButtonAction}
            onKeyPress={this.handleKeyPress(nextCardButtonAction)}
          />
        )}
      </React.Fragment>
    );
  };

  private handleKeyPress =
    (action?: () => void) => (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        action?.();
      }
    };

  private handleKeyPressOnClose = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      this.props.handleClick?.(
        e as unknown as React.MouseEvent<HTMLDivElement>
      );
    }
  };
}
