import React from "react";

import { GridStyles } from "./GridStyles";
import { ISimpleGridColumn } from "./SimpleGrid";
import { Helper, IHelperProps } from "../Helper/Helper";

interface IHeaderCell<T> {
  /**
   * The column header cell to render
   */
  headerCell: ISimpleGridColumn<T>;
  /**
   * Styles for customization
   */
  styles: GridStyles;
  helper?: IHelperProps;
}

export const HeaderCell = <T,>(props: IHeaderCell<T>) => {
  const { headerCell, styles, helper } = props;

  return (
    <div
      className={`c-simple-grid__table-header-cell-clickable-space ${styles.headerLabel}`}
    >
      <span
        className={`c-simple-grid__table-header-cell-clickable-space-title ${styles.headerTitle}`}
      >
        {headerCell.label}
      </span>
      {helper && <Helper {...helper} />}
    </div>
  );
};
