import React from "react";
import cx from "classnames";
import { Dropdown, IDropdownProps } from "../../Atoms/Dropdown/Dropdown";
import { omit } from "../../../generics/object-manipulation";
import { InputLabel } from "../../Atoms/InputsCommon/InputLabel";
import {
  ERROR,
  InputWrapper,
  WARNING,
} from "../../Atoms/InputsCommon/InputWrapper";
import RangeSelection from "../RangeSelection";
import "./RangeSelectionDropdown.scss";

type PassthroughDropdownProps = Omit<
  IDropdownProps,
  "toggleContent" | "renderOptions"
>;

const ownPropKeys: Array<keyof IRangeSelectionDropdownProps> = [
  "value",
  "valueChanged",
  "label",
  "invalid",
  "validationMessage",
  "isFormSubmitted",
  "annex",
];

export interface IRangeSelectionValue {
  lowerRangeValue?: number;
  upperRangeValue?: number;
}

export interface IRangeSelectionDropdownContent {
  topmostDropdownPlaceholder: string;
  lowerDropdownPlaceholder: string;
  lowerDropdownLabel: string;
  upperDropdownPlaceholder: string;
  upperDropdownLabel: string;
}

export interface IRangeSelectionDropdownProps {
  /**
   * All texts for range selection dropdown
   */
  content: IRangeSelectionDropdownContent;
  /**
   * Range selection lower bound
   */
  minimumRangeValue: number;
  /**
   * Range selection upper bound
   */
  maximumRangeValue: number;
  /**
   * An optional method to provide custom representation of inner dropdown values
   */
  getValueRepresentation?: (value: number) => string;
  /**
   * The value of range selection
   */
  value: IRangeSelectionValue;
  /**
   * The onchange property sets and returns the event handler for the change event.
   */
  valueChanged: (value: IRangeSelectionValue) => void;
  /**
   * Optional parameter to change inner layout behavior
   */
  useColumnLayout?: boolean;
  /**
   * Optional label
   */
  label?: string;
  /**
   * Optional validation state
   */
  invalid?: typeof WARNING | typeof ERROR;
  /**
   * Optional validation message
   */
  validationMessage?: string;
  /**
   * Optional state of parent form, <code>true</code> if it has been submitted
   */
  isFormSubmitted?: boolean;
  /**
   * Optional annex or helper
   */
  annex?: JSX.Element;
}

export interface IRangeSelectionDropdownState {
  blurred: boolean;
}

export class RangeSelectionDropdown extends React.Component<
  IRangeSelectionDropdownProps & PassthroughDropdownProps,
  IRangeSelectionDropdownState
> {
  public readonly state: Readonly<IRangeSelectionDropdownState> = {
    blurred: false,
  };

  public render() {
    const {
      annex,
      invalid,
      isFormSubmitted,
      label,
      validationMessage,
      content,
      useColumnLayout,
      minimumRangeValue,
      maximumRangeValue,
      valueChanged,
      value,
      getValueRepresentation,
    } = this.props;
    const { blurred } = this.state;

    const invalidState =
      invalid && (blurred || isFormSubmitted) ? invalid : undefined;
    const rootClassNames = cx("c-range-selection-dropdown", {
      "c-range-selection-dropdown--column": useColumnLayout,
    });

    return (
      <div className={rootClassNames}>
        <InputWrapper
          invalid={invalidState}
          validationMesssage={validationMessage}
        >
          <InputLabel label={label} annex={annex} />
          <Dropdown
            {...omit(this.props, ownPropKeys)}
            ariaLabel={label}
            toggleContent={content.topmostDropdownPlaceholder}
            renderOptions={() => (
              <RangeSelection
                value={value}
                valueChanged={valueChanged}
                getValueRepresentation={getValueRepresentation}
                content={content}
                maximumRangeValue={maximumRangeValue}
                minimumRangeValue={minimumRangeValue}
              />
            )}
            keepOpenOnSelect={true}
            // @ts-ignore
            onBlur={this.onBlur}
          />
        </InputWrapper>
      </div>
    );
  }

  private onBlur = (event: React.FocusEvent<HTMLElement>) => {
    this.props.onBlur && this.props.onBlur(event);
    this.setState({ blurred: true });
  };
}
