import React, { FC } from "react";
import { Trans, useTranslation } from "i18n";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import { Button } from "../../../../../Atoms/Button/Button";
import { useDynamicWindowWidthFor } from "shared-hooks";
import { SCREEN_WIDTH_ENUM } from "../../../../../../generics/dom-extensions";
import { CurrencyType, currencyMap } from "../../../types";
import { IAddOnFeature } from "../PlanSelector/PlanSelector.helpers";
import { FeatureRow } from "../FeatureRow";
import "./styles.scss";

interface IFeatureBoxProps {
  features: IAddOnFeature[];
  isFeatureSelected: boolean;
  selectFeature?: () => void;
  featurePrice: number;
  vatPrice?: string;
  currency: CurrencyType;
  texts: Record<string, any>;
  subFeatures?: {
    title: string;
    features: IAddOnFeature[];
  }[];
}

const FeatureBox: FC<IFeatureBoxProps> = ({
  features,
  isFeatureSelected,
  selectFeature,
  featurePrice,
  vatPrice,
  currency = CurrencyType.GBP,
  texts,
  subFeatures,
}) => {
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const { t } = useTranslation();
  const currencySymbol = currencyMap[currency];

  return (
    <div
      className={cx("feature-box", {
        "feature-box--selected": isFeatureSelected,
      })}
    >
      <div
        className={cx("feature-box__inner", {
          "feature-box__inner--mobile": isMobileView,
        })}
      >
        <div className="md:my-2">
          <p
            className={cx("feature-box__inner__title", {
              "feature-box__inner__title--mobile": isMobileView,
            })}
          >
            {t(texts.title)}
          </p>
          {texts.description && (
            <p className="text-sm">
              <Trans
                i18nKey={texts.description}
                components={{
                  link1: <a href={texts.descriptionLink} target="_blank" />,
                }}
              />
            </p>
          )}
        </div>

        <div
          className={cx("feature-box__inner__price-block", {
            "feature-box__inner__price-block--mobile": isMobileView,
          })}
        >
          <div
            className={cx("feature-box__inner__price-info", {
              "feature-box__inner__price-info--mobile": isMobileView,
            })}
          >
            <p className="whitespace-nowrap">{t(texts.pricePrefix)}</p>
            <p
              className={cx("flex flex-row gap-1 text-xl", {
                "md:justify-center": texts.pricePrefix,
                "md:items-center": texts.period,
              })}
            >
              {currencySymbol}
              {featurePrice.toFixed(2)}{" "}
              <span className="justify-center leading-5 whitespace-nowrap">
                {t(texts.period)}
              </span>
            </p>
            {texts.vatPrice && vatPrice && (
              <span className="text-xs text-color-neutral-one whitespace-nowrap">
                {t(texts.vatPrice, { price: vatPrice })}
              </span>
            )}
          </div>
          {selectFeature && (
            <Button
              text={t(
                isFeatureSelected
                  ? texts.button.selected
                  : texts.button.unselected
              )}
              type={isFeatureSelected ? "secondary" : "primary"}
              onClick={selectFeature}
              className={cx("feature-box__inner__price-button", {
                "feature-box__inner__price-button--mobile": isMobileView,
              })}
              data-testid={
                dataTestIds.componentLibrary[
                  "Organisms.Billing.AddOnCheckout.FeatureBox.addFeatureButton"
                ]
              }
            />
          )}
        </div>
      </div>
      <div className="feature-box__inner__features-list">
        {features.map((feature: IAddOnFeature) => (
          <FeatureRow key={feature.title} feature={feature} />
        ))}
        {subFeatures?.map((subFeature) => (
          <div key={subFeature.title} className="w-full">
            <p className="feature-box__inner__features-sublist">
              {t(subFeature.title).toUpperCase()}
            </p>
            <div className="flex flex-col gap-1">
              {subFeature.features.map((feature: IAddOnFeature) => (
                <FeatureRow key={feature.title} feature={feature} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureBox;
