import React, { ChangeEvent, FC, ReactNode, useRef } from "react";
import { useField } from "formik";
import cx from "classnames";

import "../../ToggleSwitch/styles.scss";
import ToggleSwitch from "../../ToggleSwitch";

interface IToggleFormikProps {
  name: string;
  value?: any;
  className?: string;
  label?: ReactNode;
  testId?: string;
  onChange?: (value: boolean) => void;
}

const ToggleFormik: FC<IToggleFormikProps> = ({
  name,
  value,
  className,
  label,
  testId,
  onChange,
}) => {
  const [field, _, helpers] = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };

  const handleWrapperClick = () => {
    if (inputRef.current) {
      inputRef.current.checked = !inputRef.current.checked;
      onChange?.(inputRef.current.checked);
      helpers.setValue(inputRef.current.checked);
    }
  };

  const handleWrapperKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === "  ") {
      handleWrapperClick();
    }
  };

  return (
    <ToggleSwitch
      onCheck={handleChange}
      onWrapperClick={handleWrapperClick}
      onWrapperKeydown={handleWrapperKeyDown}
      checked={value || field.value}
      value={field.value}
      name={field.name}
      inputRef={inputRef}
      className={cx("switch", className)}
      label={label}
      testId={testId}
    />
  );
};

export default ToggleFormik;
