import { useDispatch } from "react-redux";
import { useTranslation } from "i18n";
import { hidePopup, showPopup } from "store/ui/ui.actions";
import { PopupType } from "store/ui/ui.types";

export function useUpdateDetailsPopUp(goToAccountSettings: () => void) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hideUpdateDetailsPopUp = () => dispatch(hidePopup());

  return {
    showUpdateDetailsPopUp: () =>
      dispatch(
        showPopup({
          type: PopupType.ErrorPopup,
          data: {
            title: t("performer:home.reactivateSubscription.popup.title"),
            description: t(
              "performer:home.reactivateSubscription.popup.description"
            ),
            closeButtonText: t("common:button.label.continue"),
            handleButtonClick: () => {
              hideUpdateDetailsPopUp();
              goToAccountSettings();
            },
            hidePopup: hideUpdateDetailsPopUp,
          },
        })
      ),
    hideUpdateDetailsPopUp,
  };
}
