import cx from "classnames";
import React from "react";

import "./SectionDivider.scss";

export interface ISectionDividerProps {
  className?: string;
  withOuterPadding?: boolean;
}

const SectionDivider: React.FC<ISectionDividerProps> = (props) => (
  <div
    className={cx(
      "c-section-divider",
      {
        "c-section-divider--outer-padding": props.withOuterPadding,
      },
      props.className
    )}
  >
    <div className="g-split-line" />
  </div>
);

export default SectionDivider;
