import React from "react";
import ReactDOM from "react-dom";

import * as LocalizationUtils from "./LocalizationUtils";
import { LocalizationButton } from "../../Atoms/LocalizationButton/LocalizationButton";
import { LocalizationDialog } from "../../Molecules/LocalizationDialog/LocalizationDialog";
import {
  ILocalizationPopUpTexts,
  LOCALIZATION_ORGANISM_FALLBACK_TEXT,
} from "./LocalizationOrganismTexts";
import { AlertMessage } from "../../Molecules/AlertMessage/AlertMessage";
import { Popup } from "../../Molecules/Popup/Popup";
import { Spinner } from "../../Atoms/Spinner/Spinner";

import "./LocalizationOrganism.scss";

interface ILocalizationOrganismCommon {
  onLocalizationOptionsSaved?: (language: string, location: string) => void;
  popupContainer?: React.RefObject<Element>;
}

interface ILocalizationOrganismTranslations {
  localizationTexts: ILocalizationPopUpTexts;
}

interface ILocalizationOrganismWithLocalizationApi {
  localizationTexts: undefined;
  localizationUrl: string;
}

export type LocalizationOrganismProps = ILocalizationOrganismCommon &
  (
    | ILocalizationOrganismTranslations
    | ILocalizationOrganismWithLocalizationApi
  );

interface ILocalizationOrganismState {
  isDialogOpen?: boolean;
  selectedLocation: string;
  selectedLanguage: string;
  texts?: ILocalizationPopUpTexts;
}

export class LocalizationOrganism extends React.Component<
  LocalizationOrganismProps,
  ILocalizationOrganismState
> {
  constructor(props: LocalizationOrganismProps) {
    super(props);

    const localizationInfo = LocalizationUtils.getUserLocalizationPreference();
    this.state = {
      selectedLanguage: localizationInfo.language,
      selectedLocation: localizationInfo.location,
      texts: props.localizationTexts,
    };
  }

  public componentDidMount() {
    if (!this.state.texts) {
      if (!this.props.localizationTexts) {
        this.loadTextsFromLocalizationApi(this.props.localizationUrl);
      } else {
        this.setState({
          texts: LOCALIZATION_ORGANISM_FALLBACK_TEXT,
        });
      }
    }
  }

  public static getDerivedStateFromProps(
    nextProps: LocalizationOrganismProps,
    prevState: ILocalizationOrganismState
  ) {
    let stateUpdates = {} as Partial<ILocalizationOrganismState>;

    if (
      nextProps.localizationTexts &&
      nextProps.localizationTexts !== prevState.texts
    ) {
      stateUpdates = { texts: nextProps.localizationTexts };
    }

    return stateUpdates;
  }

  public render() {
    const { popupContainer } = this.props;
    const { texts: localizationTexts, isDialogOpen } = this.state;

    return (
      <>
        <LocalizationButton
          countryISOCode={this.state.selectedLocation.toLowerCase()}
          flagLabel={
            localizationTexts?.localizationButton.localizationButtonAriaLabel ||
            ""
          }
          onClick={this.onLocalizationButtonClick}
        />
        {isDialogOpen &&
          this.renderLocalizationDialog(localizationTexts, popupContainer)}
      </>
    );
  }

  private renderLocalizationDialog(
    localizationTexts?: ILocalizationPopUpTexts,
    popupContainer?: React.RefObject<Element>
  ) {
    const localizationPopup = this.renderLocalizationPopup(localizationTexts);

    let localizationDialog: React.ReactPortal | JSX.Element = localizationPopup;

    if (popupContainer && popupContainer.current) {
      localizationDialog = ReactDOM.createPortal(
        localizationPopup,
        popupContainer.current
      );
    }

    return localizationDialog;
  }

  private renderLocalizationPopup(localizationTexts?: ILocalizationPopUpTexts) {
    return (
      <Popup
        width={{ lg: 4, md: 6 }}
        close={this.closePopup}
        priority="high"
        texts={{
          closePopup:
            localizationTexts?.localizationPopup.closeButtonAriaLabel || "",
        }}
      >
        {localizationTexts ? (
          <AlertMessage
            texts={{
              title: localizationTexts.localizationPopup.title,
              description: "",
            }}
            buttons={[
              {
                name: localizationTexts.localizationPopup.saveButton,
                type: "primary",
                click: this.handleSaveClick,
              },
              {
                name: localizationTexts.localizationPopup.cancelButton,
                type: "secondary",
                click: this.closePopup,
              },
            ]}
          >
            <LocalizationDialog
              selectedLanguage={this.state.selectedLanguage}
              selectedLocation={this.state.selectedLocation}
              labels={{
                location: localizationTexts.localizationPopup.locationTitle,
                language: localizationTexts.localizationPopup.languageTitle,
              }}
              locationOptions={localizationTexts.localizationPopup.locations}
              locationChanged={this.handleLocationChanged}
              languageOptions={localizationTexts.localizationPopup.languages}
              languageChanged={this.handleLanguageChanged}
              locationLanguagesMapping={
                LocalizationUtils.LOCATION_LANGUAGE_MAPPING
              }
            />
          </AlertMessage>
        ) : (
          <div className="c-localization-organism__spinner">
            <Spinner inline={true} />
          </div>
        )}
      </Popup>
    );
  }

  private generateLocalizationUrl(localizationUrl: string) {
    localizationUrl = localizationUrl || "https://umbraco.spotlabs.uk/";

    if (localizationUrl[localizationUrl.length - 1] !== "/") {
      localizationUrl += "/";
    }

    return (
      localizationUrl +
      "umbraco/api/content/home-page/navigation/localisation?culture=" +
      this.state.selectedLanguage +
      "-" +
      this.state.selectedLocation
    );
  }

  private async loadTextsFromLocalizationApi(localizationUrl: string) {
    if (!this.state.texts) {
      const url = this.generateLocalizationUrl(localizationUrl);
      try {
        const response = await fetch(url);
        const result = await response.json();

        this.setState({
          texts: {
            ...result,
            localizationPopup: {
              ...result.localizationPopup,
              locations:
                result.localizationPopup.locations?.map(
                  (x: { label: string; value: string }) => ({
                    name: x.label,
                    value: (x.value || "").toUpperCase(),
                  })
                ) || [],
              languages:
                result.localizationPopup.languages?.map(
                  (x: { label: string; value: string }) => ({
                    name: x.label,
                    value: x.value || "",
                  })
                ) || [],
            },
          },
        });
      } catch {
        this.setState({ texts: LOCALIZATION_ORGANISM_FALLBACK_TEXT });
      }
    }
  }

  private handleLocationChanged = (selectedLocation: string) => {
    this.setState({
      selectedLocation,
    });
  };

  private handleLanguageChanged = (selectedLanguage: string) => {
    this.setState({
      selectedLanguage,
    });
  };

  private handleSaveClick = () => {
    // update the cookie and close the dialog
    LocalizationUtils.setUserLocalizationPreference(
      this.state.selectedLanguage,
      this.state.selectedLocation
    );
    this.setState({ isDialogOpen: false });

    // and tell other components that the language has changed
    this.props.onLocalizationOptionsSaved?.(
      this.state.selectedLanguage,
      this.state.selectedLocation
    );
  };

  private closePopup = () => {
    const localizationInfo = LocalizationUtils.getUserLocalizationPreference();
    this.setState({
      // reset the preferences back to what's in the cookie
      selectedLanguage: localizationInfo.language,
      selectedLocation: localizationInfo.location,
      isDialogOpen: false,
    });
  };

  private onLocalizationButtonClick = () => {
    this.setState({ isDialogOpen: true });
  };
}
