import { useState, ChangeEvent } from "react";
import { FieldNames } from "../constants";

export type FormData = Record<string, string | undefined>;

export type SetField = (
  field: FieldNames | ChangeEvent<HTMLInputElement>,
  value?: string
) => void;

const useBillingFormValues = () => {
  const [formValues, setFormValues] = useState<FormData>({});

  const setFieldValue: SetField = (field, value) => {
    if (typeof field === "string") {
      setFormValues((values) => ({ ...values, [field]: value }));
      return;
    }

    setFormValues((values) => ({
      ...values,
      [field.target.name]: field.target.value,
    }));
  };

  const setMultipleFields = (nextValues: FormData) => {
    setFormValues((values) => ({
      ...values,
      ...nextValues,
    }));
  };

  return {
    formValues,
    setFieldValue,
    setMultipleFields,
  };
};

export default useBillingFormValues;
