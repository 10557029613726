import { AxiosResponse } from 'axios';

const fetchWithFallback = async <T extends object>(
  fetch: (...args: any) => Promise<AxiosResponse<T>>,
  fallBackData: T,
  throwOnError: boolean = false
) => {
  let result = {} as AxiosResponse<T>;

  try {
    result = (await fetch()) || {};
  } catch (e) {
    if (throwOnError) {
      throw e;
    }
  } finally {
    result.data = {
      ...fallBackData,
      ...result.data,
    };
  }

  return result;
};

export default fetchWithFallback;
