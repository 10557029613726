export interface ITrackableChange {
  isUnsaved(): boolean;
  unsavedChangesMessage(): string;
}

export interface ITrackableChangeProvider {
  getTrackableChange(): ITrackableChange;
}

export class ChangeTrackingRegistryService {
  private changeProviders = new Set<ITrackableChangeProvider>();

  public register(item: ITrackableChangeProvider) {
    this.changeProviders.add(item);
  }

  public unregister(item: ITrackableChangeProvider) {
    this.changeProviders.delete(item);
  }

  public getUnsavedChange = () =>
    Array.from(this.changeProviders)
      .map((provider) => provider.getTrackableChange())
      .find((change) => change.isUnsaved());
}

export default new ChangeTrackingRegistryService();
