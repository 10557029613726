import { Reducer } from 'redux';

import { ActionType } from 'store/types';

import { IPageState, PageActionTypes } from './page.types';

export const initialState: IPageState = {
  key: null,
  data: null,
};

const reducer: Reducer<IPageState, PageActionTypes> = (state: IPageState = initialState, action: PageActionTypes) => {
  switch (action.type) {
    case ActionType.SetPageData:
      return {
        ...state,
        key: action.payload.key,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
