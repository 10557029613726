export const RECURLY_JS_URL = "https://js.recurly.com/v4/recurly.js";
export const FETCH_BILLING_TRANSLATIONS = "FETCH_BILLING_TRANSLATIONS";
export const DIRECT_DEBIT_PING_TIMEOUT = 1000 * 60 * 10; // 10 min
export const COUNTRIES_WITH_STATE_PROVINCE = ["CA", "US"];
export const DEFAULT_DIRECT_DEBIT_COUNTRY = "GB";
export const DISABILITY_DISCOUNT_CODE_ACC_LEVEL = "Discount 50%";
export const MAX_COUPONS_ALLOWED_WITH_ACC_LEVEL = 2;
export const USER_ACCOUNT_APP_ROOT = "/myhome";
export const JOINING_PAGES_ROOT = "/joining";

export enum FieldNames {
  firstName = "first_name",
  lastName = "last_name",
  address1 = "address1",
  address2 = "address2",
  city = "city",
  postalCode = "postal_code",
  country = "country",
  state = "state",
  phone = "phone",
  number = "number",
  month = "month",
  year = "year",
  date = "date",
  cvv = "cvv",
  accountName = "name_on_account",
  accountNumber = "account_number",
  accountNumberConfirmation = "account_number_confirmation",
  accountType = "type",
  sortCode = "sort_code",
  iban = "iban",
}

export const securityTextCommonStyles =
  "text-[14px] leading-[17px] md:text-base md:leading-[21px]";

export enum PlanType {
  Card = "Card",
  DirectDebit = "DirectDebit",
  Sepa = "Sepa",
}

export const customDefaultText: Record<string, string> = {
  benefitsLink: "https://www.spotlight.com/join-us/performers/",
  premiumCardButton: "common:button.label.choose.premium",
  premiumSelectedCardButton: "common:button.label.selected",
  classicCardButton: "common:button.label.choose.classic",
  classicSelectedCardButton: "common:button.label.selected",
};

export const directDebitFields = [
  FieldNames.accountName,
  FieldNames.accountNumber,
  FieldNames.accountNumberConfirmation,
  FieldNames.sortCode,
];

export const directDebitInitialValues = directDebitFields.reduce(
  (acc, key) => ({ ...acc, [key]: "" }),
  {}
);

export enum AddOnCodes {
  AnnualBundle = "anu_add_on_bundle",
  MonthlyBundle = "mth_add_on_bundle",
  FilmMakersAnnual = "fm_add_on_anu",
  FilmMakersMonthly = "fm_add_on_mth",
}

export const addOnNamesMap = {
  [AddOnCodes.AnnualBundle]: "common:billing.planSelector.addOns.ecp.title",
  [AddOnCodes.MonthlyBundle]: "common:billing.planSelector.addOns.ecp.title",
  [AddOnCodes.FilmMakersAnnual]: "common:billing.planSelector.addOns.fm.title",
  [AddOnCodes.FilmMakersMonthly]: "common:billing.planSelector.addOns.fm.title",
};

export const addOnDescriptionMap = {
  [AddOnCodes.AnnualBundle]:
    "common:billing.planSelector.addOns.ecp.description",
  [AddOnCodes.MonthlyBundle]:
    "common:billing.planSelector.addOns.ecp.description",
  [AddOnCodes.FilmMakersAnnual]:
    "common:billing.planSelector.addOns.fm.description",
  [AddOnCodes.FilmMakersMonthly]:
    "common:billing.planSelector.addOns.fm.description",
};

const FAQ_DESCRIPTION_LINK = "https://www.spotlight.com/help-and-faqs/";
const ECP_MORE_INFO_LINK =
  "https://www.spotlight.com/news-and-advice/spotlight-launches-extra-cover-pack/";
const FM_MORE_INFO_LINK =
  "https://www.spotlight.com/news-and-advice/filmmakers-details/";

export const addOnDescriptionLinkMap = {
  [AddOnCodes.AnnualBundle]: FAQ_DESCRIPTION_LINK,
  [AddOnCodes.MonthlyBundle]: FAQ_DESCRIPTION_LINK,
  [AddOnCodes.FilmMakersAnnual]: "",
  [AddOnCodes.FilmMakersMonthly]: "",
};

export const addOnMoreInfoLinkMap = {
  [AddOnCodes.AnnualBundle]: ECP_MORE_INFO_LINK,
  [AddOnCodes.MonthlyBundle]: ECP_MORE_INFO_LINK,
  [AddOnCodes.FilmMakersAnnual]: FM_MORE_INFO_LINK,
  [AddOnCodes.FilmMakersMonthly]: FM_MORE_INFO_LINK,
};
