import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "i18n";
import { AlertMessage, PlanFilter, Popup } from "component-library";
import { cancelSubscription } from "services/api/BillingApi/BillingApi";
import { useHistory } from "react-router-dom";
import applicationMap from "services/applicationMap";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";

interface Props {
  isOpen: boolean;
  isEntitledSubscriber: boolean;
  setSubscriptionSuccessCancelled: Dispatch<SetStateAction<boolean>>;
  closePopUp: () => void;
}

const CancellationModal: FC<Props> = ({
  isOpen,
  isEntitledSubscriber,
  setSubscriptionSuccessCancelled,
  closePopUp,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitCancelSubscription = async () => {
    setIsLoading(true);
    const success = await cancelSubscription();
    if (success) {
      setSubscriptionSuccessCancelled(true);
      pushToDataLayer({
        event: TRACKING_EVENT_TYPE.CancelSubscription,
      });
    }
    setIsLoading(false);
  };

  return isOpen ? (
    <Popup
      width={{ lg: 5, md: 6 }}
      texts={{ closePopup: t("common:button.label.cancel") }}
      close={closePopUp}
      className="z-50"
    >
      <AlertMessage
        icon="notice"
        texts={{
          title: t(
            "performer:accountSettings.mySubscriptionPage.cancelModal.title"
          ),
          description: isEntitledSubscriber
            ? t(
                "performer:accountSettings.mySubscriptionPage.cancelModal.premiumDescr"
              )
            : t(
                "performer:accountSettings.mySubscriptionPage.cancelModal.classicDescr"
              ),
        }}
        buttons={
          isEntitledSubscriber
            ? [
                {
                  name: t("common:button.label.moveToClassic"),
                  click: () =>
                    history.push(
                      `${applicationMap.routes.settingsMySubscriptionChange()}?planType=${
                        PlanFilter.Classic
                      }`
                    ),
                  type: "primary",
                },
                {
                  name: t("common:button.label.continueToCancel"),
                  click: onSubmitCancelSubscription,
                  type: "secondary",
                  ctaSecondaryDisabled: isLoading,
                },
              ]
            : [
                {
                  name: t("common:button.label.cancelSubscription"),
                  click: onSubmitCancelSubscription,
                  type: "primary",
                  ctaDisabled: isLoading,
                },
              ]
        }
      />
    </Popup>
  ) : null;
};

export default CancellationModal;
