import { IBillingCommon } from "./common";
import { IBillingUser } from "./user";

export enum BillingUser {
  Common = "common",
  Casting = "casting",
  Performer = "performer",
  Graduate = "graduate",
  YoungPerformer = "young-performer",
  JoiningCasting = "joining-casting",
  JoiningPerformer = "joining-performer",
  JoiningYoungPerformer = "joining-young-performer",
  JoiningGraduate = "joining-graduate",
}

export const billingUserMap = {
  [BillingUser.Common]: "common",
  [BillingUser.Casting]: "Casting",
  [BillingUser.Performer]: "Performer",
  [BillingUser.Graduate]: "Graduate",
  [BillingUser.YoungPerformer]: "Young Performer",
  [BillingUser.JoiningCasting]: "Casting",
  [BillingUser.JoiningPerformer]: "Performer",
  [BillingUser.JoiningYoungPerformer]: "Young Performer",
  [BillingUser.JoiningGraduate]: "Graduate",
};

export interface IBillingFallbacks {
  [BillingUser.Common]: IBillingCommon;
  [BillingUser.Casting]: IBillingUser;
  [BillingUser.Performer]: IBillingUser;
  [BillingUser.Graduate]: IBillingUser;
  [BillingUser.YoungPerformer]: IBillingUser;
  [BillingUser.JoiningCasting]: IBillingUser;
  [BillingUser.JoiningPerformer]: IBillingUser;
  [BillingUser.JoiningYoungPerformer]: IBillingUser;
  [BillingUser.JoiningGraduate]: IBillingUser;
}
