import React from "react";

import { initScrollManager } from "../ScrollManager/ScrollManager";
import {
  ERROR,
  INVALID_INPUT_SELECTOR_PREFIX,
} from "../../Atoms/InputsCommon/InputWrapper";

export interface IScrollToErrorOptions {
  overlappingElementSelector: string;
}

const INPUT_ERROR_SELECTOR = `.${INVALID_INPUT_SELECTOR_PREFIX}${ERROR}`;

type ScrollToError = (options?: IScrollToErrorOptions) => void;

export interface IWithScrollingToErrorProps {
  scrollToError: ScrollToError;
}

type HocProps<TProps extends object> = Pick<
  TProps,
  Exclude<keyof TProps, keyof IWithScrollingToErrorProps>
>;

export default function withScrollingToErrorFactory(
  containerId: string,
  customSelectors?: string[]
) {
  return function withScrollingToError<TProps extends {}>(
    WrappedComponent: React.ComponentType<TProps>
  ) {
    return class extends React.Component<HocProps<TProps>> {
      public scrollManager = initScrollManager(containerId);

      public render() {
        return (
          <WrappedComponent
            {...(this.props as TProps)}
            scrollToError={this.scrollToError}
          />
        );
      }

      public scrollToError: ScrollToError = (options) => {
        const compensation = options?.overlappingElementSelector
          ? document
              .querySelector(options?.overlappingElementSelector)
              ?.getBoundingClientRect().height
          : 0;

        const selector = customSelectors
          ? [INPUT_ERROR_SELECTOR, ...customSelectors].join(", ")
          : INPUT_ERROR_SELECTOR;
        const errorElement = (
          document.getElementById(containerId) || document
        ).querySelector(selector);

        if (errorElement) {
          this.scrollManager.scrollToElement(errorElement, compensation);
        }
      };
    };
  };
}
