import { FieldNames } from "../constants";
import _curry from "lodash/curry";
import { IBillingCommon } from "translations/src/models/billing/common";
import { buildErrorMsg } from "../../../../index";

const dateFields = [FieldNames.month, FieldNames.year];

const getInvalidCardFields = (
  fields: string[],
  form: IBillingCommon["paymentDetailForm"] &
    IBillingCommon["directDebitForm"] &
    Pick<IBillingCommon["billingForm"], "zipLabel">,
  validation: IBillingCommon["validation"]
): Record<string, string> => {
  const fieldLabels: Record<string, string> = {
    [FieldNames.number]: form.cardDetailTitle,
    [FieldNames.cvv]: form.cvvTitle,
    [FieldNames.postalCode]: form.zipLabel,
    [FieldNames.accountName]: form.accountName,
    [FieldNames.accountNumber]: form.accountNumber,
    [FieldNames.accountNumberConfirmation]: form.accountNumberConfirmation,
    [FieldNames.sortCode]: form.sortCode,
  };
  const getReqError = _curry(buildErrorMsg)(validation.invalidField);

  return fields.reduce((acc, filed: string) => {
    if (dateFields.includes(filed as FieldNames))
      return { ...acc, [FieldNames.date]: getReqError(form.expDateTitle) };

    return { ...acc, [filed]: getReqError(fieldLabels[filed]) };
  }, {});
};

export default getInvalidCardFields;
