import React from "react";
import cx from "classnames";

import {
  Autocomplete,
  IAutocompleteItem,
} from "../../../Atoms/Autocomplete/Autocomplete";
import { InputCleaner } from "../../../Atoms/InputsCommon/InputCleaner";
import PerformerType from "../../../../constants/PerformerType";
import { openExternalLinkInNewTab } from "../../../../generics/navigation";
import { debounce } from "../../../../generics/debounce";

import "./PerformerSearch.scss";

const MAXIMUM_RESULTS = 10;

export interface IPerformerSearchResult {
  url: string;
  name: string;
  surname: string;
  type: PerformerType;
}

export interface IPerformerTypesTranslation {
  youngPerformerLabel: string;
  graduateLabel: string;
  performerLabel: string;
}

export interface IPerformerSearchContent {
  inputPlaceholder: string;
  noResultsFound: string;
  showMoreResults: string;
  performerTypes: IPerformerTypesTranslation;
}

export interface IPerformerSearchProps {
  content: IPerformerSearchContent;
  loadSearchResults: (searchTerm: string) => Promise<IPerformerSearchResult[]>;
  showMoreResults: (searchTerm: string) => void;
}

export interface IPerformerSearchState {
  performerName: string;
  autocompleteList: IAutocompleteItem[];
  resultsCount: number;
  isAutocompleteVisible: boolean;
  isSearchBarHidden: boolean;
}

const INITIAL_STATE = {
  performerName: "",
  autocompleteList: [],
  resultsCount: 0,
  isAutocompleteVisible: false,
};

export default class PerformerSearch extends React.Component<
  IPerformerSearchProps,
  IPerformerSearchState
> {
  public readonly state: Readonly<IPerformerSearchState> = {
    ...INITIAL_STATE,
    isSearchBarHidden: true,
  };

  public render() {
    return (
      <div className="c-performer-search">
        {this.renderSearchToggleButton()}
        {this.renderSearchForm()}
      </div>
    );
  }

  private renderSearchToggleButton = () => (
    <div
      tabIndex={0}
      className="c-performer-search__toggle-button"
      onClick={this.toggleSearchBar}
      onKeyPress={this.toggleSearchBarOnKeyPress}
    >
      <div className="c-performer-search__toggle-button-icon icon-magnify" />
    </div>
  );

  private renderSearchForm = () => {
    const { content } = this.props;
    const {
      performerName,
      autocompleteList,
      isAutocompleteVisible,
      isSearchBarHidden,
    } = this.state;

    const isInputCleanerVisible = performerName.length > 0;
    const searchFormClassNames = cx("c-performer-search__form", {
      "c-performer-search__form--hidden": isSearchBarHidden,
    });
    const inputClassNames = cx("c-performer-search__form-input", {
      "c-performer-search__form-input--active": performerName.length > 0,
    });

    return (
      <div className="c-performer-search__form-container">
        <div className={searchFormClassNames}>
          <div className="c-performer-search__form-input-container">
            <input
              className={inputClassNames}
              placeholder={content.inputPlaceholder}
              value={performerName}
              type="text"
              autoComplete="off"
              onChange={this.changePerformerName}
              onKeyPress={this.showMoreResultsOnKeyPress}
            />
            {isInputCleanerVisible && (
              <InputCleaner onClick={this.cleanSearch} />
            )}
            <div className="c-performer-search__form-autocomplete g-bg-tertiary">
              <Autocomplete
                list={autocompleteList}
                display={isAutocompleteVisible}
                selectItem={this.selectPerformer}
                formatName={this.formatPerformerName}
                onHideAutocomplete={this.hideAutocomplete}
                renderTail={this.renderAutocompleteListTail}
              />
            </div>
          </div>
          <div
            className="c-performer-search__form-icon icon-magnify"
            tabIndex={0}
            onClick={this.showMoreResults}
            onKeyPress={this.showMoreResultsOnKeyPress}
          />
        </div>
      </div>
    );
  };

  private toggleSearchBar = () =>
    this.setState((prevState) => ({
      isSearchBarHidden: !prevState.isSearchBarHidden,
    }));

  private toggleSearchBarOnKeyPress = ({ key }: React.KeyboardEvent) => {
    if (key === "Enter") {
      this.toggleSearchBar();
    }
  };

  private changePerformerName = (event: React.FormEvent<HTMLInputElement>) => {
    const { isAutocompleteVisible: prevAutocompleteVisible } = this.state;
    const performerName = event.currentTarget.value;
    const isAutocompleteVisible =
      prevAutocompleteVisible && performerName.length > 0;

    this.setState(
      { performerName, isAutocompleteVisible },
      this.loadSearchResultsDebounced
    );
  };

  private showMoreResultsOnKeyPress = ({ key }: React.KeyboardEvent) => {
    if (key === "Enter") {
      this.showMoreResults();
    }
  };

  private loadSearchResults = async () => {
    const { performerName } = this.state;

    if (performerName.length > 0) {
      const results = await this.props.loadSearchResults(performerName);

      const autocompleteList: IAutocompleteItem[] = results
        .slice(0, MAXIMUM_RESULTS)
        .map((result) => ({
          name: `${result.name} ${result.surname}`,
          data: result,
        }));

      this.setState({
        autocompleteList,
        resultsCount: results.length,
        isAutocompleteVisible: true,
      });
    }
  };

  private loadSearchResultsDebounced = debounce(this.loadSearchResults, 300);

  private cleanSearch = () => this.setState(INITIAL_STATE);

  private selectPerformer = (item: IAutocompleteItem) =>
    openExternalLinkInNewTab(item.data.url);

  private formatPerformerName = (item: IAutocompleteItem) => {
    const { performerName } = this.state;

    const uppercasedName = item.name.toUpperCase();
    const performerType = this.getPerformerTypeTranslation(item.data);
    const label = uppercasedName.replace(
      performerName.toUpperCase(),
      performerName.toUpperCase().bold()
    );

    return label + `<br> (${performerType})`.small();
  };

  private getPerformerTypeTranslation = (performer: IPerformerSearchResult) => {
    const { performerTypes } = this.props.content;

    let performerType: string = "";

    switch (performer.type) {
      case PerformerType.Graduate:
        performerType = performerTypes.graduateLabel;
        break;
      case PerformerType.Performer:
        performerType = performerTypes.performerLabel;
        break;
      case PerformerType.YoungPerformer:
        performerType = performerTypes.youngPerformerLabel;
        break;
    }

    return performerType;
  };

  private hideAutocomplete = () =>
    this.setState({ isAutocompleteVisible: false });

  private renderAutocompleteListTail = () => {
    const { content } = this.props;
    const { resultsCount, performerName } = this.state;
    let tailComponent: React.ReactNode;

    if (resultsCount === 0) {
      tailComponent = (
        <div className="c-performer-search__not-found">
          {content.noResultsFound}
        </div>
      );
    }

    if (resultsCount > MAXIMUM_RESULTS) {
      const message = content.showMoreResults.replace("{value}", performerName);

      tailComponent = (
        <div className="c-performer-search__show-more">
          <a
            className="c-performer-search__show-more-link g-alternative-link g-alternative-link--lg"
            tabIndex={0}
            onClick={this.showMoreResults}
            onKeyPress={this.showMoreResultsOnKeyPress}
          >
            {message}
          </a>
        </div>
      );
    }

    return tailComponent;
  };

  private showMoreResults = () => {
    if (this.state.performerName.length > 0) {
      this.props.showMoreResults(this.state.performerName);
    }
  };
}
