import React, { FC } from "react";
import { RecurlyProvider, Elements } from "@recurly/react-recurly";
import { FieldNames } from "../../constants";
import { config } from "../../../../../config";

const RecurlyWrapper: FC = ({ children }) => {
  return (
    <RecurlyProvider
      publicKey={config.recurlyToken}
      required={[FieldNames.cvv, FieldNames.postalCode]}
    >
      <Elements>{children}</Elements>
    </RecurlyProvider>
  );
};

export default RecurlyWrapper;
