import axios, { CancelToken } from "axios";
import React from "react";
import {
  AccountType,
  getErrorPopupTexts,
  myAccountLink,
} from "component-library";

import { ErrorCode } from "config/common";
import PageKey from "config/PageKey";

import { IApplicationState } from "store";
import { ITranslationState } from "store/translation/translation.types";
import { SetLoading } from "store/ui/ui.types";

import { IWithRequestCancellation } from "types/axios";

import { IUser } from "models/user";

import getErrorPopupType from "helpers/getErrorPopupType";
import scrollManager from "services/scrollManager";

import GlobalSpinner from "components/GlobalSpinner/GlobalSpinner";
import ErrorPopup from "components/Popups/ErrorPopup/ErrorPopup";

export interface IWithPageDataStoreProps {
  translation: ITranslationState;
  locale: string;
  pageDataKey: PageKey | null;
  user: IUser;
  userType: AccountType | undefined;
}

export interface IWithPageDataActions {
  setLoading: SetLoading;
}

export type WithPageDataCancellableRequest = (
  cancellationToken: CancelToken
) => Promise<void>;

export interface IWithPageDataInjectedProps {
  cancellationToken: CancelToken;
  makeCancellableRequest(
    request: WithPageDataCancellableRequest
  ): Promise<void>;
}

export interface IWithPageDataProps
  extends IWithPageDataStoreProps,
    IWithPageDataActions,
    IWithPageDataInjectedProps {}

interface IWithPageDataState {
  showError: boolean;
  errorCode?: ErrorCode;
}

export interface ILoaders<Props> {
  loadData?(props: Props): Promise<any>;
  loadTranslation?(props: Props, isLocaleChanged: boolean): Promise<any>;
  loadOnLocaleChange?(props: Props): Promise<any>;
}

export default function withPageData<Props>(
  pageKey: PageKey,
  { loadData, loadTranslation, loadOnLocaleChange }: ILoaders<Props>
) {
  return (
    WrappedComponent: React.ComponentType<any>
  ): typeof React.Component => {
    return class
      extends React.Component<IWithPageDataProps & Props, IWithPageDataState>
      implements IWithRequestCancellation
    {
      public readonly state: IWithPageDataState = { showError: false };

      public requestCancellation = axios.CancelToken.source();

      public componentDidMount() {
        void this.loadPageData();
        setTimeout(() => scrollManager.reset());
      }

      public async componentDidUpdate(prevProps: IWithPageDataProps) {
        if (!this.isDataLoading()) {
          scrollManager.restore();
        }

        if (this.props.locale !== prevProps.locale) {
          try {
            await Promise.all([
              this.loadTranslation(true),
              loadOnLocaleChange?.(this.props),
            ]);
          } catch (e) {
            if (axios.isCancel(e)) {
              return;
            }

            this.setState({
              showError: true,
            });
          }
        }
      }

      public componentWillUnmount() {
        this.requestCancellation.cancel?.();
      }

      public render() {
        if (this.state.showError) {
          return this.renderErrorPopup();
        }

        if (this.isDataLoading()) {
          return <GlobalSpinner isInner={true} />;
        }

        return (
          <WrappedComponent
            {...this.props}
            cancellationToken={this.requestCancellation.token}
            makeCancellableRequest={this.makeCancellableRequest}
          />
        );
      }

      public renderErrorPopup() {
        return (
          <ErrorPopup
            {...getErrorPopupTexts()[getErrorPopupType(this.state.errorCode)]}
            handleButtonClick={this.handlePopupClick}
            handleCloseClick={this.handlePopupClick}
            hidePopup={this.handlePopupClick}
          />
        );
      }

      private handlePopupClick = () => {
        switch (this.state.errorCode) {
          case ErrorCode.Forbidden:
            window.location.assign(myAccountLink);
            break;
          default:
            window.location.reload();
        }
      };

      private async loadPageData() {
        try {
          await Promise.all([this.loadData(), this.loadTranslation()]);

          this.props.setLoading(false);
        } catch (e) {
          console.error(e);
          this.setState({
            showError: true,
            //@ts-ignore
            errorCode: e.response?.status,
          });
        }
      }

      private async loadData() {
        return loadData?.({
          ...this.props,
          cancellationToken: this.requestCancellation.token,
        });
      }

      private async loadTranslation(isLocaleChanged: boolean = false) {
        return loadTranslation?.(this.props, isLocaleChanged);
      }

      private isDataLoading() {
        return (
          (loadTranslation && !this.props.translation.pages[pageKey]) ||
          (loadData && this.props.pageDataKey !== pageKey)
        );
      }

      private makeCancellableRequest = async (
        request: WithPageDataCancellableRequest
      ) => {
        this.requestCancellation.cancel?.();
        this.requestCancellation = axios.CancelToken.source();

        try {
          await request(this.requestCancellation.token);
        } catch (e) {
          if (axios.isCancel(e)) {
            return;
          }

          throw e;
        }
      };
    };
  };
}

export const mapStateToWithPageDataProps = (
  state: IApplicationState
): IWithPageDataStoreProps => {
  return {
    locale: state.ui.locale,
    translation: state.translation,
    pageDataKey: state.page.key,
    user: state.user.data!,
    userType: state.user.data?.type,
  };
};
