export enum PerformerType {
  YoungPerformer = "YoungPerformer",
  Graduate = "Graduate",
  Performer = "Performer",
}

export interface IPerformer {
  id: string;
  artistRef: string;
  forename: string;
  surname: string;
  performerType: PerformerType;
  publicationCode: number;
  viewPin: string;
}

export interface IPerformersList {
  profiles: IPerformer[];
  total: number;
}

export interface IPerformerTypesContent {
  youngPerformerLabel: string;
  graduateLabel: string;
  performerLabel: string;
}
