import {
  getCookie,
  setCookie,
  getCookieDomain,
  ExpiryTimeUnits,
} from "shared-utils";
import { SPOTLIGHT_PORTAL_ID_COOKIE } from "../services/authorization/constants";

export function checkAndSetPortalCookie(portalId: string): void {
  const portalCookie = getCookie(SPOTLIGHT_PORTAL_ID_COOKIE);

  if (portalCookie === portalId) {
    return;
  }

  setCookie(SPOTLIGHT_PORTAL_ID_COOKIE, portalId, {
    expiryTime: 1,
    expiryTimeUnit: ExpiryTimeUnits.DAY,
    domain: getCookieDomain(),
    options: {
      Secure: true,
      SameSite: "None",
    },
  });
}
