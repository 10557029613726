import { i18n } from "i18n";

export const myAccountLink = "/myhome/";

export function getHeaderLabels() {
  return {
    signIn: i18n.t("common:nav.signin"),
    signOut: i18n.t("common:nav.signout"),
    myAccount: i18n.t("common:nav.links.home"),
    logoLabel: i18n.t("common:nav.spotlightLogoAlt"),
    flagLabel: i18n.t("common:nav.changeLanguage"),
    burger: {
      labelOpen: i18n.t("common:nav.openHamburgerMenu"),
      labelClose: i18n.t("common:nav.closeHamburgerMenu"),
      name: i18n.t("common:nav.menu"),
    },
    myAccountLink,
  };
}
