import React, { useRef, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useTranslation } from "i18n";
import cx from "classnames";
import { getYear } from "date-fns";
import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import {
  ActionButton,
  CheckBoxFormik,
  CommonErrorPopup,
  DatePickerFormik,
  Helper,
  Page,
  RecordWithActionControls,
  StepTracker,
  StickyCTABar,
  TextInputFormik,
} from "component-library";
import { useStepTracking } from "shared-hooks";
import UserAccountService from "services/api/UserAccount/UserAccountService";
import Layout from "app-shell/Layout/Layout";
import {
  FieldNames,
  graduatesTransferSteps,
  GET_AGENTS_QUERY_KEY,
  SUBMIT_TRANSFER_QUERY_KEY,
  VALIDATE_SCHOOL_QUERY_KEY,
  submitTransferFormInitialValues,
  validateSchoolFormInitialValues,
  SubmitTransferFormValues,
  getSchoolPasswordTooltip,
  SELF_REPRESENTED_AGENT_REF,
  getGraduateStepTrackingItems,
} from "./constants";
import { transferProfileValidationSchema } from "./validationSchema";
import { AxiosError } from "axios";
import applicationMap, { CheckoutType } from "services/applicationMap";
import { ISubmitTransferResponse } from "models/page";
import { constructQueryString } from "shared-services";

const COURSE_START_MIN_YEAR = "2018";
const COURSE_END_MAX_YEAR = "2050";

const TransferProfilePage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const [schoolPassword, setSchoolPassword] = useState(""); // need to keep track of this separately, as Formik is unmounted after school is validated & is needed in payload for network call
  const mainFormRef = useRef<FormikProps<SubmitTransferFormValues> | null>(
    null
  );
  const passwordFormRef = useRef<FormikProps<
    typeof validateSchoolFormInitialValues
  > | null>(null);
  const stepNumber = graduatesTransferSteps.indexOf(pathname);

  const {
    mutate: validateSchool,
    reset,
    data: schoolData,
    isLoading: schoolDataIsLoading,
  } = useMutation({
    mutationKey: VALIDATE_SCHOOL_QUERY_KEY,
    mutationFn: (schoolCode: string) =>
      UserAccountService.validateSchool(schoolCode),
    onError: () => {
      if (passwordFormRef.current) {
        passwordFormRef.current.setFieldError(
          FieldNames.SchoolPassword,
          t("performer:graduateTransfer.information.schoolCode.error")
        );
      }
    },
  });

  const {
    mutate: submitTransfer,
    isLoading: submitTransferIsLoading,
    error: submitTransferError,
  } = useMutation({
    mutationKey: SUBMIT_TRANSFER_QUERY_KEY,
    mutationFn: (values: any) => UserAccountService.submitTransfer(values),
    onSuccess: (data: ISubmitTransferResponse) => {
      return history.push(
        applicationMap.routes.checkout({
          type: CheckoutType.graduate,
        }) + constructQueryString({ applicationId: data.applicationId })
      );
    },
  });

  const { data: agentsData, error: agentsDataError } = useQuery({
    queryKey: GET_AGENTS_QUERY_KEY,
    queryFn: () => UserAccountService.getAgents(),
  });
  const filteredAgentsData =
    agentsData?.agents.filter(
      (agent) => agent.refNumber !== SELF_REPRESENTED_AGENT_REF
    ) || [];

  const handleFindSchool = (values: typeof validateSchoolFormInitialValues) => {
    const password = values[FieldNames.SchoolPassword];
    setSchoolPassword(password);
    validateSchool(password);
  };

  const handleSubmitTransfer = (values: SubmitTransferFormValues) => {
    if (mainFormRef.current) {
      const trainingFromYear = getYear(values[FieldNames.CourseStartDate] || 0);
      const trainingToYear = getYear(values[FieldNames.CourseEndDate] || 0);
      const payloadValues = {
        ...values,
        trainingFromYear,
        trainingToYear,
        schoolPassword,
      };
      try {
        submitTransfer(payloadValues);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const steps = useStepTracking({
    stepTrackingItems: getGraduateStepTrackingItems(t),
    stepNumber,
  });

  if (submitTransferError || agentsDataError) {
    const error = submitTransferError || agentsDataError;
    return (
      <CommonErrorPopup
        errorStatusCode={(error as AxiosError)?.response?.status}
      />
    );
  }

  return (
    <Layout>
      <Page background="primary">
        <Page.Content className="p-2.5 max-w-[1280px]">
          <StepTracker items={steps} />
          <div className="max-w-3xl">
            <h1 className="mb-5">
              {t("performer:graduateTransfer.information.page.title")}
            </h1>
            <h3 className="mb-5">
              {t("performer:graduateTransfer.information.training.subtitle")}
            </h3>
            {schoolData ? (
              <RecordWithActionControls
                className="mb-5 border-2 border-dashed rounded bg-grey-whisper font-semi-bold"
                title={schoolData.school}
                controls={[
                  <ActionButton
                    key={schoolData.school}
                    icon="delete"
                    onClick={() => {
                      reset();
                      setSchoolPassword("");
                    }}
                    size="xs"
                  />,
                ]}
              />
            ) : (
              <Formik<typeof validateSchoolFormInitialValues>
                initialValues={validateSchoolFormInitialValues}
                onSubmit={(values) => handleFindSchool(values)}
                innerRef={passwordFormRef}
              >
                {({ submitForm, values }) => (
                  <Form>
                    <TextInputFormik
                      name={FieldNames.SchoolPassword}
                      label={t(
                        "performer:graduateTransfer.information.training.title"
                      )}
                      placeholder={t("common:placeholder.schoolCode")}
                      submitButton={{
                        label: t("common:button.label.submit"),
                        onClick: submitForm,
                        disabled:
                          schoolDataIsLoading ||
                          !values[FieldNames.SchoolPassword],
                      }}
                      annex={<Helper tooltip={getSchoolPasswordTooltip(t)} />}
                    />
                  </Form>
                )}
              </Formik>
            )}
            <Formik<SubmitTransferFormValues>
              initialValues={submitTransferFormInitialValues}
              onSubmit={handleSubmitTransfer}
              innerRef={mainFormRef}
              validationSchema={transferProfileValidationSchema(t)}
            >
              {({ values }) => {
                return (
                  <Form>
                    <TextInputFormik
                      name={FieldNames.Training}
                      label={t("common:label.courseColon")}
                      placeholder={t("common:placeholder.course")}
                      maxLength={75}
                    />
                    <div className="flex flex-col mb-6 md:gap-5 md:flex-row">
                      <DatePickerFormik
                        name={FieldNames.CourseStartDate}
                        label={t("common:label.courseDatesStart")}
                        type="yearPicker"
                        dateFormat="yyyy"
                        className="flex-1"
                        minDate={new Date(COURSE_START_MIN_YEAR)}
                        maxDate={
                          values[FieldNames.CourseEndDate]
                            ? new Date(values[FieldNames.CourseEndDate])
                            : undefined
                        }
                      />
                      <DatePickerFormik
                        name={FieldNames.CourseEndDate}
                        label={t("common:label.courseDatesEnd")}
                        type="yearPicker"
                        dateFormat="yyyy"
                        className="flex-1"
                        minDate={
                          values[FieldNames.CourseStartDate]
                            ? new Date(values[FieldNames.CourseStartDate])
                            : undefined
                        }
                        maxDate={new Date(COURSE_END_MAX_YEAR)}
                      />
                    </div>
                    {filteredAgentsData?.length > 0 && !agentsDataError && (
                      <div className="mb-5">
                        <hr className="mb-5" />
                        <h3 className="mb-5">
                          {t(
                            "performer:graduateTransfer.information.agents.title"
                          )}
                        </h3>
                        <p className="mb-5">
                          {t(
                            "performer:graduateTransfer.information.agents.description"
                          )}
                        </p>
                        {filteredAgentsData.map((agent) => (
                          <CheckBoxFormik
                            name={FieldNames.Agents}
                            key={agent.refNumber}
                            value={agent.refNumber.toString()}
                            label={agent.name}
                            className={cx("mb-2", {
                              "font-semibold": values.agents.includes(
                                agent.refNumber.toString()
                              ),
                            })}
                          />
                        ))}
                        <p>
                          {t(
                            "performer:graduateTransfer.information.agents.disclaimer"
                          )}
                        </p>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Page.Content>
        <StickyCTABar
          prev={{
            text: t("common:button.label.cancelAndExit"),
            onClick: () =>
              history.push(applicationMap.routes.settingsMySubscription()),
          }}
          next={{
            text: t("common:button.label.continue"),
            onClick: () => mainFormRef.current?.submitForm?.(),
            disabled: !schoolData || submitTransferIsLoading,
            loading: submitTransferIsLoading,
          }}
          isSticky
        />
      </Page>
    </Layout>
  );
};

export default TransferProfilePage;
