import { BillingInfo } from "@recurly/recurly-js/lib/bank-account";
import { PaymentMethodType } from "../types";

const generateBillingInfo = (formValues: any, isSepa: boolean): BillingInfo => {
  // If this is a SEPA plan, we need to remove the account_number field
  const { account_number, ...otherFormValues } = formValues;

  return {
    ...(isSepa
      ? otherFormValues
      : {
          ...otherFormValues,
          account_number,
          type: PaymentMethodType.Bacs,
        }),
  };
};

export default generateBillingInfo;
