import { ActionType } from "store/types";
import { SetUserData, SetUserType } from "store/user/user.types";

// ============================== Redux Actions =======================================================================

export const setUserData: SetUserData = (userData) => ({
  type: ActionType.SetUserData,
  payload: userData,
});

export const setUserType: SetUserType = (userType) => ({
  type: ActionType.SetUserType,
  payload: userType,
});
