import React, { FC } from "react";
import { IBillingCommon } from "translations/src/models/billing/common";
import { ReactComponent as LockIcon } from "component-library/src/images/svg/icons/lock.svg";
import { directDebitFields, FieldNames } from "../../../constants";
import {
  getTootlipProps,
  QuestionTooltip,
} from "../../../../../Molecules/QuestionTooltip";
import BillingInput from "../../CardElements/BillingInput";
import { BillingAndPaymentFormProps } from "../../../types";
import useScrollBillingField from "../../../hooks/useScrollBillingField";

type Props = {
  firstError: string;
  directDebitForm: IBillingCommon["directDebitForm"];
} & Pick<
  BillingAndPaymentFormProps,
  | "formValues"
  | "setFieldValue"
  | "isSubmitted"
  | "formErrors"
  | "tootlipContainerConst"
  | "removeInvalidCardError"
>;

const SepaForm: FC<Props> = ({
  firstError,
  formValues,
  isSubmitted,
  formErrors,
  setFieldValue,
  directDebitForm,
  tootlipContainerConst,
  removeInvalidCardError,
}) => {
  const shouldScroll = directDebitFields.includes(firstError as FieldNames);
  const ref = useScrollBillingField(shouldScroll);

  return (
    <div className="relative">
      <BillingInput
        label={directDebitForm.accountName}
        name={FieldNames.accountName}
        value={formValues[FieldNames.accountName]}
        dataRecurly={FieldNames.accountName}
        setFieldValue={setFieldValue}
        validationMessage={formErrors[FieldNames.accountName]}
        isSubmitted={isSubmitted}
        annex={
          <QuestionTooltip
            tooltipProps={{
              ...getTootlipProps(
                directDebitForm.accountNameTooltipHeader,
                directDebitForm.accountNameTooltipBody
              ),
              overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
            }}
          />
        }
      />
      <BillingInput
        label={directDebitForm.ibanLabel}
        name={FieldNames.iban}
        value={formValues[FieldNames.iban]}
        dataRecurly={FieldNames.iban}
        setFieldValue={(e) => {
          setFieldValue(e);
          if (isSubmitted && formErrors[FieldNames.iban])
            removeInvalidCardError(FieldNames.iban);
        }}
        validationMessage={formErrors[FieldNames.iban]}
        isSubmitted={isSubmitted}
        annex={
          <QuestionTooltip
            tooltipProps={{
              ...getTootlipProps(
                directDebitForm.ibanTooltipHeader,
                directDebitForm.ibanTooltipBody
              ),
              overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
            }}
          />
        }
        wrapperClassName="flex-1"
        className="lock-input"
      >
        <LockIcon className="lock-icon" />
      </BillingInput>
      <div className="absolute bottom-[-150px]" ref={ref} />
    </div>
  );
};

export default SepaForm;
