import React from "react";
import { v4 as uuid } from "uuid";
import cx from "classnames";
import { dataTestIds } from "data-testids";

interface ILabelValueRowProps {
  label: string;
  value?: (string | Date | JSX.Element) | JSX.Element[];
  labelClassNames?: string;
}

const LabelValueRow = ({
  label,
  value,
  labelClassNames = "",
}: ILabelValueRowProps) => {
  const getValue = () => {
    if (Array.isArray(value)) {
      return value.map((v) => <span key={uuid()}>{v}</span>);
    }
    return value;
  };

  return (
    <div className="flex flex-col md:flex-row">
      <span
        className={cx(
          "min-w-[50%] md:min-w-[30%] font-semibold",
          labelClassNames
        )}
      >
        {label}
      </span>
      <div
        className="flex flex-col gap-2 lg:w-1/2"
        data-testid={`${label}${dataTestIds.userAccountApp["LabelValueRow.LabelValueRow"]}`}
      >
        {getValue()}
      </div>
    </div>
  );
};
export default LabelValueRow;
