import {
  IGetMatchingPhotographerNamesResponse,
  IGetProductionTypesResponse,
} from "./profile.types";
import { constructQueryString } from "../helpers/query-string";

export class ProfileService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  getProductionTypes(): Promise<IGetProductionTypesResponse> {
    return fetch(`${this.url}/profiles/productiontypes`).then((res) =>
      res.json()
    );
  }

  getMatchingPhotographerNames(
    namePart: string
  ): Promise<IGetMatchingPhotographerNamesResponse> {
    return fetch(
      `${this.url}/profiles/photographers${constructQueryString({ namePart })}`
    ).then((res) => res.json());
  }
}
