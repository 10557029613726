import React from "react";

/**
 * Used to scroll to an element
 * Returns a ref to place on the element
 * and a function to trigger the scroll
 */
export const useScrollToElement = (scrollConfig?: ScrollIntoViewOptions) => {
  const ref = React.useRef<any>(null);

  const scrollToElement = () => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      ...scrollConfig,
    });
  };

  return { ref, scrollToElement };
};
