export enum FacetType {
  Memberships = "MembershipBodies",
  Gender = "Gender",
  Other = "Other",
  QualificationLevels = "Qualification levels",
  EurozoneCountries = "eurozoneCountries",
  HeritageAndEthnicity = "Heritage And Ethnicity",
  EthnicBackground = "Ethnic Background",
  AgentTypes = "agentTypes",
}

export enum SkillsFacetsType {
  AccentsAndDialects = "Accents & Dialects",
  Languages = "Languages",
  MusicAndDance = "Music & Dance",
  Performance = "Performance",
  Presenting = "Presenting",
  Sports = "Sports",
  VehicleLicences = "Vehicle Licences",
  VoiceOver = "Voice Over",
  OtherSkills = "Other Skills",
}

export enum SkillFacetNames {
  AccentsAndDialects = "accentsAndDialects",
  Languages = "languages",
  MusicAndDance = "musicAndDance",
  Performance = "performances",
  Presenting = "presenting",
  Sports = "sports",
  VehicleLicences = "vehicleLicences",
  VoiceOver = "voiceOver",
  OtherSkills = "otherSkills",
}

export type IGetFacetsResponse = IFacetResponse[];

export interface IFacetLevel {
  id: number;
  maxActiveCount: number;
  name: string;
}

export interface IFacetResponse {
  id: number;
  name: string;
  code: string;
  systemName: string;
  maxActiveCount: number;
  facetGroups: IFacetGroup[];
  facetLevels: IFacetLevel[];
}

export interface IFacetGroup {
  id: number;
  name: string;
  code: string;
  facets: IFacet[];
}

export interface IFacet {
  id: number;
  name: string;
  code: string;
  orderKey: number | null;
}
