import React from "react";
import routes from "config/routes";
import Routes from "app-shell/Routes/Routes";

export class Content extends React.Component {
  public render() {
    return <Routes items={routes} />;
  }
}

export default Content;
