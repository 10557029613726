import { parseUrl, stringifyUrl } from "query-string";
import { PlanFilter } from "component-library";
import { PlanCodePeriod } from "component-library/src/components/Organisms/Billing/types";
import applicationMap, { CheckoutType } from "services/applicationMap";

export function getRenewalLink(
  isAddOnFlagEnabled: boolean,
  {
    planCode,
    isReactivation,
  }: { planCode?: string; isReactivation?: boolean } = {}
) {
  let param;

  if (!isAddOnFlagEnabled) {
    param = PlanFilter.Classic;
  } else {
    param = planCode?.includes(PlanCodePeriod.Annual)
      ? PlanCodePeriod.Annual
      : PlanCodePeriod.Monthly;
  }

  const parsedUrl = parseUrl(
    applicationMap.routes.checkout({
      type: isReactivation ? CheckoutType.reactivation : CheckoutType.renewal,
    })
  );
  if (planCode) {
    parsedUrl.query.preSelectedPlan = param;
  }
  const stringifiedUrl = stringifyUrl(parsedUrl);

  return stringifiedUrl;
}
