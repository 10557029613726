import { IBreadcrumbBarItem } from "component-library";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";
import applicationMap from "services/applicationMap";

export const ANNUAL_PLAN = "annual";
export const GB_COUNTRY_CODE = "GB";

export enum SubscriptionCalculatedStatus {
  Renewing = "renewing",
  Expired = "expired",
  Current = "current",
}

export enum AddOnCalculatedStatus {
  Active = "active",
  Canceled = "canceled",
  Inactive = "inactive",
}

export enum BreadcrumbSection {
  BackToAccount,
  MyAccount,
  AccountSettings,
  MySubscriptions,
}

export const breadcrumbs: IBreadcrumbBarItem[] = [
  {
    text: "performer:accountSettings.breadcrumb.backToHome",
    url: applicationMap.routes.index(),
  },
  {
    text: "performer:accountSettings.breadcrumb.account",
    url: applicationMap.routes.index(),
  },
  {
    text: "performer:accountSettings.pageTitle",
    url: applicationMap.routes.accountSettings(),
  },
  {
    text: "performer:accountSettings.mySubscription.header",
    url: applicationMap.routes.settingsMySubscription(),
  },
];

export const buyAddOnButtonNamesMap = {
  [AddOnCodes.AnnualBundle]: "common:button.label.buyEcp",
  [AddOnCodes.MonthlyBundle]: "common:button.label.buyEcp",
  [AddOnCodes.FilmMakersAnnual]: "common:button.label.buyFm",
  [AddOnCodes.FilmMakersMonthly]: "common:button.label.buyFm",
};
