import React, { FC, ReactNode } from "react";
import cx from "classnames";
import "./styles.scss";

interface Props {
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWrapperClick?: () => void;
  onWrapperKeydown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  className?: string;
  name?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  checked?: boolean;
  value?: any;
  testId?: string;
  label?: ReactNode;
}

const ToggleSwitch: FC<Props> = ({
  onWrapperClick,
  onWrapperKeydown,
  checked,
  value,
  testId,
  name,
  inputRef,
  onCheck,
  className,
  label,
}) => {
  return (
    <div
      className={cx("switch", className)}
      onClick={onWrapperClick}
      onKeyDown={onWrapperKeydown}
    >
      <label htmlFor={name}>
        <input
          ref={inputRef}
          name={name}
          type="checkbox"
          value={value}
          checked={checked}
          data-testid={testId}
          onChange={(e) => onCheck(e)}
        />
        <div className="slider round" />
      </label>
      <div className="pl-[54px] text-sm md:text-base">{label}</div>
    </div>
  );
};

export default ToggleSwitch;
