import React, { useCallback } from "react";
import { useField } from "formik";
import { useFormikScrollToError } from "shared-hooks";
import { ERROR } from "../../InputsCommon/InputWrapper";
import PhoneField from "../../PhoneField";
import { getNumber } from "./getNumberAndCallingCode";
interface IPhoneFieldFormikProps {
  name: string;
  label?: string;
  searchPlaceholderText?: string;
  tooltipComponent?: () => JSX.Element;
  shouldScrollToView?: boolean;
}

/**
 * Initial phone number value to be provided in format: "<country-code> <phone-number>"
 */
export const PhoneFieldFormik: React.FC<IPhoneFieldFormikProps> = ({
  name,
  label,
  searchPlaceholderText,
  tooltipComponent,
  shouldScrollToView = true,
}) => {
  const { fieldRef, containerElementRef } = useFormikScrollToError<
    HTMLInputElement,
    HTMLDivElement
  >(name);
  const [field, meta, helpers] = useField(name);
  const invalid = meta.touched && meta.error ? ERROR : undefined;

  const setTouched = useCallback(
    () => helpers.setTouched(true),
    [helpers.setTouched]
  );

  const { callingCode, number } = getNumber(field.value);

  return (
    <PhoneField
      name={field.name}
      label={label}
      setValue={helpers.setValue}
      setTouched={setTouched}
      searchPlaceholderText={searchPlaceholderText}
      tooltipComponent={tooltipComponent}
      shouldScrollToView={shouldScrollToView}
      initialCountryCode={callingCode}
      initialNumber={number}
      invalid={invalid}
      validationMessage={meta.error}
      fieldRef={fieldRef}
      containerElementRef={containerElementRef}
    />
  );
};
