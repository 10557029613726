import { useQuery } from "react-query";
import { authAxios as axios } from "shared-auth";
import { config } from "../../../../../../../config";
import { IAddOns } from "../../../../types";
import { AddOnCodes } from "../../../../constants";
import { AxiosError } from "axios";

const GET_ADD_ON_PREVIEW = "GET_ADD_ON_PREVIEW";

export function useGetAddOnPreview(addOnCode?: AddOnCodes, isEnabled = true) {
  return useQuery<IAddOns[], AxiosError>(
    [GET_ADD_ON_PREVIEW, addOnCode],
    async () => {
      const { data } = await axios(
        `${config.billingApiUrl}addons/${addOnCode}/preview`
      );

      return data;
    },
    {
      enabled: !!addOnCode && isEnabled,
    }
  );
}
