enum MemberGroups {
  castingDirector = "castingDirector",
  searchOnlyCD = "castingDirector:searchOnlyCastingDirector",
  performer = "performer",
  agent = "agent",
  yPerformer = "performer:youngPerformer",
  graduatePerformer = "performer:graduate",
  anonymous = "anonymous",
}

export default MemberGroups;
