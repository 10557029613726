import React, { FC, SyntheticEvent, useContext, useState } from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import { useDynamicWindowWidthFor } from "shared-hooks";
import {
  AlertMessage,
  BillingContext,
  Popup,
  SCREEN_WIDTH_ENUM,
} from "../../../../../index";
import { Button } from "../../../../Atoms/Button/Button";
import ShowMoreLessButton from "../../../../Atoms/ShowMoreLessButton";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { BillingUser } from "translations/src/models/billing";
import { ISubscription, SelectPlan } from "../../types";
import checkIsDirectDebit from "../../utils/checkIsDirectDebit";
import PaymentSystemIcons from "./PaymentSystemIcons";
import "./styles.scss";

const currencyPrice: Record<string, string> = {
  GBP: "294.00",
  EUR: "345.00",
};

const currencyDiscount: Record<string, string> = {
  GBP: "54",
  EUR: "69",
};

const monthAccumulatedPrice: Record<string, string> = {
  GBP: "342.00",
  EUR: "402.00",
};

interface Props {
  plan: ISubscription;
  isSelected?: boolean;
  isShaded?: boolean;
  onSelect: SelectPlan;
  onShowMorePlanInfoOnMobile: (planId: ISubscription["id"]) => void;
  showMoreCardId: ISubscription["id"];
  isPromo?: boolean;
}

const Subscription: FC<Props> = ({
  plan,
  isSelected = false,
  isShaded = false,
  onSelect,
  onShowMorePlanInfoOnMobile,
  showMoreCardId,
  isPromo,
}) => {
  const { isAnnualPromoEnabled } = useContext(BillingContext);
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const {
    texts: { subscriptionText, common: billingCommonText, directDebit },
  } = useBillingTranslations(BillingUser.Common);
  const [directDebitModalIsOpen, setDirectDebitModal] = useState(false);

  const isShowMoreOnThisCard = showMoreCardId === plan.id;
  const isMobileAndInfoCollapsed = isMobileView && !isShowMoreOnThisCard;
  const isMobileAndInfoExpanded = isMobileView && isShowMoreOnThisCard;
  const isDirectDebit = checkIsDirectDebit(plan.code);

  const isAnnual =
    plan.code.includes("annual") && plan.code.includes("premium");
  const isPromotionAnnualView = isPromo && isAnnualPromoEnabled && isAnnual;
  const isPromotionMonthView = isPromo && isAnnualPromoEnabled && !isAnnual;

  const subscriptionWrapperClass = cx("subscription-wrapper", {
    "subscription-wrapper_selected": isSelected,
    "subscription-wrapper_shaded": !isSelected && isShaded,
  });
  const subscriptionPriceNetClass = cx("subscription__price-net", {
    "subscription__price-net_collapsed": isMobileAndInfoCollapsed,
    "subscription__price-net_expanded": isMobileAndInfoExpanded,
    "h-full flex items-center": isAnnualPromoEnabled,
  });

  const selectPlan = () => onSelect(plan);

  const onCloseDirectDebitModal = (e: SyntheticEvent) => {
    e.stopPropagation();
    setDirectDebitModal(false);
  };

  const onCardClick = () => {
    if (isSelected) return;

    if (isDirectDebit) {
      setDirectDebitModal(true);
      return;
    }

    selectPlan();
  };

  const confirmDirectDebitWarning = (e: SyntheticEvent) => {
    e.stopPropagation();
    selectPlan();
    setDirectDebitModal(false);
  };

  const { name, description, amount, total, currency, currencySymbol } = plan;

  const priceGross = `[ ${amount} + ${subscriptionText.vatPerUser} ]`;

  return (
    <div
      className={subscriptionWrapperClass}
      onClick={onCardClick}
      data-testid={
        dataTestIds.componentLibrary[
          "Organisms.AddOnCheckout.SubscriptionCard.subscriptionWrapper"
        ]
      }
    >
      {isPromotionAnnualView && (
        <div className="absolute flex items-center justify-center w-full h-[46px] bg-purple-rich rounded-t-[13px] text-white font-semibold">
          {currencySymbol}
          {currencyDiscount[currency]} off - For a limited time only!
        </div>
      )}

      <div
        className={cx("subscription", {
          subscription__promo: isAnnualPromoEnabled,
        })}
      >
        <h3 className="subscription__header">{name}</h3>
        <div className="subscription__type">
          {isDirectDebit
            ? directDebit.directDebit
            : subscriptionText.paymentCard}
        </div>
        <div className="subscription__delimiter" />
        <div className={subscriptionPriceNetClass}>
          <div
            className={cx("subscription__price-net_text", {
              "text-center": isAnnualPromoEnabled,
            })}
          >
            {isPromotionAnnualView && (
              <>
                <span className="text-color-neutral-one text-[1.6rem] line-through">
                  {currencySymbol}
                  {currencyPrice[currency]}
                </span>{" "}
              </>
            )}
            {total}
            {isPromotionMonthView && (
              <div className="text-[1rem] leading-5 sm:text-[1.375rem] sm:leading-10 text-color-neutral-one ">
                {currencySymbol}
                {monthAccumulatedPrice[currency]} annually
              </div>
            )}
          </div>
          {isMobileAndInfoCollapsed && (
            <PaymentSystemIcons
              isMobileView={isMobileView}
              planType={plan.planType}
            />
          )}
          {isMobileAndInfoExpanded && (
            <div className="subscription__price-gross_mobile">{priceGross}</div>
          )}
        </div>

        {isMobileView ? (
          <>
            {showMoreCardId === plan.id && (
              <p className="subscription__description--mobile">{description}</p>
            )}
          </>
        ) : (
          <>
            <div className="subscription__price-gross">{priceGross}</div>
            <p className="subscription__description">{description}</p>
          </>
        )}

        {isMobileAndInfoCollapsed && (
          <>
            <ShowMoreLessButton
              handleButtonClick={() => onShowMorePlanInfoOnMobile(plan.id)}
              showMoreBtnText={billingCommonText.showMoreBtnText}
            />
          </>
        )}
        {(isMobileAndInfoExpanded || !isMobileView) && (
          <>
            {!isMobileView && <div className="subscription__delimiter" />}
            <PaymentSystemIcons
              isMobileView={isMobileView}
              planType={plan.planType}
            />
          </>
        )}
      </div>
      <Button
        className="subscription-button"
        data-testid={
          dataTestIds.componentLibrary[
            "Organisms.Billing.AddOnCheckout.SubscriptionCard.subscriptionButton"
          ]
        }
        type={isSelected ? "primary" : "secondary"}
        text={isSelected ? subscriptionText.yourPlan : subscriptionText.select}
        onClick={onCardClick}
      />
      {directDebitModalIsOpen && (
        <Popup
          width={{ lg: 5, md: 5 }}
          close={onCloseDirectDebitModal}
          priority="high"
        >
          <AlertMessage
            icon="warning"
            texts={{
              title: directDebit.authorisation,
              description: directDebit.confirmAuthBannerBody,
            }}
            buttons={[
              {
                name: billingCommonText.confirm,
                type: "primary",
                click: confirmDirectDebitWarning,
              },
              {
                name: billingCommonText.cancel,
                type: "secondary",
                click: onCloseDirectDebitModal,
              },
            ]}
          />
        </Popup>
      )}
    </div>
  );
};

export default Subscription;
