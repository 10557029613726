import React from "react";
import cx from "classnames";

import "./PageHeader.scss";

interface IPageHeaderTopSections {
  /** Title for page header */
  pageTitle?: string | JSX.Element;

  /** Section for adding ActionList for actions */
  actionSection?: JSX.Element | JSX.Element[];
  className?: string;
}

interface IPageHeaderGeneralBottomSection {
  /** Generalized bottom section for adding whatever you need */
  bottomSection?: JSX.Element;
}

interface IPageHeaderDiscreteBottomSection {
  /** Generalized bottom section is mutually exclusive with the descrete sections */
  bottomSection?: never;

  /** Section for adding search component */
  searchSection?: JSX.Element;

  /** Section for adding DropdownInput for sorting */
  sortingSection?: JSX.Element;
}

type IPageHeaderBottomSections =
  | IPageHeaderGeneralBottomSection
  | IPageHeaderDiscreteBottomSection;

export type IPageHeaderProps = IPageHeaderTopSections &
  IPageHeaderBottomSections;

export class PageHeader extends React.Component<IPageHeaderProps> {
  public render() {
    const { pageTitle, className = "" } = this.props;
    return (
      <div
        className={cx(
          "c-page-header",
          "g-bg-tertiary",
          {
            "c-page-header--with-input-section": this.hasInputSection(),
          },
          className
        )}
      >
        <div className="g-content-area">
          {pageTitle && (
            <div className="c-page-header__titlebar g-col-lg-12">
              {typeof pageTitle === "string" ? <h1>{pageTitle}</h1> : pageTitle}
            </div>
          )}
          {this.renderActionSection()}
          {this.renderInputSection()}
        </div>
      </div>
    );
  }

  private renderActionSection(): React.ReactNode | null {
    if (!this.props.actionSection) {
      return null;
    }

    return (
      <div className="c-page-header__linksbar g-col-lg-12">
        {this.props.actionSection}
      </div>
    );
  }

  private renderInputSection(): React.ReactNode | null {
    const { searchSection, sortingSection } = this
      .props as IPageHeaderDiscreteBottomSection;
    const { bottomSection } = this.props as IPageHeaderGeneralBottomSection;

    if (!this.hasInputSection()) {
      return null;
    }

    return (
      <>
        {bottomSection ? (
          <div className="c-page-header__content g-col-lg-12">
            {bottomSection}
          </div>
        ) : (
          <div className="c-page-header__content g-col-lg-12 g-inner-grid-12">
            {searchSection ? (
              <div className="c-page-header__searchbar">{searchSection}</div>
            ) : null}

            {sortingSection ? (
              <div className="c-page-header__sortbar">{sortingSection}</div>
            ) : null}
          </div>
        )}
      </>
    );
  }

  private hasInputSection(): boolean {
    const { searchSection, sortingSection } = this
      .props as IPageHeaderDiscreteBottomSection;
    const { bottomSection } = this.props as IPageHeaderGeneralBottomSection;

    return (
      Boolean(searchSection) ||
      Boolean(sortingSection) ||
      Boolean(bottomSection)
    );
  }
}
