import React from "react";
import { AlertMessage, Popup } from "component-library";
import { useTranslation } from "i18n";

type CancelAddOnModalProps = {
  isOpen: boolean;
  closePopUp: () => void;
  onSubmitCancelAddOn: () => void;
  isCancelAddOnLoading: boolean;
};

const CancelAddOnModal = ({
  isOpen,
  closePopUp,
  onSubmitCancelAddOn,
  isCancelAddOnLoading,
}: CancelAddOnModalProps) => {
  const { t } = useTranslation();

  return isOpen ? (
    <Popup
      width={{ lg: 5, md: 6 }}
      texts={{ closePopup: t("common:button.label.cancel") }}
      close={closePopUp}
      className="z-50"
    >
      <AlertMessage
        icon="notice"
        texts={{
          title: t("performer:accountSettings.addOns.cancelAddOnModal.title"),
          description: t(
            "performer:accountSettings.addOns.cancelAddOnModal.description"
          ),
        }}
        buttons={[
          {
            name: t("performer:accountSettings.addOns.cancelAddOnModal.title"),
            click: onSubmitCancelAddOn,
            type: "primary",
            ctaDisabled: isCancelAddOnLoading,
            loading: isCancelAddOnLoading,
          },
          {
            name: t("common:button.label.close"),
            click: closePopUp,
            type: "secondary",
          },
        ]}
      />
    </Popup>
  ) : null;
};

export default CancelAddOnModal;
