import React, { FC, ReactNode } from "react";
import cx from "classnames";
import { ReactComponent as PlusIcon } from "../../../../../../images/svg/icons/plus.svg";
import { ReactComponent as CheckIcon } from "../../../../../../images/svg/icons/tick-icon.svg";

interface Props {
  text?: string;
  isSelected?: boolean;
  onSelect?: () => void;
  children?: ReactNode;
  variant?: "primary" | "secondary";
  id?: string;
}

const PaymentMethodButton: FC<Props> = ({
  text,
  isSelected,
  onSelect,
  children,
  variant = "primary",
  id = "payment-method-button",
}) => {
  const props = {
    className: cx("payment-method", {
      "payment-method--selected": isSelected,
      "payment-method--primary": variant === "primary",
      "payment-method--secondary": variant === "secondary",
    }),
    onClick: onSelect,
    id,
  };

  if (variant === "secondary") {
    return <div {...props}>{children}</div>;
  }

  const dimensions = { width: 32, height: 32 };

  return (
    <div {...props}>
      {isSelected ? (
        <CheckIcon {...dimensions} />
      ) : (
        <PlusIcon {...dimensions} />
      )}
      {text}
    </div>
  );
};

export default PaymentMethodButton;
