import React, { FC, ReactNode } from "react";
import cx from "classnames";
import { ReactComponent as SuccessIcon } from "../../../../../images/svg/icons/plus.svg";
import "./styles.scss";

interface Props {
  text?: string;
  isSelected?: boolean;
  onSelect: () => void;
  children?: ReactNode;
  variant?: "primary" | "secondary";
  id?: string;
}

const PaymentMethodButton: FC<Props> = ({
  text,
  isSelected,
  onSelect,
  children,
  variant = "primary",
  id = "payment-method-button",
}) => {
  const props = {
    className: cx("payment-method-LEGACY", {
      "payment-method-LEGACY--selected": isSelected,
      "payment-method-LEGACY--primary": variant === "primary",
      "payment-method-LEGACY--secondary": variant === "secondary",
    }),
    onClick: onSelect,
    id,
  };

  if (variant === "secondary") {
    return <div {...props}>{children}</div>;
  }

  return (
    <div {...props}>
      <SuccessIcon width={32} height={32} />
      {text}
    </div>
  );
};

export default PaymentMethodButton;
