import { useQuery } from "react-query";
import { authAxios as axios } from "shared-auth";
import { FETCH_ACCESS_TO_CHECKOUT } from "../services";
import { config } from "../../../../config";
import { CheckoutAccessTypes } from "../types";
import { AxiosError } from "axios";

export const useCanAccessCheckout = (
  enabled = false,
  redirectOnForbiddenUrl = "/",
  shouldRedirectOnForbidden = true
) => {
  const { data, isLoading } = useQuery<
    { data: { checkoutType: CheckoutAccessTypes } } | undefined,
    AxiosError
  >(
    [FETCH_ACCESS_TO_CHECKOUT, enabled],
    async () => {
      try {
        return await axios.get(`${config.billingApiUrl}account/access`);
      } catch (e) {
        if (shouldRedirectOnForbidden)
          window.location.replace(redirectOnForbiddenUrl);
      }
    },
    {
      enabled: enabled,
    }
  );

  return { data, isLoading };
};
