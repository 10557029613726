import React from "react";

import "./SectionContainer.scss";

export interface IHeaderControl {
  text: string;
  onClick: () => void;
}

export interface ISectionContainerProps {
  /**
   * Variant of the section container according to designs. If empty, will default to a white background with grey borders.
   */
  variant?: "secondary" | "shadowed";
  /**
   * Text within container header.
   */
  header?: string;
  /**
   * Array of JSX elements for container controls in header.
   */
  controls?: IHeaderControl[];
  /**
   * Children will be rendered inside container.
   */
  children?: React.ReactNode;
  headerAsH1?: boolean;
}

export const SectionContainer: React.SFC<ISectionContainerProps> = (
  props: ISectionContainerProps
) => {
  return (
    <div
      className={`c-section-container c-section-container--${
        props.variant || ""
      }`}
    >
      {renderHeader(props)}
      <div className="c-section-container__container">{props.children}</div>
    </div>
  );
};

const renderHeader = (
  props: ISectionContainerProps
): React.ReactNode | null => {
  if (!props.header && !props.controls) {
    return null;
  }

  return (
    <div className="c-section-container__header">
      {props.headerAsH1 ? (
        <h1 className="text-3xl">{props.header}</h1>
      ) : (
        <div className="c-section-container__header-text">{props.header}</div>
      )}
      {renderControls(props)}
    </div>
  );
};

const renderControls = (
  props: ISectionContainerProps
): React.ReactNode | null => {
  if (!props.controls) {
    return null;
  }

  const controls = props.controls.map((control, i) => {
    const handleKeyPress = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (["Enter", " "].includes(event.key)) {
        control.onClick();
        event.preventDefault();
      }
    };

    return (
      <a
        className="c-section-container-control g-alternative-link g-alternative-link--lg"
        key={i}
        tabIndex={0}
        onClick={control.onClick}
        onKeyPress={handleKeyPress}
      >
        {control.text}
      </a>
    );
  });

  return <div className="c-section-container__header-controls">{controls}</div>;
};
