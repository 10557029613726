// @ts-nocheck
import React from "react";
import merge from "lodash/merge";

import {
  CheckboxGroup,
  ICheckboxGroupConfig,
  ICheckboxGroupValue,
} from "../../Organisms/CheckboxGroup/CheckboxGroup";
import {
  ICheckboxConfig,
  ICheckboxGroupItemConfig,
  ICheckboxGroupItemValue,
} from "../../Organisms/CheckboxGroup/components/CheckboxGroupItem";
import { Dropdown, IDropdownProps } from "../../Atoms/Dropdown/Dropdown";
import { omit } from "../../../generics/object-manipulation";
import { Omit } from "../../../generics/type-manipulation";
import { InputLabel } from "../../Atoms/InputsCommon/InputLabel";
import {
  ERROR,
  InputWrapper,
  WARNING,
} from "../../Atoms/InputsCommon/InputWrapper";
import {
  getDropdownCheckboxValueComponent,
  IDropdownCheckboxGroupValueItemConfig,
  IDropdownCheckboxValueItemConfig,
} from "./DropdownCheckboxValue";
import { Input } from "../Input/Input";
import { InputCleaner } from "../../Atoms/InputsCommon/InputCleaner";
import { InputSearchIcon } from "../../Atoms/InputsCommon/InputSearchIcon";

import "./DropdownCheckbox.scss";

export interface IDropdownCheckboxValueProps<
  TOption extends ICheckboxGroupItemConfig | ICheckboxConfig =
    | ICheckboxGroupItemConfig
    | ICheckboxConfig
> {
  options: TOption[];
  selected: ICheckboxGroupValue;
}

export interface IDropdownCheckboxSearchInput {
  /**
   * Text for search input placeholder
   */
  placeholder?: string;
  /**
   * No results notification text
   */
  noResults?: string;
}

export interface IDropdownCheckboxProps {
  /**
   * The config to be passed into CheckboxGroup
   */
  checkboxGroupConfig: ICheckboxGroupConfig<
    IDropdownCheckboxGroupValueItemConfig | IDropdownCheckboxValueItemConfig
  >;
  /**
   * The value to be passed into CheckboxGroup
   */
  value: ICheckboxGroupValue;
  /**
   * The onchange property sets and returns the event handler for the change event.
   */
  valueChanged: (value: ICheckboxGroupValue) => void;
  /**
   * The renderer to format selected options
   */
  ValueComponent: React.ComponentType<IDropdownCheckboxValueProps>;
  /**
   * Optional label
   */
  label?: string;
  /**
   * Optional validation state
   */
  invalid?: typeof WARNING | typeof ERROR;
  /**
   * Optional validation message
   */
  validationMessage?: string;
  /**
   * Optional state of parent form, <code>true</code> if it has been submitted
   */
  isFormSubmitted?: boolean;
  /**
   * Optional annex or helper
   */
  annex?: JSX.Element;
  /**
   * Show input in a dropdown for option filtering if value provided
   */
  searchInput?: IDropdownCheckboxSearchInput;
  /**
   * Defines function which will render after options list
   */
  renderOptionsTail?: () => React.ReactNode;

  "data-testid"?: string;
}

export interface IDropdownCheckboxState {
  blurred: boolean;
  searchTerm: string;
  hiddenValue: ICheckboxGroupValue;
  availableItems: Array<
    IDropdownCheckboxGroupValueItemConfig | IDropdownCheckboxValueItemConfig
  >;
}

type PassthroughDropdownProps = Omit<
  Omit<IDropdownProps, "toggleContent">,
  "renderOptions"
>;

const ownPropKeys: Array<keyof IDropdownCheckboxProps> = [
  "checkboxGroupConfig",
  "value",
  "valueChanged",
  "ValueComponent",
  "searchInput",
];

export class DropdownCheckbox extends React.Component<
  IDropdownCheckboxProps & PassthroughDropdownProps,
  IDropdownCheckboxState
> {
  public static getDefaultValueComponent = getDropdownCheckboxValueComponent;

  public constructor(props: IDropdownCheckboxProps) {
    super(props);

    this.state = {
      blurred: false,
      searchTerm: "",
      hiddenValue: {},
      availableItems: props.checkboxGroupConfig.options,
    };
  }

  public render() {
    const {
      props: {
        annex,
        invalid,
        isFormSubmitted,
        label,
        validationMessage,
        renderOptionsTail,
      },
      state: { blurred },
    } = this;
    const invalidState =
      invalid && (blurred || isFormSubmitted) ? invalid : undefined;

    return (
      <InputWrapper
        invalid={invalidState}
        validationMesssage={validationMessage}
      >
        <InputLabel label={label} annex={annex} />
        <Dropdown
          {...omit(this.props, ownPropKeys)}
          ariaLabel={label}
          className="c-dropdown-checkbox"
          toggleContent={this.renderToggleContent()}
          renderOptions={this.renderPopupContent}
          renderOptionsTail={renderOptionsTail}
          keepOpenOnSelect={true}
          onBlur={this.onBlur}
          data-testid={this.props["data-testid"]}
        />
      </InputWrapper>
    );
  }

  public static getDerivedStateFromProps(
    nextProps: IDropdownCheckboxProps,
    prevState: IDropdownCheckboxState
  ) {
    let stateUpdates = {} as Partial<IDropdownCheckboxState>;

    const {
      checkboxGroupConfig: { options },
      value,
    } = nextProps;
    const { searchTerm } = prevState;

    if (searchTerm.length === 0) {
      stateUpdates = { availableItems: options, hiddenValue: {} };
    } else {
      const availableItems = DropdownCheckbox.getAvailableItems(
        options,
        searchTerm
      );
      const hiddenValue = DropdownCheckbox.getHiddenValue(
        availableItems,
        value
      );

      stateUpdates = { availableItems, hiddenValue };
    }

    return stateUpdates;
  }

  private renderToggleContent = () => {
    const { checkboxGroupConfig, ValueComponent, value } = this.props;
    return (
      <ValueComponent options={checkboxGroupConfig.options} selected={value} />
    );
  };

  private renderPopupContent = () => {
    const { searchInput, checkboxGroupConfig, value } = this.props;
    const { availableItems } = this.state;

    const containerClassNames = Boolean(searchInput)
      ? "c-dropdown-checkbox__group-container-with-search"
      : "c-dropdown-checkbox__group-container";
    const optionsConfig: ICheckboxGroupConfig = {
      ...checkboxGroupConfig,
      options: availableItems,
    };
    const hasNoOptions = optionsConfig.options.length === 0;
    const currentValue = DropdownCheckbox.getCurrentValue(
      availableItems,
      value
    );

    return (
      <div className={containerClassNames}>
        {searchInput && this.renderSearchInput(searchInput, hasNoOptions)}
        <CheckboxGroup
          config={optionsConfig}
          value={currentValue}
          valueChanged={this.handleCheckboxGroupUpdate}
          dropDown={true}
        />
      </div>
    );
  };

  private onBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }

    this.setState({ blurred: true });
  };

  private renderSearchInput = (
    searchInput: IDropdownCheckboxSearchInput,
    hasNoOptions: boolean
  ): JSX.Element => {
    const { placeholder, noResults } = searchInput;
    const { searchTerm } = this.state;

    return (
      <div className="c-dropdown-checkbox__search">
        <Input
          className="g-with-search-icon"
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          notifying={hasNoOptions}
          validationMessage={noResults}
          isFormSubmitted={true}
          onChange={this.handleSearchInputChange}
        >
          <InputSearchIcon />
          {searchTerm && <InputCleaner onClick={this.handleCleanClick} />}
        </Input>
      </div>
    );
  };

  private handleCheckboxGroupUpdate = (value: ICheckboxGroupValue) => {
    const { valueChanged } = this.props;
    const { hiddenValue } = this.state;

    const newValue = merge({}, value, hiddenValue);

    valueChanged(newValue);
  };

  private handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      checkboxGroupConfig: { options },
      value,
    } = this.props;
    const searchTerm = event.target.value;

    const availableItems = DropdownCheckbox.getAvailableItems(
      options,
      searchTerm
    );
    const hiddenValue = DropdownCheckbox.getHiddenValue(availableItems, value);

    this.setState({ searchTerm, availableItems, hiddenValue });
  };

  private handleCleanClick = () =>
    this.setState({
      searchTerm: "",
      hiddenValue: {},
      availableItems: this.props.checkboxGroupConfig.options,
    });

  private static getAvailableItems = (
    options: Array<
      IDropdownCheckboxGroupValueItemConfig | IDropdownCheckboxValueItemConfig
    >,
    searchTerm: string
  ) => {
    const availableItems: Array<
      IDropdownCheckboxGroupValueItemConfig | IDropdownCheckboxValueItemConfig
    > = [];

    options.forEach((item) => {
      const itemOptions = (item as IDropdownCheckboxGroupValueItemConfig)
        .options;
      const itemMatchesSearchTerm = item.label
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      if (itemOptions && itemOptions.length > 0) {
        const itemsSubset = DropdownCheckbox.getAvailableItems(
          itemOptions,
          searchTerm
        );

        if (itemsSubset.length > 0) {
          const newItem = { ...item, options: itemsSubset };
          availableItems.push(newItem);
        }
      } else if (itemMatchesSearchTerm) {
        availableItems.push(item);
      }
    });

    return availableItems;
  };

  private static getCurrentValue = (
    availableItems: Array<
      IDropdownCheckboxGroupValueItemConfig | IDropdownCheckboxValueItemConfig
    >,
    value: ICheckboxGroupItemValue
  ) => {
    const currentValue: ICheckboxGroupItemValue = {};

    Object.keys(value).forEach((key) => {
      const selectedValue = value[key];

      if (typeof selectedValue === "object") {
        const valueOptionsItem = availableItems.find(
          (item) =>
            (item as IDropdownCheckboxGroupValueItemConfig)?.name === key
        );
        const valueOptions = (
          valueOptionsItem as IDropdownCheckboxGroupValueItemConfig
        )?.options;

        if (valueOptions && valueOptions.length > 0) {
          currentValue[key] = DropdownCheckbox.getCurrentValue(
            valueOptions,
            value[key]
          );
        }
      } else if (selectedValue === true) {
        const valueOption = availableItems.find(
          (item) => (item as IDropdownCheckboxValueItemConfig).value === key
        );

        if (valueOption) {
          currentValue[key] = true;
        }
      }
    });

    return currentValue;
  };

  private static getHiddenValue = (
    availableItems: Array<
      IDropdownCheckboxGroupValueItemConfig | IDropdownCheckboxValueItemConfig
    >,
    value: ICheckboxGroupItemValue
  ) => {
    const hiddenValue: ICheckboxGroupItemValue = {};

    Object.keys(value).forEach((key) => {
      const selectedValue = value[key];

      if (typeof selectedValue === "object") {
        const valueOptionsItem = availableItems.find(
          (item) =>
            (item as IDropdownCheckboxGroupValueItemConfig)?.name === key
        );
        const valueOptions = (
          valueOptionsItem as IDropdownCheckboxGroupValueItemConfig
        )?.options;

        if (valueOptions && valueOptions.length > 0) {
          hiddenValue[key] = DropdownCheckbox.getHiddenValue(
            valueOptions,
            value[key]
          );
        } else {
          hiddenValue[key] = selectedValue;
        }
      } else if (selectedValue === true) {
        const valueOption = availableItems.find(
          (item) => (item as IDropdownCheckboxValueItemConfig).value === key
        );

        if (!valueOption) {
          hiddenValue[key] = true;
        }
      }
    });

    return hiddenValue;
  };
}
