import { Reducer } from 'redux';

import { DEFAULT_LOCALE } from 'config/common';

import { ActionType } from '../types';

import { ITranslationState, TranslationActionTypes } from './translation.types';

export const initialState: ITranslationState = {
  common: {},
  pages: {},
  isCommonLoaded: false,
  isPageLoaded: false,
  commonLocale: DEFAULT_LOCALE,
  pageLocale: DEFAULT_LOCALE,
} as ITranslationState;

const reducer: Reducer<ITranslationState, TranslationActionTypes> = (
  state: ITranslationState = initialState,
  action: TranslationActionTypes
) => {
  switch (action.type) {
    case ActionType.GetCommonTranslationSuccess:
      return {
        ...state,
        common: { ...action.payload.data },
        isCommonLoaded: true,
        commonLocale: action.payload.locale,
      };
    case ActionType.GetPageTranslationSuccess:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.pageKey]: action.payload.data,
        },
        isPageLoaded: true,
        pageLocale: action.payload.locale,
      };
    default:
      return state;
  }
};

export default reducer;
