import { AccountType } from "shared-types";

import { INavigation } from "../../models/navigation";

import { CastingDirectorFallback } from "./CastingDirectorFallback";
import { CastingSearchOnlyFallback } from "./CastingSearchOnlyFallback";
import { DefaultUserFallback } from "./DefaultUserFallback";

export interface IFallbackNavigation {
  [AccountType.CastingDirector]: INavigation;
  [AccountType.CastingSearchOnly]: INavigation;
  [AccountType.DefaultUser]: INavigation;
}

export const fallbackNavigation: IFallbackNavigation = {
  [AccountType.CastingDirector]: CastingDirectorFallback,
  [AccountType.CastingSearchOnly]: CastingSearchOnlyFallback,
  [AccountType.DefaultUser]: DefaultUserFallback,
};
