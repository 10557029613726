import { object, string, ref } from "yup";
import { TFunction } from "i18n";
import { FormFields } from "./constants";

export function passwordValidationSchema(t: TFunction, username: string) {
  return object().shape({
    [FormFields.Password]: string()
      .min(6, t("common:validation.passwordLength"))
      .max(
        128,
        t("common:validation.maxLength", { field: "Password", length: "128" })
      )
      .matches(/\d/, t("common:validation.passwordNumber"))
      .matches(/[A-Za-z]/, t("common:validation.passwordLetter"))
      .notOneOf([username], t("common:validation.passwordUsername"))
      .required(
        t("common:validation.required", {
          field: t("common:label.password"),
        })
      ),
    [FormFields.ConfirmPassword]: string()
      .required(
        t("common:validation.required", {
          field: t("common:label.confirmPassword"),
        })
      )
      .oneOf(
        [ref(FormFields.Password)],
        t("common:validation.passwordMismatch")
      ),
  });
}

export function securityQuestionValidationSchema(t: TFunction) {
  return object().shape({
    [FormFields.SecurityQuestion]: string().required(
      t("common:validation.required", {
        field: t("common:label.securityQuestion"),
      })
    ),
    [FormFields.SecurityAnswer]: string()
      .max(
        255,
        t("common:validation.maxLength", { field: "Answer", length: "255" })
      )
      .trim()
      .required(
        t("common:validation.required", {
          field: t("common:label.securityAnswer"),
        })
      ),
  });
}
