import _endsWith from "lodash/endsWith";
import { PlanCodeMethod } from "../types";

const checkMethodType = (
  planCodeMethod: PlanCodeMethod,
  code: string = ""
): boolean => {
  return _endsWith(code, planCodeMethod);
};

export default checkMethodType;
