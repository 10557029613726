import React from "react";

import { dataTestIds } from "data-testids";

import "./Tab.scss";

export interface ITabProps {
  /**
   * Text of tab
   */
  text: string;
  /**
   * Event on click
   */
  click: (event: React.KeyboardEvent | React.MouseEvent) => void;
  /**
   * Event on focus
   */
  focus?: (event: React.FocusEvent) => void;
  /**
   * Is active tab
   */
  isActive?: boolean;
  /**
   * Id
   */
  id?: string;
}

export const Tab: React.SFC<ITabProps> = (props: ITabProps) => {
  const keyboardEventHandler = (event: React.KeyboardEvent) => {
    if (["Enter", " "].includes(event.key)) {
      props.click(event);
      event.preventDefault();
    }
  };

  const onClickEvent = (event: React.MouseEvent<HTMLDivElement>) => {
    (event.target as any).closest(".c-tab").blur();
    props.click(event);
  };

  return (
    <div
      id={props.id}
      tabIndex={0}
      onKeyPress={keyboardEventHandler}
      onFocus={props.focus}
      role="tab"
      aria-label={props.text}
      className={`c-tab ${props.isActive ? "active" : ""}`}
      onClick={onClickEvent}
      data-testid={dataTestIds.componentLibrary["Atoms.Tab.tab"]}
    >
      <div className="c-tab__container">{props.text}</div>
      <div className="c-tab__container-invisible">{props.text}</div>
    </div>
  );
};
