import React, { FC } from "react";
import { Input } from "../../../../Molecules/Input/Input";
import { FieldNames } from "../../constants";
import { SetField } from "../../hooks/useBillingFormValues";
import useScrollBillingField from "../../hooks/useScrollBillingField";
import { CardElementWrapperProps } from "./CardElementWrapper";

interface Props extends CardElementWrapperProps {
  name: FieldNames;
  value?: string;
  dataRecurly: string;
  wrapperClassName?: string;
  isSubmitted: boolean;
  shouldScroll?: boolean;
  setFieldValue: SetField;
}

const BillingInput: FC<Props> = ({
  name,
  label,
  value,
  setFieldValue,
  validationMessage,
  isSubmitted,
  shouldScroll,
  className,
  wrapperClassName,
  dataRecurly,
  annex,
  children,
}) => {
  const ref = useScrollBillingField(!!(validationMessage && shouldScroll));

  return (
    <div ref={ref} className={wrapperClassName}>
      <Input
        label={label}
        name={name}
        className={className}
        value={value}
        onChange={setFieldValue}
        validationMessage={validationMessage}
        invalid={!!validationMessage}
        isFormSubmitted={isSubmitted}
        annex={annex}
        data-recurly={dataRecurly}
      >
        {children}
      </Input>
    </div>
  );
};

export default BillingInput;
