import { ActionType, IAction } from 'store/types';

import { IErrorPopupDynamicProps } from 'components/Popups/ErrorPopup/ErrorPopup';

export enum PopupType {
  ErrorPopup,
}

export interface IPopup {
  type: PopupType;
  data?: IErrorPopupDynamicProps;
}

export interface IUIState {
  popup: IPopup | null;
  locale: string;
  isLoading: boolean;
  isPageLoading: boolean;
  isPage404: boolean;
}

// ============================== Actions Types =======================================================================

export type ShowPopup = (popupData: IPopup) => IAction<ActionType.ShowPopup, IPopup>;
export type HidePopup = () => IAction<ActionType.HidePopup, undefined>;
export type SetLocale = (locale: string) => IAction<ActionType.SetLocale, string>;
export type SetLoading = (value: boolean) => IAction<ActionType.SetLoading, boolean>;
export type SetPageLoading = (value: boolean) => IAction<ActionType.SetPageLoading, boolean>;
export type SetPage404 = (value: boolean) => IAction<ActionType.SetPage404, boolean>;

export type UIActionTypes =
  | ReturnType<ShowPopup>
  | ReturnType<HidePopup>
  | ReturnType<SetLocale>
  | ReturnType<SetLoading>
  | ReturnType<SetPageLoading>
  | ReturnType<SetPage404>;
