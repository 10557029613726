import React, { FC } from "react";
import { ThreeDSecureAction } from "@recurly/react-recurly";
import { BillingUser } from "translations/src/models/billing";
import { ITokenPayload } from "../../types";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import ScrollToTopContainer from "../ScrollToTopContainer";

interface Props {
  threeDSToken: string;
  threeDSClassName?: string;
  submitThreeDSecure: (token: ITokenPayload) => Promise<void>;
  onThreeDSecureError: () => void;
}

const ThreeDSecurePage: FC<Props> = ({
  threeDSToken,
  threeDSClassName,
  submitThreeDSecure,
  onThreeDSecureError,
}) => {
  const {
    texts: { common },
  } = useBillingTranslations(BillingUser.Common);

  return (
    <ScrollToTopContainer>
      {({ elRef }) => (
        <div className="h-[80vh] md:h-[100%] flex flex-col p-4 md:p-0">
          <h1
            ref={elRef}
            className="text-[26px] leading-[39px] text-center font-semibold py-5 border-y border-solid border-y-greys-light mt-5 mb-[60px]"
          >
            {common.threeDSPageHeader}
          </h1>
          <ThreeDSecureAction
            actionTokenId={threeDSToken}
            onToken={submitThreeDSecure}
            onError={onThreeDSecureError}
            className={threeDSClassName}
          />
        </div>
      )}
    </ScrollToTopContainer>
  );
};

export default ThreeDSecurePage;
