/**
 * Undocumented component, use as a wrapper when declaring inputs, to define their styles and display validation borders + messages
 */

import React from "react";
import cx from "classnames";
import "./InputWrapper.scss";
import { ValidationMessage } from "./ValidationMessage";

export const WARNING = "warning";
export const ERROR = "error";
export const NOTIFICATION = "notification";

export const INVALID_INPUT_SELECTOR_PREFIX = "c-input-wrapper--invalid-";

export const INVALID_LEVELS = {
  warning: WARNING, // As a friendly warning, to show to a user that they might be approaching bounds
  error: ERROR, // To be shown when the surrounded input fails validation and prevents form submission
  notification: NOTIFICATION, // To show some text without color highlighting
};

export interface IInputWrapperProps {
  invalid?: typeof WARNING | typeof ERROR | typeof NOTIFICATION;
  /** Provide custom render function to call it with validationMessage provided as an argument */
  renderChildren?: (validationMessage: React.ReactNode) => React.ReactNode;
  validationMesssage?: string;
  children?: React.ReactNode;
  className?: string;
}

export class InputWrapper extends React.Component<IInputWrapperProps> {
  public render() {
    const { invalid, className = "" } = this.props;

    return (
      <div
        className={cx(
          "c-input-wrapper",
          {
            [INVALID_INPUT_SELECTOR_PREFIX + invalid]: invalid,
          },
          className
        )}
      >
        {this.renderContent()}
      </div>
    );
  }

  public renderContent() {
    let validationMessage: JSX.Element | null = null;
    if (this.props.invalid && this.props.validationMesssage) {
      validationMessage = (
        <ValidationMessage
          message={this.props.validationMesssage}
          type={this.props.invalid}
        />
      );
    }

    if (this.props.renderChildren) {
      return this.props.renderChildren(validationMessage);
    }

    return (
      <React.Fragment>
        {this.props.children}
        {validationMessage}
      </React.Fragment>
    );
  }
}
