import en from './en';
import fr from './fr';
import { ITranslations } from './translationModels';

enum Locales {
  enGb = 'en-GB',
  enIE = 'en-IE',
  frFr = 'fr-FR',
}

export const DEFAULT_LOCALE = Locales.enGb;

export const translationMap = {
  [Locales.enGb]: en,
  [Locales.enIE]: en,
  [Locales.frFr]: fr,
};

export const getTranslation = (locale: string = DEFAULT_LOCALE): ITranslations =>
  translationMap[locale] ?? translationMap[DEFAULT_LOCALE];

export default getTranslation;
