import React, { FC, useContext } from "react";
import { useTranslation } from "i18n";
import Section from "../../Section";
import PaymentMethodButton from "../../PaymentMethodButton";
import {
  ExistingPaymentMethod,
  PaymentMethodType,
  PlanCodeMethod,
  PlanCodePeriod,
} from "../../../types";
import "./styles.scss";
import { BillingContext } from "../../../BillingContext";
import { Spinner } from "../../../../../Atoms/Spinner/Spinner";
import ExistingPaymentMethodBlock from "../../ExistingPaymentMethodBlock/ExistingPaymentMethodBlock";

interface Props {
  planPeriod: PlanCodePeriod;
  existingPaymentMethodData: ExistingPaymentMethod | undefined;
  existingPaymentMethodIsLoading: boolean;
  existingPaymentMethodIsError: boolean;
  selectedMethod?: PlanCodeMethod;
  setPaymentMethod: (paymentMethodType: PlanCodeMethod) => void;
}

const PaymentMethodBlock: FC<Props> = ({
  planPeriod,
  selectedMethod,
  setPaymentMethod,
  existingPaymentMethodData,
  existingPaymentMethodIsLoading = false,
  existingPaymentMethodIsError = false,
}) => {
  const { t } = useTranslation();
  const { setUseExistingPaymentMethod, useExistingPaymentMethod } =
    useContext(BillingContext);

  const method =
    existingPaymentMethodData?.paymentMethodType ===
    PaymentMethodType.CreditCard
      ? PlanCodeMethod.CreditCard
      : PlanCodeMethod.DirectDebit;

  const handleSelectExistingPaymentMethod = () => {
    setUseExistingPaymentMethod(!useExistingPaymentMethod);
    setPaymentMethod(method);
  };

  const handleSelectPaymentMethod = (planCodeMethod: PlanCodeMethod) => {
    setPaymentMethod(planCodeMethod);
    setUseExistingPaymentMethod(false);
  };

  const renderExistingPaymentMethod = () => {
    if (
      existingPaymentMethodIsError ||
      (planPeriod !== PlanCodePeriod.Annual &&
        method === PlanCodeMethod.CreditCard)
    ) {
      setUseExistingPaymentMethod(false);
      return null;
    }

    // For some reason this useQuery sets the response from a 404 as data, instead of throwing an error. Instead checking for a value that must exist on successful call.
    if (!!existingPaymentMethodData?.paymentMethodType) {
      if (existingPaymentMethodIsLoading) return <Spinner inline />;
      else {
        return (
          <PaymentMethodButton
            isSelected={useExistingPaymentMethod}
            onSelect={handleSelectExistingPaymentMethod}
            variant="secondary"
          >
            <ExistingPaymentMethodBlock data={existingPaymentMethodData} />
          </PaymentMethodButton>
        );
      }
    } else return null;
  };

  return (
    <Section
      isOpen={!!planPeriod}
      customClasses="max-w-[680px]"
      title={t("common:billing.section.payment.method", { position: "2." })}
    >
      <h2 className="text-[1.1rem] mb-4">
        {t("common:billing.select.payment.method")}
      </h2>
      <div className="flex flex-col gap-[4px]">
        {renderExistingPaymentMethod()}
        <PaymentMethodButton
          isSelected={
            selectedMethod === PlanCodeMethod.DirectDebit &&
            !useExistingPaymentMethod
          }
          text={t("common:button.label.add.dd")}
          onSelect={() => handleSelectPaymentMethod(PlanCodeMethod.DirectDebit)}
          id="payment-method-button-direct-debit"
        />
        {planPeriod === PlanCodePeriod.Annual && (
          <PaymentMethodButton
            isSelected={
              selectedMethod === PlanCodeMethod.CreditCard &&
              !useExistingPaymentMethod
            }
            text={t("common:button.label.add.cc")}
            onSelect={() =>
              handleSelectPaymentMethod(PlanCodeMethod.CreditCard)
            }
            id="payment-method-button-credit-card"
          />
        )}
      </div>
    </Section>
  );
};

export default PaymentMethodBlock;
