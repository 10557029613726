import React, { FC } from "react";
import cx from "classnames";
import { useTranslation } from "i18n";
import { BillingUser } from "translations/src/models/billing";
import BillingInput from "../BillingInput";
import {
  COUNTRIES_WITH_STATE_PROVINCE,
  DEFAULT_DIRECT_DEBIT_COUNTRY,
  FieldNames,
} from "../../../constants";
import {
  getTootlipProps,
  QuestionTooltip,
} from "../../../../../Molecules/QuestionTooltip";
import CountryStateSelect from "../CountryStateSelect";
import BillingPhoneNumber from "../BillingPhoneNumber";
import { useBillingTranslations } from "../../../hooks/useBillingTranslations";
import { BillingAndPaymentFormProps } from "../../../types";
import { useGetStatsProvincesByCountryOptions } from "../../../../../Functional/locations/useGetStatsProvincesByCountryOptions";
import { useGetCountriesOptions } from "../../../../../Functional/locations/useGetCountriesOptions";

type Props = {
  firstError: string;
  isDirectDebit?: boolean;
  isSepa?: boolean;
} & Pick<
  BillingAndPaymentFormProps,
  | "formValues"
  | "setFieldValue"
  | "formErrors"
  | "isSubmitted"
  | "tootlipContainerConst"
  | "isMobileView"
  | "removeInvalidCardError"
>;

const BillingDetailsForm: FC<Props> = ({
  formValues,
  setFieldValue,
  formErrors,
  isSubmitted,
  firstError,
  tootlipContainerConst,
  isMobileView,
  removeInvalidCardError,
  isDirectDebit,
  isSepa,
}) => {
  const { t } = useTranslation();
  const {
    texts: { billingForm },
  } = useBillingTranslations(BillingUser.Common);

  const selectedCountryCode = formValues[FieldNames.country];
  const statesProvincesOptions =
    useGetStatsProvincesByCountryOptions(selectedCountryCode);
  const { countries, isLoading: isCountryOptionsLoading } =
    useGetCountriesOptions({
      shouldIncludeStates: true,
      shouldGetOnlyEUCountries: isSepa,
    });
  const countryOptions = countries;

  const selectedCountryHasStateProvince =
    COUNTRIES_WITH_STATE_PROVINCE.includes(selectedCountryCode as string);
  const isCountrySelectDirectDebit = isDirectDebit && !isSepa;

  return (
    <>
      <BillingInput
        label={billingForm.firstNameLabel}
        name={FieldNames.firstName}
        value={formValues[FieldNames.firstName]}
        dataRecurly={FieldNames.firstName}
        setFieldValue={setFieldValue}
        validationMessage={formErrors[FieldNames.firstName]}
        isSubmitted={isSubmitted}
        shouldScroll={firstError === FieldNames.firstName}
      />
      <BillingInput
        label={billingForm.lastNameLabel}
        name={FieldNames.lastName}
        value={formValues[FieldNames.lastName]}
        dataRecurly={FieldNames.lastName}
        setFieldValue={setFieldValue}
        validationMessage={formErrors[FieldNames.lastName]}
        isSubmitted={isSubmitted}
        shouldScroll={firstError === FieldNames.lastName}
      />
      <BillingInput
        label={billingForm.address1Label}
        name={FieldNames.address1}
        value={formValues[FieldNames.address1]}
        dataRecurly={FieldNames.address1}
        setFieldValue={setFieldValue}
        validationMessage={formErrors[FieldNames.address1]}
        isSubmitted={isSubmitted}
        shouldScroll={firstError === FieldNames.address1}
        annex={
          <QuestionTooltip
            tooltipProps={{
              ...getTootlipProps(
                t("common:billing.existingMethodBlock.label.billingAddress"),
                billingForm.address1TooltipBody
              ),
              overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
            }}
          />
        }
      />
      <BillingInput
        label={billingForm.address2Label}
        name={FieldNames.address2}
        value={formValues[FieldNames.address2]}
        dataRecurly={FieldNames.address2}
        setFieldValue={setFieldValue}
        validationMessage={formErrors[FieldNames.address2]}
        isSubmitted={isSubmitted}
        shouldScroll={firstError === FieldNames.address2}
      />
      <div className={cx("flex gap-5", { "flex-col": isMobileView })}>
        <BillingInput
          label={billingForm.cityLabel}
          name={FieldNames.city}
          value={formValues[FieldNames.city]}
          dataRecurly={FieldNames.city}
          setFieldValue={setFieldValue}
          validationMessage={formErrors[FieldNames.city]}
          isSubmitted={isSubmitted}
          shouldScroll={firstError === FieldNames.city}
          wrapperClassName="flex-1 grow-[2]"
        />
        <BillingInput
          label={billingForm.zipLabel}
          name={FieldNames.postalCode}
          value={formValues[FieldNames.postalCode]}
          dataRecurly={FieldNames.postalCode}
          setFieldValue={(e) => {
            if (isSubmitted && formErrors[FieldNames.postalCode]) {
              removeInvalidCardError(FieldNames.postalCode);
            }
            setFieldValue(e);
          }}
          validationMessage={formErrors[FieldNames.postalCode]}
          isSubmitted={isSubmitted}
          shouldScroll={firstError === FieldNames.postalCode}
          wrapperClassName="flex-1"
        />
      </div>
      <CountryStateSelect
        label={billingForm.countryLabel}
        placeholder={billingForm.countryPlaceholder}
        options={countryOptions}
        value={formValues[FieldNames.country]}
        setFieldValue={setFieldValue}
        validationMessage={formErrors[FieldNames.country]}
        isFormSubmitted={isSubmitted}
        shouldScroll={firstError === FieldNames.country}
        fieldName={FieldNames.country}
        isDisabled={isCountrySelectDirectDebit}
        presetCountry={
          isCountrySelectDirectDebit ? DEFAULT_DIRECT_DEBIT_COUNTRY : ""
        }
      />
      {selectedCountryHasStateProvince && (
        <CountryStateSelect
          label={billingForm.stateProvinceLabel}
          placeholder={billingForm.stateProvincePlaceholder}
          options={statesProvincesOptions}
          value={formValues[FieldNames.state]}
          setFieldValue={setFieldValue}
          validationMessage={formErrors[FieldNames.state]}
          isFormSubmitted={isSubmitted}
          shouldScroll={firstError === FieldNames.state}
          fieldName={FieldNames.state}
          isDisabled={isCountryOptionsLoading}
        />
      )}
      <BillingPhoneNumber
        label={billingForm.phoneLabel}
        value={formValues.phone}
        setFieldValue={setFieldValue}
        validationMessage={formErrors[FieldNames.phone]}
        shouldScroll={firstError === FieldNames.phone}
      />
    </>
  );
};

export default BillingDetailsForm;
