import React, { FC } from "react";
import {
  CreditCardType,
  ExistingPaymentMethod,
  PaymentMethodType,
} from "../../types";
import { ReactComponent as VisaLogo } from "../../../../../images/svg/icons/visa.svg";
import { ReactComponent as MastercardLogo } from "../../../../../images/svg/icons/mastercard.svg";
import { ReactComponent as DirectDebitLogo } from "../../../../../images/svg/icons/direct-debit.svg";
import { ReactComponent as SepaLogo } from "../../../../../images/svg/icons/sepa.svg";
import { useTranslation } from "i18n";

type ExistingPaymentMethodBlockPropsType = {
  data: ExistingPaymentMethod | undefined;
};

const LOGO_MAP = {
  [CreditCardType.Visa]: VisaLogo,
  [CreditCardType.Mastercard]: MastercardLogo,
  [CreditCardType.MasterCard]: MastercardLogo,
  [PaymentMethodType.Bacs]: DirectDebitLogo,
  [PaymentMethodType.Sepa]: SepaLogo,
};

const ExistingPaymentMethodBlock: FC<ExistingPaymentMethodBlockPropsType> = ({
  data,
}) => {
  const { t } = useTranslation();
  const logoProps = {
    width: 64,
    height: 32,
  };

  const renderLogoComponent = (
    key: CreditCardType | PaymentMethodType.Bacs | PaymentMethodType.Sepa
  ) => {
    const LogoComponent = LOGO_MAP[key];

    return <LogoComponent {...logoProps} />;
  };

  if (!data) return null;

  if (data.paymentMethodType === PaymentMethodType.CreditCard) {
    const truncatedCardNumber = data.cardNumber.replaceAll("*", "");
    return (
      <>
        {renderLogoComponent(data.cardType as CreditCardType)}

        <div>
          <p className="text-lg font-semibold">{data.cardType}</p>
          <p>
            {t("common:button.subtitle.existingCc", {
              cardNumber: truncatedCardNumber,
            })}
          </p>
        </div>
      </>
    );
  }

  if (
    data.paymentMethodType === PaymentMethodType.Bacs ||
    data.paymentMethodType === PaymentMethodType.Sepa
  ) {
    return (
      <>
        {renderLogoComponent(data.paymentMethodType)}

        <div>
          <p className="text-lg font-semibold">
            {t("common:button.title.existingDd")}
          </p>
          <p>
            {t("common:button.subtitle.existingDd", {
              accountNumber: data.bankAccountNumber,
            })}
          </p>
        </div>
      </>
    );
  }

  return null;
};

export default ExistingPaymentMethodBlock;
