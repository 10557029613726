import { i18n } from "i18n";

export const getErrorPopupTexts = () => {
  const closeButtonText = i18n.t("common:button.label.close");

  return {
    systemError: {
      title: i18n.t("common:errorPopup.httpStatus.system.title"),
      description: i18n.t("common:errorPopup.httpStatus.system.body"),
      closeButtonText,
    },
    accountError: {
      title: i18n.t("common:errorPopup.httpStatus.401.title"),
      description: i18n.t("common:errorPopup.httpStatus.401.body"),
      closeButtonText,
    },
    accountAccessError: {
      title: i18n.t("common:errorPopup.httpStatus.403.title"),
      description: i18n.t("common:errorPopup.httpStatus.403.body"),
      closeButtonText,
    },
    networkError: {
      title: i18n.t("common:errorPopup.httpStatus.network.title"),
      description: i18n.t("common:errorPopup.httpStatus.network.body"),
      closeButtonText,
    },
  };
};
