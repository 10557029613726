import React from "react";
import cx from "classnames";

import "./LocalizationButton.scss";

export interface ILocalizationButtonProps {
  countryISOCode: string | undefined;
  onClick: () => void;
  flagLabel: string;
}

export const LocalizationButton: React.FC<ILocalizationButtonProps> = (
  props: ILocalizationButtonProps
) => {
  return (
    <a
      className={cx(
        "c-navigation-header__country-flag",
        `flag-${props.countryISOCode}`,
        "country-flag--size-30"
      )}
      aria-label={props.flagLabel}
      onClick={props.onClick}
      onKeyDown={(e) => e.key === "Enter" && props.onClick()}
      tabIndex={0}
    />
  );
};
