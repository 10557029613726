import { i18n } from "i18n";
import { MemberGroups } from "shared-types";

export function getLHNavBottomLinks(memberGroup: string) {
  const getInTouch = {
    href: "/get-in-touch",
    html: i18n.t("common:nav.links.contact"),
    title: i18n.t("common:nav.links.contact"),
  };
  const newsAndAdvice = {
    href: "https://www.spotlight.com/news-and-advice/",
    html: i18n.t("common:nav.links.newsAdvice"),
    title: i18n.t("common:nav.links.newsAdvice"),
  };
  const contacts = {
    href: "/contacts",
    html: i18n.t("common:nav.links.contactsListings"),
    title: i18n.t("common:nav.links.contactsListings"),
  };
  const discounts = {
    href: "/discounts/members",
    html: i18n.t("common:nav.links.discounts"),
    title: i18n.t("common:nav.links.discounts"),
  };
  const rooms = {
    href: "https://www.spotlight.com/rooms-and-studios/studios/",
    html: i18n.t("common:nav.links.hireRoomsStudios"),
    title: i18n.t("common:nav.links.hireRoomsStudios"),
  };
  const eventsAgent = {
    href: "https://www.spotlight.com/uncategorized/spotlight-performer-events/",
    html: i18n.t("common:nav.links.clientEvents"),
    title: i18n.t("common:nav.links.clientEvents"),
  };
  const eventsCasting = {
    href: "https://www.spotlight.com/uncategorized/spotlight-performer-events/",
    html: i18n.t("common:nav.links.events"),
    title: i18n.t("common:nav.links.events"),
  };

  switch (memberGroup) {
    case MemberGroups.agent:
      return [getInTouch, newsAndAdvice, eventsAgent, contacts, rooms];
    case MemberGroups.castingDirector:
    case MemberGroups.searchOnlyCD:
      return [getInTouch, newsAndAdvice, eventsCasting, contacts, rooms];
    case MemberGroups.performer:
    case MemberGroups.graduatePerformer:
    case MemberGroups.yPerformer:
      return [getInTouch, newsAndAdvice, contacts, discounts, rooms];
    default:
      return [];
  }
}
