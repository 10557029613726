import React, { FC } from "react";
import cx from "classnames";
import { IAddOnFeature } from "../PlanSelector/PlanSelector.helpers";
import { useDynamicWindowWidthFor } from "shared-hooks";
import { SCREEN_WIDTH_ENUM } from "../../../../../../generics/dom-extensions";
import { Trans, useTranslation } from "i18n";

interface IFeatureRowProps {
  feature: IAddOnFeature;
}

const FeatureRow: FC<IFeatureRowProps> = ({ feature }) => {
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const { t } = useTranslation();

  return (
    <div
      key={feature.title}
      className={cx("feature-box__inner__feature", {
        "feature-box__inner__feature--mobile": isMobileView,
      })}
    >
      <div className="feature-box__inner__feature-text-block">
        <img
          className="feature-box__inner__feature-icon"
          src={feature.icon}
          alt={
            feature.iconAlt
              ? t(feature.iconAlt)
              : t("common:billing.planSelector.addOns.iconAlt")
          }
        />
        <div className="feature-box__inner__container">
          <p className="feature-box__inner__feature-title">
            {t(feature.title)}
          </p>
          {feature.additionalIcon && (
            <img
              className={cx("feature-box__inner__feature-additional-icon", {
                "feature-box__inner__feature-additional-icon--mobile":
                  isMobileView,
              })}
              src={feature.additionalIcon}
              alt={
                feature.additionalIconAlt
                  ? t(feature.additionalIconAlt)
                  : t("common:billing.planSelector.addOns.additionalIconAlt")
              }
            />
          )}
          <span>
            <Trans
              i18nKey={feature.description}
              components={{
                link1: <a href={feature.link} target="_blank" />,
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FeatureRow;
