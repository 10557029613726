import _compact from "lodash/compact";
import _keys from "lodash/keys";
import _groupBy from "lodash/groupBy";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _map from "lodash/map";
import _toPairs from "lodash/toPairs";
import _flatten from "lodash/flatten";
import {
  IFacetResponse,
  SkillFacetNames,
  SkillsFacetsType,
} from "shared-services/src/services/facets.types";
import { ISkill } from "shared-types";

export const skillFacetMapping = {
  [SkillsFacetsType.AccentsAndDialects]: SkillFacetNames.AccentsAndDialects,
  [SkillsFacetsType.Languages]: SkillFacetNames.Languages,
  [SkillsFacetsType.MusicAndDance]: SkillFacetNames.MusicAndDance,
  [SkillsFacetsType.Performance]: SkillFacetNames.Performance,
  [SkillsFacetsType.Presenting]: SkillFacetNames.Presenting,
  [SkillsFacetsType.Sports]: SkillFacetNames.Sports,
  [SkillsFacetsType.VehicleLicences]: SkillFacetNames.VehicleLicences,
  [SkillsFacetsType.VoiceOver]: SkillFacetNames.VoiceOver,
  [SkillsFacetsType.OtherSkills]: SkillFacetNames.OtherSkills,
};

export interface IPerformerSkills {
  [SkillFacetNames.AccentsAndDialects]: ISkill[];
  [SkillFacetNames.Languages]: ISkill[];
  [SkillFacetNames.MusicAndDance]: ISkill[];
  [SkillFacetNames.Performance]: ISkill[];
  [SkillFacetNames.Presenting]: ISkill[];
  [SkillFacetNames.Sports]: ISkill[];
  [SkillFacetNames.VehicleLicences]: ISkill[];
  [SkillFacetNames.VoiceOver]: ISkill[];
  [SkillFacetNames.OtherSkills]: ISkill[];
}

export function getPerformersSkills(
  performerSkills: Partial<IPerformerSkills>,
  facetData: IFacetResponse[]
) {
  if (_isEmpty(performerSkills) || _isEmpty(facetData)) return [];

  return _compact(
    _keys(skillFacetMapping).map((facetKey) => {
      const getPerformerFacetsByLevel = _groupBy(
        performerSkills[
          skillFacetMapping[facetKey as SkillsFacetsType.AccentsAndDialects]
        ],
        "levelId"
      );

      // performer doesn't have that skill
      if (_isEmpty(getPerformerFacetsByLevel)) return undefined;

      // find selected skill data
      const findFacet = _find(facetData, {
        systemName: facetKey,
      }) as IFacetResponse;

      const facets =
        findFacet &&
        _map(_toPairs(getPerformerFacetsByLevel), ([levelId, facets]) => {
          // find facet level name
          const getLevelName = _find(findFacet.facetLevels, {
            id: Number(levelId),
          })?.name;
          // get all facets as flat array
          const getCategoryFacets = _flatten(
            _map(findFacet.facetGroups, "facets")
          );
          // find facet name by id
          const facetsNames = _map(
            facets,
            ({ facetId }) => _find(getCategoryFacets, { id: facetId })?.name
          );

          return [getLevelName, facetsNames.join(", ")];
        });

      return [findFacet?.name, facets];
    }) as [string, [string, string][]][]
  );
}
