// TODO in SL-5420: Remove this file

import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import cx from "classnames";
import { useTranslation } from "i18n";
import { useHistory } from "react-router-dom";
import { useRecurly } from "@recurly/react-recurly";
import { RecurlyError } from "@recurly/recurly-js/lib/error";
import { TokenPayload } from "@recurly/recurly-js/lib/token";
import {
  useDynamicWindowWidthFor,
  useExternalScript,
  useMobileKeyboardVisibility,
  useScrollToElement,
} from "shared-hooks";
import SummaryBlock from "./SummaryBlock";
import checkPaymentMethod from "../../utils/checkPaymentMethod";
import DirectDebitConfirmPage from "../DirectDebitCofirmPage";
import SubscriptionBlock from "./SubscriptionBlock";
import {
  directDebitInitialValues,
  DISABILITY_DISCOUNT_CODE_ACC_LEVEL,
  FieldNames,
  PlanType,
  RECURLY_JS_URL,
  USER_ACCOUNT_APP_ROOT,
} from "../../constants";
import { Spinner } from "../../../../Atoms/Spinner/Spinner";
import { BillingUser } from "translations/src/models/billing";
import StickyCTABar from "../../../../Organisms/StickyCTABar";
import {
  CheckoutFlow,
  CommonErrorPopup,
  PlanFilter,
  SCREEN_WIDTH_ENUM,
} from "../../../../../index";
import { billingService } from "../../services";
import usePreventLeaveBillingPage from "../../hooks/usePreventLeaveBillingPage";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { BillingContext } from "../../BillingContext";
import { useGetSubscriptions } from "../../hooks/useGetSubscriptions";
import { useCanAccessCheckout } from "../../hooks/useCanAccessCheckout";
import { useGetSummary } from "../../hooks/useGetSummary";
import {
  ISubscription,
  ISummary,
  ITokenPayload,
  PlanCodeMethod,
  PlanCodePeriod,
} from "../../types";
import useBillingFormValues from "../../hooks/useBillingFormValues";
import useBillingFormValidation from "../../hooks/useBillingFormValidation";
import getInvalidCardFields from "../../utils/getInvalidCardFields";
import ThreeDSecurePage from "../ThreeDSecurePage";
import useCouponCodes from "../../hooks/useCouponCode";
import BillingAndPaymentForm from "./BillingAndPaymentForm/BillingAndPaymentForm";
import useReinitializeCardForm from "../../hooks/useReinitializeCardForm";
import RecurlyWrapper from "../RecurlyWrapper";
import { getPurchaseType } from "../../utils/getPurchaseType";
import MembershipHeader from "./SubscriptionBlock/MembershipHeader";
import "./styles.scss";
import generateBillingInfo from "../../utils/generateBillinginfo";
import { PaymentErrorModal } from "../PaymentErrorModal";

const TOOLTIP_CONTAINER = "TOOLTIP_CONTAINER";

interface Props {
  subscriptions: ISubscription[];
  preselectedSubscription?: ISubscription;
  summary: ISummary;
}

const getCouponCodeForPurchase = (
  coupons: string[],
  hasAccountDiscount: boolean,
  shouldReturnArray: boolean
): string | string[] => {
  const hasAccountDiscountAndCoupon = hasAccountDiscount && coupons.length > 1;

  if (hasAccountDiscountAndCoupon) {
    const couponsLessAccountDiscount = coupons.filter(
      (coupon) => coupon !== DISABILITY_DISCOUNT_CODE_ACC_LEVEL
    );
    return shouldReturnArray
      ? couponsLessAccountDiscount
      : couponsLessAccountDiscount[0];
  }

  if (hasAccountDiscount) {
    return shouldReturnArray ? [] : "";
  }

  return shouldReturnArray ? coupons : coupons[0];
};

const SinglePageCheckoutNew: FC<Props> = ({
  subscriptions,
  preselectedSubscription,
  summary,
}) => {
  const { t } = useTranslation();
  const {
    postCheckoutCallback,
    successCallback,
    goBack,
    goBackText,
    checkoutText,
    navigateToCompletePage,
    planTypeFilter,
    useExistingPaymentMethod,
    checkoutFlow,
    billingUserType,
  } = useContext(BillingContext);
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const mobileKeyboardIsOpen = useMobileKeyboardVisibility(isMobileView);
  const { ref: containerRef, scrollToElement: scrollToHeaderContainer } =
    useScrollToElement();
  const [plan, setPlan] = useState<ISubscription | undefined>(
    preselectedSubscription
  );
  const [isFailed, setIsFailed] = useState<boolean>();
  const [isDirectDebitPreview, setIsDirectDebitPreview] = useState<boolean>();
  const [formSubmissionState, setFormSubmissionState] = useState<{
    isSubmitted: boolean;
    isSubmitting: boolean;
  }>({
    isSubmitted: false,
    isSubmitting: false,
  });
  const [recurlyToken, setRecurlyToken] = useState<string>("");
  const [threeDSToken, setThreeDSToken] = useState<string>("");
  const [cardDetailError, setCardDetailError] = useState<
    Record<string, string>
  >({});
  const [recurlyErrorMsg, setRecurlyErrorMsg] = useState("");
  const [selectedPlanType, setSelectedPlanType] = useState<PlanFilter>(
    // preselectedPlanOverride || PlanFilter.Premium
    PlanFilter.Classic
  );
  const [planPeriod, setPlanPeriod] = useState<PlanCodePeriod>(
    // (planPeriodFilter as PlanCodePeriod) || PlanCodePeriod.Annual
    PlanCodePeriod.Annual
  );
  const [paymentMethod, setPaymentMethod] = useState<PlanCodeMethod>();
  const [isFormOpen, setIsFormOpen] = useState(
    isMobileView ? false : !!preselectedSubscription
  );

  const htmlFormRef = useRef<HTMLFormElement>(null);
  const recurly = useRecurly();
  const setPreventLeave = usePreventLeaveBillingPage();
  const {
    texts: {
      common,
      billingForm,
      paymentDetailForm,
      directDebitForm,
      validation,
    },
  } = useBillingTranslations(BillingUser.Common);

  const { formValues, setFieldValue, setMultipleFields } =
    useBillingFormValues();
  const isDirectDebit = checkPaymentMethod(
    PlanCodeMethod.DirectDebit,
    plan?.code
  );
  const isSepa = plan?.planType === PlanType.Sepa;
  const validationMessages = useBillingFormValidation(
    formValues,
    { ...billingForm, ...directDebitForm },
    validation,
    plan?.planType
  );

  const isMobileViewAndFormOpen = isMobileView && isFormOpen;

  const {
    price,
    userCouponCodesObject,
    userCouponCodesStrings,
    hasAccountDiscount,
    couponErrors,
    setCoupon,
    removeCoupon,
    isCheckoutPreviewRequestLoading,
  } = useCouponCodes(plan?.id, summary.discounts);
  const { cardFormIsVisible, reinitializeForm } = useReinitializeCardForm();
  const { ref: sectionRef } = useScrollToElement({
    block: "nearest",
    inline: "start",
  });

  const history = useHistory();
  // implemented renewal call logic directly because useMutation was messing with billingService's context.
  const [isRenewalLoading, setIsRenewalLoading] = useState(false);
  const [renewalError, setRenewalError] = useState("");

  useEffect(() => {
    if (planTypeFilter) {
      setPlan(undefined);
      setIsFormOpen(false);
    }
  }, [planTypeFilter]);

  const couponBag = {
    hasAccountDiscount,
    userCouponCodesObject,
    setCoupon,
    removeCoupon,
    couponErrors,
    isCheckoutPreviewRequestLoading,
  };

  const formErrors = {
    ...(formSubmissionState.isSubmitted ? validationMessages : {}),
    ...cardDetailError,
  };

  const formWrapperClassName = cx("flex justify-between mb-10", {
    "flex-col": !isFormOpen,
  });

  const genericErrorMsg = t("common:billing.genericErrorMessage");

  const failurePurchaseAction = useCallback(
    (err?: string) => {
      setThreeDSToken("");
      setIsFailed(true);
      setRecurlyErrorMsg(err || genericErrorMsg);
      setFormSubmissionState({
        isSubmitted: true,
        isSubmitting: false,
      });
      reinitializeForm();
    },
    [setThreeDSToken, setIsFailed, reinitializeForm]
  );

  const trackingData = {
    memberType: billingUserType,
    purchaseType: getPurchaseType(checkoutFlow),
    paymentPeriod: plan?.code,
    paymentValue: plan?.amount,
    discountValue: price?.discount,
    promoCode: userCouponCodesStrings,
  };

  const classicSubscription = subscriptions.find(
    (sub) =>
      sub.code.includes(planPeriod) && sub.code.includes(PlanFilter.Classic)
  );
  const premiumSubscription = subscriptions.find(
    (sub) =>
      sub.code.includes(planPeriod) && sub.code.includes(PlanFilter.Premium)
  );

  const purchase = async (token: string, threeDsToken?: string) => {
    try {
      const result = await billingService.purchase({
        token,
        ...(threeDsToken && { threeDsToken }),
        planId: plan?.id || "",
        couponCodes: getCouponCodeForPurchase(
          userCouponCodesStrings,
          hasAccountDiscount,
          true
        ) as string[],
      });

      if (result?.data?.RecurlyError || result?.RecurlyError) {
        failurePurchaseAction(
          result?.data?.RecurlyError?.message || result?.RecurlyError?.message
        );
      }

      if (result?.threeDSActionRequired && result?.actionTokenId) {
        setRecurlyToken(token);
        setThreeDSToken(result.actionTokenId);
        return;
      }

      if (result?.tokenId) {
        if (postCheckoutCallback) {
          await postCheckoutCallback(
            {
              planId: plan?.id,
              planCurrency: plan?.currency,
              couponCodes: getCouponCodeForPurchase(
                userCouponCodesStrings,
                hasAccountDiscount,
                true
              ),
              planTypeFilter: selectedPlanType,
            },
            trackingData
          );
        }

        setPreventLeave(false);

        successCallback && successCallback();
      }

      setFormSubmissionState({
        isSubmitted: true,
        isSubmitting: false,
      });

      if (result?.tokenId) {
        // to render <SuccessPage /> on separate route
        navigateToCompletePage?.(isDirectDebit);
      }
    } catch (e) {
      failurePurchaseAction();
    }
  };

  const removeInvalidCardError = useCallback(
    (field) => {
      setCardDetailError((values) => _omit(values, field));
    },
    [setCardDetailError]
  );

  const tokenHandler =
    (failedCallback?: () => void) =>
    async (err: RecurlyError | null, token: TokenPayload) => {
      if (err) {
        setFormSubmissionState({
          isSubmitted: true,
          isSubmitting: false,
        });
        setRecurlyErrorMsg(err.message || genericErrorMsg);
        setCardDetailError(
          getInvalidCardFields(
            err.fields || [],
            {
              ...paymentDetailForm,
              ...directDebitForm,
              zipLabel: billingForm.zipLabel,
            },
            validation
          )
        );

        failedCallback && failedCallback();
      } else {
        await purchase(token.id);
      }
    };

  const submitForm = async (e?: SyntheticEvent<any>) => {
    setIsFailed(false);
    setFormSubmissionState({
      isSubmitted: true,
      isSubmitting: true,
    });

    e?.preventDefault();

    if (
      useExistingPaymentMethod &&
      (checkoutFlow === CheckoutFlow.AccountSettings ||
        checkoutFlow === CheckoutFlow.Default)
    ) {
      setIsRenewalLoading(true);
      const planId = plan?.id ?? "";
      const couponCodes = getCouponCodeForPurchase(
        userCouponCodesStrings,
        hasAccountDiscount,
        true
      );

      try {
        const renewalData = await billingService.renewSubscriptionWithExisting({
          planId,
          // @ts-ignore
          couponCodes, // safe to ignore because we're passing shouldReturnArray = true to getCouponCodeForPurchase
        });

        if (!renewalData.accountId) {
          setRenewalError(genericErrorMsg);
          return;
        }

        if (postCheckoutCallback) {
          await postCheckoutCallback(
            {
              planId,
              planCurrency: plan?.currency,
              couponCodes,
              planTypeFilter,
            },
            trackingData
          );
        }

        setIsRenewalLoading(false);

        if (navigateToCompletePage) {
          navigateToCompletePage(false);
          return;
        } else {
          history.push("/myhome/account-settings");
        }
      } catch {
        setRenewalError(genericErrorMsg);
        return;
      }
    }

    if (!_isEmpty(validationMessages) || !_isEmpty(cardDetailError)) {
      setFormSubmissionState({
        isSubmitted: true,
        isSubmitting: false,
      });
      return;
    }

    if (isDirectDebit && !isDirectDebitPreview) {
      setIsDirectDebitPreview(true);
      return;
    }

    if (isDirectDebit && isDirectDebitPreview) {
      recurly.bankAccount.token(
        generateBillingInfo(formValues, isSepa),
        tokenHandler(() => {
          setIsDirectDebitPreview(false);
        })
      );

      return;
    }

    recurly.token(htmlFormRef.current as HTMLFormElement, tokenHandler());
  };

  const submitThreeDSecure = useCallback(
    async (threeDSToken: ITokenPayload) => {
      await purchase(recurlyToken, threeDSToken.id);
    },
    [recurlyToken, purchase]
  );

  const handlePlanSelect = (
    paymentMethod: PlanCodeMethod,
    planPeriod: PlanCodePeriod,
    selectedPlanType: PlanFilter
  ) => {
    const selectedPlan = subscriptions.find(
      (sub) =>
        sub.code.includes(planPeriod) &&
        sub.code.includes(selectedPlanType) &&
        checkPaymentMethod(paymentMethod, sub.code)
    );

    if (selectedPlan) {
      setPlan(selectedPlan);

      if (!isMobileView) {
        setIsFormOpen(true);
      }
    }
  };

  const handlePlanTypeChange = (planType: PlanFilter) => {
    setSelectedPlanType(planType);

    handlePlanSelect(paymentMethod as PlanCodeMethod, planPeriod, planType);
  };

  const changePlanPeriod = (isChecked: boolean) => {
    const period = isChecked ? PlanCodePeriod.Annual : PlanCodePeriod.Monthly;
    const isMonthlyPeriod = period === PlanCodePeriod.Monthly;

    setPlanPeriod(period);

    if (setPaymentMethod) setPaymentMethod(PlanCodeMethod.DirectDebit);

    handlePlanSelect(
      isMonthlyPeriod
        ? PlanCodeMethod.DirectDebit
        : (paymentMethod as PlanCodeMethod),
      period,
      selectedPlanType
    );
  };

  const handleChangePlanMethod = (paymentMethodType: PlanCodeMethod) => {
    setPaymentMethod(paymentMethodType);
    setFormSubmissionState({
      isSubmitted: false,
      isSubmitting: false,
    });
    setMultipleFields(directDebitInitialValues);

    handlePlanSelect(paymentMethodType, planPeriod, selectedPlanType);
  };

  const handleBackButton = () => {
    if (isMobileViewAndFormOpen) {
      setIsFormOpen(false);
      scrollToHeaderContainer();
      return;
    }
    return goBack && goBack();
  };

  const handleContinueButton = (e: SyntheticEvent<Element, Event>) => {
    if (isMobileView && !!plan && !isFormOpen) {
      setIsFormOpen(true);
      scrollToHeaderContainer();
      return;
    }

    return submitForm(e);
  };

  const directDebitPreviewOnEdit = () => {
    setFormSubmissionState({
      isSubmitted: true,
      isSubmitting: false,
    });
    setIsDirectDebitPreview(false);
  };

  const getBackBtnText = () => {
    if (isMobileViewAndFormOpen) {
      return common.backMobile;
    }

    if (goBackText) {
      return goBackText;
    }

    return common.back;
  };

  const getCheckoutBtnText = () => {
    if (isMobileView && !isFormOpen) {
      return common.continueMobile;
    }

    if (useExistingPaymentMethod) {
      return t("common:button.label.continue");
    }

    if (checkoutText) {
      return checkoutText;
    }

    return common.submit;
  };

  if (isRenewalLoading) return <Spinner />;

  if (renewalError) {
    return (
      <CommonErrorPopup
        errorStatusCode={400}
        popupProps={{
          close: () => history.go(0),
        }}
        alertMessageProps={{
          buttons: [
            {
              name: t("common:button.label.close"),
              type: "primary",
              click: () => history.go(0),
            },
          ],
        }}
      />
    );
  }

  if (threeDSToken && !isFailed)
    return (
      <ThreeDSecurePage
        threeDSToken={threeDSToken}
        submitThreeDSecure={submitThreeDSecure}
        onThreeDSecureError={failurePurchaseAction}
        threeDSClassName="h-[800px] m-auto w-full md:w-[400px]"
      />
    );
  if (isDirectDebitPreview && !isFailed)
    return (
      <DirectDebitConfirmPage
        name={`${summary.firstName} ${summary.lastName}`}
        email={summary.email}
        accountName={formValues[FieldNames.accountName]}
        sortCode={formValues[FieldNames.sortCode]}
        accountNumber={
          formValues[FieldNames.accountNumber] || formValues[FieldNames.iban]
        }
        directDebitPreviewOnEdit={directDebitPreviewOnEdit}
        submitForm={submitForm}
        setPreventLeave={setPreventLeave}
        planType={plan?.planType}
      />
    );

  return (
    <div
      ref={containerRef}
      className="checkout-single-page"
      id={TOOLTIP_CONTAINER}
    >
      {!!recurlyErrorMsg && (
        <PaymentErrorModal
          handleCloseModal={() => {
            setRecurlyErrorMsg("");
          }}
          title={t("common:billing.errorPopup.title")}
          description={recurlyErrorMsg}
        />
      )}
      <MembershipHeader
        isChecked={planPeriod === PlanCodePeriod.Annual}
        onCheck={changePlanPeriod}
      />
      {isMobileViewAndFormOpen && plan ? (
        <SummaryBlock
          plan={plan}
          summary={summary}
          price={price}
          couponBag={couponBag}
          isMobileView={isMobileView}
        />
      ) : (
        <SubscriptionBlock
          classicSubscription={classicSubscription}
          premiumSubscription={premiumSubscription}
          planPeriod={planPeriod}
          selectedPlanType={selectedPlanType}
          setSelectedPlanType={handlePlanTypeChange}
        />
      )}

      <div className={formWrapperClassName}>
        <BillingAndPaymentForm
          isFormOpen={isFormOpen}
          formErrors={formErrors}
          submitForm={submitForm}
          billingFormRef={htmlFormRef}
          sectionRef={sectionRef}
          setFieldValue={setFieldValue}
          isSubmitted={formSubmissionState.isSubmitted}
          tootlipContainerConst={TOOLTIP_CONTAINER}
          formValues={formValues}
          cardDetailError={cardDetailError}
          removeInvalidCardError={removeInvalidCardError}
          isMobileView={isMobileView}
          cardFormIsVisible={cardFormIsVisible}
          isDirectDebit={isDirectDebit}
          planType={plan?.planType}
          planPeriod={planPeriod}
          selectedMethod={paymentMethod}
          setPaymentMethod={handleChangePlanMethod}
        />
        {isFormOpen && !isMobileView && plan && (
          <SummaryBlock
            plan={plan}
            summary={summary}
            price={price}
            couponBag={couponBag}
            isMobileView={isMobileView}
          />
        )}
      </div>
      <StickyCTABar
        prev={{
          text: getBackBtnText(),
          onClick: handleBackButton,
        }}
        next={{
          text: getCheckoutBtnText(),
          onClick: handleContinueButton,
          disabled: isMobileView
            ? !plan || formSubmissionState.isSubmitting
            : !isFormOpen || formSubmissionState.isSubmitting,
        }}
        isSticky={!mobileKeyboardIsOpen}
        ctaBarAdditionalClassName="max-w-full"
      />
    </div>
  );
};

export default ({ isRenewalReactivation = false }) => {
  const [loaded] = useExternalScript(RECURLY_JS_URL);
  const { planCodeFilter } = useContext(BillingContext);
  const { isLoading: canAccessCheckoutLoading } = useCanAccessCheckout(
    isRenewalReactivation,
    USER_ACCOUNT_APP_ROOT
  );

  const { data: summary, isLoading: summaryLoading } = useGetSummary();
  const { data: subscriptions, isLoading: subscriptionLoading } =
    useGetSubscriptions();

  if (!Array.isArray(subscriptions)) return null;

  const preselectedSubscription = subscriptions.find(
    ({ code }) => code === planCodeFilter
  );

  if (
    !loaded ||
    subscriptionLoading ||
    summaryLoading ||
    canAccessCheckoutLoading
  )
    return <Spinner />;

  return (
    <RecurlyWrapper>
      <SinglePageCheckoutNew
        subscriptions={subscriptions}
        preselectedSubscription={preselectedSubscription}
        summary={summary}
      />
    </RecurlyWrapper>
  );
};
