/**
 * Stateless select input-like component
 */
import React from "react";
import { ERROR, InputWrapper } from "../../Atoms/InputsCommon/InputWrapper";
import { IBaseInputProps } from "../../Atoms/InputsCommon/BaseInputProps";
import { InputLabel } from "../../Atoms/InputsCommon/InputLabel";
import { Select } from "../Select/Select";
import { Omit } from "../../../generics/type-manipulation";
import { IOption } from "../../../generics/interfaces";

import "./SelectInput.scss";

export type ISelectInputProps<T extends string | number> = Omit<
  IBaseInputProps<HTMLDivElement, T>,
  "onChange"
> & {
  /**
   * List of options
   */
  options: Array<IOption<T>>;
  /**
   * Placeholder
   */
  placeholder?: string;
  /**
   * Callback to selecting an option which provides just the option's value.
   */
  valueChanged?: (selectedValue: T) => void;
  /**
   * Callback to selecting an option which provides the option itself.
   */
  optionSelected?: (selectedOption: IOption<T>) => void;
};

export interface ITextSelectInputState {
  blurred: boolean;
}

export class SelectInput<T extends string | number> extends React.Component<
  ISelectInputProps<T>,
  ITextSelectInputState
> {
  public constructor(props: ISelectInputProps<T>) {
    super(props);

    this.state = {
      blurred: false,
    };
  }

  public render() {
    const invalidState =
      this.props.invalid && (this.state.blurred || this.props.isFormSubmitted)
        ? ERROR
        : undefined;
    return (
      <div
        className={`c-select-input${
          this.props.className ? " " + this.props.className : ""
        }`}
      >
        <InputWrapper
          invalid={invalidState}
          validationMesssage={this.props.validationMessage}
        >
          <InputLabel label={this.props.label} annex={this.props.annex} />
          <Select<T>
            {...this.props}
            onChange={this.valueChanged}
            label={undefined}
            onBlur={this.onBlur}
            className={`c-select-input__select${
              this.props.disabled ? " disabled" : ""
            }`}
          />
        </InputWrapper>
      </div>
    );
  }

  private valueChanged = (selectedOption: IOption<T>) => {
    if (this.props.valueChanged) {
      this.props.valueChanged(selectedOption.value);
    }
    if (this.props.optionSelected) {
      this.props.optionSelected(selectedOption);
    }
  };

  private onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }

    this.setState({
      blurred: true,
    });
  };
}
