import cx from "classnames";
import React from "react";

import PageContent from "./PageContent/PageContent";
import PageSection from "./PageSection/PageSection";
import PageTitle from "./PageTitle/PageTitle";
import PageSubtitle from "./PageSubtitle";

import "./Page.scss";

export interface IPageProps {
  background: "primary" | "secondary";
  fullHeight?: boolean;
  withBottomPadding?: boolean;
  className?: string;
}

class Page extends React.PureComponent<IPageProps> {
  public static Title = PageTitle;
  public static Content = PageContent;
  public static Section = PageSection;
  public static Subtitle = PageSubtitle;

  public render() {
    const { className, background, fullHeight, withBottomPadding, children } =
      this.props;

    return (
      <section
        className={cx("c-page", className, `c-page--background-${background}`, {
          "c-page--full-height": fullHeight,
          "c-page--with-bottom-padding": withBottomPadding,
        })}
      >
        {children}
      </section>
    );
  }
}

export default Page;
