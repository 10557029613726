import { useQueryClient } from "react-query";
import _map from "lodash/map";
import { FETCH_COUNTRIES } from "shared-services";
import { IOption } from "../../../generics/interfaces";
import { LocalizationUtils } from "../../../index";

interface ICountryWithState {
  localisedCountryName: string;
  countryCode: string;
  states?: {
    id: string;
    stateName: string;
  }[];
}

const locale = LocalizationUtils.getUserLocalizationPreference().locale;

export const useGetStatsProvincesByCountryOptions = (
  selectedCountryCode?: string
) => {
  const queryClient = useQueryClient();

  if (!selectedCountryCode) return [];

  const countriesAndStatesDataFromCache: ICountryWithState[] | undefined =
    queryClient.getQueryData([FETCH_COUNTRIES, locale], {});

  if (!countriesAndStatesDataFromCache) return [];

  const statesProvincesByCountry = countriesAndStatesDataFromCache.find(
    (country) => country.countryCode === selectedCountryCode
  );

  const statesProvinces: IOption<string>[] = _map(
    statesProvincesByCountry?.states || [],
    (state) => ({
      name: state.stateName,
      value: state.id,
    })
  );

  return statesProvinces;
};
