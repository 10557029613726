import React from "react";
import { dataTestIds } from "data-testids";
import "./LinksListWithTitle.scss";
import { ILink } from "../../../generics/interfaces";
import DOMPurify from "dompurify";

export interface ILinksListWithTitleProps {
  /**
   * The text to show as a title above the border
   */
  titleText: string;
  /**
   * The list of links to show under the border
   */
  links: ILink[];
  /** Optional css class for wrapper */
  className?: string;
}

export class LinksListWithTitle extends React.Component<ILinksListWithTitleProps> {
  public render() {
    return (
      <div
        className={`c-links-list${
          this.props.className ? " " + this.props.className : ""
        }`}
      >
        <div className="c-links-list__site-links">
          <div className="c-links-list__site-links-header">
            {this.props.titleText}
          </div>
          <ul className="c-links-list__site-links-list">{this.linksList()}</ul>
        </div>
      </div>
    );
  }

  public linksList = () => {
    return this.props.links.map((link, index) => (
      <li
        key={index}
        data-testid={
          dataTestIds.componentLibrary["Molecules.LinksListWithTitle.siteLinks"]
        }
      >
        <a
          className="c-links-list__link"
          href={link.href}
          title={link.title}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link.html) }}
        />
      </li>
    ));
  };
}
