import { IBillingUser } from "../../models/billing/user";

export const BillingCastingTranslations: IBillingUser = {
  common: {
    pageTitle: "Your subscription plan",
    pageCopy: `<p>Please select the subscription plan you wish to purchase.</p><p>We will contact you 14 days before your expiry date to remind you to renew your Spotlight subscription. At this point you can select an alternative term.</p><p><strong>Your details</strong></p><p>If any of your details on this page are incorrect please <a href="https://www.spotlight.com/get-in-touch" title="Contact us">contact us</a> immediately.</p>`,
    userType: "Spotlight Casting Professionals",
    successHeader: "Payment successful!",
    successBody: `<p>Thank you for renewing your subscription to Spotlight, the UK's number one casting resource.</p><p>Continue to <a href="https://spotlight.com/myhome" title="My Spotlight account">your account</a> to search our database of 60,000+ professional actors, dancers, child performers, presenters, plus you can watch performer showreels and listen to their voice-clips.</p><p>We'd like to help you make the most of everything Spotlight has to offer, so please don't hesitate to get in touch if you have any queries. Just <a href="mailto:casting@spotlight.com?subject=Spotlight casting support and advice">email us</a> or call <a href="tel:004420 7437 7631">020 7437 7631</a>.</p><p><strong>Need to book space for your next project?</strong></p><p>If you need a casting venue, don't forget we have a selection of casting rooms and studios at our <a href="https://www.spotlight.com/get-in-touch" title="Contact Us">Leicester Square offices</a>.<br />Visit <a href="https://www.spotlight.com/rooms-and-studios" title="Spotlight Rooms &amp; Studios">our dedicated page</a> to make a booking.</p>`,
    goBackButton: "Go to My Account",
  },
};
