import React, { FC, useRef, useState } from "react";
import { useTranslation } from "i18n";
import { dataTestIds } from "data-testids";
import { Popup } from "../../../../../Molecules/Popup/Popup";
import { Button } from "../../../../../Atoms/Button/Button";
import AddOnPurchaseSteps, { AddOnPurchaseStep } from "./AddOnPurchaseSteps";
import { PurchaseFormRefObject } from "./types";
import AddOnPreview from "./components/AddOnPreview";
import { useGetAddOnPreview } from "./hooks/useGetAddOnPreview";
import { IAddOns } from "../../../types";
import { AddOnCodes } from "../../../constants";
import { getModalTexts } from "../PlanSelector/PlanSelector.helpers";
import CommonErrorPopup from "../../../../CommonErrorPopup";

interface Props {
  closePopUp: () => void;
  onPrimaryCtaClick?: () => void;
  onSecondaryCtaClick?: () => void;
  onSuccessCallback: (trackingData?: any) => void;
  preselectedAddOn?: IAddOns;
  selectedAddOnCode?: AddOnCodes;
  isOpen?: boolean;
  texts?: Record<string, string>;
}

const AddOnPurchaseModal: FC<Props> = ({
  closePopUp,
  onSuccessCallback,
  onPrimaryCtaClick,
  onSecondaryCtaClick,
  preselectedAddOn,
  selectedAddOnCode,
  isOpen,
  texts,
}) => {
  const purchaseFormRef = useRef<PurchaseFormRefObject>({
    submitPayment: undefined,
  });
  const { t } = useTranslation();
  const [step, setStep] = useState(AddOnPurchaseStep.Preview);
  const [loading, setLoading] = useState(false);

  const isCheckoutSpeedbump = !!preselectedAddOn;

  const {
    data = [],
    isLoading,
    error,
  } = useGetAddOnPreview(selectedAddOnCode, !isCheckoutSpeedbump);

  const isPreview = step === AddOnPurchaseStep.Preview;

  const submit = async () => {
    setLoading(true);
    await purchaseFormRef.current?.submitPayment?.();
    setLoading(false);
  };

  const handleNextClick = () => {
    if (isCheckoutSpeedbump && onPrimaryCtaClick) return onPrimaryCtaClick();

    if (isPreview) return setStep(AddOnPurchaseStep.Checkout);

    submit();
  };

  const onClickClose = () => {
    closePopUp();
    setStep(AddOnPurchaseStep.Preview);
  };

  const renderContent = () => {
    if (isCheckoutSpeedbump && preselectedAddOn) {
      return (
        <AddOnPreview
          data={[preselectedAddOn]}
          isLoading={isLoading}
          texts={texts}
        />
      );
    }

    if (selectedAddOnCode) {
      return (
        <AddOnPurchaseSteps
          step={step}
          purchaseFormRef={purchaseFormRef}
          onSuccessCallback={onSuccessCallback}
          data={data}
          isLoading={isLoading}
          texts={{ ...getModalTexts(isCheckoutSpeedbump), ...texts }}
        />
      );
    }
  };

  const handleBackClick = () => {
    if (onSecondaryCtaClick) return onSecondaryCtaClick();
    return onClickClose();
  };

  if ((!selectedAddOnCode && !preselectedAddOn) || isOpen === false)
    return null;

  if (error) {
    return <CommonErrorPopup errorStatusCode={error.response?.status} />;
  }

  return (
    <Popup
      width={{ lg: 8, md: 10 }}
      texts={{
        closePopup: t("common:button.label.cancel"),
      }}
      close={onClickClose}
      className="z-[105]"
      data-testid={
        dataTestIds.componentLibrary[
          "Organisams.Billing.AddOnCheckout.AddOnPurchaseModal"
        ]
      }
    >
      <div className="g-popup-content">
        <div className="g-popup-content__header">
          {t(getModalTexts(isCheckoutSpeedbump).modalTitle)}
        </div>
        <div className="g-popup-content__scrollable">
          <div className="px-[10px] py-5 min-h-[200px]">
            <div className="mb-[11px]">{renderContent()}</div>
          </div>
        </div>
        <hr className="g-split-line" />
        <div className="g-popup-content__buttons-set g-popup-content__buttons-set--reverse-small g-inner-grid g-col-lg-8 g-col-md-10">
          <div className="g-col-lg-3 g-col-md-3 g-start-lg-1 g-start-md-1">
            <Button
              type="secondary"
              text={
                isPreview
                  ? t(getModalTexts(isCheckoutSpeedbump).secondaryCta)
                  : t("common:button.label.back")
              }
              onClick={
                isPreview
                  ? handleBackClick
                  : () => setStep(AddOnPurchaseStep.Preview)
              }
              data-testid={
                dataTestIds.componentLibrary[
                  "Organisams.Billing.AddOnCheckout.AddOnPurchaseModal.secondaryCta"
                ]
              }
            />
          </div>
          <div className="g-col-lg-3 g-col-md-3 g-start-md-8 g-start-lg-6">
            <Button
              type="primary"
              text={
                isPreview
                  ? t(getModalTexts(isCheckoutSpeedbump).primaryCta)
                  : t("common:button.label.makePayment")
              }
              onClick={handleNextClick}
              loading={loading}
              data-testid={
                dataTestIds.componentLibrary[
                  "Organisams.Billing.AddOnCheckout.AddOnPurchaseModal.primaryCta"
                ]
              }
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default AddOnPurchaseModal;
