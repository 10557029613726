import React from "react";
import DOMPurify from "dompurify";

import { ReactComponent as SpotlightLogo } from "@assets/svg/Spotlight-Logo-white.svg";

import "./Header.scss";

import { Button } from "../../Atoms/Button/Button";
import { ILink } from "../../../generics/interfaces";

export interface IHeaderTexts {
  signIn?: string;
  signOut?: string;
  myAccount?: string;
  logoLabel: string;
  burgerLabelOpen?: string;
  burgerLabelClose?: string;
}

export interface IHeaderProps {
  /**
   * Texts for the Sign In, Sign Out, My Account buttons
   */
  texts: IHeaderTexts;
  /**
   * Link to user account
   */
  myAccountLink?: string;
  /**
   * Clicking on the logo takes the user here
   */
  logoLink: string;
  /**
   * List of navigation links
   */
  links?: ILink[];
  /**
   * Authentication status
   */
  signedIn?: boolean;
  /**
   * Used to hide the sign in button
   */
  hideSignIn?: boolean;
  /**
   * Used to hide the burger menu
   */
  hideMobileMenu?: boolean;
  /**
   * Defines handlers for sign in, sign out, click country icon
   */
  functions?: {
    signIn: () => void;
    signOut: () => void;
    clickCountryIcon?: () => void;
  };
  /**
   * ISO 3166-1 country codes (Alpha-2 code) in lower case. ex: gb, fr
   */
  countryISOCode?: string;
  /** Optional css class for the topmost div */
  className?: string;
}

export interface IHeaderState {
  isOpened: boolean;
  maxHeight: string;
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
  public toggleButton: React.RefObject<HTMLAnchorElement>;
  private menu: React.RefObject<HTMLElement>;

  constructor(props: IHeaderProps) {
    super(props);

    this.toggleButton = React.createRef();
    this.menu = React.createRef();
    this.state = {
      isOpened: false,
      maxHeight: "auto",
    };
  }

  public render() {
    const buttons = this.getButtons(this.props);
    const links = this.getLinkList(this.props);

    return (
      <header
        className={`c-header${
          this.props.className ? " " + this.props.className : ""
        }`}
      >
        <div className="c-header__container">
          <div className="c-header__background g-hidden-lg" />
          <a
            href={this.props.logoLink}
            aria-label={this.props.texts.logoLabel}
            className="c-header__logo"
          >
            <SpotlightLogo />
          </a>
          {!this.props.hideMobileMenu && (
            <>
              <a
                ref={this.toggleButton}
                href="#/"
                aria-label={
                  this.state.isOpened
                    ? this.props.texts?.burgerLabelClose
                    : this.props.texts?.burgerLabelOpen
                }
                onClick={this.handleToggle}
                onKeyPress={this.handleToggleKey}
                className={`c-header__nav-toggle g-hidden-lg ${
                  this.state.isOpened ? "icon-cross" : "icon-burger"
                }`}
              />
              <nav
                ref={this.menu}
                style={{ maxHeight: this.state.maxHeight }}
                className={`c-header__nav ${
                  this.state.isOpened ? "active" : ""
                }`}
              >
                {links}
                {!this.props.hideSignIn && buttons}
                {this.props.countryISOCode ? (
                  <a
                    className={`c-header__country flag-${this.props.countryISOCode}`}
                    onClick={this.props.functions?.clickCountryIcon}
                  />
                ) : null}
              </nav>
            </>
          )}
        </div>
      </header>
    );
  }

  public handleToggleKey = (event: any): void => {
    if (event.key === "Enter" || event.key === " ") {
      this.handleToggle(event);
    }
  };

  public handleToggle = (event: any): void => {
    event.preventDefault();
    this.toggle();
  };

  public toggle = (): void => {
    let maxHeight = "auto";

    if (!this.state.isOpened) {
      document.addEventListener("click", this.handleOutsideClick, false);

      const navMenu = this.menu.current as HTMLElement;

      if (navMenu) {
        maxHeight = navMenu.offsetHeight + "px";
      }
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.setState((previousState: IHeaderState) => {
      return { isOpened: !previousState.isOpened, maxHeight };
    });
  };

  public handleOutsideClick = (event: any) => {
    if (
      this.toggleButton.current &&
      this.toggleButton.current.contains(event.target)
    ) {
      return;
    }

    this.toggle();
  };

  private getLinkList(props: IHeaderProps): JSX.Element[] {
    if (!props.links) {
      return [];
    }

    return props.links.map((link, index) => {
      return (
        <a
          className="c-header__nav-item"
          key={index}
          href={link.href}
          title={link.title}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(link.html) }}
        />
      );
    });
  }

  private getButtons(props: IHeaderProps): JSX.Element[] {
    const buttons: JSX.Element[] = [];
    if (props.signedIn) {
      buttons.push(
        <Button
          type="primary"
          text={props.texts.myAccount || ""}
          href={this.props.myAccountLink}
        />
      );
      buttons.push(
        <Button
          type="secondary"
          text={props.texts.signOut || ""}
          onClick={this.signOut}
        />
      );
    } else {
      buttons.push(
        <Button
          type="primary"
          text={props.texts.signIn || ""}
          onClick={this.signIn}
        />
      );
    }

    return buttons.map((button, index) => {
      return (
        <div key={index} className="c-header__nav-button">
          {button}
        </div>
      );
    });
  }

  private signIn = () => {
    return this.props.functions!.signIn();
  };

  private signOut = () => {
    return this.props.functions!.signOut();
  };
}
