import React, { RefObject, useCallback } from "react";
import Select, {
  components,
  OptionProps,
  createFilter,
  SingleValueProps,
  GroupBase,
} from "react-select";
import { ReactComponent as ArrowDown } from "../../../images/svg/icons/arrow-select-down.svg";
import { InputWrapper } from "../InputsCommon/InputWrapper";
import { Countries } from "./PhoneOptions";
import { InputLabel } from "../InputsCommon/InputLabel";
import { IOption } from "../FormikInputs/SelectFormik/SelectFormik";
import { InputSearchIcon } from "../InputsCommon/InputSearchIcon";
import { customStyles } from "./customStyles";
import "./PhoneField.scss";

const { MenuList, ValueContainer, SingleValue, Placeholder, Option } =
  components;

interface IPhoneFieldProps {
  name: string;
  label?: string;
  searchPlaceholderText?: string;
  tooltipComponent?: () => JSX.Element;
  shouldScrollToView?: boolean;
  invalid?: string;
  setValue?: (value: string) => void;
  setTouched?: () => void;
  initialCountryCode?: string;
  initialNumber?: string;
  validationMessage?: string;
  fieldRef?: RefObject<HTMLInputElement>;
  containerElementRef?: RefObject<HTMLDivElement>;
}

const CA_US_COUNTRY_CODE = "+1";
const UK_COUNTRY_CODE = "+44";

const getSelectedOptionCode = (selectedLabel: string) =>
  Countries.find((c) => c.label === selectedLabel)?.code;

const IconOption = (props: OptionProps<IOption, false, GroupBase<IOption>>) => {
  const optionCode = getSelectedOptionCode(props.label);

  if (!optionCode) return null;

  return (
    <Option {...props}>
      <img
        src={require(`../../../images/svg/flags/${optionCode}.svg`)}
        className="phone-label__icon"
      />
      {props.data.label}
    </Option>
  );
};

const ValueOption = (
  props: SingleValueProps<IOption, false, GroupBase<IOption>>
) => {
  const optionCode = getSelectedOptionCode(props.data.label);

  if (!optionCode) return null;

  return (
    <SingleValue {...props}>
      <div className="phone-label phone-label__icon-section">
        <img
          src={require(`../../../images/svg/flags/${optionCode}.svg`)}
          className="phone-label__icon"
          alt={props.selectProps.getOptionLabel(props.data)}
        />
        <div className="">{props.selectProps.getOptionValue(props.data)}</div>
      </div>
    </SingleValue>
  );
};

/**
 * Initial phone number value to be provided in format: "<country-code> <phone-number>"
 * To make it work(search for code) with CA and US, as they both have +1 country code, we need to save the country code abbreviation in session storage (sessionStorageCountryPhoneCodeAbbr)
 * To preserve phone code when navigating back/forth - we retrieve it from local storage, if it's not +1 - we use initialCountryCode val saved in formik
 */
const PhoneField: React.FC<IPhoneFieldProps> = ({
  name,
  label,
  searchPlaceholderText,
  tooltipComponent,
  setValue,
  setTouched,
  shouldScrollToView = true,
  invalid,
  initialCountryCode = UK_COUNTRY_CODE,
  initialNumber = "",
  validationMessage = "",
  fieldRef,
  containerElementRef,
}) => {
  const sessionStorageCountryPhoneCodeAbbr = `cc-${name}`;
  const containerRef = React.useRef(null);
  const [countryCodeValue, setCountryCodeValue] =
    React.useState(initialCountryCode);
  const [number, setNumber] = React.useState(initialNumber);
  const [isFocused, setIsFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(initialNumber);

  // @ts-ignore
  const onDomClick = (e: MouseEvent) => {
    // @ts-ignore
    const menu = containerRef?.current?.querySelector(".select__menu");

    if (
      // @ts-ignore
      !containerRef?.current?.contains(e.target) ||
      !menu ||
      !menu.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValue("");
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", onDomClick);
    // need to remove item from local storage if user did not chose +1(CA or US) previously
    // to reset the value to initialCountryCode
    if (
      initialCountryCode !== CA_US_COUNTRY_CODE &&
      !!sessionStorage.getItem(sessionStorageCountryPhoneCodeAbbr)
    ) {
      sessionStorage.removeItem(sessionStorageCountryPhoneCodeAbbr);
    }

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

  React.useEffect(() => {
    if (setValue) {
      setValue(`${countryCodeValue} ${number}`);
    }
  }, [countryCodeValue, number]);

  const onChange = useCallback(
    (option: { value: string; code: string }) => {
      setCountryCodeValue(option.value);
      setIsFocused(false);

      // to be able to preserve phone code when navigating back/forth
      if (option.value === CA_US_COUNTRY_CODE) {
        sessionStorage.setItem(sessionStorageCountryPhoneCodeAbbr, option.code);
      } else {
        sessionStorage.removeItem(sessionStorageCountryPhoneCodeAbbr);
      }
    },
    [setCountryCodeValue, setIsFocused]
  );
  const onBlur = useCallback(() => setTouched && setTouched(), [setTouched]);
  const onInputChange = useCallback(
    (val: any) => setInputValue(val),
    [setInputValue]
  );
  const onMenuInputFocus = useCallback(
    () => setIsFocused(true),
    [setIsFocused]
  );

  const getSelectedPhoneOption = () => {
    const storedPhoneCode = sessionStorage.getItem(
      sessionStorageCountryPhoneCodeAbbr
    );

    return PhoneOptions.find((option) => {
      // for CA and US we need to use the code from local storage
      if (storedPhoneCode) {
        return option.code === storedPhoneCode;
      }

      return option.value === countryCodeValue;
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={shouldScrollToView ? containerElementRef : null}
        style={{ position: "absolute", top: "-54px" }}
      />
      <InputWrapper invalid={invalid} validationMesssage={validationMessage}>
        <div className="flex items-baseline justify-between">
          <InputLabel label={label} />
          {tooltipComponent && tooltipComponent()}
        </div>
        <div className="select">
          <Select
            name={`${name}PhoneCountryCode`}
            defaultValue={getSelectedPhoneOption()}
            filterOption={createFilter({
              ignoreCase: true,
              ignoreAccents: true,
              matchFrom: "any",
              stringify: (option) => `${option?.label}`,
              trim: true,
            })}
            onChange={onChange}
            options={PhoneOptions}
            onBlur={onBlur}
            placeholder={""}
            styles={customStyles}
            tabIndex={0}
            components={{
              MenuList: CustomMenuList,
              ValueContainer: CustomValueContainer,
              DropdownIndicator,
              IndicatorSeparator: () => null,
              Option: IconOption,
              SingleValue: ValueOption,
            }}
            inputValue={inputValue}
            onInputChange={onInputChange}
            isSearchable={false}
            onMenuInputFocus={onMenuInputFocus}
            menuIsOpen={isFocused || undefined}
            isFocused={isFocused || undefined}
            searchPlaceholder={searchPlaceholderText || ""}
          />
        </div>

        <div className="c-input-phone">
          <input
            ref={shouldScrollToView ? fieldRef : null}
            name={name}
            id={name}
            value={!number ? "" : number}
            onChange={(e) => {
              let value = e.target.value;

              if (value.startsWith("0")) {
                value = value.substring(1);
              }

              setNumber(value);
            }}
            onBlur={() => setTouched && setTouched()}
            type="tel"
          />
        </div>
      </InputWrapper>
    </div>
  );
};

const PhoneOptions = Countries.map(({ value, label, code }) => {
  return {
    value,
    label,
    code,
  };
});

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDown />
    </components.DropdownIndicator>
  );
};

// @ts-ignore
const CustomMenuList = ({ selectProps, ...props }) => {
  const { onInputChange, inputValue, onMenuInputFocus, searchPlaceholder } =
    selectProps;

  const ariaAttributes = {
    "aria-autocomplete": "list",
    "aria-label": selectProps["aria-label"],
    "aria-labelledby": selectProps["aria-labelledby"],
  };

  return (
    <div className="bg-color-neutral-three">
      <div className="p-2 bg-color-neutral-three">
        {/* @ts-ignore */}
        <input
          className="box-border w-full p-2 bg-white border border-color-neutral-one pl-9"
          autoCorrect="off"
          autoComplete="off"
          spellCheck="false"
          type="text"
          value={inputValue}
          onChange={(e) =>
            onInputChange(e.currentTarget.value, {
              action: "input-change",
            })
          }
          onMouseDown={(e) => {
            e.stopPropagation();
            // @ts-ignore
            e.target.focus();
          }}
          onTouchEnd={(e) => {
            e.stopPropagation();
            // @ts-ignore
            e.target.focus();
          }}
          onFocus={onMenuInputFocus}
          placeholder={searchPlaceholder}
          {...ariaAttributes}
        />
        <div style={{ marginTop: 2 }}>
          <InputSearchIcon />
        </div>
      </div>

      {/* @ts-ignore */}
      <MenuList {...props} selectProps={selectProps} />
    </div>
  );
};

// @ts-ignore
const CustomValueContainer = ({ children, selectProps, ...props }) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps,
    theme: props.theme,
    getClassNames: props.getClassNames,
  };

  const optionCode = getSelectedOptionCode(selectProps.value?.label);

  return (
    // @ts-ignore
    <ValueContainer {...props} selectProps={selectProps}>
      {React.Children.map(children, (child) => {
        const propValue = props.hasValue ? (
          <SingleValue
            {...commonProps}
            // @ts-ignore
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}
          >
            <div className="phone-label phone-label__icon-section">
              <img
                src={require(`../../../images/svg/flags/${optionCode}.svg`)}
                className="phone-label__icon"
                alt={selectProps.getOptionLabel(props.getValue()[0])}
              />
              <div className="">
                {selectProps.getOptionValue(props.getValue()[0])}
              </div>
            </div>
          </SingleValue>
        ) : (
          <Placeholder
            {...commonProps}
            key="placeholder"
            isDisabled={selectProps.isDisabled}
            // @ts-ignore
            data={props.getValue()}
          >
            {selectProps.placeholder}
          </Placeholder>
        );

        return child ? child : propValue;
      })}
    </ValueContainer>
  );
};

export default PhoneField;
