import React from "react";
import { connect } from "react-redux";
import Rox from "rox-browser";

import { RoxFlag } from "config/rox";

import { getRox } from "store/rox/rox.actions";
import { GetRox } from "store/rox/rox.types";

interface IRoxSettingsContainer {
  [RoxFlag.LocalizationControl]: Rox.Flag;
}

export class RoxSettingsContainer implements IRoxSettingsContainer {
  public [RoxFlag.LocalizationControl] = new Rox.Flag(false) ?? false;
  public [RoxFlag.UseRecurlyForRenewals] = new Rox.Flag(false) ?? false;
  public [RoxFlag.Premium_PerformerSearch] = new Rox.Flag(false) ?? false;
  public [RoxFlag.NewAccSettings] = new Rox.Flag(false) ?? false;
  public [RoxFlag.NewAccSettings_AllowUpdtSubscription] =
    new Rox.Flag(false) ?? false;
  public [RoxFlag.NewAccSettings_AllowSubscriptionCancellation] =
    new Rox.Flag(false) ?? false;
  public [RoxFlag.NewAccSettings_AllowSubscriptionUpgradeDowngrade] =
    new Rox.Flag(false) ?? false;
  public [RoxFlag.NewAccSettings_AllowDiscountCode] =
    new Rox.Flag(false) ?? false;
  public [RoxFlag.Premium_AnnualPromo] = new Rox.Flag(false) ?? false;
  public [RoxFlag.NewCheckoutPage] = new Rox.Flag(false) ?? false;
}

interface IRoxProviderProps {
  getRox: GetRox;
  children: JSX.Element | JSX.Element[];
}

export class RoxProvider extends React.Component<IRoxProviderProps> {
  public render() {
    return this.props.children;
  }

  public componentDidMount() {
    this.loadRox();
  }

  private async loadRox() {
    await this.props.getRox();
  }
}

const mapDispatchToProps = {
  getRox,
};

export default connect(undefined, mapDispatchToProps)(RoxProvider);
