import { Button } from "component-library";
import { useTranslation } from "i18n";
import React from "react";
import { useHistory } from "react-router-dom";
import applicationMap from "services/applicationMap";

const TransferToGrads = ({ enabled }: { enabled: boolean }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const buttonType = enabled ? "secondary" : "primary";

  return (
    <div className="w-full lg:w-[80%] mb-6 flex flex-col gap-3">
      <div>
        <h3 className="text-xxl">
          {t(
            "performer:accountSettings.mySubscriptionPage.transferToGrad.title"
          )}
        </h3>
      </div>
      <span className="whitespace-pre-line">
        {t(
          "performer:accountSettings.mySubscriptionPage.transferToGrad.description"
        )}
      </span>
      {/* @ts-ignore secondary buttons are not allowed to have a disabled state (apparently). We set the type to primary when disabled to satisfy TS & add styling */}
      <Button
        type={buttonType}
        text={t(
          "performer:accountSettings.mySubscriptionPage.transferToGrad.buttonTitle"
        )}
        className="w-fit"
        disabled={!enabled}
        size="small"
        onClick={() =>
          history.push(applicationMap.routes.graduatesTransferInformation())
        }
      />
    </div>
  );
};

export default TransferToGrads;
