import React, { FC } from "react";
import cx from "classnames";

import "./Pill.scss";

export interface IPillProps {
  /** Title for component */
  title?: string;
  /** Icon for component */
  icon?: string | JSX.Element | FC;
  /** Theme for component */
  theme?: "dark" | "light";
  /** Size of component */
  size?: "lg" | "md";
  className?: string;
  variant?: "link-to-profile";
}

export const Pill: React.FC<IPillProps> = ({
  title,
  icon,
  children,
  theme = "dark",
  size = "lg",
  variant,
  className,
}) => {
  const rootClassNames = cx(
    "pill",
    `pill--${theme}`,
    `pill--${size}`,
    className,
    {
      "pill--link-to-profile": variant === "link-to-profile",
    }
  );

  return (
    <span className={rootClassNames} title={title}>
      {icon && (
        <span className="pill__icon-container">
          {typeof icon === "string" ? (
            <img src={icon} alt={`${title} icon`} className="pill__icon-img" />
          ) : (
            icon
          )}
        </span>
      )}
      <span className="pill__children-container">{children}</span>
    </span>
  );
};
