import React from "react";
import cx from "classnames";

import "./Radio.scss";
import { omit } from "../../../generics/object-manipulation";

import { ExpandingArea } from "../../Molecules/ExpandingArea/ExpandingArea";

export type IGenericRadioOnChangeEvent<T> = Pick<
  IGenericRadioProps<T>,
  "name" | "value" | "checked"
>;

export type IGenericRadioOnChangeHandler = <T>(
  event: IGenericRadioOnChangeEvent<T>
) => void;

export interface IGenericRadioProps<T> {
  /** Name of the form element, as you would use for a "normal" radio */
  name?: string;
  /** Value of the form element, as you would use for a "normal" radio */
  value: T;
  /** Optional id for the input element */
  htmlId?: string;
  /** Optional label */
  label?: string | JSX.Element;
  /** Callback for the change, returns value. Necessary to pass data to external consumers */
  valueChanged?: (value: T) => void;
  /** Optional handler to hook into the change event. */
  onChange?: IGenericRadioOnChangeHandler;
  onFocus?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  /** Controls the checked/unchecked style of the radio. Mandatory since component is stateless */
  checked: boolean;
  /** Controls whether the input should be disabled */
  disabled?: boolean;
  /** Optional css class for the topmost div */
  className?: string;
  /** Show custom content in expanding area when radio is checked */
  renderExpandingArea?: () => JSX.Element;
}

export class GenericRadio<T> extends React.Component<IGenericRadioProps<T>> {
  public render() {
    const { className, disabled, label, htmlId } = this.props;

    const attributes = {
      ...omit(this.props, [
        "value",
        "className",
        "valueChanged",
        "htmlId",
        "renderExpandingArea",
      ]),
      id: htmlId,
    };

    return (
      <div
        className={cx("c-radio", {
          [className as string]: Boolean(className),
          "c-radio__disabled": disabled,
        })}
      >
        <label className="c-radio__container">
          <input
            {...attributes}
            className="c-radio__input"
            type="radio"
            onChange={this.handleChange}
            disabled={disabled}
          />
          <span className="c-radio__content">
            <span className="c-radio__box" />
            <span className="c-radio__text" data-testid={label}>
              {label}
            </span>
          </span>
        </label>
        {this.renderExpandingArea()}
      </div>
    );
  }

  public renderExpandingArea(): JSX.Element | null {
    if (!this.props.renderExpandingArea) {
      return null;
    }

    return (
      <div className="c-radio__expanding-area">
        <ExpandingArea isOpen={this.props.checked}>
          {this.props.renderExpandingArea()}
        </ExpandingArea>
      </div>
    );
  }

  private handleChange = () => {
    if (!this.props.disabled && this.props.onChange) {
      this.props.onChange<T>({
        name: this.props.name,
        value: this.props.value,
        checked: this.props.checked,
      });
    }

    if (!this.props.disabled && this.props.valueChanged) {
      return this.props.valueChanged(this.props.value);
    }
  };
}
