import { useState } from "react";
import _compact from "lodash/compact";
import _map from "lodash/map";
import { useQuery } from "react-query";
import { FacetType, IFacet } from "shared-services";
import { FacetService } from "shared-services/src/services/facets";
import { config } from "../../../../config";
import { getFacetListByType } from "../../../Functional/helpers/getFacetListByType";
import { FilterConfig, IFilterValues } from "../types";

const facetsAPI = new FacetService({ url: config.facetsApiUrl });

const GET_FILTER_FACETS = "GET_FILTER_FACETS";

interface IUserFilters<T> {
  values: T;
  defaultValues: T;
  filterDrawerValues: T;
  setFilterValues: (nextValues: Partial<T>) => void;
  setFilterDrawerValues: (nextValues: Partial<T>) => void;
  facets: Record<FacetType, IFacet[]>;
  isLoading: boolean;
  error: unknown;
  config: FilterConfig<T>;
}

export function useFilters<T extends IFilterValues>(
  config: FilterConfig<T>
): IUserFilters<T> {
  const defaultValues = {
    ...config.reduce(
      (acc, { name, defaultValue }) => ({ ...acc, [name]: defaultValue }),
      {}
    ),
  } as T;
  const [values, setValues] = useState(defaultValues);
  const [filterDrawerValuesState, setFilterDrawerValuesState] =
    useState(defaultValues);

  const facetsCategories = _compact(_map(config, "facetName"));

  const {
    data = [],
    isLoading,
    error,
  } = useQuery(
    [GET_FILTER_FACETS, facetsCategories],
    () => facetsAPI.getFacetsCategories(facetsCategories),
    { enabled: !!facetsCategories.length }
  );

  const facets = facetsCategories.reduce(
    (acc, facet) => ({ ...acc, [facet]: getFacetListByType(data, facet) }),
    {} as Record<FacetType, IFacet[]>
  );

  const setFilterValues = (nextValues: Partial<T>) => {
    const filterValues = { ...values, ...nextValues };

    setValues(filterValues);
    setFilterDrawerValuesState(filterValues);
  };

  const setFilterDrawerValues = (nextValues: Partial<T>) =>
    setFilterDrawerValuesState({
      ...filterDrawerValuesState,
      ...nextValues,
    });

  return {
    values,
    filterDrawerValues: filterDrawerValuesState,
    defaultValues,
    setFilterValues,
    setFilterDrawerValues,
    facets,
    isLoading,
    error,
    config,
  };
}
