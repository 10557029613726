import React from "react";
import cx from "classnames";
import {
  removeClass,
  hasClass,
  addClass,
} from "../../../generics/dom-extensions";
import { IOption } from "../../../generics/interfaces";
import "./Select.scss";

export interface ISelectProps<T extends string | number | boolean> {
  /**
   * List of options
   */
  options: Array<IOption<T>>;
  /**
   * Value of selected the element.
   */
  value?: T;
  /**
   * Placeholder
   */
  placeholder?: string;
  /**
   * The onchange property sets and returns the event handler for the change event.
   */
  onChange?: (selectedValue: IOption<T>) => void;
  /**
   * Defines function which will format name
   */
  formatName?: (item: IOption<T>) => string | React.ReactNode;
  /**
   * The onFocus property sets and returns the event handler for the change event.
   */
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void;
  /**
   * The onBlur property sets and returns the event handler for the change event.
   */
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
  /**
   * 	Optional css class for the topmost div
   */
  className?: string;
  /**
   * 	Optional label
   */
  label?: string;
  /**
   * Flag to disable the select
   */
  disabled?: boolean;
  isControlled?: boolean;
}

export interface ISelectState<T> {
  selectedItem?: ISelectItem<T>;
  isOpened: boolean;
  isFocused: boolean;
  options: Array<ISelectItem<T>>;
  disabled: boolean;
}

interface ISelectItem<T> extends IOption<T> {
  index?: number;
  originalOption?: IOption<T>;
}

export class Select<
  T extends string | number | boolean
> extends React.Component<ISelectProps<T>, ISelectState<T>> {
  private isScroll: boolean = false;
  private selectRef: React.RefObject<HTMLDivElement | any>;
  private listRef: React.RefObject<HTMLUListElement | any>;

  constructor(props: ISelectProps<T>) {
    super(props);

    this.selectRef = React.createRef<HTMLDivElement>();
    this.listRef = React.createRef<HTMLUListElement>();

    let selectedItem: ISelectItem<T> | undefined;

    const selectOptions = props.options.map((item, index) => {
      return {
        index,
        name: item.name,
        value: item.value,
        originalOption: item,
      };
    });

    if (props.value) {
      selectedItem = selectOptions.find((item) => item.value === props.value);
    }

    this.state = {
      selectedItem,
      isOpened: false,
      isFocused: false,
      options: selectOptions,
      disabled: props.disabled === undefined ? false : props.disabled,
    };
  }

  static getDerivedStateFromProps(
    props: ISelectProps<T>,
    state: ISelectState<T>
  ) {
    return props.isControlled
      ? {
          selectedItem: state.options.find(
            (item) => item.value === props.value
          ),
        }
      : null;
  }

  public render() {
    const nameFormatting = this.props.formatName || this.defaultNameFormatting;

    return (
      <div className="c-select">
        {this.props.label ? <label>{this.props.label}</label> : null}
        <div
          className={`c-select__control bottom ${
            this.props.className ? this.props.className : ""
          }`}
          ref={this.selectRef}
        >
          <div
            tabIndex={0}
            onBlur={this.blur}
            onFocus={this.focus}
            className="c-select__selected"
            onClick={this.toggle}
            onKeyPress={this.toggle}
            onKeyDown={this.navigation}
            role="button"
          >
            {this.state.selectedItem === undefined
              ? this.props.placeholder
              : nameFormatting(this.state.selectedItem)}
          </div>
          <ul
            ref={this.listRef}
            className={`c-select__list ${this.state.isOpened ? "opened" : ""}`}
          >
            {this.getList(this.state.options)}
          </ul>
        </div>
      </div>
    );
  }

  public componentDidUpdate() {
    if (this.isScroll) {
      this.isScroll = false;
      this.calculateScrollPosition();
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
    window.removeEventListener("scroll", this.calculateListPosition);
    window.removeEventListener("resize", this.calculateListPosition);
  }

  private getList(list: Array<ISelectItem<T>>) {
    const nameFormatting = this.props.formatName || this.defaultNameFormatting;

    return list.map((item, index) => {
      const isSelectedCondition =
        this.state.selectedItem !== undefined &&
        item.value === this.state.selectedItem.value;

      return (
        <li
          key={index}
          className={cx("c-select__list-item", {
            selected: isSelectedCondition,
            bold: item.originalOption?.bold,
          })}
          onClick={this.selectItem.bind(this, item)}
        >
          {nameFormatting(item)}
        </li>
      );
    });
  }

  private selectItem(item: ISelectItem<T>) {
    const { isControlled } = this.props;

    if (!isControlled) this.setState({ selectedItem: item });
    this.close();
    if (this.props.onChange && item.originalOption) {
      this.props.onChange(item.originalOption);
    }
  }

  private navigation = (event: React.KeyboardEvent) => {
    if (this.state.isOpened && this.state.isFocused) {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.preventDefault();

        if (this.state.selectedItem === undefined) {
          this.setState({ selectedItem: this.state.options[0] });
          this.isScroll = true;
        } else {
          for (let index = 0; index < this.state.options.length; index++) {
            const item = this.state.options[index];

            if (
              event.key === "ArrowDown" &&
              item.index === this.state.selectedItem.index &&
              this.state.options.length - 1 > index
            ) {
              this.setState({ selectedItem: this.state.options[index + 1] });
              this.isScroll = true;
              break;
            }

            if (
              event.key === "ArrowUp" &&
              item.index === this.state.selectedItem.index &&
              -1 < index - 1
            ) {
              this.setState({ selectedItem: this.state.options[index - 1] });
              this.isScroll = true;
              break;
            }
          }
        }
      }

      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        if (this.state.selectedItem) {
          this.selectItem(this.state.selectedItem);
        }
      }

      if (event.key === "Tab") {
        if (this.state.selectedItem) {
          this.selectItem(this.state.selectedItem);
        } else {
          this.close();
        }
      }
    }
  };

  private handleOutsideClick = (event: any) => {
    const select = this.selectRef.current;
    if (select) {
      if (!select.contains(event.target)) {
        this.close();
      }
    }
  };

  private calculateScrollPosition = (): void => {
    const list = this.listRef.current;

    if (list) {
      const containerHeight = list.offsetHeight;
      const scrollValue = list.scrollTop;
      const selectedElement = list.getElementsByClassName("selected")[0] as any;

      if (selectedElement) {
        if (scrollValue > selectedElement.offsetTop) {
          list.scrollTo({ top: selectedElement.offsetTop });
        } else if (scrollValue + containerHeight <= selectedElement.offsetTop) {
          list.scrollTo({
            top:
              selectedElement.offsetTop -
              containerHeight +
              selectedElement.offsetHeight,
          });
        }
      }
    }
  };

  private toggle = () => {
    if (this.state.disabled) {
      return;
    }

    this.setState((state) => {
      if (!state.isOpened) {
        window.addEventListener("scroll", this.calculateListPosition);
        window.addEventListener("resize", this.calculateListPosition);
        document.addEventListener("click", this.handleOutsideClick);
      } else {
        window.removeEventListener("scroll", this.calculateListPosition);
        window.removeEventListener("resize", this.calculateListPosition);
        document.removeEventListener("click", this.handleOutsideClick);
      }
      return { ...state, isOpened: !state.isOpened };
    });
  };

  private close = () => {
    this.setState({ isOpened: false });
  };

  private blur = (event: React.FocusEvent<HTMLDivElement>) => {
    this.setState({ isFocused: false }, () => {
      if (this.props.onBlur) {
        this.props.onBlur(event);
      }
    });
  };

  private focus = (event: React.FocusEvent<HTMLDivElement>) => {
    this.setState({ isFocused: true }, () => {
      if (this.props.onFocus) {
        this.props.onFocus(event);
      }
    });
  };

  private calculateListPosition = () => {
    const select = this.selectRef.current;
    const list = this.listRef.current;
    const documentElement = document.documentElement;
    if (select && list && documentElement) {
      const distanceToBottom =
        documentElement.clientHeight -
        select.getBoundingClientRect().top -
        select.offsetHeight -
        list.offsetHeight;

      if (hasClass(select, "bottom") && 0 > distanceToBottom) {
        addClass(select, "top");
        removeClass(select, "bottom");
      } else if (hasClass(select, "top") && 0 < distanceToBottom) {
        addClass(select, "bottom");
        removeClass(select, "top");
      }
    }
  };

  private defaultNameFormatting(item: IOption<T>) {
    return item.name;
  }
}
