import React, { ReactElement } from "react";
import cx from "classnames";

import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { i18n } from "i18n";

import "./Button.scss";
import iconMap from "../../GlobalStyles/iconMap";

interface IBasicButtonProps {
  /** Optional unique identifier  */
  id?: string;
  /** The text within the button */
  text?: string | ReactElement;
  /** The css class name of icon */
  iconName?: string;
  /** URL to go to if the button is used as a link */
  href?: string;
  /** Whether or not to open the URL in a new tab, if the button is used as a link */
  newTab?: boolean;
  /** Handler for interacting with the button via click, Enter or Spacebar keys */
  onClick?: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => void;
  /** Optional css class for the topmost div */
  className?: string;
  /** Whether to show loading indicator */
  loading?: boolean;
  /** Text for loading indicator */
  loadingText?: string;
  /** Button size if it is differs from standard one */
  size?: "small" | "medium" | "large";
  variant?: "thin";
  /** Optional aria-label - useful with icon only buttons where a description is needed */
  ariaLabel?: string;
  "data-testid"?: string;
}

export interface IPrimaryTertiaryButtonProps extends IBasicButtonProps {
  /** One of the three button types */
  type?: "primary" | "tertiary";
  /** Flag to disable the button, only available on the 'primary' variant */
  disabled?: boolean;
}

export interface ISecondaryButtonProps extends IBasicButtonProps {
  type?: "secondary";
  disabled?: void; // we're not supposed to disable secondary button variants. This insures that the "disabled" attribute cannot be added outright.
  // it _can_ be added as disabled={undefined}, but please don't do this, and that wouldn't manage to disable it anyway.
}

export type ButtonProps = IPrimaryTertiaryButtonProps | ISecondaryButtonProps;

interface IOpts {
  href?: string;
  role?: string;
  target?: string;
}

export class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    size: "large",
  };

  public render() {
    const opts: IOpts = {
      /**
       * Anchors must have an href so they are focusable with a keyboard. This particular value means "don't do anything" and the click event
       * default is prevented when onClick is provided.
       */
      href: "#/",
    };
    if (this.props.href !== undefined) {
      opts.href = this.props.href;
    }
    if (this.props.onClick) {
      opts.role = "button";
    }
    if (this.props.newTab) {
      opts.target = "_blank";
    }
    return (
      <a
        {...opts}
        id={this.props.id}
        className={cx(
          "c-button",
          `c-button__${this.props.type}`,
          {
            "c-button__disabled": this.props.disabled,
            "c-button__input-inset": this.props.iconName && !this.props.text,
            "c-button--loading": this.props.loading,
            [`c-button--${this.props.size}`]: Boolean(this.props.size),
            [`c-button--${this.props.variant}`]: Boolean(this.props.variant),
          },
          this.props.className
        )}
        onClick={this.handleInteraction}
        onKeyPress={this.handleKeyPress}
        aria-label={this.props.ariaLabel}
        data-testid={this.props["data-testid"]}
      >
        {this.props.text && !this.props.loading && (
          <span className="c-button__text">{this.props.text}</span>
        )}
        {this.props.iconName && !this.props.loading && (
          <span className="c-button__icon">
            {String.fromCharCode(iconMap[this.props.iconName])}
          </span>
        )}
        {this.props.loading && (
          <LoadingIndicator
            text={
              this.props.loadingText || i18n.t("common:button.label.loading")
            }
            variant={this.props.type === "primary" ? "primary" : "secondary"}
          />
        )}
      </a>
    );
  }

  public handleInteraction = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    if (this.props.onClick && !this.props.disabled && !this.props.loading) {
      event.preventDefault();
      this.props.onClick(event);
    }
  };
  private handleKeyPress = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === " " || event.key === "Enter") {
      return this.handleInteraction(event);
    }
  };
}
