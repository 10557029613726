import React from "react";
import cx from "classnames";

import {
  addClass,
  removeClass,
  hasClass,
  getWindowWidth,
} from "../../../generics/dom-extensions";
import { debounce } from "../../../generics/debounce";

import "./Tooltip.scss";

export interface ITooltipContent {
  header?: string;
  content: React.ReactNode;
  closeButton: string;
  tooltipName: string;
}

export interface ITooltipConfig {
  minimalTopDistance: number;
  isRichText?: boolean;
}

export interface ITooltipProps {
  /**
   * Texts for header, content and alt text
   */
  texts: ITooltipContent;
  /**
   * Tooltip configuration
   */
  config: ITooltipConfig;
  /**
   * Optional css class for the topmost div
   */
  className?: string;

  /**
   * Disable showing tooltip on hover
   */
  disableShowOnHover?: boolean;

  /**
   * Optional CSS selector of HTML-element with `overflow: hidden` or equivalent value which restricts horizontal overflowing.
   * This is required for tooltip to correctly calculate free space while deciding which side to show to.
   */
  overflowRestrictingContainerSelector?: string;
}

export interface ITooltipState {
  isOpened: boolean;
}

export class Tooltip extends React.Component<ITooltipProps, ITooltipState> {
  private tooltipRef: React.RefObject<HTMLDivElement>;
  private tooltipContainerRef: React.RefObject<HTMLDivElement>;
  private tooltipTriggerRef: React.RefObject<HTMLDivElement>;
  private tooltipTextsRef: React.RefObject<HTMLDivElement>;
  private closeTooltipRef: React.RefObject<HTMLDivElement>;

  public static defaultProps = {
    disableShowOnHover: false,
  };

  constructor(props: ITooltipProps) {
    super(props);
    this.tooltipRef = React.createRef<HTMLDivElement>();
    this.tooltipContainerRef = React.createRef<HTMLDivElement>();
    this.tooltipTriggerRef = React.createRef<HTMLDivElement>();
    this.tooltipTextsRef = React.createRef<HTMLDivElement>();
    this.closeTooltipRef = React.createRef<HTMLDivElement>();

    this.state = {
      isOpened: false,
    };
  }

  public componentDidUpdate(prevProps: Readonly<ITooltipProps>) {
    if (
      this.state.isOpened &&
      this.tooltipTextsRef &&
      this.tooltipTextsRef.current
    ) {
      this.tooltipTextsRef.current.focus();

      if (prevProps !== this.props) {
        this.calculateTooltipPosition();
      }
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.recalculateTooltipPosition);
    window.removeEventListener("resize", this.recalculateTooltipPosition);
  }

  public render() {
    const tooltipClass = cx(
      "c-tooltip",
      { active: this.state.isOpened },
      this.props.className
    );
    const tabIndexValue = this.state.isOpened ? 0 : -1;

    const hoverEvents = !this.props.disableShowOnHover &&
      !this.state.isOpened && {
        onMouseEnter: this.displayTooltip,
        onMouseLeave: this.hideTooltip,
      };

    return (
      <div ref={this.tooltipContainerRef} className={tooltipClass}>
        <div
          ref={this.tooltipTriggerRef}
          onClick={this.open}
          role="button"
          tabIndex={0}
          aria-label={this.props.texts.tooltipName}
          className={cx({
            "c-tooltip__trigger-hover": !this.props.disableShowOnHover,
            "c-tooltip__trigger-hover--open": this.state.isOpened,
          })}
          onKeyUp={this.triggerKeyboardHandler}
          onFocus={this.open}
          {...hoverEvents}
        >
          {this.props.children}
        </div>
        <div
          role="tooltip"
          ref={this.tooltipRef}
          className="hidden c-tooltip__container top"
        >
          <div className="c-tooltip__section">
            <div
              className="c-tooltip__texts"
              ref={this.tooltipTextsRef}
              tabIndex={tabIndexValue}
              onKeyUp={this.triggerKeyboardHandler}
            >
              <div className="c-tooltip__header" aria-live="assertive">
                {this.props.texts.header}
              </div>
              <div className="c-tooltip__content" aria-live="assertive">
                {this.renderTooltipContent(
                  this.props.texts.content,
                  this.props.config.isRichText
                )}
              </div>
              <div className="c-tooltip__pointer" />
            </div>
            <div
              ref={this.closeTooltipRef}
              onClick={this.close}
              aria-label={this.props.texts.closeButton}
              tabIndex={tabIndexValue}
              onKeyUp={this.closeByKeyboard}
              role="button"
              className="c-tooltip__close icon-cross"
            />
          </div>
        </div>
      </div>
    );
  }

  private renderTooltipContent = (
    content: React.ReactNode,
    isRichText: boolean = false
  ) => {
    let contentNode = content;

    if (typeof content === "string" && isRichText) {
      contentNode = <span dangerouslySetInnerHTML={{ __html: content }} />;
    }

    return contentNode;
  };

  private displayTooltip = () => {
    if (this.tooltipRef.current) {
      this.tooltipRef.current.classList.remove("hidden");
      this.calculateTooltipPosition();
    }
  };

  private hideTooltip = (event?: any, callback?: any) => {
    if (this.tooltipRef.current) {
      setTimeout(() => {
        if (
          this.tooltipTriggerRef.current &&
          this.tooltipRef.current &&
          !this.isHover(this.tooltipTriggerRef.current)
        ) {
          addClass(this.tooltipRef.current, "hidden");
        }

        if (callback) {
          callback();
        }
      }, 500);
    }
  };

  private close = (event: any) => {
    this.setState({ isOpened: false });
    this.hideTooltip();
    document.removeEventListener("click", this.handleOutsideClick, false);
    document.removeEventListener("keydown", this.handleOutsideFocus, false);
    window.removeEventListener("scroll", this.recalculateTooltipPosition);
    window.removeEventListener("resize", this.recalculateTooltipPosition);
    event.stopPropagation();
  };

  private closeWithFocus = (event: any) => {
    this.close(event);
    if (this.tooltipTriggerRef && this.tooltipTriggerRef.current) {
      this.tooltipTriggerRef.current.focus();
    }
  };

  private open = () => {
    if (this.state.isOpened) {
      return;
    }

    this.setState({ isOpened: true });
    this.displayTooltip();
    document.addEventListener("click", this.handleOutsideClick, false);
    document.addEventListener("keydown", this.handleOutsideFocus, false);
    window.addEventListener("scroll", this.recalculateTooltipPosition);
    window.addEventListener("resize", this.recalculateTooltipPosition);
  };

  private closeByKeyboard = (event: any): void => {
    if (
      event.key === " " ||
      event.key === "Enter" ||
      event.key === "Esc" ||
      event.key === "Escape"
    ) {
      event.preventDefault();
      return this.closeWithFocus(event);
    }
  };

  private handleOutsideClick = (event: any) => {
    if (
      this.tooltipContainerRef.current &&
      this.tooltipContainerRef.current.contains(event.target)
    ) {
      return;
    }

    this.close(event);
  };

  private handleOutsideFocus = (event: any) => {
    if (
      event.keyCode === 9 &&
      this.closeTooltipRef.current &&
      event.target === this.closeTooltipRef.current
    ) {
      this.close(event);
    }
  };

  private triggerKeyboardHandler = (event: any) => {
    if ((event.key === " " || event.key === "Enter") && !this.state.isOpened) {
      event.preventDefault();
      return this.open();
    }

    if (
      (event.key === "Esc" || event.key === "Escape") &&
      this.state.isOpened
    ) {
      event.preventDefault();
      event.stopPropagation();
      return this.closeWithFocus(event);
    }
  };

  private calculateTooltipPosition = () => {
    const tooltip = this.tooltipRef.current;
    const tooltipContainer = this.tooltipContainerRef.current;

    if (tooltip && tooltipContainer) {
      const tooltipBoundingRect = tooltip.getBoundingClientRect();
      const tooltipContainerBoundingRect =
        tooltipContainer.getBoundingClientRect();

      const distanceToTop = tooltipBoundingRect.top;
      const isExceededMaxDistance =
        this.props.config.minimalTopDistance > distanceToTop;
      const hasFreeSpaceForTooltip =
        tooltipContainer.offsetHeight +
          this.props.config.minimalTopDistance +
          tooltip.offsetHeight <
        distanceToTop;

      if (
        hasClass(tooltip, "top") &&
        isExceededMaxDistance &&
        !hasFreeSpaceForTooltip
      ) {
        addClass(tooltip, "bottom");
        removeClass(tooltip, "top");
      } else if (
        hasClass(tooltip, "bottom") &&
        !isExceededMaxDistance &&
        hasFreeSpaceForTooltip
      ) {
        addClass(tooltip, "top");
        removeClass(tooltip, "bottom");
      }

      let overflowRestrictingContainerBoundingRect;

      if (this.props.overflowRestrictingContainerSelector) {
        const overflowRestrictingContainer = document.querySelector(
          this.props.overflowRestrictingContainerSelector
        );
        overflowRestrictingContainerBoundingRect =
          overflowRestrictingContainer?.getBoundingClientRect();
      }

      const hasFreeSpaceToLeftForTooltip =
        tooltipBoundingRect.width <=
        tooltipContainerBoundingRect.right -
          (overflowRestrictingContainerBoundingRect?.left ?? 0);

      if (!hasFreeSpaceToLeftForTooltip) {
        const windowWidth = getWindowWidth();
        const hasFreeSpaceToRightForTooltip =
          tooltipContainerBoundingRect.left + tooltipBoundingRect.width <=
          windowWidth;

        if (!hasFreeSpaceToRightForTooltip) {
          addClass(tooltip, "stretched");
          removeClass(tooltip, "right");
          tooltip.style.right = `-${Math.floor(
            tooltip.getBoundingClientRect().width -
              tooltipContainerBoundingRect.right
          )}px`;
        } else {
          addClass(tooltip, "right");
          removeClass(tooltip, "stretched");
          tooltip.style.right = "";
        }
      } else {
        removeClass(tooltip, "right");
        removeClass(tooltip, "stretched");
        tooltip.style.right = "";
      }
    }
  };

  private recalculateTooltipPosition = debounce(
    this.calculateTooltipPosition,
    100
  );

  private isHover(element: HTMLDivElement): boolean {
    return !!(element as any).querySelector(":hover");
  }
}
