import React from "react";
import cx from "classnames";

import "./ActionButton.scss";

export interface IActionButtonProps {
  /** Which of the icons to use (check the available list) */
  icon: string;
  /** What to do when clicking */
  onClick: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => void;
  /** Optional label */
  label?: string;
  /** Optional label for screen readers */
  ariaLabel?: string;
  /** Optional disabled status */
  disabled?: boolean;
  /** Optional css class for the topmost div */
  className?: string;
  /** Optional class modifier for button size if it is differs from standard one */
  size?: "xs" | "small";
  /** Option for pre-designed variants; add mroe as necessary */
  variant?: "toolbar";
}

export class ActionButton extends React.Component<IActionButtonProps> {
  public render() {
    const {
      disabled,
      className,
      onClick,
      icon,
      label,
      ariaLabel,
      size,
      variant,
    } = this.props;

    return (
      <div
        className={cx("c-action-button", className, {
          disabled,
          [`c-action-button--${size}`]: size,
          [`c-action-button--${variant}`]: variant,
        })}
      >
        <a
          role="button"
          aria-disabled={disabled}
          className="c-action-button-holder"
          tabIndex={disabled ? -1 : 0}
          onClick={onClick}
          onKeyPress={onClick}
        >
          <div className={`icon-${icon}`} />
          <div className="c-action-button-label" aria-label={ariaLabel}>
            {label}
          </div>
        </a>
      </div>
    );
  }
}
