import React from "react";

import "./AudioControl.scss";

export interface IAudioControlProps {
  onClick: () => void;
  icon: string;
  ariaLabel?: string;
}

export const AudioControl: React.FC<IAudioControlProps> = ({
  icon,
  onClick,
  ariaLabel,
}) => (
  <button
    className={`c-audio-control icon-${icon}`}
    onClick={onClick}
    aria-label={ariaLabel}
  />
);
