import React from "react";
import cx from "classnames";

interface IMobileDataCellProps {
  label: string;
  value: string;
  className?: string;
}

const MobileDataCell = ({ label, value, className }: IMobileDataCellProps) => {
  return (
    <div className={cx("c-simple-grid__table-body-row-cell", className)}>
      {label && (
        <div className="c-simple-grid__table-body-row-cell-label mb-2">
          {label}:
        </div>
      )}
      <div className="c-simple-grid__table-body-row-cell-value">{value}</div>
    </div>
  );
};

export default MobileDataCell;
