import React, { FC, useState, useEffect, useMemo } from "react";
import { format } from "date-fns";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";
import _map from "lodash/map";
import { ICreditHeading, ICredit } from "shared-types";
import { useTranslation, TFunction } from "i18n";
import { GridStyles } from "../../SimpleGrid/GridStyles";
import { ISimpleGridColumns, SimpleGrid } from "../../SimpleGrid/SimpleGrid";
import { Tabs } from "../../../Molecules/Tabs/Tabs";
import "./styles.scss";

interface Props {
  creditHeadings: ICreditHeading[];
  credits: ICredit[];
}

declare type GridCreditType = Pick<
  ICredit,
  | "id"
  | "name"
  | "dateFrom"
  | "dateFromFormat"
  | "dateTo"
  | "dateToFormat"
  | "typeId"
  | "role"
  | "company"
  | "director"
>;

export function getDateFormat(format: string = "y") {
  if (format === "m") {
    return "MM/yyyy";
  }
  if (format === "y") {
    return "yyyy";
  }

  return "dd/MM/yyyy";
}

const getGridColumns = (
  creditsHeaders: ICreditHeading[],
  t: TFunction
): ISimpleGridColumns<GridCreditType> => ({
  id: "hide",
  name: {
    type: "string",
    label: "",
    width: 4,
    formatCell(value: string) {
      return <div className="credits-grid__credit-title">{value}</div>;
    },
  },
  dateFrom: {
    type: "string",
    label: t("common:label.date"),
    width: 2,
    formatCell(_: string, values: GridCreditType) {
      const dateFrom = values?.dateFrom
        ? format(
            new Date(values?.dateFrom),
            getDateFormat(values?.dateFromFormat)
          )
        : "";
      const dateTo = values?.dateTo
        ? format(new Date(values?.dateTo), getDateFormat(values?.dateToFormat))
        : "";
      const isDateRange = !!dateFrom && !!dateTo;

      return `${dateFrom}${isDateRange ? "-" : ""}${dateTo}`;
    },
  },
  typeId: {
    type: "string",
    label: t("common:label.type"),
    width: 2,
    formatCell(value: string) {
      return creditsHeaders.find(
        (header) => header.creditType.id === Number(value)
      )?.creditType.name;
    },
  },
  role: {
    type: "string",
    label: t("common:label.role"),
    width: 3,
  },
  company: {
    type: "string",
    label: t("common:label.company"),
    width: 3,
  },
  director: {
    type: "string",
    label: t("common:label.director"),
    width: 3,
  },
});

const gridStyles = new GridStyles();

gridStyles.body = "credits-grid__body";
gridStyles.bodyCell = "credits-grid__body-cell";
gridStyles.bodyRow = "credits-grid__body-row";
gridStyles.header = "credits-grid__header";
gridStyles.headerCell = "credits-grid__header-cell";
gridStyles.headerLabel = "credits-grid__header-label";
gridStyles.headerRow = "credits-grid__header-row";
gridStyles.headerTitle = "credits-grid__header-title";

const FURTHER_CATEGORY = -1;

const CreditsTable: FC<Props> = ({ creditHeadings, credits }) => {
  const { t } = useTranslation();
  const [selectedTab, setHeadingTab] = useState<number>();

  const sortedHeadings = _sortBy(creditHeadings, "position");
  const sortedCredits = _sortBy(credits, "position");
  const categoryWithHeading = _filter(sortedHeadings, { isHidden: false });
  const furtherCategories = _map(
    _filter(sortedHeadings, { isHidden: true }),
    "creditType.id"
  );
  const tabsIsVisible = !!categoryWithHeading.length;
  const firstSelectedTab = categoryWithHeading[0];

  const categoryTabs = categoryWithHeading.map((creditType) => ({
    isActive: selectedTab === creditType.creditType.id,
    text: creditType.creditType.name,
    click: () => setHeadingTab(creditType.creditType.id),
  }));

  const tabs = !!furtherCategories.length
    ? [
        ...categoryTabs,
        {
          isActive: selectedTab === FURTHER_CATEGORY,
          text: t("common:tabs.label.further"),
          click: () => setHeadingTab(FURTHER_CATEGORY),
        },
      ]
    : categoryTabs;

  const filteredCredits = useMemo(() => {
    if (!tabsIsVisible) {
      return sortedCredits;
    }

    if (selectedTab === FURTHER_CATEGORY) {
      return sortedCredits.filter((credit) =>
        furtherCategories.includes(credit.typeId)
      );
    }

    return sortedCredits.filter((credit) => credit.typeId === selectedTab);
  }, [tabsIsVisible, sortedCredits, selectedTab, furtherCategories]);

  useEffect(() => {
    setHeadingTab(
      firstSelectedTab ? firstSelectedTab.creditType.id : FURTHER_CATEGORY
    );
  }, [firstSelectedTab]);

  return (
    <div>
      {tabsIsVisible && <Tabs tabs={tabs} />}
      <div className="credits-grid">
        <div className="credits-grid__container">
          <SimpleGrid<GridCreditType>
            data={filteredCredits}
            styles={gridStyles}
            columns={getGridColumns(sortedHeadings, t)}
            maxHeight="10000000px"
          />
        </div>
      </div>
    </div>
  );
};

export default CreditsTable;
