import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { config } from "./config";

import enGBCommon from "./lang/en-GB/common.json";
import enIECommon from "./lang/en-IE/common.json";
import frFRCommon from "./lang/fr-FR/common.json";

import enGBAgent from "./lang/en-GB/agent.json";
import enIEAgent from "./lang/en-IE/agent.json";
import frFRAgent from "./lang/fr-FR/agent.json";

import enGBCasting from "./lang/en-GB/casting.json";
import enIECasting from "./lang/en-IE/casting.json";
import frFRCasting from "./lang/fr-FR/casting.json";

import enGBPerformer from "./lang/en-GB/performer.json";
import enIEPerformer from "./lang/en-IE/performer.json";
import frFRPerformer from "./lang/fr-FR/performer.json";

import enGBJoining from "./lang/en-GB/joining.json";
import enIEJoining from "./lang/en-IE/joining.json";
import frFRJoining from "./lang/fr-FR/joining.json";

const supportedLanguages = ["en", "en-GB", "en-IE", "fr-FR"];

const backendResources: { resources: Record<string, any> } = {
  resources: {
    "en-GB": {
      common: enGBCommon,
      agent: enGBAgent,
      casting: enGBCasting,
      performer: enGBPerformer,
      joining: enGBJoining,
    },
    "en-IE": {
      common: enIECommon,
      agent: enIEAgent,
      casting: enIECasting,
      performer: enIEPerformer,
      joining: enIEJoining,
    },
    "fr-FR": {
      common: frFRCommon,
      agent: frFRAgent,
      casting: frFRCasting,
      performer: frFRPerformer,
      joining: frFRJoining,
    },
  },
};

type Namespace = "agent" | "casting" | "performer" | "joining";

export default function initI18N(
  namespaces: Namespace[] = []
): Promise<TFunction> {
  const httpBackendOptions = {
    backend: {
      loadPath: (lngs: string[], nss: string[]) => {
        const lookupLng = lngs?.[0];
        const lookupNs = nss?.[0];

        return backendResources?.resources?.[lookupLng]?.[lookupNs];
      },
    },
  };

  const isDev = config?.isDev;

  const backendOptions = !isDev ? httpBackendOptions : backendResources;

  const options: any = {
    language: "en-GB",
    fallbackLng: "en-GB",
    ns: ["common", ...namespaces],
    defaultNS: "common",
    supportedLngs: supportedLanguages,
    debug: isDev,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p"],
    },
    ...backendOptions,
  };

  const initFn = i18n.use(initReactI18next);

  if (!isDev) {
    initFn.use(HttpApi);
  }

  return initFn.init(options);
}
