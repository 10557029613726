import PageKey from "config/PageKey";
import { IUserInformation } from "models/page";
import { memberGroupNames, memberGroupSubTypes } from "models/user";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import UserAccountService from "services/api/UserAccount/UserAccountService";
import { AccountType } from "component-library";
import { getCookie } from "shared-utils";

import { GetMyAccountPage, SetPageData } from "store/page/page.types";
import { ActionType } from "store/types";
import { setUserType } from "store/user/user.actions";
import { AsyncAction } from "types/utilities";
import config from "config/global";

// ============================== Redux Actions =======================================================================

export const setPageData: SetPageData = (key, data) => ({
  type: ActionType.SetPageData,
  payload: {
    key,
    data,
  },
});

const ACCOUNT_TYPE_LOOKUP: Record<AccountType, keyof typeof memberGroupNames> =
  {
    [AccountType.DefaultUser]: "performer",
    [AccountType.Agent]: "agent",
    [AccountType.CastingDirector]: "castingDirector",
    [AccountType.Performer]: "performer",
    [AccountType.CastingSearchOnly]: "castingSearchOnly",
  } as const;

export const getMyAccountPage: AsyncAction<GetMyAccountPage> =
  () => async (dispatch) => {
    const userInfo = handleDebugQueryStringParameter(
      await UserAccountService.getUserInfo(),
      dispatch
    );

    // Spotlight's checkout sets a cookie if someone has just paid. We use this determine if the banner should be hidden temporarily.
    const spotlightCheckoutPaymentStatusCookie = Boolean(
      getCookie("PaymentStatus")
    );

    dispatch(
      setPageData(PageKey.MyAccount, {
        userInfo: userInfo,
        hasPaidRecently: spotlightCheckoutPaymentStatusCookie,
      })
    );
    if (userInfo?.length == 0) {
      window.location.href =
        (config.siteRootUrl || "").replace(/\/$/, "") + "/join-us"; // TODO this should be the felinesoft config.joinPagesUrl at some point;
    }
  };

const debugUserTypes =
  (document.cookie || "").indexOf("usertypeoverride=1") >= 0;
let debugUserTypesOverride: IUserInformation[] | undefined;

function handleDebugQueryStringParameter(
  actualValues: IUserInformation[] | undefined,
  dispatch: ThunkDispatch<{}, {}, AnyAction>
): IUserInformation[] | undefined {
  if (actualValues && debugUserTypes) {
    if (debugUserTypesOverride) {
      return debugUserTypesOverride;
    }

    while (!debugUserTypesOverride) {
      const txt = prompt("Enter override below", JSON.stringify(actualValues));
      try {
        debugUserTypesOverride = JSON.parse(txt || "");
        if (debugUserTypesOverride && debugUserTypesOverride[0]) {
          dispatch(
            setUserType({
              type:
                debugUserTypesOverride[0].memberGroup == "castingDirector"
                  ? AccountType.CastingDirector
                  : debugUserTypesOverride[0].memberGroup == "performer"
                  ? AccountType.Performer
                  : debugUserTypesOverride[0].memberGroup == "agent"
                  ? AccountType.Agent
                  : AccountType.DefaultUser,
              memberGroups: [
                {
                  group:
                    ACCOUNT_TYPE_LOOKUP[debugUserTypesOverride[0].memberGroup],
                  subType: debugUserTypesOverride[0]
                    .memberSubType as keyof typeof memberGroupSubTypes,
                },
              ],
            })
          );
        }
      } catch {}
    }
    return debugUserTypesOverride;
  }
  return actualValues;
}
