import React from "react";

// import TickIcon from '-!svg-react-loader?name=TickIcon!src/images/svg/icons/tick-icon.svg';
import { ReactComponent as TickIcon } from "../../../images/svg/icons/tick-icon.svg";

import "./StepTrackerItem.scss";

interface ITagConfig {
  Tag: React.ReactType;
  tagProps?: {
    role?: string;
    tabIndex?: number;
    onClick?: () => void;
  };
}

export enum StepTrackerItemStatus {
  Default = "default",
  Active = "active",
  Completed = "completed",
}

export interface IStepTrackerItem {
  label: string;
  title: string;
  isClickable?: boolean;
  subtitle?: string;
  onClick?: () => void;
  status?: StepTrackerItemStatus;
}

export interface IStepTrackerItemProps extends IStepTrackerItem {
  itemId: number;
  setTransformListener: (itemId: any, listener: any) => void;
  unsetTransformListener: (itemId: any) => void;
}

export class StepTrackerItem extends React.PureComponent<IStepTrackerItemProps> {
  public static defaultProps = {
    status: StepTrackerItemStatus.Default,
    isClickable: false,
  };

  private initialContentHeight: number = 0;
  private opacityMultiplier = 0.5;
  private componentRef = React.createRef<HTMLDivElement>();
  private contentRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    this.props.setTransformListener(this.props.itemId, this.transformItem);

    if (this.contentRef.current) {
      this.initialContentHeight =
        this.contentRef.current.getBoundingClientRect().height;
    }
  }

  public componentWillUnmount() {
    this.props.unsetTransformListener(this.props.itemId);
  }

  public render(): React.ReactNode {
    const { Tag, tagProps } = this.getTagConfig();

    return (
      <Tag
        {...tagProps}
        className={`c-step-tracker-item c-step-tracker-item--${this.props.status}`}
        ref={this.componentRef}
      >
        <span className="c-step-tracker-item__inner">
          <span className="c-step-tracker-item__label">
            {this.renderLabel()}
          </span>
          <span
            className="c-step-tracker-item__content-container"
            ref={this.contentRef}
          >
            <span className="c-step-tracker-item__content">
              <span className="c-step-tracker-item__title">
                {this.props.title}
              </span>
              {this.renderSubtitle()}
            </span>
          </span>
        </span>
      </Tag>
    );
  }

  public renderLabel() {
    if (this.props.status === StepTrackerItemStatus.Completed) {
      return <TickIcon width="20px" height="20px" />;
    }

    return this.props.label;
  }

  public renderSubtitle() {
    if (!this.props.subtitle) {
      return null;
    }

    return (
      <span className="c-step-tracker-item__subtitle">
        {this.props.subtitle}
      </span>
    );
  }

  private transformItem = (scrollDelta: number) => {
    const transformDelta: number =
      (this.initialContentHeight - scrollDelta) / this.initialContentHeight;

    if (!this.contentRef.current) {
      return;
    }

    const currentOpacity = this.contentRef.current.style.opacity;

    if (transformDelta > 1 && (!currentOpacity || currentOpacity === "1")) {
      return;
    }

    if (transformDelta < 0 && currentOpacity === "0") {
      return;
    }

    this.contentRef.current.style.opacity =
      transformDelta >= 1
        ? "1"
        : String(
            transformDelta < 0.01 ? 0 : transformDelta * this.opacityMultiplier
          );
    this.contentRef.current.style.height = `${
      transformDelta >= 1
        ? this.initialContentHeight
        : transformDelta <= 0
        ? 0
        : this.initialContentHeight - scrollDelta
    }px`;
  };

  private getTagConfig(): ITagConfig {
    if (!this.props.isClickable) {
      return {
        Tag: "div",
      };
    }

    return {
      Tag: "a" as React.ReactType,
      tagProps: {
        role: "button",
        tabIndex: 0,
        onClick: this.props.onClick,
      },
    };
  }
}
