export interface ILocalizationPopUpItem {
  name: string;
  value: string;
}

export interface ILocalizationPopUpTexts {
  localizationButton: {
    localizationButtonAriaLabel: string;
  };
  localizationPopup: {
    cancelButton: string;
    languageTitle: string;
    languages: ILocalizationPopUpItem[];
    locationTitle: string;
    locations: ILocalizationPopUpItem[];
    saveButton: string;
    title: string;
    closeButtonAriaLabel: string;
  };
}

export const LOCALIZATION_ORGANISM_FALLBACK_TEXT: ILocalizationPopUpTexts = {
  localizationButton: { localizationButtonAriaLabel: 'Switch language' },
  localizationPopup: {
    languages: [
      { name: 'English', value: 'en' },
      { name: 'Française', value: 'fr' },
    ],
    locations: [
      { name: 'United Kingdom', value: 'GB' },
      { name: 'Ireland', value: 'IE' },
      { name: 'France', value: 'FR' },
    ],
    locationTitle: 'Location',
    languageTitle: 'Language',
    title: 'Your location and language',
    cancelButton: 'Cancel',
    closeButtonAriaLabel: 'Close',
    saveButton: 'Save',
  },
};
