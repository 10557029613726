export const performersDefaultQuickLinks = [
  {
    text: "common:nav.links.viewUpdateProfile",
    href: "/profile",
  },
  {
    text: "common:nav.links.memberEvents",
    href: "https://www.spotlight.com/the-small-print/spotlight-performer-events/",
  },
  {
    text: "common:nav.links.boostEvents",
    href: "https://www.spotlight.com/boost-events/",
  },
  {
    text: "common:nav.links.workshopRentalLib",
    href: "https://www.spotlight.com/workshop-rental-library/",
  },
  {
    text: "common:nav.links.filmmakers",
    href: "https://www.spotlight.com/members/expand-your-horizons-with-filmmakers-premium/",
  },
  {
    text: "common:nav.links.ecp",
    href: "https://www.spotlight.com/news-and-advice/spotlight-launches-extra-cover-pack/",
  },
  {
    text: "common:nav.links.jobsFeed",
    href: "/jobs/matching-my-filters",
  },
  {
    text: "common:nav.links.memberAssistProgram",
    href: "https://www.spotlight.com/the-small-print/health-wellbeing-member-services-terms/",
  },
  {
    text: "common:nav.links.newsAdvice",
    href: "https://www.spotlight.com/news-and-advice",
  },
  {
    text: "common:nav.links.healthAndWellbeingHub",
    href: "https://www.spotlight.com/news-and-advice/supporting-your-mental-health-and-wellbeing/",
  },
  {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
  {
    text: "common:nav.links.faq",
    href: "https://www.spotlight.com/help-and-faqs",
  },
];

export const filmmakersQuickLinks = [
  {
    text: "common:nav.links.filmmakers",
    href: "https://www.spotlight.com/news-and-advice/spotlight/filmmakers-details/",
  },
  {
    text: "common:nav.links.jobsFeed",
    href: "/jobs/matching-my-filters",
  },
  {
    text: "common:nav.links.newsAdvice",
    href: "https://www.spotlight.com/news-and-advice",
  },
  {
    text: "common:nav.links.healthAndWellbeingHub",
    href: "https://www.spotlight.com/news-and-advice/supporting-your-mental-health-and-wellbeing/",
  },
  {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
  {
    text: "common:nav.links.faq",
    href: "https://www.spotlight.com/help-and-faqs",
  },
];

export const performersGraduatesQuickLinks = [
  {
    text: "common:nav.links.memberEvents",
    href: "https://www.spotlight.com/the-small-print/spotlight-performer-events/",
  },
  {
    text: "common:nav.links.boostEvents",
    href: "https://www.spotlight.com/boost-events/",
  },
  {
    text: "common:nav.links.workshopRentalLib",
    href: "https://www.spotlight.com/workshop-rental-library/",
  },
  {
    text: "common:nav.links.viewUpdateProfile",
    href: "/profile",
  },
  {
    text: "common:nav.links.jobsFeed",
    href: "/jobs/matching-my-filters",
  },
  {
    text: "nav.links.memberAssistanceProgramme",
    href: "https://www.spotlight.com/the-small-print/health-wellbeing-member-services-terms/",
  },
  {
    text: "common:nav.links.newsAdvice",
    href: "https://www.spotlight.com/news-and-advice",
  },
  {
    text: "common:nav.links.healthAndWellbeingHub",
    href: "https://www.spotlight.com/news-and-advice/supporting-your-mental-health-and-wellbeing/",
  },
  {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
  {
    text: "common:nav.links.faq",
    href: "https://www.spotlight.com/help-and-faqs",
  },
];

export const performersYPQuickLinks = [
  {
    text: "common:nav.links.viewUpdateProfile",
    href: "/profile",
  },
  {
    text: "common:nav.links.support",
    href: "https://www.spotlight.com/news-and-advice/tips-and-advice/young-performer-resources-industry-hub/",
  },
  {
    text: "common:nav.links.faq",
    href: "https://www.spotlight.com/help-and-faqs/faqs-for-young-performers/",
  },
  {
    text: "common:nav.links.memberEvents",
    href: "https://www.spotlight.com/the-small-print/spotlight-performer-events/",
  },
  {
    text: "common:nav.links.newsAdvice",
    href: "https://www.spotlight.com/news-and-advice",
  },
  {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
];

export const performersAddOnBundleQuickLinks = [
  {
    text: "common:nav.links.memberEvents",
    href: "https://www.spotlight.com/the-small-print/spotlight-performer-events/",
  },
  {
    text: "common:nav.links.boostEvents",
    href: "https://www.spotlight.com/boost-events/",
  },
  {
    text: "common:nav.links.workshopRentalLib",
    href: "https://www.spotlight.com/workshop-rental-library/",
  },
  {
    text: "common:nav.links.viewUpdateProfile",
    href: "/profile",
  },
  {
    text: "common:nav.links.jobsFeed",
    href: "/jobs/matching-my-filters",
  },
  {
    text: "common:nav.links.memberAssistProgram",
    href: "https://www.spotlight.com/the-small-print/health-wellbeing-member-services-terms/",
  },
  {
    text: "common:nav.links.newsAdvice",
    href: "https://www.spotlight.com/news-and-advice",
  },
  {
    text: "common:nav.links.healthAndWellbeingHub",
    href: "https://www.spotlight.com/news-and-advice/supporting-your-mental-health-and-wellbeing/",
  },
  {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
  {
    text: "common:nav.links.faq",
    href: "https://www.spotlight.com/help-and-faqs",
  },
];

export const performersInactiveQuickLinks = [
  {
    text: "common:nav.links.newsAdvice",
    href: "https://www.spotlight.com/news-and-advice",
  },
  {
    text: "common:nav.links.boostEvents",
    href: "https://www.spotlight.com/boost-events/",
  },
  {
    text: "common:nav.links.workshopRentalLib",
    href: "https://www.spotlight.com/workshop-rental-library/",
  },
  {
    text: "common:nav.links.contacts",
    href: "https://www.spotlight.com/contacts",
  },
  {
    text: "common:nav.links.faq",
    href: "https://www.spotlight.com/help-and-faqs",
  },
];
