import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { IRoute } from 'config/routes';

import Page404Trigger from 'app-shell/Page404Container/Page404Trigger';

export interface IRoutesProps {
  items: IRoute[];
}

function renderRoute(route: IRoute) {
  const render = (props: RouteComponentProps) => <route.component {...props} routes={route.routes} />;

  return <Route key={route.path} exact={route.exact || false} path={route.path} render={render} />;
}

export default function Routes(props: IRoutesProps) {
  return (
    <Switch>
      {props.items.map((route) => renderRoute(route))}
      <Route>
        <Page404Trigger />
      </Route>
    </Switch>
  );
}
