import { useHistory, useLocation } from "react-router-dom";

export const useReturnUrl = () => {
  const history = useHistory();
  const location = useLocation();

  const getReturnUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("returnUrl");
  };

  const redirectToReturnUrl = () => {
    const returnUrl = getReturnUrl();
    if (returnUrl) {
      history.push(returnUrl);
    }
  };

  const redirectWithReturnUrl = (pathname: string, returnPath: string) => {
    history.push({
      pathname: pathname,
      search: `?returnUrl=${encodeURIComponent(returnPath)}`,
    });
  };

  return {
    getReturnUrl,
    redirectToReturnUrl,
    redirectWithReturnUrl,
  };
};
