import React from "react";
import "./Copyright.scss";

export interface ICopyrightProps {
  /**
   * The title on the copyright banner
   */
  title?: string;
  /**
   * The address/subtitle underneath the title
   */
  address: string;
  /** Optional css class for the topmost div */
  className?: string;
}

export class Copyright extends React.Component<ICopyrightProps> {
  public render() {
    return (
      <div
        className={`c-copyright${
          this.props.className ? " " + this.props.className : ""
        }`}
      >
        <div className="c-copyright__inner">
          {this.props.title && (
            <span className="c-copyright__notice">{this.props.title}</span>
          )}
          <span className="c-copyright__address">{this.props.address}</span>
        </div>
      </div>
    );
  }
}
