// @ts-nocheck
import React from "react";
import "./ToTop.scss";

export interface IToTopProps {
  /** The text for "Top" */
  label: string;
  /**  */
  isHidden?: boolean;
  /** Optional handler for the click event */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  /** Scrollable container ID selector */
  scrollableContainerId?: string;
}

export const MIN_BODY_TO_WINDOW_RATIO_FOR_BACK_TO_TOP = 4;

interface IToTopState {
  isVisible: boolean;
}

export class ToTop extends React.Component<IToTopProps, IToTopState> {
  public readonly state: IToTopState = {
    isVisible: false,
  };

  private lastScrollPosition: number = 0;
  private scrolledPastOneWindowHeightAndUpOnce: boolean = false;
  private scrollableContainer: Element | Window;

  public render() {
    return (
      <>
        {this.state.isVisible && !this.props.isHidden ? (
          <div className="c-to-top">
            <a
              className="c-to-top__element"
              href="#/"
              onClick={this.onClick}
              tabIndex={0}
            >
              <div className="c-to-top__button">
                <div
                  className="c-to-top__icon icon-backtotoparrow"
                  aria-label={this.props.label}
                />
                <div className="c-to-top__label">{this.props.label}</div>
              </div>
            </a>
          </div>
        ) : null}
      </>
    );
  }

  public componentDidMount() {
    this.setScrollableContainer(this.props);
    this.scrollableContainer.addEventListener(
      "scroll",
      this.decideVisibility,
      false
    );
    window.addEventListener("resize", this.decideVisibility, false);
  }

  public componentDidUpdate(prevProps: IToTopProps) {
    if (prevProps.scrollableContainerId !== this.props.scrollableContainerId) {
      this.scrollableContainer.removeEventListener(
        "scroll",
        this.decideVisibility,
        false
      );
      this.setScrollableContainer(this.props);
      window.addEventListener("resize", this.decideVisibility, false);
    }
  }

  public componentWillUnmount() {
    this.scrollableContainer.removeEventListener(
      "scroll",
      this.decideVisibility,
      false
    );
    window.removeEventListener("resize", this.decideVisibility, false);
  }

  private onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const htmlContainer = this.scrollableContainer;

    if (this.scrollableContainer === window) {
      this.scrollableContainer.scrollTo(0, 0);
    } else {
      (htmlContainer as Element).scrollTop = 0;
    }

    this.lastScrollPosition = 0;
    this.scrolledPastOneWindowHeightAndUpOnce = false;
    this.setState({ isVisible: false });

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  private decideVisibility = (event: Event) => {
    let container;
    let scrollOffset;

    if (this.scrollableContainer === window) {
      container = document.body;
      scrollOffset = window.pageYOffset;
    } else {
      container = this.scrollableContainer;
      scrollOffset = container.scrollTop;
    }

    const isScrollingUp = scrollOffset < this.lastScrollPosition;
    const containerHeight = Math.max(
      container.scrollHeight,
      container.offsetHeight
    );

    if (
      containerHeight >
        window.innerHeight * MIN_BODY_TO_WINDOW_RATIO_FOR_BACK_TO_TOP &&
      scrollOffset > window.innerHeight &&
      (this.scrolledPastOneWindowHeightAndUpOnce || isScrollingUp)
    ) {
      if (!this.state.isVisible) {
        this.setState({ isVisible: true });
      }

      if (!this.scrolledPastOneWindowHeightAndUpOnce) {
        this.scrolledPastOneWindowHeightAndUpOnce = true;
      }
    } else {
      this.setState({ isVisible: false });

      this.scrolledPastOneWindowHeightAndUpOnce = false;
    }

    this.lastScrollPosition = scrollOffset;
  };

  private setScrollableContainer = (props: IToTopProps) => {
    this.scrollableContainer = props.scrollableContainerId
      ? document.getElementById(props.scrollableContainerId) || window
      : window;
  };
}
