import React, { FC, ReactElement, useEffect, RefObject } from "react";
import { useScrollToElement } from "shared-hooks";

interface Props {
  children: ({ elRef }: { elRef: RefObject<any> }) => ReactElement;
}

const ScrollToTopContainer: FC<Props> = ({ children }) => {
  const { ref, scrollToElement: scrollToHeaderContainer } = useScrollToElement({
    block: "nearest",
    inline: "start",
  });

  useEffect(() => {
    scrollToHeaderContainer();
  }, []);

  return children({ elRef: ref });
};

export default ScrollToTopContainer;
