import cx from "classnames";
import React from "react";

import "./SectionTitle.scss";

export interface ISectionTitleProps {
  className?: string;
}

const SectionTitle: React.FC<ISectionTitleProps> = (props) => (
  <h2 className={cx("c-section-title", props.className)}>{props.children}</h2>
);

export default SectionTitle;
