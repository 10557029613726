import React from "react";
import cx from "classnames";
import { useField } from "formik";
import { useFormikScrollToError } from "shared-hooks";
import { ERROR, InputWrapper } from "../../InputsCommon/InputWrapper";
import "./CheckboxFormik.scss";

interface ICheckBoxFormikProps {
  name: string;
  value: any;
  className?: string;
  disabled?: boolean;
  label?: string;
  labelPostFix?: number;
  annex?: JSX.Element;
  onCheck?: (checked: boolean) => void;
  halfChecked?: boolean;
  white?: boolean;
}

export const CheckBoxFormik: React.FC<ICheckBoxFormikProps> = ({
  name,
  value,
  className,
  disabled,
  label,
  labelPostFix,
  annex,
  onCheck,
  halfChecked,
  white,
  ...props
}) => {
  const [field, meta] = useField(name);

  let checked = field.checked || field.value === value;
  const invalid = meta.touched && meta.error ? ERROR : undefined;

  const { containerElementRef, fieldRef } = useFormikScrollToError<
    HTMLInputElement,
    HTMLDivElement
  >(name);

  // when the field value is an array of items
  if (Array.isArray(field.value)) {
    checked = field.value.includes(value);
  }

  React.useEffect(() => {
    if (onCheck) {
      onCheck(Boolean(checked));
    }
  }, [checked]);

  const boxClassName = cx("c-checkbox-formik__box", {
    "c-checkbox-formik__box--disabled": disabled,
    "c-checkbox-formik__box--half-checked": halfChecked,
    "c-checkbox-formik__box--white": white,
    "c-checkbox-formik__box--error": invalid,
  });
  const labelClassName = cx("c-checkbox-formik__label", {
    "c-checkbox-formik__label--disabled": disabled,
  });
  const labelPostfixClassName = cx("c-checkbox-formik__label-postfix", {
    "c-checkbox-formik__label-postfix--disabled": disabled,
  });

  return (
    <div
      className={cx("c-checkbox-formik", className, {
        "c-checkbox-formik--disabled": disabled,
      })}
      ref={containerElementRef}
    >
      <InputWrapper invalid={invalid}>
        <div className="c-checkbox-formik__wrapper">
          <input
            {...field}
            {...props}
            value={value}
            type="checkbox"
            checked={checked}
            ref={fieldRef}
          />
          <div className={boxClassName} />
          {label && (
            <label className={labelClassName} htmlFor={field.name}>
              {label}
            </label>
          )}
          {labelPostFix !== undefined && (
            <div className={labelPostfixClassName}>({labelPostFix})</div>
          )}
        </div>
        {annex && <div className="c-checkbox-formik__annex">{annex}</div>}
      </InputWrapper>
    </div>
  );
};
