import { IBillingUser } from "../../models/billing/user";

export const BillingGraduateTranslations: IBillingUser = {
  common: {
    pageTitle: "Your subscription plan",
    pageCopy: `<p>Please choose your subscription plan below.</p><p><strong>Annual Card Payment</strong></p><p>We will remind you 14 days prior to the subscription expiry date to renew again.</p><p><strong>Your details</strong></p><p>If any of your details on this page are incorrect please <a href="https://www.spotlight.com/get-in-touch" title="Contact us">contact us</a> immediately.</p>`,
    userType: "Spotlight Graduate",
    successHeader: "Payment successful!",
    successBody: `<p>Thank you for your payment and membership to Spotlight for another year.</p><p>You now have access to the following benefits as part of your Spotlight Graduate subscription:</p><ul><li><a href="http://www.spotlight.com/help-and-faqs/spotlight-career-advice-for-members/" title="1-2-1 career advice sessions">1-2-1 career advice sessions</a>.</li><li><a href="http://www.spotlight.com/news-and-advice/supporting-your-mental-health-and-wellbeing/" title="Mental health and wellbeing events">Mental health and wellbeing events and schemes.</a></li><li><a href="https://www.spotlight.com/news-and-advice/events/" title="Spotlight events">Workshops &amp; webinars</a> to help you navigate the industry and improve your chances of getting a role.</li><li>Access to our <a href="http://www.spotlight.com/news-and-advice/news/spotlight-launches-exciting-new-discount-scheme/" title="Discount Scheme Launched">exclusive member discounts</a>, which includes savings on cinema tickets, gym memberships, retailers, including a TOTUM card.</li><li>and <a href="http://www.spotlight.com/the-small-print/what-we-offer-our-members/" title="What we offer our members">much, much more!</a></li></ul><p>Our monthly newsletters always offer more information, so make sure you’re opted in to receive them (you can do this by going to ‘<a href="http://www.spotlight.com//artists/privacy.aspx" title="Privacy settings">My Privacy Settings</a>’</p>`,
    goBackButton: "Go to My Account",
    directDebitSuccessHeader: "Direct Debit set up successfully!",
    directDebitSuccessBody:
      "<p>Thank you for purchasing your Spotlight subscription.</p><p>Your Direct Debit mandate has been set up and your details have been sent to your bank to authorise. The name on your bank statement will be Spotlight. You will receive an email within three business days confirming that the Direct Debit mandate has been set up.</p>",
  },
};
