import React, { FC, useCallback } from "react";
import PhoneField from "../../../../Atoms/PhoneField";
import { FieldNames } from "../../constants";
import { SetField } from "../../hooks/useBillingFormValues";
import { ERROR } from "../../../../Atoms/InputsCommon/InputWrapper";
import useScrollBillingField from "../../hooks/useScrollBillingField";

interface Props {
  label: string;
  value?: string;
  validationMessage?: string;
  shouldScroll?: boolean;
  setFieldValue: SetField;
}

const BillingPhoneNumber: FC<Props> = ({
  label,
  value = "",
  validationMessage,
  shouldScroll,
  setFieldValue,
}) => {
  const ref = useScrollBillingField(!!(validationMessage && shouldScroll));
  const onSetValue = useCallback(
    (phone: string) => setFieldValue(FieldNames.phone, phone),
    [setFieldValue]
  );
  const [initialCountryCode, initialNumber] = value.split(" ");

  return (
    <div ref={ref}>
      <PhoneField
        name="phone"
        label={label}
        initialNumber={initialNumber}
        setValue={onSetValue}
        validationMessage={validationMessage}
        invalid={validationMessage && ERROR}
        initialCountryCode={initialCountryCode || undefined}
      />
      <input type="hidden" value={value} data-recurly={FieldNames.phone} />
    </div>
  );
};

export default BillingPhoneNumber;
