import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export interface IHeadProps {
  registerPageView?: () => void;
  children?: React.ReactNode;
}

export const Head: React.FC<IHeadProps> = ({ children, registerPageView }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (registerPageView) registerPageView();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <Helmet>{children}</Helmet>;
};
