import { CheckoutFlow } from "../types";

export const getPurchaseType = (checkoutFlow: CheckoutFlow): string => {
  switch (checkoutFlow) {
    case CheckoutFlow.Default:
      return "renewal/reactivation";
    case CheckoutFlow.Joining:
      return "sign-up";
    case CheckoutFlow.AccountSettings:
      return "upgrade/downgrade";
    case CheckoutFlow.AddOnBundle:
      return "add-on-bundle";
    default:
      return "other";
  }
};
