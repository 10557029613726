import React, { FC } from "react";
import { AxiosResponse } from "axios";
import { useTranslation } from "i18n";
import { AlertMessage, Popup } from "component-library";
import { UseMutateFunction } from "react-query";

type FilmmakersTrialConfirmPopupProps = {
  setFmConfirmPopupOpen: (value: boolean) => void;
  handleTrialOptOut: () => void;
  isApplyFMCouponLoading: boolean;
  applyFMCouponMutation: UseMutateFunction<
    AxiosResponse<any, any> | undefined,
    unknown,
    void,
    unknown
  >;
};

const FilmmakersTrialConfirmPopup: FC<FilmmakersTrialConfirmPopupProps> = ({
  setFmConfirmPopupOpen,
  handleTrialOptOut,
  isApplyFMCouponLoading,
  applyFMCouponMutation,
}) => {
  const { t } = useTranslation();

  return (
    <Popup
      width={{ lg: 8, md: 8 }}
      priority="high"
      close={() => {
        handleTrialOptOut();
        setFmConfirmPopupOpen(false);
      }}
    >
      <AlertMessage
        icon="notice"
        texts={{
          title: t("performer:home.filmmakersTrial.popup.title"),
          description: t("performer:home.filmmakersTrial.popup.description", {
            href: "https://www.spotlight.com/the-small-print/filmmakers-terms-and-conditions/",
            className: "popup-link",
          }),
        }}
        buttonAlignment="right"
        buttons={[
          {
            name: t("common:button.label.cancel"),
            click: handleTrialOptOut,
            type: "secondary",
            ctaSecondaryDisabled: isApplyFMCouponLoading,
          },
          {
            name: t("performer:home.filmmakersTrial.popup.confirmTrial"),
            click: applyFMCouponMutation,
            type: "primary",
            ctaDisabled: isApplyFMCouponLoading,
          },
        ]}
      />
    </Popup>
  );
};

export default FilmmakersTrialConfirmPopup;
