import { useState } from "react";

const useReinitializeCardForm = () => {
  const [cardFormIsVisible, setCardFormIsVisible] = useState<boolean>(true);

  const reinitializeForm = () => {
    setCardFormIsVisible(false);
    setCardFormIsVisible(true);
  };

  return {
    cardFormIsVisible,
    reinitializeForm,
  };
};

export default useReinitializeCardForm;
