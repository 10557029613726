import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";

// import MoreIcon from '-!svg-react-loader?name=MoreIcon!src/images/svg/icons/more-icon.svg';
import { ReactComponent as MoreIcon } from "../../../images/svg/icons/more-icon.svg";

import { isSmallDeviceWidth } from "../../../generics/dom-extensions";
import {
  IconTextLink,
  IIconTextLinkProps,
} from "../../Atoms/IconTextLink/IconTextLink";
import { PropType } from "../../../generics/type-manipulation";

import "./ActionList.scss";

type VariantType = keyof typeof CLASS_NAME_MAPPING;

const HIDDEN_ELEMENT_STYLE_CLASS = "c-action-list__link--hidden";
const NO_OPACITY_ELEMENT = "c-action-list__link--opacity";

const CLASS_NAME_MAPPING = {
  pageHeader: "page-header",
  nameBar: "name-bar",
};

const LINK_VARIANTS_MAPPING: Record<
  VariantType,
  PropType<IIconTextLinkProps, "variant">
> = {
  pageHeader: "button-icon-text",
  nameBar: "quaternary",
};

export interface IActionListProps {
  /** A list of props for the IconTextLink elements to be rendered within */
  items: IIconTextLinkProps[];
  /** Text to show on the button which expands the menu of actions which don't fit on the first line */
  moreButtonText?: string;
  /** Different styling based on where it's used */
  variant: VariantType;
  /** Prevents mouse events on the whole component, e.g. while waiting for an action to finish */
  isInteractionDisabled?: boolean;
  /** Which direction menu should open - defaults to 'down' */
  moreElementsDirection?: "up" | "down";
}

interface IActionListState {
  isMoreDropdownOpen: boolean;
}

export class ActionList extends React.PureComponent<
  IActionListProps,
  IActionListState
> {
  public static defaultProps: Partial<IActionListProps> = {
    moreElementsDirection: "down",
  };
  private moreButtonRef = React.createRef<HTMLButtonElement>();
  private linksContainerRef = React.createRef<HTMLDivElement>();
  private shadowLinksContainerRef = React.createRef<HTMLDivElement>();
  private moreButtonContainerRef = React.createRef<HTMLDivElement>();
  private moreLinksContainerRef = React.createRef<HTMLDivElement>();
  private moreLinksSectionRef = React.createRef<HTMLDivElement>();

  public readonly state: Readonly<IActionListState> = {
    isMoreDropdownOpen: false,
  };

  public render() {
    const { items, variant, isInteractionDisabled } = this.props;

    const containerClasses = cx(
      "c-action-list",
      `c-action-list--${CLASS_NAME_MAPPING[variant]}`,
      {
        "c-action-list--prevent-interaction": isInteractionDisabled,
      }
    );

    if (items.length <= 0) {
      return null;
    }

    return (
      <div className={containerClasses}>
        <div
          ref={this.shadowLinksContainerRef}
          className="c-action-list__shadow-links"
        >
          {this.renderShadowLinks(items, variant)}
        </div>
        <div ref={this.linksContainerRef} className="c-action-list__inner">
          {this.renderLinks(items, LINK_VARIANTS_MAPPING[variant])}
          {this.renderMoreButton()}
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
    window.removeEventListener("resize", this.resizeHandler);
  }

  public componentDidMount() {
    const { variant } = this.props;
    if (variant === "pageHeader") {
      this.resizeHandler();
      window.addEventListener("resize", this.resizeHandler);
    }

    this.updateMoreLinksContainer();
  }

  private renderShadowLinks = (
    items: IIconTextLinkProps[],
    variant: VariantType
  ) => {
    const shadowLinks: React.ReactNode[] = [];

    if (variant === "pageHeader") {
      const links = items.map((item, index) => (
        <div key={item.id || index} className="c-action-list__shadow-link">
          <IconTextLink {...item} />
        </div>
      ));
      shadowLinks.push(...links);
    }

    return shadowLinks;
  };

  private renderLinks = (
    items: IIconTextLinkProps[],
    variant: PropType<IIconTextLinkProps, "variant">,
    prefix?: string
  ) =>
    items.map((item, index) => {
      const uniqKey = `${prefix || ""}_${item.id || index}`;
      return (
        <span className="c-action-list__link" key={uniqKey}>
          <IconTextLink {...item} id={uniqKey} variant={variant} />
        </span>
      );
    });

  private renderMoreButton = () => {
    if (!this.props.moreButtonText) {
      return null;
    }

    return (
      <div
        className="c-action-list__more-container"
        ref={this.moreButtonContainerRef}
      >
        <button
          className={cx(
            `c-action-list__more-button`,
            `c-action-list__more-button--${
              LINK_VARIANTS_MAPPING[this.props.variant]
            }`
          )}
          aria-haspopup="listbox"
          onClick={this.toggleMoreDropdown}
          ref={this.moreButtonRef}
          data-testid={
            dataTestIds.componentLibrary["Molecules.ActionList.dropDown"]
          }
        >
          <span className="c-action-list__more-button-icon">
            <MoreIcon />
          </span>
          <span className="c-action-list__more-button-text">
            {this.props.moreButtonText}
          </span>
        </button>
        {this.renderMoreDropdown()}
      </div>
    );
  };

  private renderMoreDropdown() {
    const { items, moreElementsDirection } = this.props;

    const moreDropdownContainerClasses = cx(
      "g-dropdown-list",
      "c-action-list__more-dropdown",
      `c-action-list__more-dropdown--${moreElementsDirection}`
    );

    this.updateMoreLinksContainer();

    return (
      <div
        ref={this.moreLinksSectionRef}
        className={moreDropdownContainerClasses}
        role="listbox"
        data-testid={
          dataTestIds.componentLibrary["Molecules.ActionList.dropDown"]
        }
      >
        <div
          className="c-action-list__more-dropdown-container"
          ref={this.moreLinksContainerRef}
        >
          {this.renderLinks(items, "tertiary", "more-dropdown")}
        </div>
      </div>
    );
  }

  private handleOutsideClick = ({ target }: MouseEvent) => {
    const { current: moreButton } = this.moreButtonRef;

    if (moreButton && !moreButton.contains(target as HTMLElement)) {
      this.closeMoreDropdown();
    }
  };

  private toggleMoreDropdown = () =>
    this.state.isMoreDropdownOpen
      ? this.closeMoreDropdown()
      : this.openMoreDropdown();

  private openMoreDropdown = () => {
    this.setState({ isMoreDropdownOpen: true });
    document.addEventListener("click", this.handleOutsideClick, false);
  };

  private closeMoreDropdown = () => {
    this.setState({ isMoreDropdownOpen: false });
    document.removeEventListener("click", this.handleOutsideClick);
  };

  private resizeHandler = () => {
    const { variant } = this.props;

    if (variant === "pageHeader") {
      this.validateItemHorizontalVisibility();
    }

    this.validateMoreButtonVisibility();
  };

  private isMoreItems = (items: Element[]) => {
    for (const item of items) {
      if (item.classList.contains(NO_OPACITY_ELEMENT)) {
        return true;
      }
    }
    return false;
  };

  private validateMoreButtonVisibility = () => {
    const { current: shadowLinksContainer } = this.shadowLinksContainerRef;
    const { current: moreButtonContainer } = this.moreButtonContainerRef;
    const { current: linksContainer } = this.linksContainerRef;
    const { variant, items } = this.props;
    const isSmallDevice = isSmallDeviceWidth();

    if (
      variant === "pageHeader" &&
      !isSmallDevice &&
      shadowLinksContainer &&
      moreButtonContainer
    ) {
      if (linksContainer && linksContainer.children) {
        // display more button when any shadow link is hidden
        const { style: moreButtonContainerStyle } = moreButtonContainer;
        moreButtonContainerStyle.display = this.isMoreItems(
          Array.from(linksContainer.children)
        )
          ? "block"
          : "none";
      }
    } else if (variant === "nameBar" && !isSmallDevice && moreButtonContainer) {
      moreButtonContainer.style.display = items.length > 2 ? "block" : "none";
    } else if (isSmallDevice && moreButtonContainer) {
      moreButtonContainer.style.display = items.length > 1 ? "block" : "none";
    }
  };

  private validateItemHorizontalVisibility = () => {
    const { current: linksContainer } = this.linksContainerRef;
    const { current: shadowLinksContainer } = this.shadowLinksContainerRef;
    const { current: moreButtonContainer } = this.moreButtonContainerRef;
    const { current: moreLinksContainer } = this.moreLinksContainerRef;

    if (
      linksContainer &&
      moreLinksContainer &&
      moreButtonContainer &&
      moreLinksContainer &&
      shadowLinksContainer
    ) {
      const { children: moreLinks } = moreLinksContainer;
      const outerWidth = linksContainer.clientWidth;
      const allLinks = linksContainer.children;

      if (!allLinks || !moreLinks) {
        return;
      }

      // used to hide and show elements between more dropdown and header
      let currentTotalSize = 0;
      Array.from(allLinks).forEach((link, index) => {
        const moreLink = moreLinks.item(index)!;
        if (!moreLink) {
          return;
        }

        currentTotalSize += link.clientWidth;

        if (currentTotalSize >= outerWidth - 120) {
          link.classList.add(NO_OPACITY_ELEMENT);
          moreLink.classList.remove(HIDDEN_ELEMENT_STYLE_CLASS);
        } else {
          link.classList.remove(NO_OPACITY_ELEMENT);
          moreLink.classList.add(HIDDEN_ELEMENT_STYLE_CLASS);
        }
      });

      this.updateMoreLinksContainer();
    }
  };

  private updateMoreLinksContainer = () => {
    const { isMoreDropdownOpen } = this.state;
    const { current: moreLinksContainer } = this.moreLinksContainerRef;
    const { current: moreLinksSection } = this.moreLinksSectionRef;

    if (moreLinksSection) {
      let maxHeight = 0;

      if (moreLinksContainer && isMoreDropdownOpen) {
        maxHeight = moreLinksContainer.offsetHeight;
      }

      moreLinksSection.style.maxHeight = `${maxHeight}px`;
    }
  };
}
