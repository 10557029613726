import { Reducer } from "redux";
import { LocalizationUtils } from "component-library";

import { ActionType } from "../types";

import { IUIState, UIActionTypes } from "./ui.types";

export const initialState: IUIState = {
  popup: null,
  locale: LocalizationUtils.getUserLocalizationPreference().locale,
  isLoading: true,
  isPageLoading: false,
  isPage404: false,
};

const reducer: Reducer<IUIState, UIActionTypes> = (
  state: IUIState = initialState,
  action: UIActionTypes
) => {
  switch (action.type) {
    case ActionType.ShowPopup:
      return {
        ...state,
        popup: action.payload,
      };
    case ActionType.HidePopup:
      return {
        ...state,
        popup: null,
      };
    case ActionType.SetLocale:
      return {
        ...state,
        locale: action.payload,
      };
    case ActionType.SetLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionType.SetPageLoading:
      return {
        ...state,
        isPageLoading: action.payload,
      };
    case ActionType.SetPage404:
      return {
        ...state,
        isPage404: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
