import { IJoiningAsPageTranslations } from "../../models/joining-pages/JoiningAsPage";

export const JoiningAsPageTranslations: IJoiningAsPageTranslations = {
  changePathwayModal: {
    changePathwayTitle: "Warning",
    changePathwayContent: `<p>Are you sure you want to start the application again?</p><p>If you choose to start the application again using a different eligibility criteria, your current application will be deleted.</p><b>All information entered will be lost.</b>`,
    changePathwayConfirm: "Delete all info and start application again",
    changePathwayCancel: "Continue application",
    changePathwayError:
      "There was an error continuing your application. Please try again.",
  },
  joiningAsPage: {
    joiningAsPageTitle: "Eligibility",
    joiningAsDescription:
      "To join Spotlight you must meet one the following criteria. Please select which you applied to you to continue. If you meet more than one criterion....words.",
    joiningAsExperience: "Experience",
    joiningAsExperienceDescription: `<p>You must have one featured role with an <strong>Equity</strong> or equivalent union contract OR <strong>two</strong> professional performance credits</p>`,
    joiningAsExperienceLabel: "I meet the experience criteria",
    joiningAsExperienceNextText: `Next you will be asked to provide details of the credits, including dates, director and production company. If you don’t have the information to hand you can save the application and come back later. The more information you provide the quicker your application will be reviewed.`,
    joiningAsTraining: "Training",
    joiningAsTrainingDescription: `You must have complete at least a year’s professional performance training`,
    joiningAsTrainingLabel: "I meet the training criteria",
    joiningAsTrainingNextText: `Next you will be asked to provide details of the training, including school name, course name and dates. If you don’t have the information to hand you can save the application and come back later. The more information you provide the quicker your application will be reviewed.`,
    joiningAsRecommendation: "Recommendation",
    joiningAsRecommendationDescription: `<p>You must have a recommendation from a member of the <strong>PMA, CDG, CSA, CDA, CPMA</strong></p>`,
    joiningAsRecommendationLabel: "I have a recommendation",
    joiningAsRecommendationNextText: `Next you will be asked to provide details of your recommendation, including referee’s name and a PDF of the letter or email recommending you. If you don’t have the information to hand you can save the application and come back later. The more information you provide the quicker your application will be reviewed.`,
  },
  eligibilityExperiencedPage: {
    experiencedAddCreditTitle: "Please add your credit information",
    experiencedAddCreditButton: "+ Add credit information",
    experiencedAddCreditFootnote: `<p>Please note if you do not provide the relevant evidential information above, your application is unlikely to be successful.</p>\n<p>Any further information can be added to your profile once the application is processed and providing the details given are approved.</p>`,
    experiencedTypeLabel: "Type:",
    experiencedRoleNameLabel: "Role name:",
    experiencedCompanyLabel: "Company:",
    experiencedDirectorLabel: "Director:",
    experiencedUrlLabel: "URL:",
    experiencedDateLabel: "Date:",
    experiencedUploadLabel: "Upload:",
    experiencedEquityContractLabel: "Equity contract:",
    experiencedDeleteModalTitle: "Delete credit",
    experiencedDeleteModalConfirm: "Delete credit",
    experiencedDeleteModalCancel: "Cancel",
    experiencedDeleteModalContent:
      "Are you sure you want to delete this credit?",
  },
  addCreditModal: {
    creditModalTitle: "Add credit information",
    creditProductionLabel: "Production:",
    creditTypeLabel: "Type:",
    creditRoleNameLabel: "Role name:",
    creditCompanyLabel: "Company:",
    creditDirectorLabel: "Director:",
    creditUrlLabel: "URL link to credit (IMDb or equivalent):",
    creditDateFromLabel: "Date from:",
    creditDateToLabel: "Date to:",
    creditUploadLabel: "Please upload your contract:",
    creditEquityRoleLabel:
      "Was the role an Equity or equivalent union contract?",
    creditEquityContractYesMessage:
      "Please ensure you have uploaded your Equity contract. If you do not upload your contract your application will be delayed",
    creditSaveCreditLabel: "Save credit and add another",
    creditCancelCreditLabel: "Cancel",
  },
  eligibilityTrainingPage: {
    trainingAddCourseTitle: "Please add your training information",
    trainingAddCourseButton: "+ Add course information",
    trainingAddCourseFootnote: `<p>Please note if you do not provide the relevant evidential information above, your application is unlikely to be successful.</p>\n<p>Any further information can be added to your profile once the application is processed and providing the details given are approved.</p>`,
    trainingCourseNameLabel: "Course name:",
    trainingDateLabel: "Date:",
    trainingQualificationLabel: "Qualification level:",
    trainingUrlLabel: "URL:",
    trainingCourseTypeLabel: "Full time:",
    trainingUpload: "Upload:",
    trainingDeleteModalTitle: "Delete course",
    trainingDeleteModalConfirm: "Delete course",
    trainingDeleteModalCancel: "Cancel",
    trainingDeleteModalContent: "Are you sure you want to delete this course?",
  },
  addCourseModal: {
    courseModalTitle: "Add course information",
    courseSchoolNameLabel: "School name:",
    courseCourseNameLabel: "Course name:",
    courseDateFromLabel: "Date from:",
    courseDateToLabel: "Date to:",
    courseQualificationLevelLabel: "Qualification level:",
    courseCourseUrlLabel: "Course URL link:",
    courseFullTimeLabel: "Was the course full time or part time?",
    courseUploadLabel: "Please upload your certificate/acceptance letter etc:",
    courseSaveCourseLabel: "Save course",
    courseCancelCourseLabel: "Cancel",
    courseFullTime: "Full time",
    coursePartTime: "Part time",
  },
  eligibilityRecommendationPage: {
    recommendationTitle: "Please add your recommendation information",
    recommendationFullNameLabel: "Full name of referee:",
    recommendationMembershipLabel: "Referee's membership",
    recommendationUploadLabel: "Upload recommendation letter/email:",
  },
};
