import React, { FC, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import cx from "classnames";
import { dataTestIds } from "data-testids";

import { InputLabel } from "../../Atoms/InputsCommon/InputLabel";
import { ReactComponent as WarningIcon } from "../../../images/svg/icons/warning-icon.svg";
import "react-quill/dist/quill.snow.css";
import { formats, modules } from "./richTextConfig";
import "./style.scss";

// Prepend http:// to links if scheme is missing
const Link = Quill.import("formats/link");
Link.sanitize = function (url: string) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
};

export interface IRichTextAreaProps {
  label: string;
  placeholder?: string;
  formikValue: string;
  formikOnChange: (value: string) => void;
  isInvalid?: boolean;
  validationErrorMessage?: string;
  annex?: JSX.Element;
  maxLength?: number;
  textAreaRef?: React.RefObject<any>;
  customClassNameForQuill?: string;
  customClassNameForCounter?: string;
  "data-testid"?: string;
}

const RichTextArea: FC<IRichTextAreaProps> = ({
  maxLength,
  label,
  placeholder,
  formikValue,
  formikOnChange,
  isInvalid,
  validationErrorMessage = "Required",
  annex,
  textAreaRef,
  customClassNameForQuill,
  customClassNameForCounter,
  "data-testid": dataTestId,
}) => {
  const reactQuillRef = useRef<ReactQuill>(null);
  const quillRef = textAreaRef || reactQuillRef;

  const handleChange = (content: string) => {
    const quillInstance = quillRef.current?.getEditor(); //gets Quill(not React-Quill) instance

    if (!quillInstance) return;

    // If user pastes content that is too long, trim it
    quillInstance.on("text-change", function () {
      if (!maxLength) return;

      // https://quilljs.com/docs/api#getlength
      // Note even when Quill is empty, there is still a blank line represented by '\n', so getLength will return 1.
      const quillInstanceLengthLessFirstChar = quillInstance.getLength() - 1;

      if (quillInstanceLengthLessFirstChar > maxLength) {
        quillInstance.deleteText(maxLength, quillInstanceLengthLessFirstChar);
      }
    });

    // If user deletes all content, set formik value to empty string
    const noUserInputedContent = content === "<p><br></p>";

    if (noUserInputedContent) {
      formikOnChange("");
      return;
    }

    // formik Stringifies the value, so all html from Quill is sent as a string
    formikOnChange(content);
  };

  const getCurrentCharCount = () => {
    const quillInstance = quillRef.current?.getEditor();

    // https://quilljs.com/docs/api#getlength
    // Note even when Quill is empty, there is still a blank line represented by '\n', so getLength will return 1.
    const quillInstanceLengthLessFirstChar = quillInstance?.getLength() - 1;

    if (!quillInstanceLengthLessFirstChar) return 0;

    return quillInstanceLengthLessFirstChar;
  };
  const charCount = getCurrentCharCount();

  const wrapperClassNames = cx("rich-text__wrapper", {
    "rich-text__wrapper--invalid": isInvalid,
  });

  const getValidationError = () => {
    return (
      <div className="flex items-center justify-start gap-1 text-sm font-semibold pt-[2px]">
        <WarningIcon height={16} width={16} />
        <span className="text-red-cardinal">{validationErrorMessage}</span>
      </div>
    );
  };

  const combinedDataTestId = `${dataTestIds.componentLibrary["Molecules.RichTextArea.richTextArea"]}${dataTestId}`;

  return (
    <div className={wrapperClassNames} data-testid={combinedDataTestId}>
      <InputLabel label={label} annex={annex} />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        formats={formats}
        modules={modules}
        placeholder={placeholder}
        value={formikValue}
        onChange={handleChange}
        bounds={".app"}
        className={customClassNameForQuill}
        tabIndex={0}
      />
      {(maxLength || isInvalid) && (
        <span
          className={`${customClassNameForCounter} rich-text__word-count-below`}
        >
          {isInvalid ? getValidationError() : `${charCount}/${maxLength}`}
        </span>
      )}
    </div>
  );
};

export default RichTextArea;
