import React from "react";
import { IAudioModel } from "shared-types";
import { Audio } from "./components/Audio";
import { AudioTrackList } from "./components/AudioTrackList";

import "./AudioPlayer.scss";

export interface IAudioPlayerProps {
  /**
   * List of audio tracks
   */
  tracks: IAudioModel[];
  /**
   * Current audio track
   */
  currentTrack: IAudioModel;
  /**
   * Handler function for selecting audio to play
   */
  selectTrack?: (track: IAudioModel) => void;
  /**
   * Autoplay mode (by default false)
   */
  isAutoPlay: boolean;
  /**
   * Handler function for onPlay event
   */
  onPlay?: () => void;
  /**
   * Any extra info to include into the file title
   */
  titlePrefix?: string;
}

export interface IAudioPlayerState {
  isAutoplayOn: boolean;
}

export class AudioPlayer extends React.Component<
  IAudioPlayerProps,
  IAudioPlayerState
> {
  public static defaultProps = {
    tracks: [],
    isAutoPlay: false,
  };

  public readonly state: Readonly<IAudioPlayerState> = {
    isAutoplayOn: this.props.isAutoPlay,
  };

  public render() {
    const { currentTrack, tracks, onPlay, titlePrefix } = this.props;
    const { isAutoplayOn } = this.state;

    const nextTrackFunction =
      tracks.length > 1 ? this.switchToNextTrack : undefined;

    return (
      <div className="c-audio-player">
        <Audio
          onNextTrack={nextTrackFunction}
          track={currentTrack}
          isAutoPlay={isAutoplayOn}
          onPlay={onPlay}
          titlePrefix={titlePrefix}
        />
        {tracks.length > 0 && (
          <AudioTrackList
            currentTrack={currentTrack}
            selectTrack={this.selectTrack}
            tracks={tracks}
          />
        )}
      </div>
    );
  }

  private switchToNextTrack = () => {
    const { tracks, currentTrack: selectedTrack } = this.props;
    const activeTrackIndex = tracks.findIndex(
      (item) => item.id === selectedTrack.id
    );
    const nextIndex =
      activeTrackIndex + 1 > tracks.length - 1 ? 0 : activeTrackIndex + 1;

    this.selectTrack(tracks[nextIndex]);
  };

  private selectTrack = (selectedTrack: IAudioModel) => {
    this.props.selectTrack && this.props.selectTrack(selectedTrack);
    this.setState({ isAutoplayOn: true });
  };
}
