import { MemberGroups } from "shared-types";
import { getLHNavBottomLinks } from "./getLHNavBottomLinks";
import { getHeaderLabels } from "./getHeaderLabels";
import { getLocalPopUpText } from "./getLocalPopUpText";
import { getMainNav } from "./getMainNav";

export interface IModifiers {
  isCastingBBC?: boolean;
  isAddonPerformer?: boolean;
  isAgencySettingsFlagEnabled?: boolean;
  isMediaRequestEnabled?: boolean;
}

export function getNavHeaderLinks(
  memberGroup: string = MemberGroups.anonymous,
  modifiers: IModifiers = {}
) {
  const mainNav = getMainNav(memberGroup, modifiers);
  const lhNavBottomLinks = getLHNavBottomLinks(memberGroup);
  const headerLabels = getHeaderLabels();
  const localPopUpText = getLocalPopUpText();

  return { lhNavBottomLinks, headerLabels, localPopUpText, mainNav };
}
