import React from "react";
import _compact from "lodash/compact";
import {
  FiltersFieldNames,
  IFilterFieldConfig,
  IFilterValues,
} from "../../types";
import {
  FilterDrawerMediaFilter,
  FilterDrawerMediaFilterNames,
} from "../../../FilterDrawerMediaFilter";

export const mediaFilter: IFilterFieldConfig<IFilterValues> = {
  name: FiltersFieldNames.Media,
  defaultValue: {
    [FilterDrawerMediaFilterNames.HasShowreels]: false,
    [FilterDrawerMediaFilterNames.HasAudioClips]: false,
  },
  getFilterFiledProps: ({
    values,
    filterDrawerValues,
    setFilterValues,
    setFilterDrawerValues,
    stats,
    t,
  }) => {
    const mediaFilterDrawerValue = filterDrawerValues[FiltersFieldNames.Media];
    const showreelsChecked =
      mediaFilterDrawerValue[FilterDrawerMediaFilterNames.HasShowreels];
    const audioClipsChecked =
      mediaFilterDrawerValue[FilterDrawerMediaFilterNames.HasAudioClips];

    const filterDrawerConfig = {
      id: `${FilterDrawerMediaFilterNames.HasShowreels}-${FilterDrawerMediaFilterNames.HasAudioClips}`,
      title: t("common:search.filters.media.title"),
      selected: showreelsChecked || audioClipsChecked,
      renderFilterContent: () => (
        <FilterDrawerMediaFilter
          showreelsValueChanged={(value) =>
            setFilterDrawerValues({
              [FiltersFieldNames.Media]: {
                ...mediaFilterDrawerValue,
                [FilterDrawerMediaFilterNames.HasShowreels]: value,
              },
            })
          }
          showreelsChecked={showreelsChecked}
          showreelsCount={stats?.performersWithShowReelsCount}
          audioClipValueChanged={(value) =>
            setFilterDrawerValues({
              [FiltersFieldNames.Media]: {
                ...mediaFilterDrawerValue,
                [FilterDrawerMediaFilterNames.HasAudioClips]: value,
              },
            })
          }
          audioClipChecked={audioClipsChecked}
          audioClipCount={stats?.performersWithAudioClipsCount}
        />
      ),
    };

    const mediaFilterValue = values[FiltersFieldNames.Media];
    const showreelTag = mediaFilterValue[
      FilterDrawerMediaFilterNames.HasShowreels
    ] && {
      id: FilterDrawerMediaFilterNames.HasShowreels,
      text: `${t("common:search.filters.tagLabel.media")} ${t(
        "common:search.filters.showreels"
      )}`,
      onClick: () =>
        setFilterValues({
          [FiltersFieldNames.Media]: {
            ...mediaFilterValue,
            [FilterDrawerMediaFilterNames.HasShowreels]: false,
          },
        }),
    };
    const audioClipTag = mediaFilterValue[
      FilterDrawerMediaFilterNames.HasAudioClips
    ] && {
      id: FilterDrawerMediaFilterNames.HasAudioClips,
      text: `${t("common:search.filters.tagLabel.media")} ${t(
        "common:search.filters.audioClip"
      )}`,
      onClick: () =>
        setFilterValues({
          [FiltersFieldNames.Media]: {
            ...mediaFilterValue,
            [FilterDrawerMediaFilterNames.HasAudioClips]: false,
          },
        }),
    };

    const tags = _compact([showreelTag, audioClipTag]);

    return {
      tags,
      filterDrawerConfig,
    };
  },
};
