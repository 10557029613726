import React from "react";
import { IAudioModel } from "shared-types";
import { AudioControls } from "../AudioControls";
import { AudioTimeBar } from "../AudioTimeBar";
import { transformMillisecToSec } from "../../../../../generics/time-formatting";

import "./Audio.scss";

const VOLUME_LEVELS = {
  VOLUME_DELTA: 0.25,
  VOLUME_MAX: 0.75,
  VOLUME_MIN: 0.25,
};

const RATIO = 1000;

export interface IAudioProps {
  track: IAudioModel;
  isAutoPlay: boolean;
  onNextTrack?: () => void;
  onPlay?: () => void;
  titlePrefix?: string;
}

interface IAudioState {
  isMuted: boolean;
  currentTime: number;
  id: string;
  isLoading: boolean;
}

export class Audio extends React.Component<IAudioProps, IAudioState> {
  public readonly state: Readonly<IAudioState>;

  private audioRef = React.createRef<HTMLAudioElement>();

  constructor(props: Readonly<IAudioProps>) {
    super(props);

    this.state = {
      isMuted: false,
      currentTime: 0,
      id: props.track.id,
      isLoading: true,
    };
  }

  public static getDerivedStateFromProps(
    props: IAudioProps,
    state: IAudioState
  ) {
    let newState: Partial<IAudioState> | null = null;

    if (props.track.id !== state.id) {
      newState = {
        id: props.track.id,
      };
    }

    return newState;
  }

  public render() {
    const { isMuted, currentTime, isLoading } = this.state;
    const { onNextTrack } = this.props;
    const audioElem = this.audioRef.current;

    return (
      <div className="c-audio">
        {this.renderTitle()}
        {this.renderAudio()}
        <AudioTimeBar
          currentValue={transformMillisecToSec(currentTime)}
          totalValue={
            audioElem?.duration
              ? transformMillisecToSec(audioElem.duration * RATIO)
              : 0
          }
          changeValue={this.changeCurrentTime}
        />
        <AudioControls
          isPlayed={audioElem ? !audioElem.paused : false}
          isMuted={isMuted}
          duration={
            audioElem?.duration
              ? transformMillisecToSec(audioElem.duration * RATIO)
              : 0
          }
          currentTime={transformMillisecToSec(currentTime)}
          onPlay={this.playTrack}
          onNext={onNextTrack}
          onPause={this.pauseTrack}
          onVolumeUp={this.volumeUp}
          onVolumeDown={this.volumeDown}
          onMuteToggle={this.muteToggleHandler}
          isLoading={isLoading}
        />
      </div>
    );
  }

  private setLoading = () => this.setState({ isLoading: false });

  private renderTitle = () => {
    const {
      track: { fileName },
      titlePrefix,
    } = this.props;

    const prefix = titlePrefix ? titlePrefix + " | " : "";

    return (
      <div className="c-audio__title">
        <span className="c-audio__title-prefix">{prefix}</span>
        {fileName}
      </div>
    );
  };

  private renderAudio = () => {
    const { isMuted } = this.state;
    const { track, isAutoPlay, onPlay } = this.props;

    return (
      <audio
        ref={this.audioRef}
        onTimeUpdate={this.updateTime}
        autoPlay={isAutoPlay}
        src={track.src}
        muted={isMuted}
        onPlay={onPlay}
        onCanPlay={this.setLoading}
      />
    );
  };

  private changeCurrentTime = (time: number) => {
    const { current: audioElement } = this.audioRef;

    if (audioElement) {
      audioElement.currentTime = time;
      this.setState({ currentTime: time * RATIO });
    }
  };

  private updateTime = (
    event: React.SyntheticEvent<HTMLAudioElement, Event>
  ) => {
    const {
      currentTarget: { currentTime },
    } = event;
    this.setState({ currentTime: currentTime * RATIO });
  };

  private playTrack = async () => {
    const { current: audioElement } = this.audioRef;

    if (audioElement) {
      try {
        await audioElement.play();
      } catch (error) {
        console.error("Error is happened during playing audio", error);
      }
    }
  };

  private pauseTrack = async () => {
    const { current: audioElement } = this.audioRef;

    if (audioElement) {
      try {
        await audioElement.pause();
      } catch (error) {
        console.error("Error is happened during pausing audio", error);
      }
    }
  };

  private volumeUp = () => {
    const { current: audioElement } = this.audioRef;

    if (audioElement) {
      this.setState({ isMuted: false });

      if (audioElement.volume <= VOLUME_LEVELS.VOLUME_MAX) {
        audioElement.volume += VOLUME_LEVELS.VOLUME_DELTA;
      }
    }
  };

  private volumeDown = () => {
    const { current: audioElement } = this.audioRef;

    if (audioElement) {
      if (audioElement.volume >= VOLUME_LEVELS.VOLUME_MIN) {
        audioElement.volume -= VOLUME_LEVELS.VOLUME_DELTA;
      }

      if (audioElement.volume === 0) {
        this.setState({ isMuted: true });
      }
    }
  };

  private muteToggleHandler = () => {
    const { current: audioElement } = this.audioRef;

    if (audioElement) {
      const { isMuted } = this.state;

      if (isMuted && audioElement.volume === 0) {
        audioElement.volume = VOLUME_LEVELS.VOLUME_MIN;
      }

      this.setState({ isMuted: !isMuted });
    }
  };
}
