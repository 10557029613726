import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";

import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

import "./ButtonSplit.scss";

export interface IAction {
  /** Label to be displayed on the action's clickable element */
  label: string;
  /** Click handler for each action */
  onClick: (
    e?:
      | React.MouseEvent<HTMLAnchorElement>
      | React.KeyboardEvent<HTMLAnchorElement>
  ) => void;
  dataTestId?: string;
}

export interface IButtonSplitProps {
  /** Label to be displayed on the button trigger */
  label: string;
  /** The actions in the menu */
  actions: IAction[];
  /** Which direction the menu should open - defaults to 'up' */
  menuDirection: "up" | "down";
  /** Externally controls opened state */
  isOpenByDefault?: boolean;
  /** Optional css class for the topmost div */
  className?: string;
  /** Whether to show loading indicator */
  loading?: boolean;
  /** Text for loading indicator */
  loadingText?: string;
  /** Flag to disable the button */
  disabled?: boolean;
  /** Event listener for clicking or hitting enter on the toggle menu */
  onMenuToggleClick?: (
    e?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => void;
}

interface IButtonSplitState {
  isOpen: boolean;
}

export class ButtonSplit extends React.Component<
  IButtonSplitProps,
  IButtonSplitState
> {
  public static defaultProps: Partial<IButtonSplitProps> = {
    menuDirection: "up",
  };

  private buttonSplitRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private triggerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  public constructor(props: IButtonSplitProps) {
    super(props);

    this.state = {
      isOpen: !this.props.disabled && !!this.props.isOpenByDefault,
    };
  }

  public render() {
    return (
      <div
        className={cx(
          "c-button-split",
          this.props.className,
          `c-button-split--${this.props.menuDirection}`,
          {
            "c-button-split--loading": this.props.loading,
            "c-button-split--disabled": this.props.disabled,
          }
        )}
        ref={this.buttonSplitRef}
      >
        <div
          tabIndex={this.props.disabled ? -1 : 0}
          ref={this.triggerRef}
          role="button"
          onClick={this.toggle}
          onKeyPress={this.toggleByKey}
          className="c-button-split__trigger"
          data-testid={
            dataTestIds.componentLibrary["Atoms.ButtonSplit.trigger"]
          }
        >
          {!this.props.loading && (
            <div className="c-button-split__label">{this.props.label}</div>
          )}
          {this.props.loading && (
            <div className="c-button-split__spinner">
              <LoadingIndicator text={this.props.loadingText} />
            </div>
          )}
          <div className="c-button-split__arrow" />
        </div>
        {this.state.isOpen ? (
          <div className="c-button-split__actions-list-container">
            <ul className="c-button-split__actions-list">
              {this.props.actions.map((action, index) => (
                <li className="c-button-split__action-container" key={index}>
                  <a
                    href="#/"
                    className="c-button-split__action"
                    onClick={this.onActionClick(action)}
                    tabIndex={0}
                    data-testid={action.dataTestId}
                  >
                    {action.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }

  public componentWillMount() {
    if (this.props.isOpenByDefault) {
      document.addEventListener("click", this.handleOutsideClick, false);
    }
  }

  private toggleByKey = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      this.toggle(event);
    }
  };

  private toggle = (
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (this.props.disabled) {
      return;
    }

    this.state.isOpen ? this.close() : this.open();

    if (this.props.onMenuToggleClick) {
      this.props.onMenuToggleClick(event);
    }
  };

  private open = () => {
    this.setState({ isOpen: true }, () => {
      document.addEventListener("click", this.handleOutsideClick, false);
      if (this.triggerRef.current) {
        this.triggerRef.current.blur();
      }
    });
  };

  private close = () => {
    this.setState({ isOpen: false }, () => {
      document.removeEventListener("click", this.handleOutsideClick, false);
    });
  };

  private onActionClick =
    (action: IAction) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      action.onClick(event);
      this.close();
    };

  public handleOutsideClick = (event: any) => {
    if (
      this.buttonSplitRef.current &&
      this.buttonSplitRef.current.contains(event.target)
    ) {
      return;
    }
    this.close();
  };

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }
}
