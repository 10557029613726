import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import LoadingIndicator, {
  LoadingIndicatorSizes,
} from "../LoadingIndicator/LoadingIndicator";

import "./Spinner.scss";

export interface ISpinnerProps {
  inline?: boolean;
  variant?: "primary" | "secondary";
}

export class Spinner extends React.Component<ISpinnerProps> {
  public render() {
    const { inline, variant } = this.props;
    return (
      <div
        className={cx("c-spinner", { "c-spinner--inline": inline })}
        data-testid={dataTestIds.componentLibrary["Atoms.Spinner.spinner"]}
      >
        <div
          className={cx(
            "c-spinner__icon",
            variant ? `c-spinner__icon--${variant}` : null,
            "flex flex-col items-center gap-2"
          )}
        >
          <LoadingIndicator
            variant="secondary"
            size={LoadingIndicatorSizes.MEDIUM}
          />
        </div>
      </div>
    );
  }
}
