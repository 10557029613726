import React from "react";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { authAxios as axios, authorizationService } from "shared-auth";
import { LaunchDarklyProvider, initSentry } from "shared-services";
import { getAlertMessageTexts, getErrorPopupTexts } from "component-library";
import { i18n } from "i18n";
import { IApplicationState } from "store/index";
import { GetCommonTranslation } from "store/translation/translation.types";
import {
  hidePopup,
  setLoading,
  setLocale,
  setPage404,
  showPopup,
} from "store/ui/ui.actions";
import {
  HidePopup,
  IUIState,
  PopupType,
  SetLoading,
  SetLocale,
  SetPage404,
  ShowPopup,
} from "store/ui/ui.types";

import changeTrackingRegistryService from "services/changeTrackingRegistryService";

import Content from "app-shell/Content/Content";
import Page404Container from "app-shell/Page404Container/Page404Container";

import withGlobalSpinner from "hoc/withGlobalSpinner";
import withLocalizedData from "hoc/withLocalizedData";
import withZendesk from "hoc/withZendesk";
import withNavigator from "hoc/withNavigator";
import withUnsavedChangesNavigationPrevention from "hoc/withUnsavedChangesNavigationPrevention";

import Popups from "components/Popups/Popups";

import withOidcAuthentication from "./features/oidc/withOidcAuthentication";
import { getCommonTranslation } from "store/translation/translation.actions";

createBrowserHistory();
export interface IAppActions {
  setLoading: SetLoading;
  setLocale: SetLocale;
  showPopup: ShowPopup;
  hidePopup: HidePopup;
  getCommonTranslation: GetCommonTranslation;
  setPage404: SetPage404;
}

export interface IAppStoreProps {
  ui: IUIState;
  locale: string;
  isLoading: boolean;
  isCommonTranslationLoaded: boolean;
  isUserLoaded: boolean;
  isPage404: boolean;
}

export interface IAppProps
  extends IAppStoreProps,
    IAppActions,
    RouteComponentProps {}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export class App extends React.Component<IAppProps> {
  public componentDidUpdate(prevProps: IAppProps) {
    this.handlePage404(prevProps);
  }

  public componentDidMount() {
    initSentry(authorizationService.getDecodedUserToken(), "user-account-app");
    authorizationService.addUserSignedOutEvent(() =>
      this.handleUserSignedOutEvent()
    );

    // replicate existing error handling behaviour
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.config?.params?.skipGenericErrorHandling)
          return Promise.reject(new Error(error));

        const errorStatusCode = error.response.status;
        const isAuthError = errorStatusCode === 401;
        const hidePopUpClick = isAuthError
          ? () => {
              authorizationService.signInRedirect();
            }
          : () => window.location.reload();
        const i18nErrorStatusCode = getAlertMessageTexts(
          i18n.t,
          errorStatusCode
        );

        this.props.showPopup({
          type: PopupType.ErrorPopup,
          data: {
            closeButtonText: i18n.t("common:button.label.close"),
            title: i18nErrorStatusCode.title,
            description: i18nErrorStatusCode.description,
            hidePopup: hidePopUpClick,
          },
        });

        return Promise.reject(new Error(error));
      }
    );
  }

  private async handleUserSignedOutEvent() {
    const errorText = getErrorPopupTexts();

    this.props.showPopup({
      type: PopupType.ErrorPopup,
      data: {
        closeButtonText: errorText.accountAccessError.closeButtonText,
        description: errorText.accountAccessError.description,
        title: errorText.accountAccessError.title,
        hidePopup: () => {
          authorizationService.signInRedirect();
        },
      },
    });
  }

  public componentWillUnmount() {
    authorizationService.removeUserSignedOutEvent();
  }

  public render() {
    if (this.props.isPage404) {
      return <Page404Container />;
    }

    return (
      <QueryClientProvider client={queryClient}>
        <LaunchDarklyProvider thisUser={authorizationService.getStoredUser()}>
          <Content />
          <Popups />
        </LaunchDarklyProvider>
      </QueryClientProvider>
    );
  }

  private handlePage404(prevProps: IAppProps) {
    if (
      this.props.isPage404 &&
      prevProps.isPage404 &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.props.setPage404(false);
    }
  }
}

const mapDispatchToProps = {
  setLoading,
  setLocale,
  showPopup,
  hidePopup,
  getCommonTranslation,
  setPage404,
};

const mapStateToProps = (state: IApplicationState): IAppStoreProps => ({
  ui: state.ui,
  locale: state.ui.locale,
  isLoading: state.ui.isLoading && !state.user.isLoaded,
  isCommonTranslationLoaded: state.translation.isCommonLoaded,
  isUserLoaded: state.user.isLoaded,
  isPage404: state.ui.isPage404,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withGlobalSpinner,
  withOidcAuthentication,
  withUnsavedChangesNavigationPrevention(
    window,
    changeTrackingRegistryService.getUnsavedChange
  ),
  withNavigator,
  withLocalizedData,
  withZendesk
)(App) as React.ComponentType;
