import { ActionType } from '../types';

import { HidePopup, SetLoading, SetLocale, SetPage404, SetPageLoading, ShowPopup } from './ui.types';

// ============================== Redux Actions =======================================================================

export const showPopup: ShowPopup = (popupData) => {
  return {
    type: ActionType.ShowPopup,
    payload: popupData,
  };
};

export const hidePopup: HidePopup = () => {
  return { type: ActionType.HidePopup, payload: undefined };
};

export const setLocale: SetLocale = (locale) => {
  return { type: ActionType.SetLocale, payload: locale };
};

export const setLoading: SetLoading = (value) => {
  return { type: ActionType.SetLoading, payload: value };
};

export const setPageLoading: SetPageLoading = (value) => {
  return { type: ActionType.SetPageLoading, payload: value };
};

export const setPage404: SetPage404 = (value) => {
  return { type: ActionType.SetPage404, payload: value };
};
