// import WarningIcon from '-!svg-react-loader?name=WarningIcon!src/images/svg/icons/warning-icon.svg';
// import NoticeIcon from '-!svg-react-loader?name=NoticeIcon!src/images/svg/icons/warning-icon-black.svg';
import { ReactComponent as WarningIcon } from "../../../images/svg/icons/warning-icon.svg";
import { ReactComponent as NoticeIcon } from "../../../images/svg/icons/warning-icon-black.svg";

import React from "react";
import DOMPurify from "dompurify";
import { dataTestIds } from "data-testids";

import "./AlertMessage.scss";
import { Button } from "../../Atoms/Button/Button";

export interface IAnswerButton {
  name: string;
  click: any;
  type: any;
  ctaDisabled?: boolean;
  ctaSecondaryDisabled?: boolean;
  "data-testid"?: string;
  loading?: boolean;
}

export interface IAlertMessageProps {
  /**
   * Type of alert message
   */
  icon?: "warning" | "notice";
  /**
   * Texts for part of alert message component
   */
  texts: {
    title: string;
    description?: string;
  };
  /**
   * List of buttons which will inserted in alert message component
   */
  buttons: IAnswerButton[];
  buttonAlignment?: "right" | "center";
  focusOnOpen?: boolean;
  /** Optional css class for the topmost div */
  className?: string;
}

export class AlertMessage extends React.Component<IAlertMessageProps, any> {
  private selfRef: React.RefObject<HTMLDivElement>;
  constructor(props: IAlertMessageProps) {
    super(props);
    this.selfRef = React.createRef<HTMLDivElement>();
  }

  private getButtonDisableState = (button: IAnswerButton) => {
    return button.type === "primary"
      ? button.ctaDisabled
      : button.ctaSecondaryDisabled;
  };

  public render() {
    return (
      <div
        role={this.props.icon ? "alertdialog" : "status"}
        aria-labelledby="alertTitle"
        aria-describedby="alertDescription"
        className={`c-alert-message${
          this.props.className ? " " + this.props.className : ""
        }`}
        tabIndex={0}
        ref={this.selfRef}
      >
        <div className="g-popup-content">
          <div className="g-popup-content__header">
            {this.renderHeaderIcon()}
            <div className="c-alert-message__title" id="alertTitle">
              {this.props.texts.title}
            </div>
          </div>
          {this.getDescriptionSection()}
        </div>
        {this.getAdditionalContentSection()}
        <div
          className={`g-popup-content__buttons-set g-inner-grid ${
            this.props.buttonAlignment === "right"
              ? "c-alert-message__buttons-right"
              : ""
          }`}
        >
          {this.getButtons()}
        </div>
      </div>
    );
  }

  public renderHeaderIcon() {
    if (!this.props.icon) {
      return null;
    }

    const sign: JSX.Element =
      this.props.icon === "notice" ? <NoticeIcon /> : <WarningIcon />;

    return <div className="c-alert-message__sign">{sign}</div>;
  }

  public componentDidMount() {
    if (this.props.focusOnOpen && this.selfRef.current) {
      this.selfRef.current.focus();
    }
  }

  private getButtons = (): JSX.Element[] => {
    return this.props.buttons.map((button, index) => {
      const testId = button["data-testid"];

      return (
        <div key={button.name + index} className="c-alert-message__button">
          <Button
            type={button.type}
            onClick={button.click}
            text={button.name}
            loading={button.loading}
            disabled={this.getButtonDisableState(button)}
            data-testid={
              testId ||
              `${dataTestIds.componentLibrary["Molecules.AlertMessage.button"]}-${button.name}`
            }
          />
        </div>
      );
    });
  };

  private getDescriptionSection = () => {
    return (
      this.props.texts.description && (
        <div
          id="alertDescription"
          className="c-alert-message__description"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(this.props.texts.description),
          }}
        />
      )
    );
  };

  private getAdditionalContentSection = () => {
    return (
      this.props.children && (
        <div className="c-alert-message__additional-content">
          {this.props.children}
        </div>
      )
    );
  };
}
