import React, { FC, useState } from "react";
import DirectDebitGuaranteeModal from "./DirectDebitGuaranteeModal";
import { useBillingTranslations } from "../../hooks/useBillingTranslations";
import { BillingUser } from "translations/src/models/billing";

interface Props {}

const DirectDebitGuaranteeButton: FC<Props> = (props) => {
  const {
    texts: { directDebit },
  } = useBillingTranslations(BillingUser.Common);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <a role="button" onClick={() => setModalIsOpen(true)}>
        {directDebit.guarantee}
      </a>
      {modalIsOpen && (
        <DirectDebitGuaranteeModal onClose={() => setModalIsOpen(false)} />
      )}
    </>
  );
};

export default DirectDebitGuaranteeButton;
