import React from "react";

import { LinkTarget } from "../../../../generics/types";

import "./QuickViewHeader.scss";

export interface IQuickViewHeaderProps {
  /** Placeholder for performer full name */
  title: string;
  /** URL for linking performer name */
  link: string;
  /** target type for person link. by default '_blank' */
  linkTarget?: LinkTarget;
}

export const QuickViewHeader: React.FC<IQuickViewHeaderProps> = ({
  title,
  link,
  linkTarget,
}) => {
  return (
    <div className="c-quick-view-header g-inner-grid-12">
      <div className="g-start-lg-4 g-start-md-4 g-col-lg-6 g-col-md-6 g-col-sm-11 text-center-sm">
        <a
          href={link}
          target={linkTarget || "_blank"}
          className="c-quick-view-header__link"
        >
          <span className="c-quick-view-header__title">{title}</span>
          <i className="c-quick-view-header__icon-font icon-open-in-new-tab" />
        </a>
      </div>
    </div>
  );
};
