import authorizationService, {
  UserAttributes,
} from "../services/authorization";
import { UserTypes } from "shared-types";

export function checkIsFilmmakersProfile() {
  return (
    Number(
      authorizationService.getUserAttribute(UserAttributes.PublicationCode)
    ) === UserTypes.filmmakersProfile
  );
}
