import { GetRox, GetRoxContentSuccess } from 'store/rox/rox.types';
import { ActionType } from 'store/types';

import { AsyncAction } from 'types/utilities';

import RoxService from 'services/api/rox/roxService';

// ============================== Redux Actions =======================================================================

const getRoxContentSuccess: GetRoxContentSuccess = (data) => ({
  type: ActionType.GetRoxSuccess,
  payload: data,
});

export const getRox: AsyncAction<GetRox> = (options = {}) => async (dispatch) => {
  try {
    const RoxProvider = await RoxService.getRoxSettingsContainer();

    dispatch(getRoxContentSuccess(RoxProvider));
  } catch (e) {
    if (options.throwOnError) {
      throw e;
    }
  }
};
