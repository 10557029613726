import useWindowSize from "./useWindowSize";
import {
  doesDeviceWidthMatchType,
  ScreenWidthEnumValueType,
} from "../../../component-library";

/**
 *
 * @param screenWidthEnum screen size 'sm' | 'md' | 'lg' to check against
 * @returns boolean if current screen size matches desired
 *
 * fully dynamic
 */

const useDynamicWindowWidthFor = (
  screenWidthEnum: ScreenWidthEnumValueType
) => {
  const currentWindowSize = useWindowSize();
  const isDesiredScreenWidth = doesDeviceWidthMatchType({
    screenWidthEnum,
    currentWidth: currentWindowSize.width,
  });

  return isDesiredScreenWidth;
};

export default useDynamicWindowWidthFor;
