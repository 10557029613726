import React, { FC } from "react";
import { ReactComponent as VisaIcon } from "component-library/src/images/svg/icons/visa.svg";
import { ReactComponent as MasterCardIcon } from "component-library/src/images/svg/icons/mastercard.svg";
import { ReactComponent as DirectDebit } from "component-library/src/images/svg/icons/direct-debit.svg";
import { ReactComponent as Sepa } from "component-library/src/images/svg/icons/sepa.svg";
import { PlanType } from "../../../constants";

interface Props {
  isMobileView?: boolean;
  planType: PlanType;
}

const PaymentSystemIcons: FC<Props> = ({ isMobileView, planType }) => {
  const icons = {
    [PlanType.DirectDebit]: (
      <DirectDebit
        height={isMobileView ? 14 : 21}
        width={isMobileView ? 47 : 69}
      />
    ),
    [PlanType.Sepa]: <Sepa />,
    [PlanType.Card]: (
      <>
        <VisaIcon
          height={isMobileView ? 14 : 21}
          width={isMobileView ? 47 : 69}
        />
        <MasterCardIcon
          height={isMobileView ? 28 : 42}
          width={isMobileView ? 39 : 58}
        />
      </>
    ),
  };

  return <div className="subscription__cards">{icons[planType]}</div>;
};

export default PaymentSystemIcons;
