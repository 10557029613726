import React from "react";
import cx from "classnames";
import { dataTestIds } from "data-testids";
import { ERROR, WARNING, NOTIFICATION } from "./InputWrapper";
// import WarningIcon from "-!svg-react-loader?name=WarningIcon!src/images/svg/icons/warning-icon.svg";
import { ReactComponent as WarningIcon } from "../../../images/svg/icons/warning-icon.svg";
import "./ValidationMessage.scss";

export interface IValidationMessageProps {
  /** The text for message */
  message?: string;
  /** Message type */
  type?: typeof WARNING | typeof ERROR | typeof NOTIFICATION;
  className?: string;
}

export const ValidationMessage: React.FC<IValidationMessageProps> = ({
  message,
  type,
  className,
}) => {
  const isWarningIconVisible = type && type !== NOTIFICATION;

  return (
    <div
      data-testid={
        dataTestIds.componentLibrary["Atoms.InputsCommon.validationMessage"]
      }
      className={cx(
        "c-input-wrapper__validation",
        {
          [`c-input-wrapper__validation--${type}`]: type,
        },
        className
      )}
    >
      {isWarningIconVisible && <WarningIcon width={20} height={20} />}
      <div
        className={`c-input-wrapper__validation-message ${
          isWarningIconVisible && "c-input-wrapper__validation-message--margin"
        }`}
      >
        {message}
      </div>
    </div>
  );
};
