/**
 * Pass an object which returns a query string.
 * Example result: ?key1=val1&key2=val2
 */
export const constructQueryString = (keyPairValues?: Object) => {
  if (!keyPairValues) return "";

  const entries = Object.entries(keyPairValues).map(
    ([key, value]) => `${key}=${value}`
  );
  return `?${entries.join("&")}`;
};

/**
 * Returns query string without the leading '?'
 * Example result: key1=val1&key2=val2
 */
export const constructQueryStringValues = (keyPairs?: Object) => {
  const queryString = constructQueryString(keyPairs);
  return queryString.replace("?", "");
};
