import { FacetType } from "shared-services";
import { dataTestIds } from "data-testids";
import {
  FiltersFieldNames,
  IFilterFieldConfig,
  IFilterValues,
} from "../../types";
import { getCheckboxGroupFilterConfigWithFacets } from "../GenericFields/CheckboxTypeFilters/getCheckboxGroupFilterConfigWithFacets";

export const genderFilterConfig: IFilterFieldConfig<IFilterValues> = {
  name: FiltersFieldNames.Gender,
  facetName: FacetType.Gender,
  defaultValue: {},
  dataTestId: dataTestIds.agent["SeekingRepresentation.Filters.genderFilter"],
  getFilterFiledProps: getCheckboxGroupFilterConfigWithFacets,
};
