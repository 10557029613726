import React from "react";

import GlobalSpinner from "components/GlobalSpinner/GlobalSpinner";

export interface IWithGlobalSpinnerProps {
  isLoading?: boolean;
}

export default function withGlobalSpinner(
  WrappedComponent: React.ComponentType<IWithGlobalSpinnerProps>
): typeof React.Component {
  return class extends React.Component<IWithGlobalSpinnerProps> {
    public renderSpinner(): React.ReactNode | null {
      if (!this.props.isLoading) {
        return null;
      }

      return <GlobalSpinner />;
    }

    public render(): React.ReactNode {
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
          {this.renderSpinner()}
        </React.Fragment>
      );
    }
  };
}
