import cx from "classnames";
import React from "react";

import "./SectionTableRow.scss";

export interface ISectionTableRowProps {
  title: string;
  content: string | JSX.Element;
  className?: string;
}

const SectionTableRow: React.FC<ISectionTableRowProps> = (props) => (
  <li className={cx("c-section-table-row g-inner-grid", props.className)}>
    <div className="c-section-table-row__column">{props.title}</div>
    <div className="c-section-table-row__column">{props.content}</div>
  </li>
);

export default SectionTableRow;
