export const agentsQuickLinks = [
  {
    text: "common:nav.links.jobsFeed",
    href: "/jobs/matching-my-filters",
  },
  {
    text: "common:nav.links.clientList",
    href: "/clients",
  },
  {
    text: "common:nav.links.searchSuggestions",
    href: "/searchsuggestions",
  },
  {
    text: "common:nav.links.industryForum",
    href: "https://www.spotlight.com/the-small-print/industry-forum-2022-highlights/",
  },
  {
    text: "common:nav.links.industryResources",
    href: "https://www.spotlight.com/help-and-faqs/links-for-professionals-on-spotlight",
  },
  {
    text: "common:nav.links.clientOnboarding",
    href: "https://www.spotlight.com/help-and-faqs/a-guide-to-spotlight-for-agents-onboarding-new-clients/",
  },
  {
    text: "common:nav.links.seekingRepresentation",
    href: "/clients/seeking-representation",
  },
  {
    text: "common:nav.links.performanceCalendar",
    href: "https://calendar.spotlight.com/",
  },
];
