import React from "react";

export enum InputSizes {
  Medium = "medium",
  Large = "large",
}

export interface IBaseInputProps<InputType, ValueType> {
  name?: string;
  id?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  valueChanged?: (value: ValueType) => void;
  onChange?: (event: React.ChangeEvent<InputType>) => void;
  onFocus?: (event: React.FormEvent<InputType>) => void;
  onBlur?: (event: React.FormEvent<InputType>) => void;
  onKeyDown?: (event: React.KeyboardEvent<InputType>) => void;
  onClick?: (event: React.MouseEvent<InputType>) => void;
  value?: ValueType;
  label?: string;
  invalid?: boolean;
  warned?: boolean;
  notifying?: boolean;
  validationMessage?: string;
  size?: InputSizes;
  "data-testid"?: string;
  /**
   * Tell the input whether an external "submit" was fired (or whatever else)
   * so that it can show validation even if it wasn't blurred
   */
  isFormSubmitted?: boolean;
  /** Optional css class for the topmost div */
  className?: string;
  /** Optional: controls native autocomplete input attribute */
  autoComplete?: "on" | "off";
  /** Optional: controls native autofocus input attribute */
  autoFocus?: boolean;
  /** Optional component to display on the top-right */
  annex?: JSX.Element;
}
