import { ICompletePageTranslations } from "../../models/joining-pages/CompletePage";

export const CompletePageFallbacks: ICompletePageTranslations = {
  completePage: {
    applicationComplete: "Application Complete!",
    subTitle: "We will contact you once your application has been approved.",
    description:
      "Application submitted<br />Vetted timeline<br />Reconfirm policy re name and photo<br />Payment timelines (no cooling off – TBC)",
    spotlightButton: "Go to Spotlight.co.uk",
    completeErrorTitle: "Something went wrong",
    completeErrorMessage: `<p>Sorry, there seems to be a problem.</p> <p>Please refresh the page.</p> <p>If the problem persists, please <a href="#">contact us</a> with your application ID: {appId}</p>`,
    completeErrorRefreshButton: "Refresh page",
    applicationIsAlreadyComplete: `<p>{forenames} {surname}'s application is already complete and was received on {date}.</p> <p>We will contact you once your application has been approved.</p>`,
  },
  completePageCD: {
    completePageTitleCD: "Application Complete!",
    completePageTextCD:
      'Thank you, your application has now been submitted and will be reviewed by our Spotlight Professionals team. Once approved, your account will be activated within 24 hours (during working week/hours). If you have any questions in the meantime, please contact <a href="mailto:casting@spotlight.com">casting@spotlight.com</a>',
    completePageSubmitCD: "Go to Spotlight.co.uk",
  },
};
