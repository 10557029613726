import React from "react";

import { PageContent } from "./PageContent";

interface IPageTitleProps {
  titleText: string;
  maxWidth?: number;
}

export const PageTitle: React.FC<IPageTitleProps> = ({
  titleText,
  maxWidth,
}) => (
  <PageContent maxWidth={maxWidth}>
    <h1 className="py-4 text-2xl">{titleText}</h1>
  </PageContent>
);
