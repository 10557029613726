import { useEffect, useState } from "react";

const useMobileKeyboardVisibility = (
  shouldDetect: boolean = true,
  minKeyboardHeight: number = 300
): boolean => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const visualViewPortIsDefined =
      typeof window.visualViewport !== "undefined";

    const eventListener = () => {
      const newState =
        window.screen.height - minKeyboardHeight > window.visualViewport.height;

      if (isOpen != newState) {
        setIsOpen(newState);
      }
    };

    if (visualViewPortIsDefined && shouldDetect) {
      window.visualViewport.addEventListener("resize", eventListener);
    }

    return () => {
      if (visualViewPortIsDefined && shouldDetect) {
        window.visualViewport.removeEventListener("resize", eventListener);
      }
    };
  }, [shouldDetect, minKeyboardHeight, isOpen, setIsOpen]);

  return isOpen;
};

export default useMobileKeyboardVisibility;
