// @ts-nocheck
import React from "react";
import ResizeObserver from "react-resize-observer";
import cx from "classnames";
import { debounce } from "../../../generics/debounce";
import { MIRROR_PROPS } from "./constants";
import "./TextEllipsis.scss";

export interface ITextEllipsisProps {
  /**
   * characters or element for use instead default ellipsis
   */
  ellipsis: string | React.ReactNode;
  /**
   * number of line from what the test will be cut off
   */
  maxLine: number;
  /**
   * text that should be cut
   */
  text: string;
  /**
   * Split by letters or words. By default it uses a guess based on your text.
   */
  basedOn?: "letters" | "words";
}

export interface ITextEllipsisState {
  prevText: string;
  text: string;
  isTextEllipsis: boolean;
}

export class TextEllipsis extends React.Component<
  ITextEllipsisProps,
  ITextEllipsisState
> {
  private isComponentMounted = false;
  private readonly ref: React.RefObject<HTMLDivElement>;
  private units: string[];
  private maxLine: number;
  private hiddenCalcArea: HTMLDivElement;
  private elementHeight: number = 0;
  private elementWidth: number = 0;

  public static defaultProps = {
    ellipsis: "…", // &hellip;
    maxLine: 1,
    text: "",
    basedOn: "letters",
  };

  constructor(props: ITextEllipsisProps) {
    super(props);

    this.state = {
      prevText: "",
      text: props.text,
      isTextEllipsis: false,
    };

    this.units = [];
    this.maxLine = 0;
    this.ref = React.createRef<HTMLDivElement>();
    this.onResize = debounce(this.onResize.bind(this), 150);
  }

  public render() {
    const { ellipsis } = this.props;
    const LAST_WHITE_SPACE = /[\s\uFEFF\xA0]+$/;

    return (
      <div
        className={cx("c-text-ellipsis", {
          "c-text-ellipsis--clamped": this.state.isTextEllipsis,
        })}
        ref={this.ref}
      >
        <ResizeObserver onResize={this.onResize} />
        {this.state.isTextEllipsis ? (
          <React.Fragment>
            {this.state.text.replace(LAST_WHITE_SPACE, "")}
            <span className="c-text-ellipsis__ellipsis">{ellipsis}</span>
          </React.Fragment>
        ) : (
          this.state.text
        )}
      </div>
    );
  }

  public componentDidMount() {
    this.isComponentMounted = true;
    this.initEllipsis();
    setInterval(this.resizeListener, 100);
  }

  public componentDidUpdate(prevProps) {
    const { text, basedOn, ellipsis, maxLine } = this.props;
    if (text !== prevProps.text || basedOn !== prevProps.basedOn) {
      this.splitTextByBases(text, basedOn);
      this.fillCalcArea(this.units);
      this.reflow(this.props);
    } else if (
      ellipsis !== prevProps.ellipsis ||
      maxLine !== prevProps.maxLine
    ) {
      this.reflow(this.props);
    }
  }

  public componentWillUnmount() {
    this.isComponentMounted = false;
    const parent = this.hiddenCalcArea.parentNode as HTMLElement;
    parent.removeChild(this.hiddenCalcArea);
  }

  private resizeListener = () => {
    if (
      this.ref &&
      this.ref.current &&
      (this.ref.current.offsetHeight !== this.elementHeight ||
        this.ref.current.offsetWidth !== this.elementWidth)
    ) {
      this.initEllipsis();
      this.elementHeight = this.ref.current.offsetHeight;
      this.elementWidth = this.ref.current.offsetWidth;
    }
  };

  private initEllipsis = () => {
    this.initHiddenCalcArea();
    this.splitTextByBases(this.props.text, this.props.basedOn);
    this.fillCalcArea(this.units);
    this.reflow(this.props);
  };

  private onResize() {
    this.copyStylesFromElementToCalcArea();
    this.fillCalcArea(this.units);
    this.reflow(this.props);
  }

  private initHiddenCalcArea() {
    if (this.hiddenCalcArea) {
      return;
    }

    const hiddenCalcArea = (this.hiddenCalcArea =
      document.createElement("div"));
    hiddenCalcArea.className = "c-text-ellipsis__hidden-calc-area";
    hiddenCalcArea.setAttribute("aria-hidden", "true");
    this.copyStylesFromElementToCalcArea();
    document.body.appendChild(hiddenCalcArea);
  }

  private splitTextByBases(text, basedOn) {
    this.units = text.split(basedOn === "letters" ? "" : /\b|(?=\W)/);
  }

  private copyStylesFromElementToCalcArea() {
    const targetStyle = this.ref.current
      ? window.getComputedStyle(this.ref.current as Element)
      : {};
    MIRROR_PROPS.forEach((key) => {
      this.hiddenCalcArea.style[key] = targetStyle[key];
    });
  }

  private fillCalcArea(units: string[], end = "") {
    this.hiddenCalcArea.innerHTML =
      units
        .map((c) => {
          return `<span class='c-text-ellipsis__unit'>${c}</span>`;
        })
        .join("") + end;
  }

  private reflow(props) {
    this.maxLine = props.maxLine;
    const ellipsisIndex = this.putEllipsis(this.calcIndexes());
    const isTextEllipsis = ellipsisIndex > -1;
    const newState = {
      isTextEllipsis,
      text: isTextEllipsis
        ? this.units.slice(0, ellipsisIndex).join("")
        : props.text,
    };

    if (this.isComponentMounted) {
      this.setState(newState);
    }
  }

  private calcIndexes() {
    const indexes = [0];

    let element = this.hiddenCalcArea.firstElementChild as HTMLElement;
    let line = 1;
    let index = 0;
    let offsetTop = 0;

    while (element) {
      if (element.offsetTop > offsetTop) {
        line++;
        indexes.push(index);
        offsetTop = element.offsetTop;
      }
      index++;
      if (line > this.maxLine) {
        break;
      }
      element = element.nextElementSibling as HTMLElement;
    }
    return indexes;
  }

  private putEllipsis(indexes) {
    if (indexes.length <= this.maxLine) {
      return -1;
    }

    const lastIndex = indexes[this.maxLine];
    const units = this.units.slice(0, lastIndex);
    const ellipsisAsNode =
      React.isValidElement(this.props.ellipsis) &&
      (this.props.ellipsis as React.ReactElement);

    this.fillCalcArea(
      units,
      ` <span class='c-text-ellipsis__ellipsis'>${
        ellipsisAsNode ? ellipsisAsNode.props.children : this.props.ellipsis
      }</span>`
    );

    const nodeEllipsis = this.hiddenCalcArea.lastElementChild as Element;
    let nodePrevUnit = nodeEllipsis.previousElementSibling;

    while (nodePrevUnit && this.ifOffset(nodePrevUnit, nodeEllipsis)) {
      this.hiddenCalcArea.removeChild(nodePrevUnit);
      nodePrevUnit = nodeEllipsis.previousElementSibling;
      units.pop();
    }
    return units.length;
  }

  private ifOffset(prevUnit, ellipsis) {
    return (
      ellipsis.offsetHeight > prevUnit.offsetHeight ||
      ellipsis.offsetTop > prevUnit.offsetTop
    );
  }
}
