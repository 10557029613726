import React, { FC } from "react";

import { Footer, getFooterLinks, Spinner } from "component-library";
import { SCROLLING_CONTAINER_ID } from "config/common";

import HeaderContainer from "app-shell/HeaderContainer/HeaderContainer";

import PageKey from "config/PageKey";
import { useTranslation } from "app/hooks/useTranslation";
import { IAgentQuickSearchTranslation } from "models/translation";
import "./Layout.scss";

interface ILayoutProps {
  children: React.ReactNode;
  contentContainerCustomClassName?: string;
  hideFooter?: boolean;
}

const Layout: FC<ILayoutProps> = ({
  children,
  hideFooter = false,
  contentContainerCustomClassName,
}) => {
  const menuContainerRef = React.useRef<HTMLDivElement>(null);
  const popupContainerRef = React.useRef<HTMLDivElement>(null);
  const footer = getFooterLinks();

  const { translation, loadingTranslation } =
    useTranslation<IAgentQuickSearchTranslation>({
      pageKey: PageKey.AgentQuickSearch,
    });

  if (loadingTranslation) return <Spinner />;

  return (
    <div className="a-layout">
      <div className="a-layout__header">
        <HeaderContainer
          menuContainer={menuContainerRef}
          popupContainer={popupContainerRef}
          agentQuickSearchPlaceholder={
            translation?.page.agentQuickSearchPlaceholderText || ""
          }
        />
      </div>
      <div className="a-layout__body">
        <div ref={menuContainerRef} className="a-layout__menu-container" />
        <div
          ref={popupContainerRef}
          className="a-layout__localization-popup-container"
        />
        <div
          className="a-layout__content-container"
          id={SCROLLING_CONTAINER_ID}
        >
          <div
            className={`a-content-container ${contentContainerCustomClassName}`}
          >
            {children}
          </div>
          {!hideFooter && (
            <div className="a-layout__footer-container">
              {!hideFooter && (
                <Footer
                  navigationLinks={footer.navigationLinks}
                  texts={footer.texts}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
