import React from "react";
import cx from "classnames";
import { IAudioModel } from "shared-types";
import {
  formatTime,
  transformMillisecToSec,
} from "../../../../../generics/time-formatting";

import "./AudioTrack.scss";

export interface IAudioTrackProps {
  isActive: boolean;
  track: IAudioModel;
  selectTrack: (track: IAudioModel) => void;
}

export const AudioTrack: React.FC<IAudioTrackProps> = ({
  track,
  isActive,
  selectTrack,
}) => {
  const trackRowClasses = cx({
    "c-audio-track": true,
    "c-audio-track--active": isActive,
  });

  const selectTrackWrapper = () => selectTrack(track);

  return (
    <li className={trackRowClasses} onClick={selectTrackWrapper}>
      <i className="c-audio-track__icon icon-controlsPlay" />
      <div className="c-audio-track__name">{track.fileName}</div>
      <div className="c-audio-track__duration">
        {formatTime(transformMillisecToSec(track.duration), ".")}
      </div>
    </li>
  );
};
