import { IAboutYouPageTranslations } from "../../models/joining-pages/AboutYouPage";

export const AboutYouPageTranslations: IAboutYouPageTranslations = {
  mainPage: {
    pageTitle: "About you",
    pageDescription:
      "The full application should take roughly XX minutes but you can save and return as many times as you like within a six week period of starting your application.",
    pageDescriptionYP:
      "The full application should take roughly XX minutes but you can save and return as many times as you like within a six week period of starting your application.",
    uploadHeadshotLabel: "Upload headshot",
    uploadHeadshotError: "Uploading a headshot is required.",
    headshotDescription:
      "To join Spotlight you will need to upload a photo of yourself. It should be cropped to show your head and shoulders.",
    phoneNumberMainLabel: "Please provide at least one phone number:",
    mobileLabel: "Mobile number:",
    landlineLabel: "Landline number:",
    nameLabel: "Name:",
    performerTypeLabel: "Performer Type:",
    emailLabel: "Email address:",
    currentSpotlightMemberLabel:
      "Are you currently, or have you ever been a Spotlight member?",
    dateOfBirthLabel: "Date of birth:",
    publishAgeLabel: "Publish your actual age on your profile?",
    playingAgeFromLabel: "Playing age from:",
    playingAgeToLabel: "Playing age to:",
    genderMainLabel: "Gender - How do you want to be found on Spotlight?",
    findMeAsLabel: "Find me as:",
    findMeAsInSearchResultsLabel:
      "Which search results would you like to appear in?",
    furtherInformationLabel: "Further information (optional):",
    genderToBeDisplayedLabel:
      "Would you like this information to be displayed on your public profile?",
    genderToBeDisplayedDescriptionYes: `<p>By selecting 'Yes', this information will be visible on your profile at all times. Everyone who has a link to your profile will be able to see this information, it will be public. To restrict visibility choose 'No'.</p>`,
    genderToBeDisplayedDescriptionNo: `<p>By selecting 'No', this information will only be visible if a casting professional filters their search results by gender or specific term. For example: if you put 'gender fluid' in the further information box and a casting professional searches for this term, your profile will be shown, including all the details you have entered.</p> <p>A casting professional who has filtered for a specific gender or gender information will see this information on your profile.</p>`,
    identifyAsDeafDisabledTitle: "Do you identify as Deaf or disabled?",
    identifyAsDeafDisabledLabel: "Yes, I identify as Deaf or disabled",
    deafOrDisabledDescription: "Words from the membership about this option",
    membershipsLabel: "Memberships (optional):",
    agentLabel: "Agent (optional):",
    playingAgeYearsLabel: "years",
    agentNotListedText: `If your agent isn't listed in this drop down, ask the agent to get in touch with us at agents@spotlight.com and continue without selecting an agent.`,
    transgenderMaleDesc:
      "By selecting transgender - male performers, you will appear in searches for male performers as well as specified searches for Transgender - male.",
    transgenderFemaleDesc:
      "By selecting transgender - female performers, you will appear in searches for female performers as well as specified searches for Transgender - female.",
    billingAddressDetail: "Please provide you current address details:",
  },
  uploadModal: {
    uploadTitle: "Upload image",
    uploadedImageAltText: "Uploaded image",
    photographerNameLabel: "Photographer name",
    photoDescriptionLabel: "Description",
    choosePhotoLabel: "Choose photo",
    cancelPhotoLabel: "Back",
    uploadImageStartTypingText: "Start by typing a name",
  },
  agencyModal: {
    addAgencyTitle: "Add agency information",
    addAgencyDescription:
      "As your agent isn’t a member of Spotlight we need their contact details to invite them to join. Your agency won’t be visible on your profile until they have been approved and registered with Spotlight. Until then, you will only be contactable via Spotlight.",
    agencyNameLabel: "Agency name:",
    agencyEmailLabel: "Email address:",
    agencyPhoneLabel: "Phone number:",
    agencyWebsiteLabel: "Website (optional):",
    agencyStreetLabel: "Street address (optional):",
    agencyPostcodeLabel: "Post code (optional):",
    saveAgencyButton: "Save agency",
    cancelAgencyButton: "Cancel",
  },
  genderOptions: {
    nonBinaryPerformersOnly: "Non-binary performers only",
    nonBinaryMaleAndFemale: "Non-binary, male and female performers",
    nonBinaryFemaleOnly: "Non-binary and female performers",
    nonBinaryMaleOnly: "Non-binary and male performers",
    nonBinaryPerformersOnlyDesc:
      "Please note: Choosing non-binary performers only may affect casting professionals' ability to find you. By selecting non binary performers only, you won't appear in female or male performer searches. You will appear in search results that don't include a gender filter and search results that have been filtered to show only non-binary performers.",
    nonBinaryMaleAndFemaleDesc:
      "By selecting female and male performers, you will appear in all search results that don't include a gender filter, as well as search results that have been filtered to show female performers, male performers or non-binary performers.",
    nonBinaryFemaleOnlyDesc:
      "By selecting female performers, you will appear in all search results that don't include a gender filter, as well as search results that have been filtered to show female performers or non-binary performers.",
    nonBinaryMaleOnlyDesc:
      "By selecting male performers, you will appear in all search results that don't include a gender filter, as well as search results that have been filtered to show male performers or non-binary performers.",
    customPerformersOnly: "Custom performers only",
    customMaleAndFemale: "Custom, male and female performers",
    customFemaleOnly: "Custom and female performers",
    customMaleOnly: "Custom and male performers",
    customPerformersOnlyDesc:
      "Please note: Choosing Custom performers only may affect casting professionals' ability to find you. By selecting Custom performers only, you won't appear in female or male performer searches. You will appear in search results that don't include a gender filter and search results that have been filtered to show only custom performers.",
    customMaleAndFemaleDesc:
      "Choosing Custom performers with female and male will increase the chances of casting professionals being able to find you. You will appear in search results where no gender filter has been applied, or specifically where either a female, male or custom gender filter has been applied.",
    customFemaleOnlyDesc:
      "By selecting Custom and female performers you will appear where no gender filter has been applied, or specifically where either a female or custom gender filter has been applied.",
    customMaleOnlyDesc:
      "By selecting Custom and male performers you will appear where no gender filter has been applied, or specifically where either a male or custom gender filter has been applied.",
  },
  aboutYouFormTooltips: {
    areYouAMemberTooltipHeader: "Are you a member Header",
    areYouAMemberTooltipBody: "Are you a member Body",
    photographerNameTooltipHeader: "Photographer name Tooltip Header",
    photographerNameTooltipBody: "Photographer name Tooltip Body",
    playingAgeFromYPTooltipHeader: "Playing age To Header",
    playingAgeFromYPTooltipBody: "Playing age To Body",
    playingAgeToYPTooltipHeader: "Playing age To Header",
    playingAgeToYPTooltipBody: "Playing age To Body",
  },
  aboutYouCDPage: {
    pageTitleCD: "About you",
    continueToReviewCD: "Continue to Review",
    experienceCDLabel:
      "If you are applying as a casting director, please state how many years you have been casting for. (Optional)",
    professionalLinkOrCVCDSectionHeader:
      "Please provide professional links to your casting credits and/or upload CV.",
    linkURLCDLabel: "Link url:",
    linkURLCDPlaceholder: "www.website.com",
    cvCDSectionTitle: "Upload CV",
    cvCDLabel:
      "Please upload your CV. We will only use your CV for the purpose of vetting. Once vetting is completed, we will delete the file. Accepted formats: PDF, PNG, DOC.",
    cvCDPlaceholder: "Click to select file or drag here",
    ageCDLabel:
      "Will you potentially be casting performers under the age of 18?",
    confirmAgeCDLabel:
      "Please confirm you are aware of any relevant regulations regarding the employment of children including any licensing requirements, chaperone fees and industry standard working conditions.",
    reasonToUseCDLabel:
      "Which of the following would you like to do/What would you like to use Spotlight for?",
    aboutYouCDModalHeader: "About you",
    aboutYouCDModalLabel:
      "Please provide details about why you would like to join Spotlight.",
    aboutYouCDModalSubmit: "Save and continue",
  },
  confirmAgeCDOptions: {
    confirmAgeCDYes: "Yes, I'm aware",
    confirmAgeCDNo: "I'm not sure",
  },
  aboutYouCDFormTooltips: {
    experienceCDTooltipHeader: "Experience Header",
    experienceCDTooltipBody: "Experience Body",
    linkURLCDTooltipHeader: "Link url Header",
    linkURLCDTooltipBody: "Link url Body",
    cvCDTooltipHeader: "Upload CV Header",
    cvCDTooltipBody: "Upload CV Body",
    aboutYouCDTooltipHeader: "About you CD Header",
    aboutYouCDTooltipBody: "About you CD Body",
  },
};
