import React, { FC } from "react";
import { ReactComponent as ShowMoreIcon } from "component-library/src/images/svg/icons/showmore.svg";
import { ReactComponent as ShowLessIcon } from "component-library/src/images/svg/icons/showless.svg";
import "./styles.scss";

/**
 * pass isShowMoreBtnState: boolean to activate Show More/Show Less functionality
 * default is Show More button only
 */

type ShowMoreLessButtonProps = {
  handleButtonClick: () => void;
  isShowMoreBtnState?: boolean;
  showMoreBtnText: string;
  showLessBtnText?: string;
};

const ShowMoreLessButton: FC<ShowMoreLessButtonProps> = ({
  handleButtonClick,
  isShowMoreBtnState = true,
  showMoreBtnText = "Show More",
  showLessBtnText = "Show Less",
}) => {
  return (
    <div
      className="flex items-center gap-2"
      role="button"
      onClick={(evt) => {
        evt.stopPropagation();
        handleButtonClick();
      }}
    >
      {isShowMoreBtnState ? (
        <ShowMoreIcon
          width="20"
          height="20"
          className="show-more-less-button"
        />
      ) : (
        <ShowLessIcon
          width="20"
          height="20"
          className="show-more-less-button"
        />
      )}
      <span className="text-sm font-semibold text-color-brand-two">
        {isShowMoreBtnState ? showMoreBtnText : showLessBtnText}
      </span>
    </div>
  );
};

export default ShowMoreLessButton;
