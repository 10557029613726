import React from "react";
import cx from "classnames";

import { formatTime } from "../../../../../generics/time-formatting";
import LoadingIndicator from "../../../../Atoms/LoadingIndicator/LoadingIndicator";
import { MAX_VOLUME_LEVEL, MIN_VOLUME_LEVEL } from "../Video/Video";

import "./VideoControls.scss";

const MIN_TIME_VALUE = 0;
const VOLUME_RANGE_STEP = 0.01;
export const VOLUME_RUNNABLE_TRACK_WIDTH = 78;

export interface IVideoControlsProps {
  togglePlayback: () => Promise<void>;
  expandVideo: () => Promise<void>;
  requestPIP: () => void;
  changeTime: (value: number) => void;
  currentTime: number;
  duration: number;
  toggleMute: () => void;
  adjustVolume: (value: number) => void;
  setPreviousVolumeLevel: () => void;
  volumeLevel: number;
  switchToNextVideo: () => void;
  shareVideo: () => void;
  isPaused: boolean;
  isMuted: boolean;
  hasDownloadButton: boolean;
  downloadLink?: string;
  hasShareVideoButton: boolean;
  showreelMode: boolean;
  isLoading: boolean;
}

export const VideoControls: React.FC<IVideoControlsProps> = (props) => {
  const {
    togglePlayback,
    expandVideo,
    changeTime,
    requestPIP,
    currentTime,
    duration,
    toggleMute,
    adjustVolume,
    setPreviousVolumeLevel,
    volumeLevel,
    switchToNextVideo,
    shareVideo,
    isPaused,
    isMuted,
    hasDownloadButton,
    downloadLink,
    hasShareVideoButton,
    showreelMode,
    isLoading,
  } = props;

  const changeTimeWrapper = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => changeTime(Number(value));
  const changeVolumeLevel = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => adjustVolume(Number(value));

  const blurElement = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => event.currentTarget.blur();

  const isPIPAvailable = Boolean(document.pictureInPictureEnabled);

  const tapTimeLineWidth = `${(currentTime / duration) * 100}%`;
  const tapVolumeWidth = `${Math.floor(
    VOLUME_RUNNABLE_TRACK_WIDTH * volumeLevel
  )}px`;

  const isDownloadAvailable = hasDownloadButton && downloadLink;

  return (
    <div className="c-video-controls">
      <div className="c-video-controls__time-bar">
        <div
          style={{ width: tapTimeLineWidth }}
          className="c-video-controls__time-line-bg"
        />
        <input
          className="c-video-controls__time-line"
          type="range"
          min={MIN_TIME_VALUE}
          max={duration}
          value={currentTime}
          onChange={changeTimeWrapper}
          tabIndex={-1}
        />
      </div>

      <div className="c-video-controls__controls-group">
        <button
          onMouseUp={blurElement}
          onClick={togglePlayback}
          type="button"
          className={cx(
            "c-video-controls__control c-video-controls__control--paused",
            {
              "icon-controlsPlay": isPaused,
              "icon-controlsPause": !isPaused,
            }
          )}
          aria-label={isPaused ? "Play" : "Pause"}
        />
        {showreelMode && (
          <button
            onMouseUp={blurElement}
            onClick={switchToNextVideo}
            type="button"
            className="c-video-controls__control c-video-controls__control--next-video icon-controlsSkip"
            aria-label="Next video"
          />
        )}
        <span className="c-video-controls__time">
          {formatTime(currentTime, ".")}/{formatTime(duration, ".")}
        </span>
        {isLoading && (
          <span className="c-video-controls__loading">
            <LoadingIndicator variant="secondary" />
          </span>
        )}
      </div>

      <div className="c-video-controls__controls-group">
        {isDownloadAvailable && (
          <a
            href={downloadLink}
            onMouseUp={blurElement}
            download=""
            className="c-video-controls__control c-video-controls__control--download-video icon-download1"
            aria-label="Download"
          />
        )}
        <button
          onMouseUp={blurElement}
          onClick={expandVideo}
          type="button"
          className="c-video-controls__control c-video-controls__control--expanding-video icon-expandimg"
          aria-label="Expand video"
        />
        {isPIPAvailable && (
          <button
            onMouseUp={blurElement}
            onClick={requestPIP}
            type="button"
            className="c-video-controls__control c-video-controls__control--pip-video icon-controlsPIP"
            aria-label="Picture in picture"
          />
        )}
        {hasShareVideoButton && (
          <button
            onMouseUp={blurElement}
            onClick={shareVideo}
            type="button"
            className="c-video-controls__control c-video-controls__control--share-video icon-sharearrow"
            aria-label="Share video"
          />
        )}
        <div className="c-video-controls__volume-wrapper">
          <button
            onMouseUp={blurElement}
            onClick={toggleMute}
            type="button"
            className={cx(
              "c-video-controls__control c-video-controls__control--muted",
              {
                "icon-controlsMute": isMuted,
                "icon-controlsvolUp": !isMuted,
              }
            )}
            aria-label={isMuted ? "Toggle mute" : "Mute sound"}
          />
          <div className="c-video-controls__volume-track-bar">
            <div className="c-video-controls__volume-runnable-track" />
            <div
              className="c-video-controls__volume-level-value"
              style={{ width: tapVolumeWidth }}
            />
            <input
              className="c-video-controls__control c-video-controls__volume-range"
              type="range"
              min={MIN_VOLUME_LEVEL}
              max={MAX_VOLUME_LEVEL}
              step={VOLUME_RANGE_STEP}
              value={volumeLevel}
              onChange={changeVolumeLevel}
              onMouseDown={setPreviousVolumeLevel}
              aria-label="Volume control"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
