import { useEffect, useState } from "react";
import _uniqBy from "lodash/uniqBy";
import { billingService } from "../services";
import { DISABILITY_DISCOUNT_CODE_ACC_LEVEL } from "../constants";
import { IPrice, ICouponObject, IAddOns } from "../types";
import { useQuery } from "react-query";

const useCouponCodes = (
  planId: string | undefined,
  discounts: string[] = [],
  addOns: IAddOns[] = []
) => {
  const [userCouponCodes, setUserCouponCode] = useState<ICouponObject[]>([]);
  const [price, setPrice] = useState<IPrice>();
  const [couponErrors, setCouponErrors] = useState<string[]>([]);
  const [isCheckoutPreviewRequestLoading, setIsCheckoutPreviewRequestLoading] =
    useState(false);
  const hasAccountDiscount = !!discounts.length;

  const addOnsCodes = addOns.map(({ code }) => code);

  const { isLoading: queryLoading, refetch: refetchPrices } = useQuery(
    ["planPreview", planId, addOnsCodes],
    () => fetchPlanPreview(planId, addOnsCodes),
    {
      enabled: !!planId,
      onSuccess: (data) => {
        setPrice(data);
      },
    }
  );

  const fetchPlanPreview = async (
    planId: string | undefined,
    addOnsCodes: string[]
  ): Promise<IPrice> => {
    if (!planId) throw new Error("Plan ID is required");

    const result = await billingService.checkoutPreview({
      planId,
      couponCodes: userCouponCodes.map(({ couponCode }) => couponCode),
      addOns: addOnsCodes,
    });

    if (!result?.total || !result?.amount || !result?.tax) {
      throw new Error("Invalid response from checkout preview");
    }

    return result;
  };

  useEffect(() => {
    setCouponErrors([]);
    setPrice(undefined);

    refetchPrices();
  }, [planId]);

  useEffect(() => {
    const preDefinedCoupons: ICouponObject[] = discounts.map(
      (discount): ICouponObject => ({
        couponCode: discount,
        isFreeTrial: false,
        freeTrialDescription: "",
      })
    );
    setUserCouponCode(preDefinedCoupons);
  }, [discounts]);

  const clearErrors = () => setCouponErrors([]);

  const setCoupon = async (couponCode: string) => {
    clearErrors();
    setIsCheckoutPreviewRequestLoading(true);

    const normiliasedCoupon = {
      couponCode,
      isFreeTrial: false,
      freeTrialDescription: "",
    };

    // do not send Disability coupon code to recurly's preview.
    // Disability coupon code is only for account level discount.
    const uniqCouponCodes = _uniqBy(
      [...userCouponCodes, normiliasedCoupon],
      "couponCode"
    ).filter(
      (coupon) => coupon.couponCode !== DISABILITY_DISCOUNT_CODE_ACC_LEVEL
    );

    const result = await billingService.checkoutPreview({
      planId: planId || "",
      couponCodes: uniqCouponCodes.map(({ couponCode }) => couponCode),
      addOns: addOnsCodes,
    });

    setIsCheckoutPreviewRequestLoading(false);

    // to hanlde 400s
    if (result?.data?.RecurlyError) {
      setCouponErrors(
        result?.data?.RecurlyError.params.map(({ message }) => message)
      );
      return;
    }

    // to handle 500s
    if (result?.RecurlyError) {
      setCouponErrors(result.RecurlyError.params.map(({ message }) => message));
      return;
    }

    if (result?.total && result?.amount && result?.tax) {
      setPrice(result);
      const uniqCouponCodes = _uniqBy([...userCouponCodes], "couponCode");
      setUserCouponCode([
        ...uniqCouponCodes,
        {
          couponCode,
          isFreeTrial: result?.isFreeTrial || false,
          freeTrialDescription: result?.freeTrialDescription || "",
        },
      ]);
    }
  };

  const removeCoupon = async (couponCode: string) => {
    clearErrors();

    const couponCodes = userCouponCodes.filter(
      (coupon) => coupon.couponCode !== couponCode
    );

    setIsCheckoutPreviewRequestLoading(true);

    const result = await billingService.checkoutPreview({
      planId: planId || "",
      // do not send Disability coupon code to recurly's preview.
      // Disability coupon code is only for account level discount.
      couponCodes: couponCodes
        .filter(
          (coupon) => coupon.couponCode !== DISABILITY_DISCOUNT_CODE_ACC_LEVEL
        )
        .map(({ couponCode }) => couponCode),
      addOns: addOnsCodes,
    });

    setIsCheckoutPreviewRequestLoading(false);

    if (result.total && result.amount && result.tax) {
      setPrice(result);
      setUserCouponCode(couponCodes);
    }
  };

  return {
    userCouponCodesObject: userCouponCodes, // for OrderSummary.tsx to show coupon code etc
    userCouponCodesStrings: userCouponCodes.map(({ couponCode }) => couponCode), // for anwhere else to use coupon code as string
    price,
    hasAccountDiscount,
    setCoupon,
    removeCoupon,
    couponErrors,
    isCheckoutPreviewRequestLoading:
      isCheckoutPreviewRequestLoading || queryLoading,
    refetchPrices,
  };
};

export default useCouponCodes;
