import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import applicationMap from "services/applicationMap";
import { IApplicationState } from "store";
import { RoxFlag } from "config/rox";
import { MemberGroups } from "shared-types";
import { authorizationService } from "shared-auth";

const allowedMemberGroups = [
  MemberGroups.performer,
  MemberGroups.yPerformer,
  MemberGroups.graduatePerformer,
];

/**
 * Pages like my-subscription and my-subscription/change
 * Should respect NewAccSettings_AllowUpdtSubscription Flag
 * That hides Update Subscription Details button on Account Settings page
 */

export function useNewAccSettingsPageAccessCheck(
  shouldConsiderAllowUpdtSubscriptionFlag = false
): boolean {
  const isAllowedMember = allowedMemberGroups.includes(
    authorizationService.getUserGroup() as MemberGroups
  );
  const history = useHistory();
  const {
    isRoxLoaded,
    isNewAccSettingsFlagOn,
    isNewAccSettingsAllowUpdSubscriptionFlagOn,
  } = useSelector((state: IApplicationState) => {
    return {
      isNewAccSettingsFlagOn:
        state.rox.configuration?.[RoxFlag.NewAccSettings].isEnabled() ?? false,
      isNewAccSettingsAllowUpdSubscriptionFlagOn:
        state.rox.configuration?.[
          RoxFlag.NewAccSettings_AllowUpdtSubscription
        ].isEnabled() ?? false,
      isRoxLoaded: state.rox.isLoaded,
    };
  });

  const pageShouldNotBeLoaded =
    !isAllowedMember || (isRoxLoaded && !isNewAccSettingsFlagOn);

  const pageShouldNotBeLoadedConsideringAllowUpdtSubscriptionFlag =
    shouldConsiderAllowUpdtSubscriptionFlag
      ? isRoxLoaded && !isNewAccSettingsAllowUpdSubscriptionFlagOn
      : false;

  const queryShouldNotBeLoaded =
    pageShouldNotBeLoadedConsideringAllowUpdtSubscriptionFlag ||
    pageShouldNotBeLoaded;

  useEffect(() => {
    if (
      pageShouldNotBeLoaded ||
      pageShouldNotBeLoadedConsideringAllowUpdtSubscriptionFlag
    ) {
      history.replace(applicationMap.routes.index());
    }
  }, [isRoxLoaded, isAllowedMember]);

  // this is to prevent react-query from running before this hook is settled
  // could be removed once we get rid of FF
  return isRoxLoaded ? queryShouldNotBeLoaded : true;
}
