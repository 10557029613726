import * as React from "react";
import {
  Input,
  InputCleaner,
  Button,
  LoadingIndicator,
} from "component-library";

export interface ISearchProps {
  maxLength?: number;
  placeholder?: string;
  label?: string;
  onSearch?: (search: string) => void;
  onClear?: () => void;
  onTextChange?: (search: string) => void;
  searchString: string;
  loading?: boolean;
}

export class Search extends React.Component<ISearchProps> {
  public render() {
    const { maxLength, placeholder, label, searchString, onSearch, loading } =
      this.props;

    return (
      <div className="c-search">
        <Input
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          label={label}
          valueChanged={this.handleTextChange}
          onKeyDown={this.handleKeyDown}
          value={searchString}
          className="c-search__input"
        >
          <div className="c-search__children-wrapper">
            {loading ? (
              <div className="c-search__spinner">
                <LoadingIndicator variant="secondary" />
              </div>
            ) : (
              searchString && <InputCleaner onClick={this.handleCleanClick} />
            )}
          </div>
        </Input>
        {onSearch && (
          <div className="c-search__button-wrapper">
            <Button
              onClick={this.searchHandler}
              type="primary"
              text=""
              iconName="icon-magnify"
            />
          </div>
        )}
      </div>
    );
  }

  private handleTextChange = (value: string) =>
    this.props.onTextChange && this.props.onTextChange(value);

  private handleCleanClick = () => this.props.onClear && this.props.onClear();

  private searchHandler = () =>
    this.props.onSearch && this.props.onSearch(this.props.searchString);

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { onSearch } = this.props;
    if (event.key === "Enter") {
      event.preventDefault();
      onSearch && onSearch(this.props.searchString);
    }
  };
}
