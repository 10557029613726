import React, { FC, ReactElement } from "react";
import { Button } from "component-library";
import { dataTestIds } from "data-testids";
import LabelValueRow from "components/LabelValueRow/LabelValueRow";

type SettingSectionProps = {
  sectionHeader?: string;
  sectionDescription: string;
  subSectionHeader?: string;
  sectionData: {
    label: string;
    value?: (string | Date | JSX.Element) | JSX.Element[];
    isHidden?: boolean;
  }[];
  actionButtonLabel: string;
  actionButtonOnClick: () => void;
  actionButtonHidden?: boolean;
  actionButtonDisabled?: boolean;
  secondaryButtonLabel?: string;
  secondaryButtonHidden?: boolean;
  secondaryButtonOnClick?: () => void;
  sectionAdditionalContent?: ReactElement;
};

const SettingSection: FC<SettingSectionProps> = ({
  sectionHeader,
  sectionDescription,
  subSectionHeader,
  sectionData,
  actionButtonLabel,
  actionButtonOnClick,
  actionButtonHidden,
  actionButtonDisabled = false,
  secondaryButtonLabel,
  secondaryButtonHidden = true,
  secondaryButtonOnClick,
  sectionAdditionalContent,
}) => {
  return (
    <div className="py-4">
      <div className="mb-2">
        {sectionHeader && <h3 className="text-[26px]">{sectionHeader}</h3>}
        <p className="leading-6 md:leading-10">{sectionDescription}</p>
      </div>
      <hr className="mb-2" />
      <div className="flex flex-col gap-2">
        {subSectionHeader && <h3 className="text-xxl">{subSectionHeader}</h3>}
        {sectionData.map((item) => {
          if (item.isHidden) return null;

          return (
            <LabelValueRow
              key={item.label}
              label={item.label}
              value={item.value}
            />
          );
        })}
      </div>
      {sectionAdditionalContent && (
        <>
          <hr className="mt-2.5 mb-5" />
          {sectionAdditionalContent}
          {(!secondaryButtonHidden || !actionButtonHidden) && (
            <hr className="mt-2.5 mb-5" />
          )}
        </>
      )}
      <div className="flex flex-col-reverse justify-end gap-4 mt-6 md:flex-row">
        {!secondaryButtonHidden && (
          <div className="flex justify-end">
            <Button
              type="secondary"
              text={secondaryButtonLabel}
              className="md:w-[315px]"
              onClick={secondaryButtonOnClick}
              data-testid={
                dataTestIds.userAccountApp[
                  "AccountSettings.SettingSection.secondaryButton"
                ]
              }
            />
          </div>
        )}
        {!actionButtonHidden && (
          <div className="flex justify-end">
            <Button
              type="primary"
              text={actionButtonLabel}
              className="md:w-[315px] mt-6 md:mt-0"
              onClick={actionButtonOnClick}
              disabled={actionButtonDisabled}
              data-testid={
                dataTestIds.userAccountApp[
                  "AccountSettings.SettingSection.primaryButton"
                ]
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingSection;
