import React from "react";
import cx from "classnames";
import { IVideoModel } from "shared-types";
import { VideoShowreel } from "./components/VideoShowreel/VideoShowreel";
import { Video } from "./components/Video/Video";

import "./VideoPlayer.scss";

export interface IShareVideoPopupTexts {
  title: string;
  mainDescription: string;
  additionalDescriptions: string;
  proceedButton: string;
  closeButton: string;
}

export interface IVideoPlayerProps {
  /** Selected video which is playing now */
  selectedVideo: IVideoModel;
  /** Close video player if close button is visible */
  close?: () => void;
  /** Whether video autoplay is on or off */
  autoPlay: boolean;
  /** Video list for showreel */
  videoList: IVideoModel[];
  /** Handler function for selecting video to play */
  selectVideo: (model: IVideoModel) => void;
  /** Whether close button is visible or not */
  hasCloseButton: boolean;
  /** Whether video controls contain download button. Default is false */
  hasDownloadButton?: boolean;
  /** Whether video controls contain share video button. Default is false */
  hasShareVideoButton?: boolean;
  /** Content for share video popup. Needs to be define if you use share button only */
  shareVideoPopupTexts?: IShareVideoPopupTexts;
  /** Handler function for onPlay event */
  onPlay?: () => void;
  /** Handler function for onPause event */
  onPause?: () => void;
  /** Any extra info to include into the file title */
  titlePrefix?: string;
}

export class VideoPlayer extends React.Component<IVideoPlayerProps> {
  public render() {
    const {
      selectedVideo,
      videoList,
      autoPlay,
      selectVideo,
      close,
      hasCloseButton,
      hasDownloadButton = false,
      hasShareVideoButton = false,
      shareVideoPopupTexts,
      onPlay,
      onPause,
      titlePrefix,
    } = this.props;
    const showreelMode = videoList.length > 1;

    const containerClasses = `c-video-player ${cx({
      "c-video-player--with-bar": showreelMode,
    })}`;

    return (
      <div className={containerClasses}>
        <Video
          hasCloseButton={hasCloseButton}
          hasDownloadButton={hasDownloadButton}
          hasShareVideoButton={hasShareVideoButton}
          video={selectedVideo}
          autoPlay={autoPlay}
          closePlayer={close}
          switchToNextVideo={this.switchToNextVideo}
          showreelMode={showreelMode}
          onPlay={onPlay}
          onPause={onPause}
          titlePrefix={titlePrefix}
          shareVideoPopupTexts={shareVideoPopupTexts}
        />
        {showreelMode && (
          <VideoShowreel
            selectVideo={selectVideo}
            videoList={videoList}
            selectedVideo={selectedVideo}
          />
        )}
      </div>
    );
  }

  private switchToNextVideo = () => {
    const { selectedVideo, videoList, selectVideo } = this.props;
    if (selectedVideo) {
      const activeVideoIndex = videoList.findIndex(
        (item) => item.id === selectedVideo.id
      );
      const nextIndex =
        activeVideoIndex + 1 > videoList.length - 1 ? 0 : activeVideoIndex + 1;

      selectVideo(videoList[nextIndex]);
    }
  };
}
