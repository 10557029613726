import React from "react";
import {
  IStepTrackerItem,
  StepTrackerItemStatus,
} from "../../../component-library/src";

interface IStepTrackingProps {
  stepNumber: number;
  stepTrackingItems: { label: string }[];
  updateStepNumber?: (newStepNumber: number) => void;
  disableAllSteps?: boolean;
}

export default function useStepTracking({
  stepNumber,
  updateStepNumber,
  disableAllSteps,
  stepTrackingItems,
}: IStepTrackingProps): IStepTrackerItem[] {
  const steps: IStepTrackerItem[] = React.useMemo(
    () =>
      stepTrackingItems.map((step, index) => {
        let status;
        let isClickable = false;
        let onClick;

        if (stepNumber === index) {
          status = StepTrackerItemStatus.Active;
        } else if (stepNumber > index) {
          status = StepTrackerItemStatus.Completed;
          isClickable = true;
          onClick = () => updateStepNumber?.(index);
        }

        if (disableAllSteps) isClickable = false;

        return {
          label: `${index + 1}`,
          title: step.label,
          status,
          isClickable,
          onClick,
        };
      }),
    [stepNumber, stepTrackingItems]
  );

  return steps;
}
