// Start of CN code
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export type UseZendeskProps = {
  /**
   * Use when you want the zendesk script to not load manually
   * This is useful for specific situations where you do not want to show the zendesk widget
   * such as Share Selects
   */
  shouldHide?: boolean;
};

export type UseZendesk = {
  hide: () => void;
  show: () => void;
};

const ZENDESK_SCRIPT =
  "https://static.zdassets.com/ekr/snippet.js?key=5078d237-f7d3-4228-afec-8f9b0083ea83";
const ZENDESK_ID = "ze-snippet";
let checkLoadedTimer: number;
let loadAttempts = 0;

const tryToAddPrintClass = () => {
  // The following code injects a class onto the zendesk chat widget to hide it on print preview screens
  // Zendesk does not provide a callback to tie into when the widget is fully loaded, so we are manually
  // waiting for the widget to appear to add the right class to it (and not just wait for the "load" event from the script)
  // Zendesk docs: https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/sdk_api_reference/
  const zenDeskButton = document.querySelector(
    'iframe[title="Button to launch messaging window"]'
  );

  if (zenDeskButton) {
    zenDeskButton.classList.add("zendesk-container");
    clearTimeout(checkLoadedTimer);
  } else if (loadAttempts < 20) {
    loadAttempts += 1;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    checkLoadedTimer = setTimeout(tryToAddPrintClass, 200);
  } else {
    clearTimeout(checkLoadedTimer);
    // Report an error that we are unable to find the zendesk script
  }
};

/**
 * Manages the zendesk widget, this will only inject a single widget script tag into the dom on initialization
 */
const useControlZendesk = ({
  shouldHide,
}: UseZendeskProps = {}): UseZendesk => {
  const body = useRef(document.querySelector("body"));
  const isVisible = useRef(false);

  const hide = () => {
    if (isVisible.current) {
      // This allows any page to hide zendesk on-demand.  Typically
      // you'd want to lean into the per-route basis but there are situations
      // like the MediaList where having the sticky footer appear you'd want to hide it
      // https://developer.zendesk.com/api-reference/widget-messaging/web/core/
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE?.("messenger", "hide");
      isVisible.current = false;
    }
  };

  const show = () => {
    if (!isVisible.current) {
      // This allows any page to show zendesk on-demand.
      // Similar to the hide technique this should only be done in rare cases.
      // Also show will only work if the route is setup to have it in the first place (the script is loaded)
      // https://developer.zendesk.com/api-reference/widget-messaging/web/core/
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zE?.("messenger", "show");
      isVisible.current = true;
    }
  };

  const handleScroll = () => {
    if (!body.current) {
      return;
    }

    if (window.innerHeight - 30 >= body.current?.scrollHeight) {
      // Always show if the window is bigger than the scrollable area:
      show();
    } else if (
      (body.current.scrollTop || window.scrollY) + window.innerHeight >=
      body.current.scrollHeight - 30
    ) {
      // If we've scrolled to the bottom:
      hide();
    } else {
      show();
    }
  };

  useEffect(() => {
    const existingZendeskScript = document.querySelector(
      `script[id="${ZENDESK_ID}"]`
    );
    // Avoid duplicates
    document.querySelector("body")?.removeEventListener("scroll", handleScroll);
    window.removeEventListener("scroll", handleScroll);

    if (!shouldHide && !existingZendeskScript) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.zESettings = {
        analytics: false,
      };

      const zendeskScript = document.createElement("script");
      zendeskScript.src = ZENDESK_SCRIPT;
      zendeskScript.id = ZENDESK_ID;
      document.querySelector("head")?.appendChild(zendeskScript);

      tryToAddPrintClass();
      // And attempt to make it show (it could be hidden from other scripts, see below)
      show();

      // Some desktop pages listen to body scroll, while mobile and some other desktop pages listen to window scroll
      document.querySelector("body")?.addEventListener("scroll", handleScroll);
      window.addEventListener("scroll", handleScroll);
    } else if (shouldHide && existingZendeskScript) {
      // If the shouldHide flips but the script is already loaded, we need to remove it
      existingZendeskScript.remove();
      // Also hide the widget if it exists:
      hide();
      document
        .querySelector("body")
        ?.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScroll);
    }
  }, [shouldHide, handleScroll]);

  return {
    hide,
    show,
  };
};
// End of CN code

const checkIsZendeskVisible = (routes: string[], pathname: string) => {
  return routes.some((route) => {
    /**
    The string can start with a slash or not.
    Then it must contain the route string.
    After the route string, there can be an optional slash.
    If there is a slash, it can be followed by a query string or nothing else.
    If there is no slash, the route string must be at the end of the string or be followed by a query string.
    The route string cannot be part of another alphanumeric string. It must be either at the start of the string, followed by a slash, or at the end of the string.
     */
    const regex = new RegExp(`(?:^|\\/)(?:${route})(?:\\/?(?:\\?.*)?$|$)`, "i");
    return regex.test(pathname);
  });
};

const useZendesk = (routes: string[]) => {
  const location = useLocation();

  const isZendeskVisible = checkIsZendeskVisible(routes, location.pathname);

  const { hide, show } = useControlZendesk({
    shouldHide: !isZendeskVisible,
  });

  useEffect(() => {
    if (isZendeskVisible) {
      show();
    } else {
      hide();
    }
  }, [location.pathname]);
};

export default useZendesk;
