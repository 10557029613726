import React from "react";
import { useZendesk } from "shared-hooks";

export default function withZendesk<P>(
  WrappedComponent: React.ComponentType<any>
) {
  return function (props: P) {
    useZendesk(["myhome"]);
    return <WrappedComponent {...props} />;
  };
}
