import React, { FC } from "react";
import DOMPurify from "dompurify";
import { ReactComponent as DirectDebit } from "component-library/src/images/svg/icons/direct-debit.svg";
import { BillingUser } from "translations/src/models/billing";
import { Popup } from "../../../../../Molecules/Popup/Popup";
import { AlertMessage } from "../../../../../Molecules/AlertMessage/AlertMessage";
import { useBillingTranslations } from "../../../hooks/useBillingTranslations";
import "./index.scss";

interface Props {
  onClose: () => void;
}

const DirectDebitGuaranteeModal: FC<Props> = ({ onClose }) => {
  const {
    texts: { directDebit, common },
  } = useBillingTranslations(BillingUser.Common);

  return (
    <Popup width={{ lg: 6, md: 6 }} close={onClose} priority="high">
      <AlertMessage
        texts={{
          title: directDebit.guarantee,
        }}
        buttons={[
          {
            name: common.close,
            type: "secondary",
            click: onClose,
          },
        ]}
      >
        <DirectDebit className="mb-[10px]" height={50} width={77} />
        <div
          className="direct-debit-guarantee-text"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(directDebit.guaranteeText),
          }}
        />
      </AlertMessage>
    </Popup>
  );
};

export default DirectDebitGuaranteeModal;
