import Rox from "rox-browser";
import { authorizationService } from "shared-auth";
import config from "config/global";
import { RoxSettingsContainer } from "app-shell/RoxProvider/RoxProvider";

class RoxService {
  public async getRoxSettingsContainer(): Promise<RoxSettingsContainer> {
    const user = await authorizationService.getUser();
    const roxSettingsContainer = new RoxSettingsContainer();
    // @ts-ignore
    await Rox.register(config.rox.namespace, roxSettingsContainer);
    await Rox.setup(config.rox.key);
    Rox.setCustomStringProperty("UserId", user?.profile.sub || "");

    return roxSettingsContainer;
  }
}

export default new RoxService();
