import { IAboutYouPageTranslations } from "./AboutYouPage";
import { IJoiningPagesCommon } from "./Common";
import { ICompletePageTranslations } from "./CompletePage";
import { IJoiningAsPageTranslations } from "./JoiningAsPage";
import { ILoginPageTranslations } from "./LoginPage";
import { IReviewPageTranslations } from "./ReviewPage";

export enum JoiningPage {
  Common = "common",
  LoginPage = "login-page",
  JoiningAsPage = "joining-as-page",
  AboutYouPage = "about-you-page",
  ReviewPage = "review-page",
  CompletePage = "complete-page",
}

export interface IJoiningPagesFallbacks {
  [JoiningPage.Common]: IJoiningPagesCommon;
  [JoiningPage.LoginPage]: ILoginPageTranslations;
  [JoiningPage.JoiningAsPage]: IJoiningAsPageTranslations;
  [JoiningPage.AboutYouPage]: IAboutYouPageTranslations;
  [JoiningPage.ReviewPage]: IReviewPageTranslations;
  [JoiningPage.CompletePage]: ICompletePageTranslations;
}
