import React, { FC } from "react";
import { SelectFormik } from "../SelectFormik/SelectFormik";
import { useGetStatsProvincesByCountryOptions } from "../../../Functional/locations/useGetStatsProvincesByCountryOptions";

interface Props {
  name: string;
  selectedCountryCode: string;
  label: string;
  placeholder?: string;
  searchableBySearchbox?: boolean;
}

const SelectStateProvinceFormik: FC<Props> = ({
  name,
  selectedCountryCode,
  label,
  placeholder,
  searchableBySearchbox,
}) => {
  const stateOptions =
    useGetStatsProvincesByCountryOptions(selectedCountryCode);

  return (
    <SelectFormik
      name={name}
      label={label}
      placeholder={placeholder}
      options={stateOptions.map((option) => ({
        ...option,
        label: option.name,
      }))}
      searchableBySearchbox={searchableBySearchbox}
    />
  );
};

export default SelectStateProvinceFormik;
