import React, { useEffect, useState, FC } from "react";
import cx from "classnames";
import { MediaType } from "shared-types";
import { useTranslation } from "i18n";
import {
  FileUploadState,
  IFilePartInfo,
  IFilePartProgress,
  IFileUpload,
  IFileUploadComplete,
  IFileUploadModel,
} from "./models";
import { ReactComponent as Cross } from "component-library/src/images/svg/icons/cross-thin.svg";
import { ProgressBar } from "../../Atoms/ProgressBar/ProgressBar";
import { ActionButton } from "../../Atoms/ActionButton/ActionButton";
import { IconTextLink } from "../../Atoms/IconTextLink/IconTextLink";
import { config } from "../../../config";
import { RecordWithActionControls } from "../../Molecules/RecordWithActionControls/RecordWithActionControls";
import { SUPPORTED_AUDIO_EXTENSIONS } from "./models/SupportedAudioTypes";
import { SUPPORTED_VIDEO_EXTENSIONS } from "./models/SupportedVideoTypes";
import { TitaniumUploadService } from "./services/TitaniumUploadService";
import MediaFilePreview from "./MediaFilePreview";
import "./TitaniumFileUploader.scss";

export const uploadService = new TitaniumUploadService(config.titaniumApiUrl);

export interface IFileUploaderTexts {
  formatValidationError: string;
  uploadError: string;
}

export interface ICompleteFileUpload {
  id: string;
  mediaInfoId: string;
  durationInMs?: number;
  mediaType: MediaType;
}

export interface IFileUploaderProps {
  texts: IFileUploaderTexts;
  mediaType: MediaType;
  preloadedFile: IFileUpload;
  onPreUpload?: (preloadedFile: IFileUpload) => boolean;
  onUploadStart?: (fileName?: string) => void;
  onComplete?: (params: ICompleteFileUpload) => void;
  onFileRemove?: (id: string) => void;
  onUploadError?: (errorMessage: string) => void;
  onUploadRetry?: () => void;
  onRenameFile?: (id: string, name: string) => void;
}

export const TitaniumFileUploader: FC<IFileUploaderProps> = (props) => {
  const { t } = useTranslation();
  const [uploadState, setUploadState] = useState<FileUploadState>(
    props.preloadedFile.uploadState
  );
  const [fileUploadDetails, setFileUploadDetails] = useState<
    IFileUploadModel | undefined
  >(props.preloadedFile.details);
  const [percentCompleted, setPercentCompleted] = useState<number>(0);
  const [fileParts, setFileParts] = useState<IFilePartInfo[] | null>(null);
  const [partProgress, setPartProgress] = useState<IFilePartProgress[]>([]);
  const [uploadedFileId, setUploadedFileId] = useState<string>();
  const [fileNameInput, setFileNameInput] = useState<string>(
    props.preloadedFile.file?.name
  );

  useEffect(() => {
    switch (uploadState) {
      case FileUploadState.NotStarted:
        prepareUpload();
        break;
      case FileUploadState.PreparationComplete:
        uploadFiles();
        break;
      case FileUploadState.UploadComplete:
        if (fileUploadDetails) {
          fileUploadDetails.isMultipartUpload
            ? completeMultiPartFileUpload()
            : completeFileUpload();
        }
        break;
      case FileUploadState.Error:
      case FileUploadState.Unsupported:
        onUploadError();
        break;
    }
  }, [uploadState]);

  const onFileRemove = (): void => {
    if (props.onFileRemove) {
      props.onFileRemove(props.preloadedFile.id);
    }
  };

  const onUploadRetry = () => {
    setUploadState(FileUploadState.NotStarted);
  };

  const onUploadError = () => {
    if (props.onUploadError) {
      props.onUploadError(props.preloadedFile.id);
    }
  };

  const onComplete = ({ id, mediaType }: IFileUploadComplete) => {
    setUploadedFileId(id);

    if (props.onComplete) {
      props.onComplete({
        id: props.preloadedFile.id,
        mediaInfoId: id,
        durationInMs: props.preloadedFile.durationInMs,
        mediaType,
      });
    }
  };

  const updateMultipartProgress = (ptProgress: IFilePartProgress[]): number => {
    if (ptProgress.length > 0 && fileUploadDetails) {
      const fpProgress: IFilePartProgress = ptProgress.reduce(
        (pVal: IFilePartProgress, cVal: IFilePartProgress) => {
          return {
            total: pVal.total + cVal.total,
            loaded: pVal.loaded + cVal.loaded,
            partNumber: 0,
          };
        }
      );
      return (100 / fileUploadDetails.size) * fpProgress.loaded;
    }
    return 0;
  };

  const handleFilePartProgress = (
    partNumber: number,
    loaded: number,
    total: number
  ) => {
    const idx = partProgress.findIndex((p) => p.partNumber === partNumber);

    const updatedFPProgress = partProgress;
    const updated: IFilePartProgress = { partNumber, loaded, total };
    if (idx > -1) {
      updatedFPProgress.splice(idx, 1, updated);
    } else {
      updatedFPProgress.push(updated);
    }
    setPartProgress(updatedFPProgress);
    setPercentCompleted(updateMultipartProgress(updatedFPProgress));
  };

  const isExtensionValid = () => {
    const extension = getFileExtension(props.preloadedFile.file);
    const supportedTypes =
      props.mediaType === MediaType.Video
        ? SUPPORTED_VIDEO_EXTENSIONS
        : SUPPORTED_AUDIO_EXTENSIONS;

    return supportedTypes.includes(extension);
  };

  const getFileExtension = (file: File) => {
    return file.name.split(".").pop()!.toLowerCase();
  };

  const prepareUpload = async () => {
    if (!isExtensionValid()) {
      setUploadState(FileUploadState.Unsupported);
      return;
    }

    if (props.onPreUpload) {
      if (!props.onPreUpload(props.preloadedFile)) {
        setUploadState(FileUploadState.Error);
        return;
      }
    }

    if (props.onUploadStart) {
      props.onUploadStart(props.preloadedFile.file?.name);
    }

    await uploadService
      .getFileUploadDetails(props.preloadedFile)
      .then((fileUploadResponse) => {
        setFileUploadDetails(fileUploadResponse.value);
        setUploadState(FileUploadState.PreparationComplete);
      })
      .catch((error) => {
        setUploadState(FileUploadState.Error);
      });
  };

  const uploadFiles = async () => {
    if (fileUploadDetails) {
      if (fileUploadDetails.isMultipartUpload) {
        uploadService
          .doMultipartFileUploadAsync(
            props.preloadedFile,
            fileUploadDetails,
            handleFilePartProgress
          )
          .then((fpList) => {
            setFileParts(fpList);
            setUploadState(FileUploadState.UploadComplete);
            setPercentCompleted(100);
          })
          .catch((error) => {
            setPercentCompleted(100);
            setUploadState(FileUploadState.Error);
          });
      } else {
        uploadService
          .putFile(
            fileUploadDetails.uploadUri,
            props.preloadedFile,
            fileUploadDetails,
            (complete) => {
              setPercentCompleted(complete);
            }
          )
          .then(() => {
            setPercentCompleted(100);
            setUploadState(FileUploadState.UploadComplete);
          })
          .catch(() => {
            setPercentCompleted(0);
            setUploadState(FileUploadState.Error);
          });
      }
    }
  };

  const completeMultiPartFileUpload = () => {
    if (fileUploadDetails && fileParts) {
      uploadService
        .completeMultipartFileUpload(
          fileUploadDetails,
          props.mediaType,
          fileParts
        )
        .then((response) => {
          if (!response.hasErrors) {
            setUploadState(FileUploadState.Complete);
            onComplete(response.value);
          } else {
            setUploadState(FileUploadState.Error);
          }
        })
        .catch((error) => {
          setUploadState(FileUploadState.Error);
        });
    }
  };

  const completeFileUpload = () => {
    if (fileUploadDetails) {
      uploadService
        .completeFileUpload(fileUploadDetails, props.mediaType)
        .then((response) => {
          if (!response.hasErrors) {
            setUploadState(FileUploadState.Complete);
            onComplete(response.value);
          } else {
            setUploadState(FileUploadState.Error);
          }
        })
        .catch(() => {
          setUploadState(FileUploadState.Error);
        });
    }
  };

  const renameFile = async () => {
    try {
      if (uploadedFileId) {
        await uploadService.renameFile(uploadedFileId, fileNameInput);
        setUploadState(FileUploadState.Complete);
        props.onRenameFile?.(props.preloadedFile.id, fileNameInput);
      }
    } catch (e) {
      setUploadState(FileUploadState.Rename);
    }
  };

  const getFilePreviewControls = (): JSX.Element[] => {
    const {
      preloadedFile: { id },
    } = props;
    let previewControls: JSX.Element[] = [];
    const cancelButton = (
      <Cross
        onClick={onFileRemove}
        className="text-color-brand-one cursor-pointer"
      />
    );

    switch (uploadState) {
      case FileUploadState.NotStarted:
      case FileUploadState.PreparationComplete:
      case FileUploadState.UploadComplete:
        previewControls = [
          ...previewControls,
          <ProgressBar key={id} percent={percentCompleted} />,
          cancelButton,
        ];
        break;
      case FileUploadState.Complete:
        previewControls = [
          ...previewControls,
          <MediaFilePreview key={`${id}-preview`} id={uploadedFileId} />,
          <ActionButton
            key={`${id}-edit`}
            icon="edit"
            onClick={() => setUploadState(FileUploadState.Rename)}
            size="small"
            ariaLabel="edit"
          />,
          <ActionButton
            key={`${id}-delete`}
            icon="delete"
            onClick={onFileRemove}
            size="small"
            ariaLabel="Delete"
          />,
        ];
        break;
      case FileUploadState.Error:
        previewControls.push(
          <ActionButton
            key={`${id}-1`}
            icon="retry"
            onClick={onUploadRetry}
            size="small"
            ariaLabel="Retry upload"
          />
        );
        previewControls.push(cancelButton);
        break;
      case FileUploadState.Unsupported:
        previewControls.push(cancelButton);
        break;
      case FileUploadState.Rename:
        previewControls.push(
          <IconTextLink
            text={t("common:button.label.save")}
            variant="primary"
            onClick={renameFile}
          />
        );
        break;
    }

    return previewControls;
  };

  const isUploadError = uploadState === FileUploadState.Error;
  const isRenameError = uploadState === FileUploadState.RenameError;
  const isUnsupportedError = uploadState === FileUploadState.Unsupported;
  const isError = isUploadError || isUnsupportedError || isRenameError;

  return (
    <div className="mb-2.5">
      <RecordWithActionControls
        className={cx("c-file-uploader__file-preview", {
          error: isError,
        })}
        key={props.preloadedFile.id}
        title={
          uploadState === FileUploadState.Rename ? (
            <input
              className="w-full min-w-[400px]"
              type="text"
              value={fileNameInput}
              onChange={(e) => setFileNameInput(e.target.value)}
            />
          ) : (
            fileNameInput
          )
        }
        controls={getFilePreviewControls()}
      />
      {isError && (
        <div className="c-file-uploader__file-preview--error-text">
          {isUnsupportedError && props.texts.formatValidationError}
          {isUploadError && props.texts.uploadError}
          {isRenameError && t("common:validation.error.renameMediaFile")}
        </div>
      )}
    </div>
  );
};
