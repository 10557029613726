import { useQuery } from "react-query";
import { billingService } from "../services";
import { FETCH_SUMMARY } from "../services/billingService";
import { ISummary } from "../types";

export const useGetSummary = () => {
  const { data, isLoading, isSuccess } = useQuery([FETCH_SUMMARY], async () => {
    return await billingService.getSummary();
  });

  return { data: data || ({} as ISummary), isLoading, isSuccess };
};
