import { useMemo } from "react";
import { object, ref, string, ValidationError } from "yup";
import _curry from "lodash/curry";
import { IBillingCommon } from "translations/src/models/billing/common";
import { FormData } from "./useBillingFormValues";
import {
  COUNTRIES_WITH_STATE_PROVINCE,
  FieldNames,
  PlanType,
} from "../constants";
import {
  buildErrorMsg,
  buildMaxErrorMessage,
  validatePhoneNumber,
} from "../../../../index";

const getValidationSchema = (
  form: IBillingCommon["billingForm"] & IBillingCommon["directDebitForm"],
  validation: IBillingCommon["validation"],
  planType?: PlanType
) => {
  const getMaxError = _curry(buildMaxErrorMessage)(validation.maxLength);
  const getReqError = _curry(buildErrorMsg)(validation.requiredField);

  const billingDetails = {
    [FieldNames.firstName]: string()
      .max(50, getMaxError(form.firstNameLabel, 50))
      .required(getReqError(form.firstNameLabel)),
    [FieldNames.lastName]: string()
      .max(50, getMaxError(form.lastNameLabel, 50))
      .required(getReqError(form.lastNameLabel)),
    [FieldNames.address1]: string()
      .max(50, getMaxError(form.address1Label, 50))
      .required(getReqError(form.address1Label)),
    [FieldNames.address2]: string()
      .max(50, getMaxError(form.address2Label, 50))
      .nullable(),
    [FieldNames.city]: string()
      .max(50, getMaxError(form.cityLabel, 50))
      .required(getReqError(form.cityLabel)),
    [FieldNames.state]: string().when(FieldNames.country, {
      is: (selectedCountry: string) =>
        COUNTRIES_WITH_STATE_PROVINCE.includes(selectedCountry),
      then: (schema) => schema.required(getReqError(form.stateProvinceLabel)),
    }),
    [FieldNames.postalCode]: string()
      .max(20, getMaxError(form.zipLabel, 20))
      .required(getReqError(form.zipLabel)),
    [FieldNames.country]: string().required(getReqError(form.countryLabel)),
    [FieldNames.phone]: string()
      .trim()
      .required(getReqError(form.phoneLabel))
      .test("validateMobile", validation.invalidPhoneNumber, (val) =>
        validatePhoneNumber("mobile", val)
      ),
  };

  const directDebitForm = {
    [FieldNames.accountName]: string()
      .max(255, getMaxError(form.accountName, 255))
      .required(getReqError(form.accountName)),
    [FieldNames.accountNumber]: string()
      .max(255, getMaxError(form.accountNumber, 255))
      .required(getReqError(form.accountNumber)),
    [FieldNames.accountNumberConfirmation]: string()
      .max(255, getMaxError(form.accountNumberConfirmation, 255))
      .oneOf(
        [ref(FieldNames.accountNumber), null],
        buildErrorMsg(
          validation.fieldsDoNotMatch,
          form.accountNumberConfirmation
        )
      )
      .required(getReqError(form.accountNumberConfirmation)),
    [FieldNames.sortCode]: string()
      .max(15, getMaxError(form.sortCode, 15))
      .required(getReqError(form.sortCode)),
  };

  const sepaForm = {
    [FieldNames.accountName]: string()
      .max(255, getMaxError(form.accountName, 255))
      .required(getReqError(form.accountName)),
    [FieldNames.iban]: string()
      .max(34, getMaxError(form.ibanLabel, 34))
      .required(getReqError(form.ibanLabel)),
  };

  if (planType === PlanType.Sepa)
    return object().shape({ ...billingDetails, ...sepaForm });

  if (planType === PlanType.DirectDebit)
    return object().shape({ ...billingDetails, ...directDebitForm });

  return object().shape(billingDetails);
};

const useBillingFormValidation = (
  formData: FormData,
  form: IBillingCommon["billingForm"] & IBillingCommon["directDebitForm"],
  validation: IBillingCommon["validation"],
  planType?: PlanType
) => {
  const validationSchema = getValidationSchema(form, validation, planType);

  return useMemo(() => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      return {};
    } catch (e) {
      if (e instanceof ValidationError) {
        return e.inner.reduce(
          (acc: Record<string, string>, error: ValidationError) => {
            return { ...acc, [String(error.path)]: error.errors[0] };
          },
          {}
        );
      }

      return {};
    }
  }, [formData, validationSchema]);
};

export default useBillingFormValidation;
