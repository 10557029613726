import cx from "classnames";
import React from "react";

import "./SectionRow.scss";

export interface ISectionRowProps {
  className?: string;
  noHorizontalPadding?: boolean;
  spacingVariant?: "primary" | "secondary";
}

const SectionRow: React.FC<ISectionRowProps> = (props) => (
  <div
    className={cx("c-section-row", props.className, {
      "c-section-row--no-horizontal-padding": props.noHorizontalPadding,
      [`c-section-row--spacing-${props.spacingVariant}`]: Boolean(
        props.spacingVariant
      ),
    })}
  >
    {props.children}
  </div>
);

export default SectionRow;
