import React from "react";

export const handleEnterPress =
  // @ts-ignore
  (callback?) => (event: React.KeyboardEvent<any>) => {
    if (callback && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault?.();
      callback();
    }
  };
