export enum RoxFlag {
  LocalizationControl = "LocalizationControl",
  UseRecurlyForRenewals = "UseRecurlyForRenewals",
  Premium_PerformerSearch = "Premium_PerformerSearch",
  NewAccSettings = "NewAccSettings",
  NewAccSettings_AllowUpdtSubscription = "NewAccSettings_AllowUpdtSubscription",
  Premium_AnnualPromo = "Premium_AnnualPromo",
  NewAccSettings_AllowSubscriptionCancellation = "NewAccSettings_AllowSubscriptionCancellation",
  NewAccSettings_AllowSubscriptionUpgradeDowngrade = "NewAccSettings_AllowSubscriptionUpgradeDowngrade",
  NewAccSettings_AllowDiscountCode = "NewAccSettings_AllowDiscountCode",
  NewCheckoutPage = "NewCheckoutPage",
}
