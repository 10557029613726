import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';

import { IApplicationState, reducers } from './index';

export default function configureStore(applicationState: IApplicationState): Store<IApplicationState> {
  // Here is ReduxDevTool configuration
  // It is unavailable in production
  // take a look on --mode parameter value in scripts
  let composeEnhancers;

  if (process.env.NODE_ENV === 'production') {
    composeEnhancers = compose;
  } else {
    composeEnhancers = require('redux-devtools-extension').composeWithDevTools({});
  }

  return createStore(reducers, applicationState, composeEnhancers(applyMiddleware(thunk)));
}
