import React from "react";
import cx from "classnames";

import "./FilterDrawerItem.scss";

export interface IFilterDrawerItemProps {
  title: string;
  selected?: boolean;
  id?: string;
  onClick?: () => void;
}

export const FilterDrawerItem: React.FC<IFilterDrawerItemProps> = ({
  title,
  selected,
  id,
  onClick,
}) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={cx("c-filter-drawer-item", {
        "c-filter-drawer-item--selected": selected,
      })}
      onClick={onClick}
      onKeyDown={(e) => e.key === "Enter" && onClick?.()}
      id={id}
    >
      <span
        className={cx("c-filter-drawer-item__title", {
          "c-filter-drawer-item__title--selected": selected,
        })}
      >
        {title}
      </span>
      <span
        className="c-filter-drawer-item__icon-font icon-chevronright"
        aria-hidden="true"
      />
    </div>
  );
};
