export enum UserTypes {
  anonymous = -1,
  youngPerformerMale = 16,
  youngPerformerFemale = 17,
  maleGraduates = 18,
  femaleGraduates = 19,
  actors = 1,
  filmmakersProfile = 40,
  agentAPITest = 99,
  suspendedActorsActresses = 201,
  suspendedPresentersMale = 206,
  suspendedPresentersFemale = 207,
  suspendedChildMale = 216,
  suspendedChildFemale = 217,
  suspendedGraduateMale = 218,
  suspendedGraduateFemale = 219,
  suspendedDancers = 224,
}
