import { BillingFallbacks } from "translations/src/fallbackTranslations/billing";
import { BillingUser } from "translations/src/models/billing";
import { TranslationType } from "translations/src/services/billing";

import { useFetchTranslation } from "./useFetchTranslation";
import { LocalizationUtils } from "../../../../index";

export const useBillingTranslations = <T extends BillingUser>(
  type: T
): { texts: TranslationType<T> } => {
  const locale = LocalizationUtils.getUserLocalizationPreference().locale;
  const { data } = useFetchTranslation(type, locale);

  return { texts: data || (BillingFallbacks[type] as TranslationType<T>) };
};
