import React from "react";
import { TFunction } from "i18n";
import { Pill } from "../../../../Atoms/Pill/Pill";

export function getMediaRequestsSubNav(
  t: TFunction,
  isMediaRequestEnabled?: boolean
) {
  const text = t("common:nav.links.mediaRequests");

  return isMediaRequestEnabled
    ? [
        {
          text,
          linkUrl: "/jobs/self-tape-requests",
          renderLabel: (
            <span>
              {text}{" "}
              <Pill className="bg-blue-sky-2 ml-1.5" theme="light" size="md">
                {t("common:nav.links.pill.beta")}
              </Pill>
            </span>
          ),
        },
      ]
    : [];
}
