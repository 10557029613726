import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { initI18N } from "i18n";

import { IApplicationState } from "store";

import Root from "app-shell/Root/Root";

import configureStore from "./app/store/configureStore";
import { Spinner } from "component-library";
import "./index.css";

initI18N(["casting", "performer", "agent"]);

//  https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
window.onpageshow = function (event: PageTransitionEvent) {
  if (event.persisted) {
    console.log("OnPageShow persisted");
    document.body.style.display = "none";
    window.location.reload();
  }
};

const store = configureStore({} as IApplicationState);
ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <Root store={store} />
  </Suspense>,
  document.getElementById("root")
);
