import React from "react";
import cx from "classnames";
import "./LeftHandNavCanvas.scss";
import AccountType from "../../../../constants/AccountType";

export interface ILeftHandNavCanvasProps {
  /**
   * The text to show as a username above the border
   */
  userName: string;
  /**
   * URL of the clickable link containing the full name
   */
  linkUrl: string;
  /** Optional css class for wrapper
   *
   */
  className?: string;
  /**
   * Account type for signed in user
   */
  accountType: AccountType;
}

export class LeftHandNavCanvas extends React.Component<ILeftHandNavCanvasProps> {
  public constructor(props: ILeftHandNavCanvasProps) {
    super(props);
  }

  public render() {
    const { className, linkUrl, userName, children, accountType } = this.props;
    return (
      <div className={cx("c-nav-canvas", className ? `${className}` : "")}>
        <div className="c-nav-canvas">
          <div className="c-nav-canvas__container">
            <div
              className={`c-nav-canvas__user-container c-nav-canvas__user-container-${AccountType[accountType]}`}
            >
              <a href={linkUrl} className="c-nav-canvas__full-name">
                {userName}
              </a>
            </div>
            <div className="c-nav-canvas__menu-container">{children}</div>
          </div>
        </div>
      </div>
    );
  }
}
