import React, { FC, useContext } from "react";
import cx from "classnames";
import { BillingUser } from "translations/src/models/billing";
import CountryStateSelect from "../../CardElements/CountryStateSelect";
import BillingPhoneNumber from "../../CardElements/BillingPhoneNumber";
import BillingInput from "../../CardElements/BillingInput";
import {
  getTootlipProps,
  QuestionTooltip,
} from "../../../../../Molecules/QuestionTooltip";
import {
  COUNTRIES_WITH_STATE_PROVINCE,
  DEFAULT_DIRECT_DEBIT_COUNTRY,
  FieldNames,
  PlanType,
  securityTextCommonStyles,
} from "../../../constants";
import { BillingAndPaymentFormProps } from "../../../types";
import Section from "../../Section";
import { useBillingTranslations } from "../../../hooks/useBillingTranslations";
import { useGetStatsProvincesByCountryOptions } from "../../../../../Functional/locations/useGetStatsProvincesByCountryOptions";
import { useGetCountriesOptions } from "../../../../../Functional/locations/useGetCountriesOptions";
import CreditCardForm from "../../CardElements/CreditCardForm";
import DirectDebitForm from "./DirectDebitForm";
import DirectDebitGuaranteeBlock from "./DirectDebitGuaranteeBlock";
import SepaForm from "./SepaForm";
import UseExistingPaymentMethodBlock from "../../UseExistingPaymentMethodBlock/UseExistingPaymentMethodBlock";
import { BillingContext } from "../../../BillingContext";

const BillingAndPaymentForm: FC<BillingAndPaymentFormProps> = ({
  isFormOpen,
  formErrors,
  submitForm,
  billingFormRef,
  sectionRef,
  setFieldValue,
  isSubmitted,
  tootlipContainerConst,
  formValues,
  cardDetailError,
  removeInvalidCardError,
  isMobileView,
  cardFormIsVisible,
  isDirectDebit,
  planType,
}) => {
  const { useExistingPaymentMethod } = useContext(BillingContext);
  const {
    texts: {
      directDebit,
      common,
      paymentDetailForm,
      billingForm,
      directDebitForm,
    },
  } = useBillingTranslations(BillingUser.Common);
  const formBlockClassName = cx("flex flex-col", {
    "flex-auto max-w-[680px]": isFormOpen,
  });
  const isSepa = planType === PlanType.Sepa;
  const firstError = Object.keys(formErrors)[0];
  const selectedCountryCode = formValues[FieldNames.country];
  const statesProvincesOptions =
    useGetStatsProvincesByCountryOptions(selectedCountryCode);
  const { countries, isLoading: isCountryOptionsLoading } =
    useGetCountriesOptions({
      shouldIncludeStates: true,
      shouldGetOnlyEUCountries: isSepa,
    });
  const countryOptions = countries;

  const selectedCountryHasStateProvince =
    COUNTRIES_WITH_STATE_PROVINCE.includes(selectedCountryCode as string);
  const isCountrySelectDirectDebit = isDirectDebit && !isSepa;

  return (
    <form onSubmit={submitForm} ref={billingFormRef} className="max-w-[680px]">
      <Section
        title={common.billingDetailsTitle}
        isOpen={isFormOpen}
        customClasses={
          isMobileView ? "border-solid border-t border-grey-light-2 pb-0" : ""
        }
      >
        <UseExistingPaymentMethodBlock />
        {!useExistingPaymentMethod && (
          <div className={formBlockClassName}>
            <BillingInput
              label={billingForm.firstNameLabel}
              name={FieldNames.firstName}
              value={formValues[FieldNames.firstName]}
              dataRecurly={FieldNames.firstName}
              setFieldValue={setFieldValue}
              validationMessage={formErrors[FieldNames.firstName]}
              isSubmitted={isSubmitted}
              shouldScroll={firstError === FieldNames.firstName}
            />
            <BillingInput
              label={billingForm.lastNameLabel}
              name={FieldNames.lastName}
              value={formValues[FieldNames.lastName]}
              dataRecurly={FieldNames.lastName}
              setFieldValue={setFieldValue}
              validationMessage={formErrors[FieldNames.lastName]}
              isSubmitted={isSubmitted}
              shouldScroll={firstError === FieldNames.lastName}
            />
            <BillingInput
              label={billingForm.address1Label}
              name={FieldNames.address1}
              value={formValues[FieldNames.address1]}
              dataRecurly={FieldNames.address1}
              setFieldValue={setFieldValue}
              validationMessage={formErrors[FieldNames.address1]}
              isSubmitted={isSubmitted}
              shouldScroll={firstError === FieldNames.address1}
              annex={
                <QuestionTooltip
                  tooltipProps={{
                    ...getTootlipProps(
                      billingForm.address1TooltipHeader,
                      billingForm.address1TooltipBody
                    ),
                    overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
                  }}
                />
              }
            />
            <BillingInput
              label={billingForm.address2Label}
              name={FieldNames.address2}
              value={formValues[FieldNames.address2]}
              dataRecurly={FieldNames.address2}
              setFieldValue={setFieldValue}
              validationMessage={formErrors[FieldNames.address2]}
              isSubmitted={isSubmitted}
              shouldScroll={firstError === FieldNames.address2}
            />
            <div
              className={isMobileView ? "flex flex-col gap-5" : "flex gap-5"}
            >
              <BillingInput
                label={billingForm.cityLabel}
                name={FieldNames.city}
                value={formValues[FieldNames.city]}
                dataRecurly={FieldNames.city}
                setFieldValue={setFieldValue}
                validationMessage={formErrors[FieldNames.city]}
                isSubmitted={isSubmitted}
                shouldScroll={firstError === FieldNames.city}
                wrapperClassName="flex-1 grow-[2]"
              />
              <BillingInput
                label={billingForm.zipLabel}
                name={FieldNames.postalCode}
                value={formValues[FieldNames.postalCode]}
                dataRecurly={FieldNames.postalCode}
                setFieldValue={(e) => {
                  if (isSubmitted && formErrors[FieldNames.postalCode]) {
                    removeInvalidCardError(FieldNames.postalCode);
                  }
                  setFieldValue(e);
                }}
                validationMessage={formErrors[FieldNames.postalCode]}
                isSubmitted={isSubmitted}
                shouldScroll={firstError === FieldNames.postalCode}
                wrapperClassName="flex-1"
              />
            </div>
            <CountryStateSelect
              label={billingForm.countryLabel}
              placeholder={billingForm.countryPlaceholder}
              options={countryOptions}
              value={formValues[FieldNames.country]}
              setFieldValue={setFieldValue}
              validationMessage={formErrors[FieldNames.country]}
              isFormSubmitted={isSubmitted}
              shouldScroll={firstError === FieldNames.country}
              fieldName={FieldNames.country}
              isDisabled={isCountrySelectDirectDebit}
              presetCountry={
                isCountrySelectDirectDebit ? DEFAULT_DIRECT_DEBIT_COUNTRY : ""
              }
            />
            {selectedCountryHasStateProvince && (
              <CountryStateSelect
                label={billingForm.stateProvinceLabel}
                placeholder={billingForm.stateProvincePlaceholder}
                options={statesProvincesOptions}
                value={formValues[FieldNames.state]}
                setFieldValue={setFieldValue}
                validationMessage={formErrors[FieldNames.state]}
                isFormSubmitted={isSubmitted}
                shouldScroll={firstError === FieldNames.state}
                fieldName={FieldNames.state}
                isDisabled={isCountryOptionsLoading}
              />
            )}
            <BillingPhoneNumber
              label={billingForm.phoneLabel}
              value={formValues.phone}
              setFieldValue={setFieldValue}
              validationMessage={formErrors[FieldNames.phone]}
              shouldScroll={firstError === FieldNames.phone}
            />
          </div>
        )}
      </Section>
      {!useExistingPaymentMethod && (
        <Section
          title={common.paymentDetailsTitle}
          isOpen={isFormOpen}
          sectionRef={sectionRef}
        >
          {planType === PlanType.Card && (
            <CreditCardForm
              paymentDetailForm={paymentDetailForm}
              cardFormIsVisible={cardFormIsVisible}
              cardDetailError={cardDetailError}
              firstError={firstError}
              tootlipContainerConst={tootlipContainerConst}
              removeInvalidCardError={removeInvalidCardError}
              isMobileView={isMobileView}
            />
          )}
          {planType === PlanType.DirectDebit && (
            <DirectDebitForm
              directDebitForm={directDebitForm}
              firstError={firstError}
              formErrors={formErrors}
              setFieldValue={setFieldValue}
              isSubmitted={isSubmitted}
              tootlipContainerConst={tootlipContainerConst}
              formValues={formValues}
              removeInvalidCardError={removeInvalidCardError}
            />
          )}
          {planType === PlanType.Sepa && (
            <SepaForm
              directDebitForm={directDebitForm}
              firstError={firstError}
              formErrors={formErrors}
              setFieldValue={setFieldValue}
              isSubmitted={isSubmitted}
              tootlipContainerConst={tootlipContainerConst}
              formValues={formValues}
              removeInvalidCardError={removeInvalidCardError}
            />
          )}
          {isDirectDebit ? (
            <DirectDebitGuaranteeBlock planType={planType} />
          ) : (
            <p
              className={`${securityTextCommonStyles} p-[15px] mb-[15px] bg-color-neutral-three rounded-[10px]`}
              dangerouslySetInnerHTML={{
                __html: common.securityDescription,
              }}
            />
          )}

          <p
            className={`${securityTextCommonStyles} p-[15px] bg-color-neutral-three rounded-[10px]`}
            dangerouslySetInnerHTML={{
              __html: isDirectDebit
                ? directDebit.termsAndConditions
                : common.termsAndConditions,
            }}
          />
        </Section>
      )}
    </form>
  );
};

export default BillingAndPaymentForm;
