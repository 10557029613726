import { CSSProperties } from "react";
import {
  ControlProps,
  CSSObjectWithLabel,
  StylesConfig,
  OptionProps,
} from "react-select";
import { IOption } from "../FormikInputs/SelectFormik/SelectFormik";

export const customStyles: StylesConfig<IOption, false> = {
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: 300,
    borderRadius: 0,
    marginBottom: 0,
    marginTop: 0,
    border: "1px solid $color-neutral-two",
    zIndex: "100",
  }),
  option: (provided: CSSObjectWithLabel, state: OptionProps<{}>) => ({
    ...provided,
    ["&:active"]: {
      backgroundColor: "#fff",
    },
    background: state.isFocused ? "#fff" : "#f5f4f9",
    color: "#1c1c1c",
    height: "50px",
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: `$font-primary`,
  }),
  control: (provided: CSSProperties, state: ControlProps<{}>) => {
    return {
      ...provided,
      ["&:hover"]: null,
      boxShadow: "none",
      borderStyle: "none",
      borderRadius: 0,
      border: "none",
      background: "transparent",
      color: "#1c1c1c",
      height: "40px",
      cursor: "pointer",
      fontFamily: `$font-primary`,
      marginTop: "5px",
      borderRight: "1px solid #DADADA",
      outline: state.isFocused ? "2px solid #476cb5" : "none",
    };
  },
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: "#1c1c1c",
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
    height: "100%",
  }),
  singleValue: (provided: CSSObjectWithLabel) => {
    return {
      ...provided,
      // ...dot(optionCode),
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      margin: 0,
      padding: 0,
    };
  },
};
