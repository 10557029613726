import React, { FC, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

interface Props {
  querySelector?: string;
}

const Portal: FC<Props> = ({ children, querySelector = "body" }) => {
  const portalElement = useRef(document.createElement("div"));

  useEffect(() => {
    const portalContainer = document.querySelector(querySelector);

    portalContainer?.appendChild(portalElement.current);

    return () => {
      portalContainer?.removeChild(portalElement.current);
    };
  }, []);

  return createPortal(children, portalElement.current);
};

export default Portal;
