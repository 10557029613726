import { useQuery } from "react-query";
import _map from "lodash/map";
import {
  FETCH_COUNTRIES,
  LocationService,
  FacetService,
  FacetType,
  IFacet,
  ICountry,
} from "shared-services";
import { config } from "../../../config";
import { LocalizationUtils } from "../../../index";

type CountryFacet = { label: string; value: string; name: string };

const locationService = new LocationService({ url: config.locationsApiUrl });
const facetService = new FacetService({ url: config.profileApiRoot });

const locale = LocalizationUtils.getUserLocalizationPreference().locale;

const facetsEUCountriesQueryFn = async () =>
  facetService
    .getFacets({
      category: FacetType.EurozoneCountries,
    })
    .then((response) => {
      const facetOptions = response[0].facetGroups[0].facets;

      return facetOptions;
    });

export const useGetCountriesOptions = ({
  shouldIncludeStates = false,
  shouldGetOnlyEUCountries = false,
} = {}) => {
  const queryKey = shouldGetOnlyEUCountries
    ? "fetch_facet_options"
    : FETCH_COUNTRIES;
  const { data, isLoading } = useQuery(
    [queryKey, locale],
    async () => {
      if (shouldGetOnlyEUCountries) {
        return await facetsEUCountriesQueryFn();
      }
      return await locationService.getCountriesAndStates(
        locale,
        shouldIncludeStates
      );
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const countries = _map(
    data || [],
    (country): CountryFacet => ({
      name: shouldGetOnlyEUCountries
        ? (country as IFacet).name
        : (country as ICountry).localisedCountryName,
      label: shouldGetOnlyEUCountries
        ? (country as IFacet).name
        : (country as ICountry).localisedCountryName,
      value: shouldGetOnlyEUCountries
        ? (country as IFacet).code
        : (country as ICountry).countryCode,
    })
  );

  return { countries, isLoading };
};
