import React, { FC } from "react";
import {
  ERROR,
  InputWrapper,
} from "../../../../Atoms/InputsCommon/InputWrapper";
import { InputLabel } from "../../../../Atoms/InputsCommon/InputLabel";
import "./styles.scss";

export interface CardElementWrapperProps {
  label: string;
  className?: string;
  validationMessage?: string;
  annex?: JSX.Element;
}

const CardElementWrapper: FC<CardElementWrapperProps> = ({
  label,
  className,
  validationMessage,
  annex,
  children,
}) => {
  return (
    <InputWrapper
      className={className}
      validationMesssage={validationMessage}
      invalid={ERROR}
    >
      <InputLabel label={label} annex={annex} />
      {children}
    </InputWrapper>
  );
};

export default CardElementWrapper;
