import React from "react";
import { Tooltip, ITooltipProps } from "../../Molecules/Tooltip/Tooltip";

import "./Helper.scss";

const SMALL = "small";
const TINY = "tiny";
const MEDIUM = "medium";

export interface IHelperProps {
  /**
   * Tooltip configuration
   */
  tooltip?: ITooltipProps;
  /**
   * Link to information
   */
  link?: {
    text: string;
    url: string;
  };
  /**
   * Size mode for the trigger icon
   */
  triggerSize?: typeof SMALL | typeof MEDIUM | typeof TINY; // add large etc. if ever needed
  /** Optional css class for the topmost div */
  className?: string;
  /**
   * Click event for non url helper links
   */
  click?: () => void;
}

export class Helper extends React.Component<IHelperProps> {
  public static defaultProps = {
    triggerSize: SMALL,
  };

  public render() {
    const theme = this.props.link
      ? "c-helper__trigger-white"
      : "c-helper__trigger-red";
    let trigger = (
      <div
        className={`c-helper__trigger ${theme} c-helper__trigger-${this.props.triggerSize}`}
      >
        ?
      </div>
    );

    const linkAdditionalProps = {
      ...(this.props.click
        ? {
            onClick: (event: React.MouseEvent | React.KeyboardEvent) => {
              event.preventDefault();
              if (this.props.click) {
                this.props.click();
              }
            },
            onKeyDown: (event: React.KeyboardEvent) => {
              if (this.props.click && event.key === "Enter") {
                this.props.click();
              }
            },
            target: null,
            href: null,
          }
        : {}),
    };

    if (this.props.tooltip && !this.props.link && !this.props.click) {
      trigger = <Tooltip {...this.props.tooltip}>{trigger}</Tooltip>;
    }

    return (
      <div
        className={`c-helper${
          this.props.className ? " " + this.props.className : ""
        }`}
      >
        {trigger}
        {this.props.link ? (
          <a
            tabIndex={0}
            // @ts-ignore
            target="_blank"
            rel="noopener noreferrer"
            className="c-helper__link"
            // @ts-ignore
            href={this.props.link.url}
            {...linkAdditionalProps}
          >
            {this.props.link.text}
          </a>
        ) : null}
      </div>
    );
  }
}
