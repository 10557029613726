import React from "react";
import cx from "classnames";
import { ReactComponent as FilmmakersLogo } from "component-library/src/images/svg/filmmakers-logo-white.svg";
import "./NameBar.scss";

export enum LogoImage {
  Spotlight = "spotlight",
  Filmmakers = "filmmakers",
}

export interface INameBarProps {
  /**
   * User's full name
   */
  fullName: string;
  /**
   * Text displaying the user type
   */
  userType: string;
  /**
   * URL of the clickable link containing the full name
   */
  linkUrl: string;
  /** Optional css class for the topmost div */
  className?: string;
  /** Show Edit button */
  showEditButton?: boolean;
  /** Section for adding ActionList for actions */
  actionSection?: JSX.Element | JSX.Element[];
  /** Section for an ActionList below the fullName and userType */
  secondaryActionSection?: JSX.Element | JSX.Element[];
  /** Handler for Edit button click event */
  onEditButtonClick?: () => void;
  /** Optional Edit button label for screen readers */
  editButtonAriaLabel?: string;
  /** Logo. By default spotlight logo */
  logo?: LogoImage | null;
}

const NameBar: React.FC<INameBarProps> = ({
  className: classNameWrapper,
  actionSection,
  secondaryActionSection,
  linkUrl,
  fullName,
  userType,
  logo,
  showEditButton = false,
  editButtonAriaLabel,
  onEditButtonClick,
}) => {
  const logoClasses = `c-name-bar__logo c-name-bar__logo--${logo}`;

  const renderEditButton = () => {
    if (!showEditButton) {
      return null;
    }

    return (
      <a
        role="button"
        aria-label={editButtonAriaLabel}
        tabIndex={0}
        className="c-name-bar__edit-button"
        onClick={onEditButtonClick}
      >
        <span className="icon-edit" />
      </a>
    );
  };

  const renderActionSection = () => {
    if (!actionSection) {
      return null;
    }

    return (
      <div className="c-name-bar__actions g-col-lg-4">{actionSection}</div>
    );
  };

  const renderSecondaryActionSection = () => {
    if (!secondaryActionSection) {
      return null;
    }

    return (
      <div
        className={`${
          userType ? "c-name-bar__secondary-action-with-margin" : ""
        }`}
      >
        {secondaryActionSection}
      </div>
    );
  };

  const renderLogo = () => {
    if (!logo) {
      return null;
    }

    return logo === LogoImage.Filmmakers ? (
      <FilmmakersLogo className={logoClasses} />
    ) : (
      <div className={logoClasses} />
    );
  };

  return (
    <div
      className={cx("c-name-bar", classNameWrapper, {
        "c-name-bar--with-action-section": actionSection,
      })}
    >
      <div className="c-name-bar__container g-content-area">
        <div
          className={`c-name-bar__user-container g-col-lg-${
            actionSection ? "8" : "10"
          }`}
        >
          <div className="c-name-bar__name-container">
            <a href={linkUrl} className="c-name-bar__full-name" role="heading">
              {fullName}
            </a>
            {renderEditButton()}
          </div>
          <div className="c-name-bar__user-type">{userType}</div>
          {renderSecondaryActionSection()}
        </div>
        {renderActionSection()}
        {renderLogo()}
      </div>
    </div>
  );
};

export default NameBar;
