import axios, { AxiosRequestConfig, CancelTokenSource } from "axios";
import { authAxios } from "shared-auth";
import { MediaType } from "shared-types";
import {
  IEmptyValueApiResponse,
  IETagInfo,
  IFilePartInfo,
  IFileUpload,
  IFileUploadCompleteRequest,
  IFileUploadModel,
  IFileUploadRequest,
  IFileUploadResponse,
} from "../models";

export interface IFileUploadService {
  getFileUploadDetails(fileUpload: IFileUpload): Promise<IFileUploadResponse>;
  completeMultipartFileUpload(
    fileData: IFileUploadModel,
    mediaType: MediaType,
    fileParts: IFilePartInfo[]
  ): Promise<IEmptyValueApiResponse>;
  completeFileUpload(
    fileData: IFileUploadModel,
    mediaType: MediaType
  ): Promise<IEmptyValueApiResponse>;
  putFile(
    url: string,
    fileUpload: IFileUpload,
    uploadInfo: IFileUploadModel,
    progressHandler?: (percentComplete: number) => void
  ): Promise<any>;
  startMultipartFileUpload(
    file: File,
    uploadInfo: IFileUploadModel,
    progressHandler?: (
      partNumber: number,
      loaded: number,
      total: number
    ) => void
  ): Promise<IFilePartInfo[]>;
  doMultipartFileUploadAsync(
    fileUpload: IFileUpload,
    uploadInfo: IFileUploadModel,
    progressHandler?: (
      partNumber: number,
      loaded: number,
      total: number
    ) => void
  ): Promise<IFilePartInfo[]>;
  renameFile(id: string, name: string): Promise<void>;
}

export class TitaniumUploadService implements IFileUploadService {
  private readonly titaniumEndpoint: string;
  private cancelTokenSource: CancelTokenSource | null | undefined;
  constructor(apiEndpoint: string) {
    this.titaniumEndpoint = apiEndpoint;
  }

  public async getFileUploadDetails(
    fileUpload: IFileUpload
  ): Promise<IFileUploadResponse> {
    const postModel: IFileUploadRequest = {
      name: fileUpload.file.name,
      mimeType: fileUpload.mimeType,
      size: fileUpload.file.size,
    };
    const url = this.processQuery(
      `${this.titaniumEndpoint}/upload/`,
      postModel
    );

    const result = await authAxios(url, {
      method: "GET",
    });

    return result.data;
  }

  public async completeMultipartFileUpload(
    fileData: IFileUploadModel,
    mediaType: MediaType,
    fileParts: IFilePartInfo[]
  ): Promise<IEmptyValueApiResponse> {
    const completeModel: IFileUploadCompleteRequest =
      this.mapUploadModelToCompleteRequest(fileData, mediaType, fileParts);

    return this.postFileUploadComplete(completeModel);
  }

  public async completeFileUpload(
    fileData: IFileUploadModel,
    mediaType: MediaType
  ): Promise<IEmptyValueApiResponse> {
    const completeModel = this.mapUploadModelToCompleteRequest(
      fileData,
      mediaType
    );
    return this.postFileUploadComplete(completeModel);
  }

  public async putFile(
    url: string,
    fileUpload: IFileUpload,
    uploadInfo: IFileUploadModel,
    progressHandler?: (percentComplete: number) => void
  ): Promise<any> {
    if (this.cancelTokenSource == null) {
      this.cancelTokenSource = axios.CancelToken.source();
    }

    const options = {
      headers: {
        "Content-Type": fileUpload.mimeType,
      },
      timeout: 60 * 30 * 1000,
      cancelToken: this.cancelTokenSource.token,
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (progressHandler != null) {
          progressHandler(percentCompleted);
        }
      },
    };
    return axios.put(url, fileUpload.file, options);
  }

  public async startMultipartFileUpload(
    file: File,
    uploadInfo: IFileUploadModel,
    progressHandler?: (
      partNumber: number,
      loaded: number,
      total: number
    ) => void
  ): Promise<IFilePartInfo[]> {
    const fpResults: IFilePartInfo[] = [];

    for (const pi of uploadInfo.partInfo) {
      const start = (pi.partNumber - 1) * uploadInfo.chunkSize;
      const end = start + uploadInfo.chunkSize;
      const blob = end < file.size ? file.slice(start, end) : file.slice(start);

      await this.putFilePart(
        blob,
        uploadInfo.mimeType,
        pi.partUploadUri,
        pi.partNumber,
        progressHandler
      ).then((fp) => {
        fpResults.push(fp);
      });
    }

    return fpResults;
  }

  public async doMultipartFileUploadAsync(
    fileUpload: IFileUpload,
    uploadInfo: IFileUploadModel,
    progressHandler?: (
      partNumber: number,
      loaded: number,
      total: number
    ) => void
  ): Promise<IFilePartInfo[]> {
    const promiseList = uploadInfo.partInfo.map((pi) => {
      const start = (pi.partNumber - 1) * uploadInfo.chunkSize;
      const end = start + uploadInfo.chunkSize;
      const blob =
        end < fileUpload.file.size
          ? fileUpload.file.slice(start, end)
          : fileUpload.file.slice(start);

      return this.putFilePart(
        blob,
        fileUpload.mimeType,
        pi.partUploadUri,
        pi.partNumber,
        progressHandler
      );
    });

    return Promise.all(promiseList).then((p) => p);
  }

  public async renameFile(id: string, name: string): Promise<void> {
    await authAxios.put(`${this.titaniumEndpoint}/media/${id}`, {
      name,
    });
  }

  public cancelUpload(): void {
    if (this.cancelTokenSource != null) {
      this.cancelTokenSource.cancel();
      this.cancelTokenSource = null;
    }
  }

  private async putFilePart(
    filePart: Blob,
    mimeType: string,
    url: string,
    partNumber: number,
    progressHandler?: (
      partNumber: number,
      loaded: number,
      total: number
    ) => void
  ): Promise<IFilePartInfo> {
    if (this.cancelTokenSource == null) {
      this.cancelTokenSource = axios.CancelToken.source();
    }

    const options: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      cancelToken: this.cancelTokenSource.token,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        if (progressHandler != null) {
          progressHandler(
            partNumber,
            progressEvent.loaded,
            progressEvent.total
          );
        }
      },
    };
    return await axios.put(url, filePart, options).then((resp) => {
      const etag: string = resp.headers.etag.replace(/"/g, "");
      const filePartInfo: IFilePartInfo = {
        Index: partNumber,
        ETag: etag,
      };
      return filePartInfo;
    });
  }

  private async postFileUploadComplete(
    completeRequest: IFileUploadCompleteRequest
  ): Promise<IEmptyValueApiResponse> {
    const url = `${this.titaniumEndpoint}/upload/`;

    if (this.cancelTokenSource == null) {
      this.cancelTokenSource = axios.CancelToken.source();
    }

    const { data } = await authAxios(url, {
      method: "POST",
      data: completeRequest,
    });

    return data;
  }

  private processQuery(url: string, data: any): string {
    if (data) {
      return `${url}?${new URLSearchParams(data).toString()}`;
    }
    return url;
  }

  private mapUploadModelToCompleteRequest(
    fileData: IFileUploadModel,
    mediaType: MediaType,
    fileParts?: IFilePartInfo[]
  ): IFileUploadCompleteRequest {
    return {
      originalFileName: fileData.name,
      mimeType: fileData.mimeType,
      size: fileData.size,
      s3ObjectKey: fileData.s3ObjectKey,
      isMultipartUpload: fileData.isMultipartUpload,
      multipartUploadId: fileData.multipartUploadId,
      mediaType,
      ...(fileParts
        ? { partETags: this.mapFilePartsToEtagModel(fileParts) }
        : {}),
    };
  }

  private mapFilePartsToEtagModel(fileParts: IFilePartInfo[]): IETagInfo[] {
    return fileParts.map(({ Index, ETag }) => ({
      partNumber: Index,
      eTag: ETag,
    }));
  }
}
