// @ts-nocheck
import ReactDOM from "react-dom";
import React from "react";
import moment from "moment";
import cx from "classnames";
import Calendar, { Detail, CalendarProps } from "react-calendar";

import {
  addClass,
  removeClass,
  getWindowWidth,
} from "../../../generics/dom-extensions";

import {
  DEFAULT_LOCALE,
  getTranslation,
} from "../../../translations/translationManager";

import { Radio } from "../../Atoms/Radio/Radio";
import { ValidationMessage } from "../../Atoms/InputsCommon/ValidationMessage";
import { InputCleaner } from "../../Atoms/InputsCommon/InputCleaner";
import { ERROR } from "../../Atoms/InputsCommon/InputWrapper";
import { Input, ITextInputProps } from "../../Molecules/Input/Input";
import { Omit } from "../../../generics/type-manipulation";

import { ReactComponent as CalendarIcon } from "../../../images/svg/icons/calendar.svg";

import "./DatePickerUniversal.scss";

const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
const DATE_RANGE_SEPARATOR = " - ";
const DEFAULT_MAX_DETAIL = "month";
const DEFAULT_MIN_DATE = "01/01/1900";

export type CalendarDate = Date | Date[];

export enum DatePickerType {
  Single = "single",
  Range = "range",
}

export enum ErrorMessageLocation {
  Input = "input",
  Calendar = "calendar",
}

export enum DateValidity {
  Valid = 0,
  Invalid = 1,
  ViolatesMinMaxRange = 2,
}

interface IDateValidityInfo {
  dateValidity: DateValidity;
  date: CalendarDate | undefined;
  value?: string;
}

export interface IDatePickerUniversalProps
  extends Omit<
    ITextInputProps,
    "value" | "type" | "valueChanged" | "onChange"
  > {
  /***
   * Optional: Allow user input in the text field
   */
  allowTextInput?: boolean;
  /**
   * Optional: Any additional props for the underlying Calendar component
   */
  calendarProps?: CalendarProps;
  /**
   * Optional: date format for displaying date values. Default: 'DD/MM/YYYY'
   */
  dateFormat?: string;
  /**
   * Optional: Location of the validation error message. Default: Calendar
   */
  errorMessageLocation?: ErrorMessageLocation;
  /**
   * Optional: Message to be displayed for the invalid date provided
   */
  invalidDateMessage?: string;
  /**
   * Optional: Initial visibility of the Calendar component
   */
  isCalendarOpen?: boolean;
  /**
   * Optional: Locale for the Calendar component
   */
  locale?: string;
  /**
   * Optional: Visibility of the Details panel
   */
  showRangePanel?: boolean;
  /**
   * Single type allows selecting one exact date.
   * Range type allows selecting two dates (range).
   */
  type: DatePickerType;
  /**
   *  Optional: Initial value
   */
  value?: CalendarDate;
  /**
   * Event triggered after date is selected
   */
  valueChanged?: (
    date?: CalendarDate,
    detailsLevel?: Detail,
    validity?: DateValidity
  ) => void;
  /**
   * Max detail of the date picker
   */
  maxDetail?: Detail;
  /**
   * Min detail of the date picker
   */
  minDetail?: Detail;
  /**
   * Minimum allowed date to be specified
   */
  minDate?: Date;
  /**
   * Error message when date is less than allowed
   */
  minDateErrorMessage?: string;
  /**
   * Optional: handler for the calendar closing event
   */
  onClose?: () => void;
  /**
   * Optional: do not fire change event if value was changed outside the component
   */
  muteExternalValueChange?: boolean;
}

export const DATEPICKER_GENERIC_TILE_TEST_ID_CLASS =
  "datepicker-universal-tile-test-id";

export interface IDatePickerUniversalState {
  isCalendarOpen: boolean;
  selectedDate?: CalendarDate;
  selectedDateString?: string;
  selectedMaxDetail: Detail;
  dateValidity: DateValidity;
  defaultValue?: CalendarDate;
  defaultDetail?: Detail;
  type: DatePickerType;
}

export class DatePickerUniversal extends React.Component<
  IDatePickerUniversalProps,
  IDatePickerUniversalState
> {
  private datePickerRef = React.createRef<HTMLDivElement>();
  private datePickerPositionerRef = React.createRef<HTMLDivElement>();
  private inputRef = React.createRef<Input>();

  private get currentLocalization() {
    return getTranslation(this.props.locale).datePickerUniversal;
  }

  public readonly state: Readonly<IDatePickerUniversalState> = {
    isCalendarOpen: Boolean(this.props.isCalendarOpen),
    selectedDate: this.props.value,
    selectedDateString: DatePickerUniversal.getStringValue(this.props.value),
    dateValidity: DateValidity.Valid,
    selectedMaxDetail: this.props.maxDetail || DEFAULT_MAX_DETAIL,
    defaultValue: this.props.value,
    defaultDetail: this.props.maxDetail,
    type: this.props.type,
  };

  public static defaultProps: Partial<IDatePickerUniversalProps> = {
    isCalendarOpen: false,
    errorMessageLocation: ErrorMessageLocation.Calendar,
    allowTextInput: true,
    dateFormat: DEFAULT_DATE_FORMAT,
    locale: DEFAULT_LOCALE,
    maxDetail: DEFAULT_MAX_DETAIL,
    minDate: moment(DEFAULT_MIN_DATE, DEFAULT_DATE_FORMAT).toDate(),
  };

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  public componentDidMount() {
    if (this.props.isCalendarOpen) {
      document.addEventListener("click", this.handleOutsideClick);
      this.setDatePickerPosition();
    }
  }

  public componentDidUpdate(
    prevProps: IDatePickerUniversalProps,
    prevState: IDatePickerUniversalState
  ) {
    if (prevState.isCalendarOpen !== this.state.isCalendarOpen) {
      if (this.state.isCalendarOpen) {
        this.setDatePickerPosition();
      } else {
        document.removeEventListener("click", this.handleOutsideClick);
      }
    }

    const propTypeChanged = prevState.type !== this.props.type;
    const detailChanged =
      prevState.selectedMaxDetail !== this.state.selectedMaxDetail;
    const defaultValueChanged = this.props.muteExternalValueChange
      ? false
      : prevState.defaultValue !== this.state.defaultValue;

    if (propTypeChanged || detailChanged || defaultValueChanged) {
      this.fireChange(this.state.selectedDate);
    }
  }

  public static getDerivedStateFromProps(
    nextProps: IDatePickerUniversalProps,
    prevState: IDatePickerUniversalState
  ) {
    let nextState = {} as IDatePickerUniversalState;
    let date = prevState.selectedDate;

    if (
      prevState.defaultValue !== nextProps.value ||
      prevState.type !== nextProps.type ||
      prevState.defaultDetail !== nextProps.maxDetail
    ) {
      nextState = {
        selectedDate: nextProps.value,
        selectedDateString: DatePickerUniversal.getStringValue(
          nextProps.value,
          nextProps.dateFormat
        ),
        dateValidity: DatePickerUniversal.getValidityForMinDate(
          nextProps.value,
          nextProps.minDate
        ),
        isCalendarOpen: prevState.isCalendarOpen,
        selectedMaxDetail: nextProps.maxDetail || DEFAULT_MAX_DETAIL,
        type: nextProps.type,
        defaultValue: nextProps.value,
        defaultDetail: nextProps.maxDetail,
      };
      date = nextProps.value;
    }

    // if detail panel becomes hidden reset the selected detail to default
    if (
      !nextProps.showRangePanel &&
      prevState.selectedMaxDetail !== DEFAULT_MAX_DETAIL
    ) {
      nextState.selectedMaxDetail = DEFAULT_MAX_DETAIL;
      if (prevState.dateValidity === DateValidity.Valid && date) {
        nextState.selectedDateString = DatePickerUniversal.formatDateAsRange(
          date,
          DEFAULT_DATE_FORMAT
        );
      }
    }

    return Object.keys(nextState).length > 0 ? nextState : null;
  }

  public render() {
    const {
      allowTextInput,
      calendarProps,
      dateFormat,
      errorMessageLocation,
      invalidDateMessage,
      isCalendarOpen,
      locale,
      showRangePanel,
      type,
      value,
      valueChanged,
      minDateErrorMessage,
      maxDetail,
      minDetail,
      minDate,
      muteExternalValueChange,
      ...inputProps
    } = this.props;

    const datePickerClassName = cx(
      "c-datepicker-universal",
      this.props.className,
      {
        "c-datepicker-universal--disabled": this.props.disabled,
        "c-datepicker-universal--input-validation":
          this.props.errorMessageLocation === ErrorMessageLocation.Input,
      }
    );

    const inputClassName = cx("c-datepicker-universal__input__field", {
      "c-datepicker-universal__input__field--selected":
        this.state.selectedDateString,
    });

    const calendarIconClassName = cx("c-datepicker-universal__input__toggle", {
      "c-datepicker-universal__input__toggle--disabled": this.props.disabled,
    });

    return (
      <div
        className={datePickerClassName}
        onKeyDown={this.handleComponentKeyDown}
        ref={this.inputRef}
      >
        <div className="c-datepicker-universal__input">
          <Input
            {...inputProps}
            type="text"
            className={inputClassName}
            onChange={this.handleInputChange}
            onClick={this.handleInputClick}
            onKeyDown={this.handleInputKeydown}
            disabled={this.props.disabled}
            onBlur={this.handleInputBlur}
            value={this.state.selectedDateString}
            placeholder={this.props.placeholder}
            invalid={inputProps.invalid || !this.isDateValid()}
            readOnly={!this.props.allowTextInput}
            validationMessage={this.getValidationMessage()}
            onFocus={this.handleFocus}
          >
            {this.state.selectedDateString && !this.props.disabled ? (
              <InputCleaner onClick={this.cleanField} />
            ) : null}
          </Input>
          <a
            className={calendarIconClassName}
            href="#"
            tabIndex={-1}
            onClick={this.handleInputClick}
            aria-label="Open calendar"
          >
            <CalendarIcon />
          </a>
        </div>
        {this.state.isCalendarOpen && this.renderCalendar()}
      </div>
    );
  }

  private renderCalendar = () => {
    return (
      <div
        ref={this.datePickerPositionerRef}
        className="c-datepicker-universal__calendar-positioner"
      >
        <div className="c-datepicker-universal__calendar-arrow" />
        <div
          className="c-datepicker-universal__calendar-wrapper g-bg-secondary"
          ref={this.datePickerRef}
          onBlur={this.handleCalendarBlur}
        >
          {!this.isDateValid() &&
            this.props.errorMessageLocation ===
              ErrorMessageLocation.Calendar && (
              <div className="c-datepicker-universal__error-message">
                <ValidationMessage
                  message={this.getDetailedErrorMessage()}
                  type={ERROR}
                />
              </div>
            )}
          {this.props.showRangePanel &&
            !this.isRangeType() &&
            this.renderDetailSelector()}
          <Calendar
            {...this.props.calendarProps}
            minDate={this.props.minDate}
            className={"c-datepicker-universal__calendar"}
            onChange={this.handleCalendarChange}
            onDrillDown={this.handleDrillChange}
            onDrillUp={this.handleDrillChange}
            locale={this.props.locale}
            value={this.getCalendarValue()}
            maxDetail={this.state.selectedMaxDetail}
            minDetail={this.props.minDetail || this.state.selectedMaxDetail}
            prev2Label={null}
            next2Label={null}
            prevLabel={
              <div className="icon-chevron" aria-label="Previous month" />
            }
            nextLabel={
              <div className="icon-chevronright" aria-label="Next month" />
            }
            selectRange={this.isRangeType()}
            onClickDay={this.handleClickDay}
            tileClassName={DATEPICKER_GENERIC_TILE_TEST_ID_CLASS}
          />
        </div>
      </div>
    );
  };

  private renderDetailSelector = () => {
    const renderRadio = (label: string, detail: Detail) => (
      <div className="c-datepicker-universal__calendar-mode">
        <Radio
          checked={this.state.selectedMaxDetail === detail}
          label={label}
          onChange={this.changeDetail}
          name="c-datepicker-universal__calendar-modes"
          value={detail}
        />
      </div>
    );

    return (
      <div className="c-datepicker-universal__calendar-modes">
        {renderRadio(this.currentLocalization.specificDate, "month")}
        {renderRadio(this.currentLocalization.byMonth, "year")}
        {renderRadio(this.currentLocalization.byYear, "decade")}
      </div>
    );
  };

  private handleFocus = (event: React.FormEvent<HTMLInputElement>) =>
    this.showCalendar();

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value;
    this.setState({
      selectedDateString: date,
    });
  };

  private handleCalendarBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const isNewTargetOutsideDatePicker =
      event.relatedTarget &&
      this.datePickerPositionerRef.current &&
      !this.datePickerPositionerRef.current.contains(
        event.relatedTarget as Element
      );

    const canHideCalendar =
      this.props.errorMessageLocation === ErrorMessageLocation.Input ||
      (this.state.dateValidity === DateValidity.Valid && !this.isRangeType());

    if (isNewTargetOutsideDatePicker && canHideCalendar) {
      this.hideCalendar();
    }
  };

  private handleClickDay = (date: Date) => {
    if (this.isRangeType()) {
      const isDateIsEmpty = !this.state.selectedDate;
      const isFullDateRange =
        !isDateIsEmpty && (this.state.selectedDate as Date[]).length === 2;

      if (isDateIsEmpty || isFullDateRange) {
        this.setDate(DateValidity.Valid, [date], this.getFormat());
      }
    }
  };

  private handleComponentKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Escape") {
      this.keydownHandlers["Escape"](event);
    }
  };

  private handleInputClick = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    if (!this.state.isCalendarOpen) {
      this.showCalendar();
    }
  };

  private handleInputKeydown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (this.keydownHandlers[event.key]) {
      this.keydownHandlers[event.key](event);
    }
  };

  private handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    const dateValidityInfo = this.getDateValidityInfo(value);
    const { date: newDate, dateValidity } = dateValidityInfo;
    const isDateChanged = !DatePickerUniversal.isDatesEquals(
      this.state.selectedDate,
      newDate
    );

    if (dateValidity !== DateValidity.Valid || isDateChanged) {
      this.setDateByDateValidityInfo(dateValidityInfo);
    }
  };

  private isDateValid = () => this.state.dateValidity === DateValidity.Valid;

  private setDateByDateValidityInfo = (dateValidityInfo: IDateValidityInfo) => {
    this.setDate(
      dateValidityInfo.dateValidity,
      dateValidityInfo.date,
      DatePickerUniversal.getFormatByDetail(
        this.state.selectedMaxDetail,
        this.props.dateFormat
      ),
      dateValidityInfo.value
    );
  };

  private getDateValidityInfo = (value: string): IDateValidityInfo => {
    if (value === "") {
      return {
        dateValidity: DateValidity.Valid,
        date: undefined,
      };
    }
    const isRange = this.isRangeType();
    const date = isRange ? this.buildDateRange(value) : this.buildDate(value);
    const isDateValid = isRange
      ? (date as Date[]).length > 0
      : date !== undefined;

    return {
      dateValidity: isDateValid
        ? DatePickerUniversal.getValidityForMinDate(date, this.props.minDate)
        : DateValidity.Invalid,
      date,
      value: !isDateValid ? value : undefined,
    };
  };

  private static getValidityForMinDate = (
    date?: CalendarDate,
    minDate?: Date
  ): DateValidity => {
    let isValidForMinDate = false;
    if (!date || !minDate) {
      return DateValidity.Valid;
    }

    const minDateMoment = moment(minDate);
    const isValid = (value: Date | null) =>
      value ? moment(value).isSameOrAfter(minDateMoment, "day") : true;

    if (Array.isArray(date)) {
      const [from, until] = date as Date[];

      isValidForMinDate = isValid(from) && isValid(until);
    } else {
      isValidForMinDate = isValid(date);
    }

    return isValidForMinDate
      ? DateValidity.Valid
      : DateValidity.ViolatesMinMaxRange;
  };

  private handleCalendarChange = (date: CalendarDate) => {
    const format = DatePickerUniversal.getFormatByDetail(
      this.state.selectedMaxDetail,
      this.props.dateFormat
    );

    this.setDate(DateValidity.Valid, date, format);
    this.hideCalendar();
  };

  private handleDrillChange = () => {
    document.removeEventListener("click", this.handleOutsideClick);
    document.addEventListener("click", this.handleOutsideClick);
  };

  private handleOutsideClick = (event: any) => {
    const datePicker = this.datePickerRef.current;
    const input = ReactDOM.findDOMNode(this.inputRef.current);

    const isCalendarClick = datePicker && datePicker.contains(event.target);
    const isInputClick = input && input.contains(event.target);
    if (!isCalendarClick && !isInputClick) {
      if (this.isRangeType()) {
        this.selectSingleDateAsRange();
      }

      // should stay open in case of Error which displayed in the Calendar
      if (
        this.props.errorMessageLocation === ErrorMessageLocation.Input ||
        this.state.dateValidity === DateValidity.Valid
      ) {
        this.hideCalendar();
      }
    }
  };

  private static formatDateAsSingle = (
    date: Date,
    format: string = DEFAULT_DATE_FORMAT
  ) => moment(date).format(format);

  private static isDatesEquals = (
    date1?: CalendarDate,
    date2?: CalendarDate
  ): boolean => {
    let result = true;
    if (date1 || date2) {
      const isSame = (value1: Date, value2: Date) =>
        moment(value1).isSame(value2, "day");
      if (Array.isArray(date1) && Array.isArray(date2)) {
        result = isSame(date1[0], date2[0]) && isSame(date1[1], date2[1]);
      } else {
        result = isSame(date1 as Date, date2 as Date);
      }
    }
    return result;
  };

  private static getFormatByDetail = (
    detail: Detail,
    dateFormat: string = DEFAULT_DATE_FORMAT
  ) => {
    let yearFormat = "YYYY";
    let indexOfYear = dateFormat.indexOf("YYYY");
    const isShortYearFormat = indexOfYear === -1;
    if (isShortYearFormat) {
      yearFormat = "YY";
      indexOfYear = dateFormat.indexOf("YY");
    }
    switch (detail) {
      default:
      case "month":
        return dateFormat;
      case "year": {
        const indexOfMonth = dateFormat.indexOf("MM");
        const isMonthFirst = indexOfMonth < indexOfYear;
        const separator = isMonthFirst
          ? dateFormat.slice(indexOfMonth + 2, indexOfYear)
          : dateFormat.slice(
              indexOfYear + (isShortYearFormat ? 2 : 4),
              indexOfMonth
            );
        return isMonthFirst
          ? "MM" + separator + yearFormat
          : yearFormat + separator + "MM";
      }
      case "decade":
        return yearFormat;
    }
  };

  private static isRangeSingleValueProvided = (date: CalendarDate) =>
    Array.isArray(date) && date.length === 1;

  private static formatDateAsRange = (
    date: CalendarDate,
    format: string = DEFAULT_DATE_FORMAT
  ) => {
    const isOnlyFromDateProvided =
      DatePickerUniversal.isRangeSingleValueProvided(date);
    const isDatesEquals =
      !isOnlyFromDateProvided &&
      DatePickerUniversal.isDatesEquals(date[0], date[1]);
    if (isDatesEquals) {
      return DatePickerUniversal.formatDateAsSingle(date[0], format);
    }
    return isOnlyFromDateProvided
      ? moment(date[0]).format(format) + DATE_RANGE_SEPARATOR
      : moment(date[0]).format(format) +
          DATE_RANGE_SEPARATOR +
          moment(date[1]).format(format);
  };

  public static getStringValue = (
    date?: CalendarDate,
    format: string = DEFAULT_DATE_FORMAT
  ) => {
    let formattedDate = "";

    if (date) {
      if (Array.isArray(date)) {
        formattedDate = DatePickerUniversal.formatDateAsRange(date, format);
      } else {
        formattedDate = DatePickerUniversal.formatDateAsSingle(date, format);
      }
    }

    return formattedDate;
  };

  private isRangeType = () => this.props.type === "range";

  private getCalendarValue = () => {
    const { selectedDate } = this.state;
    return this.isRangeType() &&
      selectedDate &&
      DatePickerUniversal.isRangeSingleValueProvided(selectedDate)
      ? selectedDate[0]
      : selectedDate;
  };

  private cleanField = (event: React.MouseEvent) => {
    event.stopPropagation();

    const { selectedDate, selectedDateString } = this.state;
    if (
      selectedDate !== undefined ||
      selectedDateString !== "" ||
      !this.isDateValid()
    ) {
      this.setState(
        {
          selectedDate: undefined,
          selectedDateString: "",
          dateValidity: DateValidity.Valid,
        },
        this.fireChange
      );
    }
  };

  private fireChange = (date?: CalendarDate) =>
    this.props.valueChanged &&
    this.props.valueChanged(
      date,
      this.state.selectedMaxDetail,
      this.state.dateValidity
    );

  private changeDetail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const detail = event.target.value as Detail;
    this.setDate(
      this.state.dateValidity,
      this.state.selectedDate as Date,
      DatePickerUniversal.getFormatByDetail(detail, this.props.dateFormat),
      !this.isDateValid() ? undefined : this.state.selectedDateString
    );
    this.setState(
      {
        selectedMaxDetail: detail,
      },
      () => this.fireChange(this.state.selectedDate)
    );
  };

  private getValidationMessage = () => {
    let message: string | undefined = this.props.validationMessage;

    if (
      !this.isDateValid() &&
      this.props.errorMessageLocation === ErrorMessageLocation.Input
    ) {
      message = this.getDetailedErrorMessage();
    }
    return message;
  };

  private getDetailedErrorMessage = () => {
    let message: string | undefined;

    switch (this.state.dateValidity) {
      case DateValidity.ViolatesMinMaxRange:
        message =
          this.props.minDateErrorMessage ||
          `${this.currentLocalization.minDateError}${
            DatePickerUniversal.formatDateAsSingle(this.props.minDate!) ||
            DEFAULT_MIN_DATE
          }`;
        break;
      case DateValidity.Invalid:
        message =
          this.props.invalidDateMessage ||
          this.currentLocalization.invalidDateError;
        break;
    }
    return message;
  };

  private setEndDateTime = (date: Date) => date.setHours(23, 59, 59, 999);

  private buildDateRange = (value: string): Date[] => {
    const dates = value
      .split(DATE_RANGE_SEPARATOR.trim())
      .filter((item) => item);

    let result: Date[] = [];
    for (const date of dates) {
      const dateValue = this.stringToDate(date.trim());
      if (!dateValue.isValid()) {
        break;
      }
      result.push(dateValue.toDate());
    }

    if (!result.length) {
      return result;
    }

    // fill toDate the same as fromDate initially
    if (result.length === 1) {
      result.push(new Date(result[0]));
    }

    // swap dates if needed
    result = result[0] > result[1] ? result.reverse() : result;

    this.setEndDateTime(result[1]);
    return result;
  };

  private buildDate = (value: string): Date | undefined => {
    const date = this.stringToDate(value);
    if (!date.isValid()) {
      return undefined;
    }

    return date.toDate();
  };

  private stringToDate = (value: string) =>
    moment(
      value,
      DatePickerUniversal.getFormatByDetail(
        this.state.selectedMaxDetail,
        this.props.dateFormat
      )
    );

  private setDate = (
    dateValidity: DateValidity,
    date?: CalendarDate,
    format: string = DEFAULT_DATE_FORMAT,
    dateString: string = ""
  ) => {
    const selectedDateString =
      dateValidity === DateValidity.Invalid
        ? dateString
        : DatePickerUniversal.getStringValue(date, format);
    this.setState(
      {
        selectedDate: dateValidity === DateValidity.Valid ? date : undefined,
        selectedDateString,
        dateValidity,
      },
      () => this.fireChange(this.state.selectedDate)
    );
  };

  private showCalendar = () => {
    this.setState({ isCalendarOpen: true }, () => {
      document.addEventListener("click", this.handleOutsideClick);
    });
  };

  private hideCalendar = () => {
    this.setState({ isCalendarOpen: false });
    this.props.onClose && this.props.onClose();
  };

  private selectSingleDateAsRange = () => {
    const { selectedDate: date } = this.state;

    if (
      date &&
      this.isDateValid() &&
      DatePickerUniversal.isRangeSingleValueProvided(date)
    ) {
      let newDate;
      if (!Array.isArray(date)) {
        newDate = [date, new Date(date)];
      } else if (
        DatePickerUniversal.isRangeSingleValueProvided(date) ||
        DatePickerUniversal.isDatesEquals(date[0], date[1])
      ) {
        newDate = [date[0], new Date(date[0])];
      }
      this.setEndDateTime(newDate[1]);
      this.setDate(DateValidity.Valid, newDate, this.getFormat());
    }
  };

  private getFormat = () => this.props.dateFormat;

  private setDatePickerPosition = () => {
    const datePickerGrid = this.datePickerRef.current;
    const datePickerContainer = this.datePickerPositionerRef.current;

    if (datePickerGrid && datePickerContainer) {
      const containerRect = datePickerContainer.getBoundingClientRect();
      const datepickerRect = datePickerGrid.getBoundingClientRect();
      const isOutOfRightSide =
        datepickerRect.width + containerRect.left > getWindowWidth();
      const isInputWiderThanDatePicker =
        containerRect.width > datepickerRect.width;

      if (isOutOfRightSide || isInputWiderThanDatePicker) {
        addClass(
          datePickerGrid,
          "c-datepicker-universal__calendar-wrapper--align-right"
        );
      } else {
        removeClass(
          datePickerGrid,
          "c-datepicker-universal__calendar-wrapper--align-right"
        );
      }
    }
  };

  private getInputFromControl = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) =>
    event.currentTarget.tagName === "INPUT"
      ? event.currentTarget
      : event.currentTarget.querySelector("input");

  private handleEscapeKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = this.getInputFromControl(event);

    if (input) {
      const dateValidityInfo = this.getDateValidityInfo(input.value);

      if (dateValidityInfo.dateValidity === DateValidity.Valid) {
        this.hideCalendar();
      }
    }
  };

  private handleEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const dateValidityInfo = this.getDateValidityInfo(
      event.currentTarget.value
    );
    this.setDateByDateValidityInfo(dateValidityInfo);
    if (dateValidityInfo.dateValidity === DateValidity.Valid) {
      this.state.isCalendarOpen && this.hideCalendar();
    } else {
      !this.state.isCalendarOpen && this.showCalendar();
    }
  };

  private keydownHandlers = {
    Enter: this.handleEnterKey,
    Escape: this.handleEscapeKey,
    Tab: (event: React.KeyboardEvent<HTMLInputElement>) => {
      // if Calendar is opened go through it's elements
      if (!this.state.isCalendarOpen) {
        return;
      }
    },
  };

  public clear = () => {
    this.setDate(DateValidity.Valid, undefined, DEFAULT_DATE_FORMAT, "");
  };
}
