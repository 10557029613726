import axios from "axios";

import config from "config/global";
import PageKey from "config/PageKey";

import { fallbackTranslation } from "services/api/fallbackData";
import fetchWithFallback from "services/api/fetchWithFallback";

import { translationUrlMapping } from "./translationService.config";

class TranslationService {
  private url: string;

  constructor({ url }: { url: string }) {
    this.url = url;
  }

  public async getTranslation<ExpectedData>(
    {
      locale,
      pageKey = "common",
    }: { locale: string; pageKey?: PageKey | string },
    options: { throwOnError?: boolean } = {}
  ): Promise<ExpectedData> {
    try {
      // TODO: Replace with actual url
      const url = `${this.url}/umbraco/api/content/home-page/${
        translationUrlMapping[pageKey] || ""
      }?culture=${locale}`;
      const response = await fetchWithFallback(
        () => axios(url),
        fallbackTranslation[pageKey],
        options.throwOnError
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new TranslationService({
  url: config.umbracoApiUrl,
});
