import React, { FC } from "react";
import { IBillingCommon } from "translations/src/models/billing/common";
import CardNumber from "../CardNumber";
import { FieldNames } from "../../../constants";
import {
  getTootlipProps,
  QuestionTooltip,
} from "../../../../../Molecules/QuestionTooltip";
import Cvv from "../Cvv";
import CardExpDate from "../CardExpDate";
import { BillingAndPaymentFormProps } from "../../../types";
import SecurityBlock from "./SecurityBlock";

type Props = {
  firstError: string;
  paymentDetailForm: IBillingCommon["paymentDetailForm"];
} & Pick<
  BillingAndPaymentFormProps,
  | "isMobileView"
  | "cardFormIsVisible"
  | "cardDetailError"
  | "removeInvalidCardError"
  | "tootlipContainerConst"
>;

const CreditCardForm: FC<Props> = ({
  isMobileView,
  cardFormIsVisible,
  paymentDetailForm,
  firstError,
  cardDetailError,
  tootlipContainerConst,
  removeInvalidCardError,
}) => {
  const shouldScrollCardExpDate = firstError === FieldNames.date;

  return (
    <div>
      {cardFormIsVisible && (
        <>
          <div className={isMobileView ? "" : "flex gap-[22px]"}>
            <CardNumber
              label={paymentDetailForm.cardDetailTitle}
              placeholder={paymentDetailForm.cardDetailPlaceholder}
              className="flex-[2]"
              validationMessage={cardDetailError[FieldNames.number]}
              shouldScroll={firstError === FieldNames.number}
              annex={
                <QuestionTooltip
                  tooltipProps={{
                    ...getTootlipProps(
                      paymentDetailForm.expDateTooltipHeader,
                      paymentDetailForm.expDateTooltipBody
                    ),
                    overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
                  }}
                />
              }
              removeError={removeInvalidCardError}
            />
            {!isMobileView && (
              <CardExpDate
                label={paymentDetailForm.expDateTitle}
                placeholderYear={paymentDetailForm.expDateYearPlaceholder}
                placeholderMonth={paymentDetailForm.expDateMonthPlaceholder}
                className="flex-[2] lg:flex-1"
                validationMessage={cardDetailError[FieldNames.date]}
                shouldScroll={shouldScrollCardExpDate}
                removeError={removeInvalidCardError}
                isMobileView={isMobileView}
              />
            )}
          </div>
          <div
            className={
              isMobileView ? "flex gap-5" : "flex gap-[25px] items-center"
            }
          >
            {isMobileView && (
              <CardExpDate
                label={paymentDetailForm.expDateTitle}
                placeholderYear={paymentDetailForm.expDateYearPlaceholder}
                placeholderMonth={paymentDetailForm.expDateMonthPlaceholder}
                className="flex-1"
                validationMessage={cardDetailError[FieldNames.date]}
                shouldScroll={shouldScrollCardExpDate}
                removeError={removeInvalidCardError}
                isMobileView={isMobileView}
              />
            )}
            <Cvv
              label={paymentDetailForm.cvvTitle}
              annex={
                <QuestionTooltip
                  tooltipProps={{
                    ...getTootlipProps(
                      paymentDetailForm.cvvTooltipHeader,
                      paymentDetailForm.cvvTooltipBody
                    ),
                    overflowRestrictingContainerSelector: `#${tootlipContainerConst}`,
                  }}
                />
              }
              validationMessage={cardDetailError[FieldNames.cvv]}
              shouldScroll={firstError === FieldNames.cvv}
              removeError={removeInvalidCardError}
            />
            {!isMobileView && (
              <SecurityBlock
                securityTitle={paymentDetailForm.securityTitle}
                isMobileView={isMobileView}
              />
            )}
          </div>
          {isMobileView && (
            <SecurityBlock
              securityTitle={paymentDetailForm.securityTitle}
              isMobileView={isMobileView}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CreditCardForm;
