import { ErrorCode, ErrorPopupType } from 'config/common';

export const errorCodeToErrorPopupTypeMapping = {
  [ErrorCode.Unauthorized]: ErrorPopupType.Account,
  [ErrorCode.Forbidden]: ErrorPopupType.Access,
  [ErrorCode.NotFound]: ErrorPopupType.System,
  [ErrorCode.InternalServerError]: ErrorPopupType.System,
};
export default function getErrorPopupType(errorCode?: ErrorCode): ErrorPopupType {
  if (!window.navigator.onLine) {
    return ErrorPopupType.Network;
  }

  return (errorCode && errorCodeToErrorPopupTypeMapping[errorCode]) || ErrorPopupType.System;
}
