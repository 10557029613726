import { object, string } from "yup";
import { validatePhoneNumber } from "component-library";
import { TFunction } from "i18n";
import { FieldNames } from "pages/AccountSettings/MyDetails/constants";

export function validationSchema(t: TFunction) {
  return object().shape({
    [FieldNames.phone]: string()
      .required(
        t("common:validation.required", {
          field: t("common:label.mobileNumber"),
        })
      )
      .test(
        "validatePhone",
        t("common:validation.notValid", {
          field: t("common:label.mobileNumber"),
        }),
        (val) => validatePhoneNumber("mobile", val)
      ),
    [FieldNames.street1]: string().required(
      t("common:validation.required", {
        field: t("common:label.address1"),
      })
    ),
    [FieldNames.street2]: string(),
    [FieldNames.city]: string().required(
      t("common:validation.required", {
        field: t("common:label.city"),
      })
    ),
    [FieldNames.postalCode]: string().required(
      t("common:validation.required", {
        field: t("common:label.zipPostCode"),
      })
    ),
    [FieldNames.country]: string().required(
      t("common:validation.required", {
        field: t("common:label.country"),
      })
    ),
    [FieldNames.region]: string(),
  });
}
