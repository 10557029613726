import React from "react";
import { dataTestIds } from "data-testids";
import "./InputCleaner.scss";

export interface IInputCleanerProps {
  /** Optional handler to hook into the click event. */
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

export const InputCleaner: React.FC<IInputCleanerProps> = ({ onClick }) => {
  return (
    <div className="c-cleaner__container">
      <div
        onClick={onClick}
        className="c-cleaner icon-cross-circle"
        data-testid={
          dataTestIds.componentLibrary["Atoms.InputsCommon.inputCleaner"]
        }
      />
    </div>
  );
};
