export const removeLeadingZero = (number: string) => {
  if (number.startsWith("0")) {
    return number.substring(1);
  }
  return number;
};

const validatePhoneNumber = (type: "landline" | "mobile", val?: string) => {
  if (!val) return false;

  const isMobile = type === "mobile";
  const [countryCode, num] = val.split(" ");

  if (!num || !countryCode) {
    // landlines are not mandatory
    return !isMobile;
  }

  const number = removeLeadingZero(num);
  const length = number.length;

  switch (countryCode) {
    // UK
    case "+44": {
      if (isMobile) {
        return length === 10;
      } else {
        return length >= 7 && length <= 10;
      }
    }
    // US/Canada
    case "+1": {
      return length === 10;
    }
    // Ireland
    case "+353": {
      if (isMobile) {
        return length === 9;
      } else {
        return length >= 7 && length <= 11;
      }
    }
    default: {
      return true;
    }
  }
};

export default validatePhoneNumber;
