import React from "react";
import DOMPurify from "dompurify";
import cx from "classnames";

import "./Autocomplete.scss";

export interface IAutocompleteItem {
  name: string;
  data: any;
}

export interface IAutocompleteProps {
  /**
   * Display dropdown list
   */
  display: boolean;
  /**
   * List of elements
   */
  list: IAutocompleteItem[];
  /**
   * Callback function if item was selected
   */
  selectItem: (item: IAutocompleteItem) => void;
  /**
   * Defines function which will format name
   */
  formatName?: (item: IAutocompleteItem) => string;
  /**
   * Callback function if calling hide autocomplete internally
   */
  onHideAutocomplete?: () => void;
  /**
   * Render function to add tail to the items list
   */
  renderTail?: () => React.ReactNode;
}

export interface IAutocompleteState {
  display: boolean;
}

export class Autocomplete extends React.Component<
  IAutocompleteProps,
  IAutocompleteState
> {
  private autocompleteRef = React.createRef<HTMLDivElement>();

  constructor(props: Readonly<IAutocompleteProps>) {
    super(props);

    this.state = {
      display: props.display,
    };

    document.addEventListener("click", this.handleOutsideClick);
  }

  public render() {
    const containerStyles = cx("c-autocomplete", {
      "c-autocomplete__hidden": !this.state.display,
    });

    return (
      <div ref={this.autocompleteRef} className={containerStyles}>
        {this.renderList(this.props.list)}
      </div>
    );
  }

  public componentWillUpdate({ display }: IAutocompleteProps) {
    if (this.state.display !== display) {
      this.setState({ display });

      if (display) {
        document.addEventListener("click", this.handleOutsideClick);
      }
    }
  }

  public componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  private renderList = (list: IAutocompleteItem[]) => {
    const { formatName, selectItem, renderTail } = this.props;
    const nameFormatting = formatName || this.defaultNameFormatting;
    const additionalAttrs: any = {};

    if (this.state.display) {
      additionalAttrs.tabIndex = 0;
    }

    const listToRender = Array.from(list).map<React.ReactNode>(
      (element, index) => (
        <div
          key={element.name + index}
          {...additionalAttrs}
          className="c-autocomplete__item"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(nameFormatting(element)),
          }}
          onClick={selectItem.bind(this, element)}
          onKeyPress={this.handleKeyPress.bind(this, element)}
        />
      )
    );

    renderTail && listToRender.push(renderTail());

    return listToRender;
  };

  private defaultNameFormatting = (item: IAutocompleteItem) => item.name;

  private handleKeyPress = (item: IAutocompleteItem, { key }) => {
    if (key === " " || key === "Enter") {
      this.props.selectItem(item);
    }
  };

  private handleOutsideClick = ({ target }: MouseEvent) => {
    const { parentElement } = this.autocompleteRef.current || {};

    if (parentElement && !parentElement.contains(target as Node)) {
      document.removeEventListener("click", this.handleOutsideClick);
      this.setState({ display: false }, this.props.onHideAutocomplete);
    }
  };
}
