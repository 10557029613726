import { v4 as uuid } from 'uuid';

import { LogHub } from './LogHub';
import { LogLevel } from './LogLevel';
import { ICommonLogEntry, ILogEntry } from './ILogEntry';
import { LogConverter } from './LogConverter';

export class Logger {
  public static counter: number = 0;
  public readonly sessionId: string;
  private groupPool: string[] = [];

  constructor(private readonly hub: LogHub, private readonly domain: string, private readonly application: string) {
    this.sessionId = uuid();
  }

  public openGroup(name: string, level: LogLevel = LogLevel.info) {
    this.groupPool.push(name);

    this.createLogEntry(
      {
        message: `Started process with name: ${name}`,
      },
      level
    );
  }

  public wrapActionFunction(action: (...args: any) => any, actionCode: string) {
    return (...args: any) => {
      let result;

      this.appendAction(actionCode, { message: 'Event started' });
      try {
        result = action(...args);
      } catch (error) {
        this.appendWarning('Warning: Action was finished with error', { actionCode, error });
        throw error;
      }
      this.appendAction(actionCode, { message: 'Event finished' });

      return result;
    };
  }

  public appendError(
    message: string,
    error: Error,
    context?: any,
    entry: Exclude<Partial<ICommonLogEntry>, 'context' | 'message' | 'stacktrace'> = {}
  ) {
    this.createLogEntry(
      {
        ...entry,
        message,
        stacktrace: error.stack,
        context: {
          $__error: error,
          ...context,
        },
      },
      LogLevel.error
    );
  }

  public appendWarning(
    message: string,
    context?: any,
    entry: Exclude<Partial<ICommonLogEntry>, 'message' | 'context'> = {}
  ) {
    this.createLogEntry({ ...entry, message, context }, LogLevel.warning);
  }

  public appendAction(actionCode: string, entry: Exclude<Partial<ICommonLogEntry>, 'actionCode'> = {}) {
    this.createLogEntry({ ...entry, actionCode }, LogLevel.action);
  }

  public appendInfo(
    message: string,
    context?: any,
    entry: Exclude<Partial<ICommonLogEntry>, 'message' | 'context'> = {},
    forcePush: boolean = false
  ) {
    this.createLogEntry({ ...entry, message, context }, LogLevel.info, forcePush);
  }

  public closeGroup(level: LogLevel = LogLevel.info) {
    const groupName = this.groupPool.pop();

    if (groupName) {
      this.createLogEntry(
        {
          message: `Finished process with name: ${groupName}`,
        },
        level
      );
    }
  }

  public closeAllGroups(level: LogLevel = LogLevel.info) {
    this.groupPool.reverse().forEach((groupName) => {
      this.createLogEntry(
        {
          message: `Finished process with name: ${groupName}`,
        },
        level
      );
    });

    this.groupPool = [];
  }

  private createLogEntry(entry: Partial<ICommonLogEntry>, level: LogLevel, forcePush: boolean = false) {
    if (entry.context) {
      entry.context = LogConverter.convert(entry.context);
    }

    const log: ILogEntry = {
      userId: this.hub.userId,
      date: new Date(),
      domain: this.domain,
      application: this.application,
      sessionId: this.hub.sessionId,
      index: Logger.counter++,
      userAgent: navigator.userAgent,
      tabSessionId: this.hub.tabSessionId,
      browserSessionId: this.hub.browserSessionId,
      level,
      ...entry,
    };

    this.hub.registerEntry(this.wrapInProcessContext(log), forcePush);
  }

  private wrapInProcessContext(entry: ILogEntry) {
    if (this.groupPool.length > 0) {
      if (!entry.context) {
        entry.context = {};
      }

      entry.context = {
        ...entry.context,
        $__process_list: this.groupPool,
        $__process: this.groupPool[this.groupPool.length - 1],
        $__logId: this.sessionId,
      };
    }

    return entry;
  }
}
