export const HIDE = 'hide';

export const DEFAULT_COLUMN_RELATIVE_WIDTH = 1;

/**
 * Table header height in pixels.
 */
export const HEADER_HEIGHT = 70;

export const WIDTH_RANGE = Array.from(Array(30).keys());

export type WidthRange = typeof WIDTH_RANGE[number];
