import React, { FC } from "react";
import { Popup } from "../../../../Molecules/Popup/Popup";
import { AlertMessage } from "../../../../Molecules/AlertMessage/AlertMessage";
import { useTranslation } from "i18n";

interface IPaymentErrorModalProps {
  title: string;
  description: string;
  handleCloseModal: () => void;
}

const PaymentErrorModal: FC<IPaymentErrorModalProps> = ({
  title,
  description,
  handleCloseModal,
}) => {
  const { t } = useTranslation();
  return (
    <Popup width={{ lg: 6, md: 8 }} close={handleCloseModal} priority="high">
      <AlertMessage
        texts={{
          title,
          description,
        }}
        buttons={[
          {
            name: t("common:button.label.close"),
            type: "primary",
            click: handleCloseModal,
          },
        ]}
      />
    </Popup>
  );
};

export default PaymentErrorModal;
