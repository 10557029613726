import React from "react";

import { AudioControl } from "../AudioControl";
import { formatTime } from "../../../../../generics/time-formatting";
import LoadingIndicator from "../../../../Atoms/LoadingIndicator/LoadingIndicator";

import "./AudioControls.scss";

export interface IAudioControlsProps {
  isPlayed: boolean;
  isMuted: boolean;
  duration: number;
  currentTime: number;
  onPlay: () => Promise<void>;
  onNext?: () => void;
  onPause: () => Promise<void>;
  onVolumeUp: () => void;
  onVolumeDown: () => void;
  onMuteToggle: () => void;
  isLoading: boolean;
}

export const AudioControls: React.FC<IAudioControlsProps> = (props) => {
  const {
    duration,
    currentTime,
    onPlay,
    isPlayed,
    onNext,
    onPause,
    onVolumeUp,
    onVolumeDown,
    onMuteToggle,
    isMuted,
    isLoading,
  } = props;

  const playToggler = isPlayed ? (
    <AudioControl
      onClick={onPause}
      icon="controlsPause"
      ariaLabel="Pause audio"
    />
  ) : (
    <AudioControl onClick={onPlay} icon="controlsPlay" ariaLabel="Play audio" />
  );

  const muteIcon = isMuted ? "controlsMute" : "controlsMuteoff";

  return (
    <div className="c-audio-controls">
      {playToggler}
      {onNext && (
        <AudioControl
          onClick={onNext}
          icon="controlsSkip"
          ariaLabel="Skip audio"
        />
      )}
      <span className="c-audio-controls__time">
        {formatTime(currentTime, ".")}/{formatTime(duration, ".")}
        {isLoading && (
          <span className="c-audio-controls__loading">
            <LoadingIndicator variant="secondary" />
          </span>
        )}
      </span>
      <AudioControl
        onClick={onMuteToggle}
        icon={muteIcon}
        ariaLabel="Toggle mute"
      />
      <AudioControl
        onClick={onVolumeDown}
        icon="controlsvolDown"
        ariaLabel="Volume down"
      />
      <AudioControl
        onClick={onVolumeUp}
        icon="controlsvolUp"
        ariaLabel="Volume up"
      />
    </div>
  );
};
