import React from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
} from "../../../generics/body-scroll-lock";
import {
  addClass,
  removeClass,
  isIOSDevice,
  isSmallDeviceWidth,
  isMediumDeviceWidth,
} from "../../../generics/dom-extensions";

// The `devices` prop decides what classes the body gets:
//
// | `props.devices`     | Resulting class                        |
// | ------------------- | -------------------------------------- |
// | `'all'`             | `g-no-scroll`                          |
// | `['small']`         | `g-no-scroll-small`                    |
// | `['medium']`        | `g-no-scroll-medium`                   |
// | `['large']`         | `g-no-scroll-large`                    |
// | `['small, medium']` | `g-no-scroll-small g-no-scroll-medium` |

type Devices = "small" | "medium" | "large";
const ALL_DEVICES: Devices[] = ["small", "medium", "large"];
const NO_SCROLL_CLASS = "g-no-scroll";

const IS_IOS_DEVICE = isIOSDevice();

const isSmallIOSDevice = () => IS_IOS_DEVICE && isSmallDeviceWidth();
const isMediumIOSDevice = () => IS_IOS_DEVICE && isMediumDeviceWidth();

export interface IRemoveScrollProps {
  /**
   * The device widths on which to remove the scrollbars. Can be the string 'all' or an array of any of 'small', 'medium' and 'large'
   */
  devices: Devices[] | "all";
  /** z-index value to prevent z-index collision */
  zIndex: number;
}

export class RemoveScroll extends React.Component<IRemoveScrollProps> {
  public static defaultProps = {
    devices: "all",
    zIndex: 1,
  };
  private iosRemoveScrollHelperRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  public render() {
    return (
      <>
        {IS_IOS_DEVICE ? (
          <div
            ref={this.iosRemoveScrollHelperRef}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: this.props.zIndex,
            }}
          >
            {this.props.children}
          </div>
        ) : (
          this.props.children
        )}
      </>
    );
  }

  public componentDidMount() {
    this.addScrollbarModifiers(document.body, this.props.devices);
  }

  public componentDidUpdate(oldProps: IRemoveScrollProps) {
    if (this.props.devices !== oldProps.devices) {
      this.removeScrollbarModifiers(document.body);
    }
    this.addScrollbarModifiers(document.body, this.props.devices);
  }

  public componentWillUnmount() {
    this.removeScrollbarModifiers(document.body);
  }

  private addScrollbarModifiers = (
    element: HTMLElement,
    devices: IRemoveScrollProps["devices"]
  ) => {
    if (devices === "all") {
      addClass(element, NO_SCROLL_CLASS);
    } else {
      (devices as Devices[]).forEach((device) => {
        addClass(element, `${NO_SCROLL_CLASS}-${device}`);

        if (device === "small" && isSmallIOSDevice()) {
          disableBodyScroll(this.iosRemoveScrollHelperRef.current);
        } else if (device === "medium" && isMediumIOSDevice()) {
          disableBodyScroll(this.iosRemoveScrollHelperRef.current);
        }
      });
    }
  };

  private removeScrollbarModifiers = (element: HTMLElement) => {
    removeClass(element, NO_SCROLL_CLASS);
    ALL_DEVICES.forEach((device) => {
      removeClass(element, `${NO_SCROLL_CLASS}-${device}`);
    });
    if (IS_IOS_DEVICE) {
      enableBodyScroll(this.iosRemoveScrollHelperRef.current);
    }
  };
}
